
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DialogAgregarTercero } from '../../tercero/dialogAgregarTercero/dialogAgregarTercero.component';
import { DialogAgregarVehiculo } from '../dialogAgregarVehiculo/dialogAgregarVehiculo.component';
import { DialogAgregarTerceroVh } from '../dialogAgregarTerceroVh/dialogAgregarTerceroVh.component';
import { DialogAgregarPropietario } from '../dialogAgregarPropietario/dialogAgregarPropietario.component';
import { DialogAgregarTerceroTenedor } from '../dialogAgregarTerceroTenedor/dialogAgregarTerceroTenedor.component';


export interface DialogDataVh {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string;
}

@Component({
  selector: 'app-DialogAgregarTenedor',
  templateUrl: './DialogAgregarTenedor.component.html',
  styleUrls: ['./DialogAgregarTenedor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarTenedor implements OnInit {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  ismenudialog=false
  
  constructor(public dialogRef: MatDialogRef<DialogAgregarTenedor>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogagregar: MatDialog,public dialogagregarVh: MatDialog) {}
     @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
     displayedColumns: string[] = ['imgEstado','nombres','apellidos','tipoDoc','identificacion','estado'];
     dataSource = new MatTableDataSource();

  ngOnInit(): void {

 this.descargarMarcas()
 this.descargarConfiguracion()
 this.descargarEstados()
 this.descargarModelos()
 this.descargarClase()
 this.descargarTerceros()
 this.descargarConTercero()

 
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }

  onNuevo(): void {

    this.dialogRef.close();
    this.dialogagregar.open(DialogAgregarTerceroTenedor, {
      width: '950px',
      maxHeight:'700px',
      data: {id: this.codigo,   
          idTercero: this.data.idTercero,
        idPropietario:this. data.idPropietario,
        nombrePropietario: this.data.nombrePropietario,
        apellidosPropietario:this.data.apellidosPropietario,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio")},
    
    });
  
  }

  atras(): void {

    this.dialogRef.close();
    this.dialogagregar.open(DialogAgregarPropietario, {
      width: '850px',
      maxHeight:'700px',
      data: {id: this.codigo,
        idTercero: this.data.idTercero,
        idPropietario:this. data.idPropietario,
        nombrePropietario: this.data.nombrePropietario,
        apelliqdosPropietario:this.data.apellidosPropietario,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
      },
    
      });
  
  }

 

  crearTipoDocumento(data: any) {
   
    this.dialogRef.close();
   
    this.dialogagregarVh.open(DialogAgregarVehiculo, {
      width: '950px',
      maxHeight:'700px',
      data: {idTerceroTenedor: data.id,
        idTenedor: data.identificacion,
        nombreTenedor: data.nombres,
        apellidosTenedor: data.apellidos,
        idTercero: this.data.idTercero,
        idPropietario:this. data.idPropietario,
        nombrePropietario: this.data.nombrePropietario,
        apellidosPropietario:this.data.apellidosPropietario},

    });
  

  }

  descargarEstados() {

    this.configuracionesService.getEstado().subscribe(configuraciones => {
  

      this.estados=configuraciones
    
  
    
    });
  }

  descargarMarcas() {

    this.configuracionesService.getTiposMarcas().subscribe(configuraciones => {
  

      this.marcas=configuraciones
    
  
    
    });
  }


  descargarClase() {

    this.configuracionesService.getClasesVehiculos().subscribe(configuraciones => {
  

      this.clases=configuraciones
    
  
    
    });
  }


  descargarConfiguracion() {

    this.configuracionesService.getconfiguracionesVh().subscribe(configuraciones => {
  

      this.confisvh=configuraciones
    
  
    
    });
  }

  descargarTerceros() {

    this.configuracionesService.getTerceros().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }
  sestado(estado: any) {

    this.estadofm=estado

    if(estado=="Activo"){
         this.imgEstadofm="/assets/activo.png"
    }

    if(estado=="Bloqueado"){
      this.imgEstadofm="/assets/bloqueado.png"
    }

    if(estado=="Novedad"){
      this.imgEstadofm="/assets/novedad.png"
    }

  if(estado=="Inactivo")
      {
    this.imgEstadofm="/assets/inactivo.png"
   }

  }

  

  descargarModelos() {

    this.configuracionesService.getModelos().subscribe(configuraciones => {
  
      this.modelos=configuraciones
      console.log('modelos',configuraciones);
    });
  }

  smodelo(modelo: any) {

    this.modelofm=modelo
   
  }

  smarca(marca: any) {

    this.marcafm=marca

  }

  sconfi(confi: any) {

    this.confifm=confi

  }

  sclase(clase: any) {

    this.clasefm=clase

  }


  descargarConTercero() {

    this.configuracionesService.getConTerceros().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      }  


    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
