import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarTipoDoc } from '../dialogAgregarTipoDoc/dialogAgregarTipoDoc.component';
import { DialogEliminarTipoDoc } from '../dialogEliminarTipoDoc/dialogEliminarTipoDoc.component';
import { DialogEditarTipoDoc } from '../dialogEditarTipoDoc/dialogEditarTipoDoc.component';
export interface DialogData {
  idTipoDoc: string;
  tipo: string;
  descripcion: string;
  tipoanterior: string;
  descripcionanterior: string;
}
@Component({
  selector: 'app-FragmentTipoDoc',
  templateUrl: './FragmentTipoDoc.component.html',
  styleUrls: ['./FragmentTipoDoc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentTipoDoc implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

   idTipoDoc: string;
   tipo: string;
   descripcion: string;
   tipoanterior: string;
   descripcionanterior: string;

   displayedColumns: string[] = ['idTipoDoc', 'tipo','descripcion','editar','eliminar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getTiposDocumentos().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getConTipoDoc ().subscribe(tipoDocus => {

      for (let tipo of tipoDocus) {

           this.codigo=tipo.contipodoc
      
      
      }  

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {

    this.dialog.open(DialogEditarTipoDoc, {
      width: '350px',
      data: {idTipoDoc: tipo.idTipoDoc,
        tipo:tipo.tipo,
        descripcion:tipo.descripcion,
        tipoanterior:tipo.tipo,
        descripcionanterior:tipo.descripcion},
    });
   

  }

  nuevoBanco():void {

    this.dialogagregar.open(DialogAgregarTipoDoc, {
      width: '350px',
      data: {idTipoDoc: this.codigo,tipo:'',descripcion:''},
    
    });
     
  
    }

  deletebanco(tipo: any) {

    this.dialogEliminar.open(DialogEliminarTipoDoc, {
      width: '350px',
      data: {idTipoDoc: tipo.idTipoDoc,tipo:tipo.tipo,descripcion:tipo.descripcion},
    });
  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaTipoDoc']);
    
  }
  
}
