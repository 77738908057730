
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
export interface DialogDataVh {
  tipo: string;
  id: string;
  pregunta: string;
  tipoOrigen:string;
 
}

@Component({
  selector: 'app-DialogAgregarEncuestaPdf',
  templateUrl: './DialogAgregarEncuestaPdf.component.html',
  styleUrls: ['./DialogAgregarEncuestaPdf.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarEncuestaPdf implements OnInit {
  tipo: string;
  id: string;
  pregunta: string;
 
  
  public tituloPregunta;
  public tipoDato;
  public tipoDetalle;
  public completed = false;
  file: File;
  uploadPercent: Observable<number>;
  constructor(public dialogRef: MatDialogRef<DialogAgregarEncuestaPdf>, private storage: AngularFireStorage,
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


     onFileSelect(event) {
      if (event.target.files.length > 0) {
        this.file = event.target.files[0];
      }
    }
    uploadFile() {
      this.completed = false;
    
      if (!this.file) {
        console.error("No hay archivo seleccionado.");
        return;
      }
    
      if (!(this.file instanceof File)) {
        console.error("El objeto seleccionado no es un archivo válido.");
        return;
      }
    
      console.error("intento");
      const filePath = this.file.name;
      console.error(this.file);
    
      try {
        // Verificar el tipo y el tamaño del archivo si es necesario
        if (this.file.size === 0) {
          console.error("El archivo está vacío.");
          return;
        }
    
        this.storage.upload(filePath, this.file)
          .then(() => {
            this.completed = true;
            console.log("Archivo cargado exitosamente.");
          })
          .catch(error => {
            console.error("Error durante la carga:", error);
            if (error.name === "NotReadableError" || error.code === 4) {
              console.error("Error al leer el archivo durante la carga:", error);
            } else {
              console.error("Error inesperado durante la carga:", error);
            }
          });
    
      } catch (error) {
        console.error("muere");
        console.error("Detalles del error:", error);
        if (error.name === "NotReadableError" || error.code === 4) {
          console.error("Error al leer el archivo:", error);
        } else {
          console.error("Error inesperado:", error);
        }
      }
    }
    
    

  ngOnInit(): void {

    if (this.data.tipo == "1"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo fecha!"
      this.tipoDato = "FE"
      this.tipoDetalle = "Fecha"
    }

    if (this.data.tipo == "2"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo foto!"
      this.tipoDato= "IM"
      this.tipoDetalle= "Foto"
    }
    if (this.data.tipo == "3"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo texto largo!"
      this.tipoDato= "TL"
      this.tipoDetalle= "Texto largo"
    }

    if (this.data.tipo == "4"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo texto corto!"
      this.tipoDato= "TC"
      this.tipoDetalle= "Texto corto"
    }

    if (this.data.tipo == "5"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo selección única!"
      this.tipoDato= "SU"
      this.tipoDetalle= "Selección única"
    }

    if (this.data.tipo == "6"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo selección múltiple!"
      this.tipoDato= "SM"
      this.tipoDetalle= "Selección Múltiple"
    }

    if (this.data.tipo == "7"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo número!"
      this.tipoDato= "NU"
      this.tipoDetalle= "Número"
    }

    if (this.data.tipo == "8"){
      this.tituloPregunta = "¡Ingrese la pregunta con respuesta tipo firma!"
      this.tipoDato= "FM"
      this.tipoDetalle= "Firma"
    }

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearDetalleEncunesta(data: any) {

   this.uploadFile()

      if(data.pregunta) {

          
    var conop = {
      con: data.id+1

    }

    

    var tipoDoc = {
          id: data.id,
          etiqueta: data.pregunta,
          orden:1,
          metadato: this.tipoDato,
          fuente:"",
          title:this.tipoDetalle,
          obligatoria: true,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
          tipoDetalle:this.tipoDetalle,
          tipoOrigen:this.data.tipoOrigen
        }
  
      
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id: data.id,
          pregunta: data.pregunta,
          tipo: data.tipo,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
      
       
        }
    
       // this.configuracionesService.crearAuditriaTercero(auditria)
        this.configuracionesService.crearDetalleEncuenstas(tipoDoc)
        this.toastr.successToastr("Pregunta:  creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');
      } 

   


  }

}
