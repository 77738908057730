import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class TareasTemporalService {
  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private ordersCollection: AngularFirestoreCollection<any>;
  orders: Observable<any[]>;

  private numeroCollection: AngularFirestoreCollection<any>;
  numero: Observable<any[]>;


  private productosTransitocollection: AngularFirestoreCollection<any>;
  transito: Observable<any[]>;

  private planillasCollection: AngularFirestoreCollection<any>;
  planillas: Observable<any[]>;

  private detalleOperacionCollection: AngularFirestoreCollection<any>;
  detalleoperacion: Observable<any[]>;

  private recaudoCollection: AngularFirestoreCollection<any>;
  recaudo: Observable<any[]>;

  private crearOrdenCollection: AngularFirestoreCollection<any>;
  crearorden: Observable<any[]>;

  private paisesCollection: AngularFirestoreCollection<any>;
  paises: Observable<any[]>;

  private userwebCollection: AngularFirestoreCollection<any>;
  userweb: Observable<any[]>;

  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");
  ordenTransporteFecha = localStorage.getItem("ordenTransporteFecha");
  constructor(private readonly afs: AngularFirestore, private userd: AuthService) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const cValue= localStorage.getItem("pedidoFecha");
    this.ordersCollection = afs.collection<any>('SLC_Tareas/registros/tareas');
    this.numeroCollection = afs.collection<any>('SCL_Operacion/con/seguimiento/');
    this.crearOrdenCollection = afs.collection<any>('SCL_OrdenTransporte/orden/ordentransporte/');
   this.planillasCollection=afs.collection<any>('SCL_Planillas/planilla/planillastransporte/')
   this.productosTransitocollection=afs.collection<any>('SCL_ProductosTransito/productos/registros/')
   this.paisesCollection=afs.collection<any>('SCL_Paises/paises/paises/')
   this.recaudoCollection=afs.collection<any>('SCL_RecibosCajas/recibos/registros/')
  
  
  }

  createTareaTemporal(order: any) {
    
    return this.ordersCollection.doc('E' + order.NumeroPed).set(order);
    
  }

  createPaises(pais: any) {
    
    return this.paisesCollection.doc('P' + pais.short_name).set(pais);
    
  }

  createProductosTransito(order: any) {
    
    return this.productosTransitocollection.doc('R' + order.idRegistro).set(order);
    
  }

  createPlanillas(planilla: any) {
    
    return this.planillasCollection.doc('P' + planilla.planilla).set(planilla);
    
  }

  createupdate(planilla: any) {
    
    return this.planillasCollection.doc('P' + planilla.planilla).update(planilla);
    
  }

  getuserweb(documentId: string) {
    this.userwebCollection = this.afs.collection<any>('userWeb', ref => ref.where('correo', '==', documentId));
    this.userweb = this.userwebCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    var data;
    this.userweb.subscribe(res => {
      data = res;

      for (let elemento of data) {

        localStorage.setItem("negocio", elemento.negocio);
        localStorage.setItem("agencia", elemento.agencia);

      }
    });

    return this.userweb

  }

  getTareas(NumeroTarea: string,codigoOperacion: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.ordersCollection = this.afs.collection<any>('SLC_Tareas/registros/tareas', ref => ref
    .where('NumeroTarea', '==', NumeroTarea)
    .where('codigoOperacion', '==', codigoOperacion));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getPlanillas() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.ordenTransporteFecha = localStorage.getItem("ordenTransporteFecha");

    this.planillasCollection = this.afs.collection<any>('SCL_Planillas/planilla/planillastransporte', ref => ref
    .where('fechaoperacion', '==',   this.ordenTransporteFecha).where('negocio', '==',    this.negocio).where('agencia', '==',   this.agencia));
    this.planillas = this.planillasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.planillas;


  }

  getProductosPlanilla(codigoPlanilla: string) {

    this.productosTransitocollection = this.afs.collection<any>('SCL_ProductosTransito/productos/registros/', ref => ref .where('codigoPlanilla', '==', codigoPlanilla));
    this.transito = this.productosTransitocollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.transito;


  }

  getRecaudoPlanilla(codigoPlanilla: string) {

    this.recaudoCollection = this.afs.collection<any>('SLC_RecibosCajas/recibos/registros/', ref => ref .where('planilla', '==', codigoPlanilla));
    this.recaudo = this.recaudoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.recaudo;


  }
  getDetalle(codigoOperacion: string) {


    this.detalleOperacionCollection = this.afs.collection<any>('SLC_detalle/detalletarea/'+codigoOperacion, ref => ref);
    this.detalleoperacion = this.detalleOperacionCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.detalleoperacion;


  }


  getConsecutivo() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");


    this.numero = this.numeroCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.numero;

  }
  numeroRegistro(order: any) {
    
    return this.ordersCollection.doc(order.codigoRegistro).set(order);
  }

  detalleRegistro(detalle: any) {
    this.detalleOperacionCollection = this.afs.collection<any>('SLC_detalle/detalletarea/'+detalle.codigoOperacion);

    return this.detalleOperacionCollection.doc("R"+detalle.registro).set(detalle);

  }

  incrementarCodigo(operacion: any) {
    return this.numeroCollection.doc("rango").set(operacion);
   
   }


  crearOrdenTransporte(orden: any) {
    return this.crearOrdenCollection.doc(orden.codigodeorden).set(orden);
   
   }
}