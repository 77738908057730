import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../../shared/orders.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Options } from "highcharts";
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { ClientesService } from '../../clientes.service';
import { PedidoService } from '../../pedidos.service';
@Component({
  selector: 'app-widget-reloj',
  templateUrl: './reloj.component.html',
  styleUrls: ['./reloj.component.scss']

})
export class RelojComponent implements OnInit {
  subscription: Subscription;
  @Input() data: any = [];
  updateFlag = false;
  private rutasDescarga: any;
  myConfiges: any
  enviados=0
  operacion=0
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0
  constructor(private ClientesService: ClientesService,private pedidoService: PedidoService,private indicadoresService: OrdersService) { }


  ngOnInit(): void {

  
    this.descargarIndicadores()


  }

  descargarIndicadores() {
    this.subscription = this.indicadoresService.getTareasdia().subscribe(tareasindicadores => {
   
      console.log("tareasindicadores",tareasindicadores);
      this.estadoPendiente=0
      this.estadoExitosa=0
      this.estadoNovedad=0
      this.estadoRepisar=0
      this.estadoParcial=0
      for (let tarea of tareasindicadores) {

        if(tarea.estado=="PENDIENTE"){

          this.estadoPendiente=this.estadoPendiente+(tarea.CostoMerc * 1)
      
         }

         if(tarea.estado=="EXITOSA"){

          this.estadoExitosa=this.estadoExitosa+(tarea.CostoMerc * 1)
         
         }


         if(tarea.estado=="NOVEDAD"){

          this.estadoNovedad=this.estadoNovedad+(tarea.CostoMerc * 1)
      
         }


         if(tarea.estado=="REPISAR"){

          this.estadoNovedad=this.estadoNovedad+(tarea.CostoMerc * 1)
      
         }

         if(tarea.estado=="PARCIAL"){

          this.estadoNovedad=this.estadoNovedad+(tarea.CostoMerc * 1)
      
         }

      }

      console.log("    this.estadoExitosa",    this.estadoExitosa);

      console.log(" this.estadoPendiente", this.estadoPendiente);
      console.log(" this.estadoParcial", this.estadoParcial);
      this.myConfiges = {
        type: "ring",
        plot: {
          borderColor: "#2B313B",
          borderWidth: 0.8,
          slice: "50%",
        
          tooltip: {
            fontSize: '18',
            fontFamily: "Open Sans",
            padding: "5 10",
            text: "%npv%"
          },
          animation: {
            effect: 2,
            method: 5,
            speed: 900,
            sequence: 1,
            delay: 3000
          }
        },
        source: {
          text: '',
          fontColor: "#8e99a9",
          fontFamily: "Open Sans"
        },
       
        plotarea: {
          margin: "0 0 0 0"
        },
        series: [{
            values: [this.estadoPendiente],
            text: "Pendiente",
            backgroundColor: '#ee630d',
          },
          {
            values: [this.estadoExitosa],
            text: 'Exitosa',
            backgroundColor: '#468189',
            detached: true
          },
          {
            text: 'Novedad',
            values: [this.estadoNovedad],
            backgroundColor: '#FFA060'
          }
        ]
      };

    });
  }

  descargar() {
    var numero=0

    numero=parseInt(localStorage.getItem('diaConsulta'))
      this.subscription = this.ClientesService.getClientesDia(numero).subscribe(rutas => {
      this.rutasDescarga = this.rutasDescarga = rutas;

      this.getTareas();
    
    });


  }

  getVista(datosCliente:any){

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(datosCliente, 'idempleado')

    let rutasClientes = []
    for (let item of Object.keys(rutasdia)) {
      var rutasODL = rutasdia[item];

  
      let tareasNovedadeS = rutasODL.filter(tarea => tarea.name == "Novedad")
      let tareasPedidos = rutasODL.filter(tarea => tarea.name == "Pedido")
      let Clientes = this.rutasDescarga.filter(cli => cli.idempleado == rutasODL[0].idempleado)

      var ruta = {
        ruta: rutasODL[0].idempleado,
        name: rutasODL[0].idempleado,
        y:Clientes.length,
        x:Clientes.length,
        novedades:tareasNovedadeS.length,
        pedidos:tareasPedidos.length,
        pendientes:Clientes.length-tareasNovedadeS.length-tareasPedidos.length,
        txPedidosPorMC: Math.round(((tareasPedidos.length * 100) / Clientes.length)) + "%",
        txtPendientesPorMC: Math.round((((Clientes.length-tareasPedidos.length)  * 100) / Clientes.length)) + "%",
        txEfePorMC: Math.round((((tareasPedidos.length+tareasNovedadeS.length) * 100) / Clientes.length)) + "%",
        txEfePenPorMC: Math.round((((Clientes.length-(tareasPedidos.length+tareasNovedadeS.length))  * 100) / Clientes.length)) + "%",
   

      };

      rutasClientes.push(ruta)
      //this.ClientesService.createRutasOdl(ruta)
    
    }

    let rutasclientesP = groupBy(this.rutasDescarga, 'idempleado')

    for (let item of Object.keys(rutasclientesP)) {
      var rutasODL = rutasclientesP[item];
      let Clientes =rutasClientes.filter(cli => cli.name == rutasODL[0].idempleado)

   
      let ClientesDescarga = this.rutasDescarga.filter(cli => cli.idempleado == rutasODL[0].idempleado)
   
      if(Clientes.length>0){


      }else{

        var rutape = {
          ruta: rutasODL[0].idempleado,
          name: rutasODL[0].idempleado,
          y:ClientesDescarga.length,
          x:ClientesDescarga.length,
          novedades:0,
          pedidos:0,
          pendientes:ClientesDescarga.length,
          txPedidosPorMC: Math.round(((0 * 100) / ClientesDescarga.length)) + "%",
          txtPendientesPorMC: Math.round((((ClientesDescarga.length-0)  * 100) / ClientesDescarga.length)) + "%",
          txEfePorMC: Math.round((((0) * 100) / ClientesDescarga.length)) + "%",
          txEfePenPorMC: Math.round((((ClientesDescarga.length-(0))  * 100) / ClientesDescarga.length)) + "%",
     
  
        };
  
        rutasClientes.push(rutape)

      }

   
    }

    this.getAllrecorridos(rutasClientes)


  }

  getTareas() {

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(this.rutasDescarga, 'idempleado')

    let vistasClientes = []


   
    this.subscription = this.pedidoService.getPedidos().subscribe(visitas => {
   
      for (let visita of visitas){

        if(visita.fnt==20){

       
          var vistacliente = {
  
            idEmpresa: visita.idEmp,
            cliente: visita.razon,
            total: visita.total,
            tipo: visita.fnt,
            obs: visita.obs,
            idempleado:visita.usr,
            name:"Novedad"
          };

          vistasClientes.push(vistacliente)
    
         // this.ClientesService.createVistasOdl(vistacliente)
   
     
        }

        if(visita.fnt==10){
          
     
          var vistacliente = {
  
            idEmpresa: visita.idEmp,
            cliente: visita.razon,
            total: visita.total,
            tipo: visita.fnt,
            obs: visita.obs,
            idempleado:visita.usr,
            name:"Pedido",
  
  
          };
    
          vistasClientes.push(vistacliente)
         // this.ClientesService.createVistasOdl(vistacliente)
         
        }


       }

       this.subscription.unsubscribe();
      this.getVista(vistasClientes)
  

    });


  }

  getAllrecorridos(vistas: any) {

    let categarias = []
    let exitosas = []
    let novedades = []
    let pendientes = []
    this.enviados=0
    this.operacion=0
    for (let ruta of  vistas) {

      categarias.push(ruta.name)
      exitosas.push(ruta.pedidos)
      novedades.push(ruta.novedades)
      pendientes.push(ruta.pendientes)

      this.enviados=this.enviados+ruta.pedidos+ruta.novedades
      this.operacion=this.operacion+ruta.pedidos+ruta.novedades+ruta.pendientes
 
    }



     


}

}