import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
export interface DialogData {
  idTipoDoc: string;
  tipo: string;
  descripcion: string;
}

@Component({
  selector: 'app-DialogEliminarEncuestaDetalle',
  templateUrl: './DialogEliminarEncuestaDetalle.component.html',
  styleUrls: ['./DialogEliminarEncuestaDetalle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEliminarEncuestaDetalle implements OnInit {
  idTipoDoc: string;
  tipo: string;
  descripcion: string;
  constructor(public dialogRef: MatDialogRef<DialogEliminarEncuestaDetalle>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
   
    this.dialogRef.close();
  
  }
 
  deleteTipoDoc(data: any) {
  
    this.configuracionesService.deleteEncuentasDetalle(data)
    this.toastr.successToastr("Documento: "+data.title+ " eliminado con éxito", 'Notificación');
    this.dialogRef.close();


  }
  
}
