
import { DashboardService } from '../dashboard.service';
import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ArticulosService } from '../../shared/articulos.service';
import { AuthService } from '../../shared/services/auth.service';
import { element } from 'protractor';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable'
import { PdfMakeWrapper } from 'pdfmake-wrapper'
import * as firebase from 'firebase';
import { MatPaginatorModule } from '@angular/material';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';


declare var require: any
const FileSaver = require('file-saver');
declare var jsPDF: any;
var jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({

  selector: 'app-fragmentArticulos',
  templateUrl: './fragmentArticulos.component.html',
  styleUrls: ['./fragmentArticulos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentArticulos implements OnInit {

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;

  displayedColumns: string[] = ['IdArt', 'CodArt', 'Art', 'IVA', 'peso', 'unidades'];
  dataSource = new MatTableDataSource();

  public negocio: any;
  public agencia: any;
  public usuario: any;

  private subscription: Subscription;

  Remove(args): void {
    args.cancel = true;
  }

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };


  constructor(private articulosService: ArticulosService, private userd: AuthService, public toastr: ToastrManager) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {

      if (document.hidden) {

        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {

        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });

  }
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngOnInit(): void {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.usuario = localStorage.getItem("correo");

    this.dialog = new Dialog({
      header: 'ERROR VALIDAR FORMATO',
      showCloseIcon: true,
      visible: false,
      target: document.getElementById("container"),
      width: '500px',
      height: '1000px'

    });


    this.dialog.appendTo('#dialog');

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      this.descargar();
      setInterval(() => {

        this.descargar();

      }, 60000);

    } else {


      this.subscription.unsubscribe();


    }

  }


  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log(json_object)


        let data = JSON.parse(json_object);
        console.log("data", data)


        for (let articulo of data) {

          this.articulosService.createArticulo(articulo);


        }

        this.descargar()

        this.uploadObj.clearAll();

      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  public onSuccess(args: any): void {
    var files = args.target.files;
    this.parseExcel(files[0]);

    console.log("archivo", files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  exportArticulos(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscription = this.articulosService.getArticulos().subscribe(articulos => {

      this.articulosService.exportAsExcelFile(articulos, 'ArticulosMaestros');

      this.subscription.unsubscribe();

    });
    
  }


  descargar() {
    this.subscription = this.articulosService.getArticulos().subscribe(articulos => {

      this.dataSource.data = articulos;
      this.subscription.unsubscribe();

    });
  }

}
