import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-widget-pieventa',
  templateUrl: './pieventa.component.html',
  styleUrls: ['./pieventa.component.scss']

})
export class PieventaComponent implements OnInit {

 
  chartOptionsdos = {};

  @Input() data: any = [];
  Highcharts = Highcharts;
 
  constructor(private orderService: OrdersService,private userd: AuthService) { }


  ngOnInit() {
 
    this.getAllrecorridos()



}


getAllrecorridos() {


this.orderService.getTiposNovedades().subscribe(res => {

this.data= res;

HC_exporting(Highcharts);
setTimeout(() => {
  window.dispatchEvent(
    new Event('resize')

  );
}, 300);


this.chartOptionsdos = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    exporting: {
      enabled: true
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Brands',
      colorByPoint: true,
      data: this.data
    }]
  };


});


this.chartOptionsdos = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
    title: {
      text: ''
    },
    tooltip: {
      pointFormat: '<b>{{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %'
        }
      }
    },
    exporting: {
      enabled: true
    },
    credits: {
      enabled: false
    },
    series: [{
      name: 'Brands',
      colorByPoint: true,
      data: this.data
    }]
  };



}

}