import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { UserI } from '../../../modules/user.interface'
import { OrdersService } from '../../../shared/orders.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logininicio',
  templateUrl: './logininicio.component.html',
  styleUrls: ['./logininicio.component.scss']
})
export class LogininicioComponent implements OnInit {

  constructor(private authSvc: AuthService, private route: Router,private orderService: OrdersService,public toastr: ToastrManager ) {}

  
  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });


  ngOnInit() {

    this.authSvc
   // this.authSvc.logout();
    
  }

  logout() {

    this.authSvc.logout();
   
}

  onLogin(form: UserI) {


    this.authSvc.logout();
    
    this.authSvc
      .loginByEmail(form)
      .then(res =>{
       
        localStorage.setItem("correo",  res.user.email);
        localStorage.setItem("login",  'pendiente');
        var data ;


        this.orderService.getuserweb(res.user.email).subscribe(res => {
           data = res;

           for (let elemento of data) {
            localStorage.setItem("negocio",  elemento.negocio);
            localStorage.setItem("agencia",  elemento.agencia);
            localStorage.setItem("perfil",  elemento.perfil);
            localStorage.setItem("departamento",  elemento.departamento);
            localStorage.setItem("responsable",  elemento.responsable);

            localStorage.setItem("login", 'exitoso');
            localStorage.setItem("cargar", 'pendiente');
            localStorage.setItem("rutaindicador", "si");
            if(elemento.perfil=="preventa"){

              this.route.navigate(['/Pedidos']);

            } else{
              this.route.navigate(['/OrdenTransporte']);
              setInterval(() => {

                location.reload()
              
              }, 500);
    

            } 
          

           this.toastr.successToastr('Ingreso exitoso.', 'Notificación');
            
           } 

        });
      
      } )
      .catch(res =>(
        this.toastr.errorToastr('Usuario o contraseña no validos.', '!Oops, algo salió mal!')
        ))
  
      
  }


}
