import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { timer, combineLatest } from 'rxjs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class PedidoService {

  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }
  private mesconsulta;
  private pedidoscollection: AngularFirestoreCollection<any>;
  private pedidos: Observable<any[]>;

  private pedidosDetallecollection: AngularFirestoreCollection<any>;
  private pedidosDetalle: Observable<any[]>;

  constructor(private readonly db: AngularFirestore, private userd: AuthService) {


  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + ''+ EXCEL_EXTENSION);
  }


  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");


   //*  consultar los clientes del maestro //
  getPedidos() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.pedidoscollection = this.db.collection<any>('pedidosOdlAprobacion/'+this.negocio+'_'+this.agencia+'/'+this.fecha, ref => ref.where('estado','==','Pendiente'));
    this.pedidos = this.pedidoscollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidos;


  }

     //*  consultar los clientes del maestro //
     getPedidosIemp(idcliente) {

      this.negocio = localStorage.getItem("negocio");
      this.agencia = localStorage.getItem("agencia");
      this.fecha = localStorage.getItem("pedidoFecha");
      this.pedidoscollection = this.db.collection<any>('pedidosOdlAprobacion/'+this.negocio+'_'+this.agencia+'/'+this.fecha,ref => ref.where('idEmp','==',idcliente));
      this.pedidos = this.pedidoscollection.snapshotChanges().pipe(map(
        actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { ...data };
        })
      ));
  
      return this.pedidos;
  
  
    }

  getPedidosDetalle() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.pedidosDetallecollection = this.db.collection<any>('pedidosDetelleOdlAprobacion/'+this.negocio+'_'+this.agencia+'/'+this.fecha);
    this.pedidosDetalle = this.pedidosDetallecollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidosDetalle;


  }

  getPedidosDetalleRfv(rfv:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.pedidosDetallecollection = this.db.collection<any>('pedidosDetelleOdlAprobacion/'+this.negocio+'_'+this.agencia+'/'+this.fecha, ref => ref.where('rfv', '==', rfv));
    this.pedidosDetalle = this.pedidosDetallecollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidosDetalle;


  }

  getPedidosRfv(rfv:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.pedidosDetallecollection = this.db.collection<any>('pedidosOdlAprobacion/'+this.negocio+'_'+this.agencia+'/'+this.fecha, ref => ref.where('rfv', '==', rfv));
    this.pedidosDetalle = this.pedidosDetallecollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidosDetalle;


  }
  getClienteAprobacionRfv(rfv:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.pedidoscollection = this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('rfv', '==', rfv));
    this.pedidos = this.pedidoscollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidos;


  }

  createCliente(prefijo: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.pedidoscollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "prefijos");
   
    return this.pedidoscollection.doc().set(prefijo);

  }

  getmes() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-01", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-02", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-03", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-04", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-05", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-06", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-07", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-08", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-09", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-10", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-11", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-12", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-13", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-14", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-15", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-16", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-17", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-18", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-19", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-20", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-21", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-22", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-23", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-24", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-25", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-26", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-27", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-28", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-29", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-30", ref => ref.where('fnt', '==',10)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-31", ref => ref.where('fnt', '==',10)).valueChanges(),
    )

  }

  getmesNovedad() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-01", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-02", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-03", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-04", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-05", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-06", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-07", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-08", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-09", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-10", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-11", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-12", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-13", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-14", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-15", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-16", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-17", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-18", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-19", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-20", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-21", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-22", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-23", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-24", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-25", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-26", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-27", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-28", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-29", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-30", ref => ref.where('fnt', '==',20)).valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-31", ref => ref.where('fnt', '==',20)).valueChanges(),
   
      )

  }
  getmesNovedad1() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-01").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-02").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-03").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-04").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-05").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-06").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-07").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-08").valueChanges(),
   
      )

  }

  getmesNovedad2() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-09").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-10").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-11").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-12").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-13").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-14").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-15").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-16").valueChanges(),
   
      )

  }


  getmesNovedad3() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-17").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-18").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-19").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-20").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-21").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-22").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-23").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-24").valueChanges(),
   
      )

  }

  getmesNovedad4() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-25").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-26").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-27").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-28").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-29").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-30").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-31").valueChanges(),
      this.db.collection<any>('pedidosOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-31").valueChanges(),
   
      )

  }


  getmesDetalle() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc negocio', this.negocio);
    console.log('agenc agencia', this.agencia);
    console.log('mesconsulta agencia', this.mesconsulta);
   return combineLatest(
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-01").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-02").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-03").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-04").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-05").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-06").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-07").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-08").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-09").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-10").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-11").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-12").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-13").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-14").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-15").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-16").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-17").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-18").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-19").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-20").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-21").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-22").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-23").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-24").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-25").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-26").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-27").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-28").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-29").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-30").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2022-" + this.mesconsulta + "-31").valueChanges(),
    )

  }

  getmesDetalle1() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc negocio', this.negocio);
    console.log('agenc agencia', this.agencia);
    console.log('mesconsulta agencia', this.mesconsulta);
   return combineLatest(
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-01").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-02").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-03").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-04").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-05").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-06").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-07").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-08").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-09").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-10").valueChanges(),
    )

  }

  getmesDetalle2() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc negocio', this.negocio);
    console.log('agenc agencia', this.agencia);
    console.log('mesconsulta agencia', this.mesconsulta);
   return combineLatest(
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-11").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-12").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-13").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-14").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-15").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-16").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-17").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-18").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-19").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-20").valueChanges(),

    )

  }

  getmesDetalle3() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc negocio', this.negocio);
    console.log('agenc agencia', this.agencia);
    console.log('mesconsulta agencia', this.mesconsulta);
   return combineLatest(

      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-21").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-22").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-23").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-24").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-25").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-26").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-27").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-28").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-29").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-30").valueChanges(),
      this.db.collection<any>('pedidosDetelleOdlAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2021-" + this.mesconsulta + "-31").valueChanges(),
    )

  }
}


