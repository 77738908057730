
import { DashboardService } from '../dashboard.service';
import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { element } from 'protractor';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { ThemePalette } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';



@Component({
  selector: 'app-fragmentRutas',
  templateUrl: './fragmentRutas.component.html',
  styleUrls: ['./fragmentRutas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentRutas implements OnInit {



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;

  myData = [
    {
      y: 275,
      target: 250
    }, {
      y: 275,
      target: 250
    }
  ];
  private negocio: any;
  private agencia: any;
  private usuario: any;
  private txtTotalFac: any;
  private txtTotalFacRuta: any;
  private txtPesoKG: any;
  private txtPesoKGDe: any;
  private txtPedidos: any;
  private txtPedidosRuta: any;
  private txtDias: any;
  private txtRutas: any;
  private txtNovedades: any;
  private txtPedidosDev: any;
  private txtPedidosDevZona: any;
  private txtCanGrupo: any;
  private txtDevGrupo: any;
  private txtGrupoPedidos: any;
  private txtGrupoDev: any;
  private txttipoNovedad: any;
  private txtFecha: any;


  //novedades

  private txtCanCliente: any;
  private txtCanComercial: any;
  private txtCanPlanta: any;
  private txtCanTransporte: any;
  private txtCanTerceros: any;

  private txtCosCliente: any;
  private txtCosComercial: any;
  private txtCosPlanta: any;
  private txtCosTransporte: any;
  private txtCosTerceros: any;

  private totalParcial: any;
  private canParcial: any;

  private totalParcialExi: any;
  private canParcialExi: any;

  private totalExitoso: any;
  private canExitoso: any;

  private totalPendiente: any;
  private canPendiente: any;

  private totalNovedad: any;
  private canNovedad: any;

  private totalGeneral: any;
  private canGeneral: any;

  private datospruebas: any;
  private datatrasnportes: any;
  private dataruta: any;

  private tareasDescarga: any;
  private rutasDescarga: any;

  subscriptionTransporte: Subscription;
  subscriptionTareas: Subscription;
  private path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  color: ThemePalette = 'primary';
  value = 50;
  bufferValue = 75;

  constructor(private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager, private route: Router) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
    
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
    
        localStorage.setItem("vistaShow", 'SHOWN');
      }

    });
  }

  displayedColumnsRutaValor: string[] = ['name', 'CostoMercExi', 'CostoParMercExi', 'CostoParMercNov', 'CostoMercPen', 'CostoMercNov', 'txtTotalFac', 'porcentaje'];
  displayedColumnsRuta: string[] = ['name', 'CanExi', 'CanParExi', 'CanPen', 'CanNov', 'txtPedidos', 'porcentaje'];

  dataSourceRuta = new MatTableDataSource();


  ngOnInit(): void {

  
    if (localStorage.getItem('rutaindicador') == 'si') {

      localStorage.setItem("rutaindicador", "no");

      
      
  


    }

    if (localStorage.getItem('pedidoFecha').length > 3) {


      

    } else {

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

    }

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

     this.descargar("RUTA");

    } else {


      this.subscriptionTransporte.unsubscribe();


    }

  }

  ngAfterViewInit() {


    this.dataSourceRuta.paginator = this.paginatorruta;


  }

  descargar(tipoRUTA:String) {
    this.dataSourceRuta = new MatTableDataSource();
    this.subscriptionTareas = this.orderService.getTareasdia().subscribe(tareasrutas => {
    this.tareasDescarga = tareasrutas;
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  
      this.rutasDescarga = groupBy(this.tareasDescarga, 'RutaVLI')

      if(tipoRUTA == "RUTA") {
        this.transportes();
      }else{
        this.transportesCambio();
      }

      this.getTareas()

    });


  }



  transportes(){

    let tareasRecaudo=[]
    let tareasRecaudoValor=[]

    let zonasEnvio = []
    this.txtRutas =0
    this.txtDias=1
    for (let ruta of Object.keys(this.rutasDescarga)) {


      let tareas = this.tareasDescarga.filter(tarea => tarea.RutaVLI == ruta)

      this.txtRutas = this.txtRutas + 1

   

        this.totalParcial = 0;
        this.canParcial = 0;
        this.totalParcialExi = 0;
        this.canParcialExi = 0;
        this.totalExitoso = 0;
        this.canExitoso = 0;
        this.totalNovedad = 0;
        this.canNovedad = 0;
        this.totalPendiente = 0;
        this.canPendiente = 0;


        this.txtTotalFacRuta = 0;
        this.txtPedidosRuta = 0;

        for (let tarea of tareas) {


          this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
          this.txtPedidosRuta = this.txtPedidosRuta + 1

          if (tarea.estado == "EXITOSA") {


            this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
            this.canExitoso = this.canExitoso + 1

          }

          if (tarea.estado == "PENDIENTE") {


            this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
            this.canPendiente = this.canPendiente + 1


          }

          if (tarea.estado == "NOVEDAD") {

            this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
            this.canNovedad = this.canNovedad + 1

          }

          if (tarea.estado == "PARCIAL") {

            this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
            this.canParcial = this.canParcial + 1


            this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
            this.canParcialExi = this.canParcialExi + 1


          }
        }


        var estadoPar = {

          name: ruta,

          CanParExi: this.canParcialExi,
          CostoParMercExi: this.totalParcialExi,

          CanParNov: this.canParcial,
          CostoParMercNov: this.totalParcial,

          CanExi: this.canExitoso,
          CostoMercExi: this.totalExitoso,

          CanPen: this.canPendiente,
          CostoMercPen: this.totalPendiente,

          CanNov: this.canNovedad,
          CostoMercNov: this.totalNovedad,

          y: this.txtPedidosRuta,

          txtTotalFac: this.txtTotalFacRuta,
          txtPedidos: this.txtPedidosRuta,

          txtPedPor: ((this.totalPendiente * 100) / this.txtTotalFacRuta) + "%",
          txtNovPor: ((this.totalNovedad * 100) / this.txtTotalFacRuta) + "%",
          txtExiPor: ((this.totalExitoso * 100) / this.txtTotalFacRuta) + "%",
          txtParPor: (((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta) + "%",

          txtPedPorM: Math.round(((this.totalPendiente * 100) / this.txtTotalFacRuta)) + "%",
          txtNovPorM: Math.round(((this.totalNovedad * 100) / this.txtTotalFacRuta)) + "%",
          txtExiPorM: Math.round(((this.totalExitoso * 100) / this.txtTotalFacRuta)) + "%",
          txtParPorM: Math.round((((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta)) + "%",

          txtPedPorC: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
          txtNovPorC: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
          txtExiPorC: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
          txtParPorC: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

          txtPedPorMC: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
          txtNovPorMC: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
          txtExiPorMC: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
          txtParPorMC: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

        };

        tareasRecaudo.push(estadoPar)
      

        var estadoPardos = {

          name: ruta,

          CanParExi: this.canParcialExi,
          CostoParMercExi: this.totalParcialExi,

          CanParNov: this.canParcial,
          CostoParMercNov: this.totalParcial,

          CanExi: this.canExitoso,
          CostoMercExi: this.totalExitoso,

          CanPen: this.canPendiente,
          CostoMercPen: this.totalPendiente,

          CanNov: this.canNovedad,
          CostoMercNov: this.totalNovedad,

          y: this.txtTotalFacRuta,

          txtTotalFac: this.txtTotalFacRuta,
          txtPedidos: this.txtPedidosRuta,

          txtPedPor: ((this.totalPendiente * 100) / this.txtTotalFacRuta) + "%",
          txtNovPor: ((this.totalNovedad * 100) / this.txtTotalFacRuta) + "%",
          txtExiPor: ((this.totalExitoso * 100) / this.txtTotalFacRuta) + "%",
          txtParPor: (((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta) + "%",

          txtPedPorM: Math.round(((this.totalPendiente * 100) / this.txtTotalFacRuta)) + "%",
          txtNovPorM: Math.round(((this.totalNovedad * 100) / this.txtTotalFacRuta)) + "%",
          txtExiPorM: Math.round(((this.totalExitoso * 100) / this.txtTotalFacRuta)) + "%",
          txtParPorM: Math.round((((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta)) + "%",



          txtPedPorC: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
          txtNovPorC: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
          txtExiPorC: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
          txtParPorC: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

          txtPedPorMC: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
          txtNovPorMC: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
          txtExiPorMC: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
          txtParPorMC: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

        };

        tareasRecaudoValor.push(estadoPardos)
  
        this.dataruta=tareasRecaudoValor;

        this.dataSourceRuta = new MatTableDataSource();
        this.dataSourceRuta.data = tareasRecaudoValor;

   

    }



   
  }

  transportesCambio(){

    let tareasRecaudo=[]
    let tareasRecaudoValor=[]

    let zonasEnvio = []
    this.txtRutas =0
    this.txtDias=1
    for (let ruta of Object.keys(this.rutasDescarga)) {


      let tareas = this.tareasDescarga.filter(tarea => tarea.RutaVLI == ruta)

      this.txtRutas = this.txtRutas + 1

   

        this.totalParcial = 0;
        this.canParcial = 0;
        this.totalParcialExi = 0;
        this.canParcialExi = 0;
        this.totalExitoso = 0;
        this.canExitoso = 0;
        this.totalNovedad = 0;
        this.canNovedad = 0;
        this.totalPendiente = 0;
        this.canPendiente = 0;


        this.txtTotalFacRuta = 0;
        this.txtPedidosRuta = 0;

        for (let tarea of tareas) {


          this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
          this.txtPedidosRuta = this.txtPedidosRuta + 1

          if (tarea.estado == "EXITOSA") {


            this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
            this.canExitoso = this.canExitoso + 1

          }

          if (tarea.estado == "PENDIENTE") {


            this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
            this.canPendiente = this.canPendiente + 1


          }

          if (tarea.estado == "NOVEDAD") {

            this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
            this.canNovedad = this.canNovedad + 1

          }

          if (tarea.estado == "PARCIAL") {

            this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
            this.canParcial = this.canParcial + 1


            this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
            this.canParcialExi = this.canParcialExi + 1


          }
        }


        var estadoPar = {

          name: ruta,

          CanParExi: this.canParcialExi,
          CostoParMercExi: this.totalParcialExi,

          CanParNov: this.canParcial,
          CostoParMercNov: this.totalParcial,

          CanExi: this.canExitoso,
          CostoMercExi: this.totalExitoso,

          CanPen: this.canPendiente,
          CostoMercPen: this.totalPendiente,

          CanNov: this.canNovedad,
          CostoMercNov: this.totalNovedad,

          y: this.txtPedidosRuta,

          txtTotalFac: this.txtTotalFacRuta,
          txtPedidos: this.txtPedidosRuta,

          txtPedPor: ((this.totalPendiente * 100) / this.txtTotalFacRuta) + "%",
          txtNovPor: ((this.totalNovedad * 100) / this.txtTotalFacRuta) + "%",
          txtExiPor: ((this.totalExitoso * 100) / this.txtTotalFacRuta) + "%",
          txtParPor: (((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta) + "%",

          txtPedPorM: Math.round(((this.totalPendiente * 100) / this.txtTotalFacRuta)) + "%",
          txtNovPorM: Math.round(((this.totalNovedad * 100) / this.txtTotalFacRuta)) + "%",
          txtExiPorM: Math.round(((this.totalExitoso * 100) / this.txtTotalFacRuta)) + "%",
          txtParPorM: Math.round((((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta)) + "%",

          txtPedPorC: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
          txtNovPorC: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
          txtExiPorC: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
          txtParPorC: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

          txtPedPorMC: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
          txtNovPorMC: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
          txtExiPorMC: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
          txtParPorMC: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

        };

        tareasRecaudo.push(estadoPar)
      

        var estadoPardos = {

          name: ruta,

          CanParExi: this.canParcialExi,
          CostoParMercExi: this.totalParcialExi,

          CanParNov: this.canParcial,
          CostoParMercNov: this.totalParcial,

          CanExi: this.canExitoso,
          CostoMercExi: this.totalExitoso,

          CanPen: this.canPendiente,
          CostoMercPen: this.totalPendiente,

          CanNov: this.canNovedad,
          CostoMercNov: this.totalNovedad,

          y: this.txtTotalFacRuta,

          txtTotalFac: this.txtTotalFacRuta,
          txtPedidos: this.txtPedidosRuta,

          txtPedPor: ((this.totalPendiente * 100) / this.txtTotalFacRuta) + "%",
          txtNovPor: ((this.totalNovedad * 100) / this.txtTotalFacRuta) + "%",
          txtExiPor: ((this.totalExitoso * 100) / this.txtTotalFacRuta) + "%",
          txtParPor: (((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta) + "%",

          txtPedPorM: Math.round(((this.totalPendiente * 100) / this.txtTotalFacRuta)) + "%",
          txtNovPorM: Math.round(((this.totalNovedad * 100) / this.txtTotalFacRuta)) + "%",
          txtExiPorM: Math.round(((this.totalExitoso * 100) / this.txtTotalFacRuta)) + "%",
          txtParPorM: Math.round((((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta)) + "%",



          txtPedPorC: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
          txtNovPorC: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
          txtExiPorC: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
          txtParPorC: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

          txtPedPorMC: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
          txtNovPorMC: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
          txtExiPorMC: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
          txtParPorMC: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

        };

        tareasRecaudoValor.push(estadoPardos)
  
        this.dataruta=tareasRecaudoValor;


        this.dataSourceRuta = new MatTableDataSource();
        this.dataSourceRuta.data = tareasRecaudoValor;

  
        this.route.navigate(['/novedades']);


   

    }



   
  }
  ngOnDestroy() {
    this.subscriptionTareas.unsubscribe()
 

  }


  novedad(order: any) {

    localStorage.setItem("txtRutaInd", order.name);
    
    this.descargar("CAMBIO")

  }

  getTareas() {

    this.txtFecha=localStorage.getItem("ordenTransporteFecha");
    this.negocio = localStorage.getItem("departamento");
    this.usuario = localStorage.getItem("correo");
    this.tareasDescarga = this.tareasDescarga;
    let tareasNovedadeS = this.tareasDescarga.filter(tarea => tarea.estado == "NOVEDAD")


    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasvli = groupBy(this.tareasDescarga, 'RutaVLI')
    let zonasODL = groupBy(this.tareasDescarga, 'Zona')
    let novedadODL = groupBy(tareasNovedadeS, 'observaciones')

    this.txtTotalFac = 0;
    this.txtPesoKG = 0;
    this.txtPesoKGDe = 0;
    this.txtPedidos = 0;
    this.txtNovedades = 0;
    this.txtPedidosDev = 0;

    this.totalParcial = 0;
    this.canParcial = 0;
    this.totalParcialExi = 0;
    this.canParcialExi = 0;
    this.totalExitoso = 0;
    this.canExitoso = 0;
    this.totalNovedad = 0;
    this.canNovedad = 0;
    this.totalPendiente = 0;
    this.canPendiente = 0;
    this.totalGeneral = 0;
    this.canGeneral = 0;

    let novEstCan = []
    let novEstVal = []
    for (let tarea of this.tareasDescarga) {


      var zonas = {
        idZona: tarea.Zona,
      };

      this.canGeneral = this.canGeneral + 1

      this.orderService.createZonaAgrupadas(zonas);

      this.txtTotalFac = this.txtTotalFac + tarea.CostoMerc
      this.txtPesoKG = this.txtPesoKG + tarea["Peso neto"]
      this.txtPedidos = this.txtPedidos + 1

      if (tarea.estado == "EXITOSA") {


        this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
        this.canExitoso = this.canExitoso + 1

      }

      if (tarea.estado == "PENDIENTE") {


        this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
        this.canPendiente = this.canPendiente + 1


      }

      if (tarea.estado == "NOVEDAD") {

        this.txtNovedades = this.txtNovedades + tarea.CostoMerc
        this.txtPedidosDev = this.txtPedidosDev + 1
        this.txtPesoKGDe = this.txtPesoKGDe + tarea["Peso neto"]

        this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
        this.canNovedad = this.canNovedad + 1

      }

      if (tarea.estado == "PARCIAL") {

        this.txtNovedades = this.txtNovedades + (tarea.devolucion * 1)

        this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
        this.canParcial = this.canParcial + 1


        this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
        this.canParcialExi = this.canParcialExi + 1


      }

    }

    var estadoPar = {
      name: "Nov Parcial",
      y: this.canParcial,
      CostoMerc: this.totalParcial,
    };

    novEstCan.push(estadoPar)

    var estadoPar = {
      name: "Exi Parcial",
      y: this.canParcialExi,
      CostoMerc: this.totalParcialExi,
    };

    novEstCan.push(estadoPar)


    var estadoPen = {
      name: "Pendiente",
      y: this.canPendiente,
      CostoMerc: this.totalPendiente,
    };

    novEstCan.push(estadoPen)


    var estadoExi = {
      name: "Exitosa",
      y: this.canExitoso,
      CostoMerc: this.totalExitoso,
    };

    novEstCan.push(estadoExi)


    var estadoNov = {
      name: "Novedad",
      y: this.canNovedad,
      CostoMerc: this.totalNovedad,
    };

    novEstCan.push(estadoNov)

    //estados

    var estadoPen = {
      name: "Pendiente",
      y: this.totalPendiente,
      CostoMerc: this.canPendiente,
    };

    novEstVal.push(estadoPen)

    var estadoExi = {
      name: "Exitosa",
      y: this.totalExitoso,
      CostoMerc: this.canExitoso,
    };

    novEstVal.push(estadoExi)

    var estadoNov = {
      name: "Novedad",
      y: this.totalNovedad,
      CostoMerc: this.canNovedad,
    };

    novEstVal.push(estadoNov)

    var estadoPar = {
      name: "Nov Parcial",
      y: this.totalParcial,
      CostoMerc: this.canParcial,
    };

    novEstVal.push(estadoPar)

    var estadoPar = {
      name: "Exi Parcial",
      y: this.totalParcialExi,
      CostoMerc: this.totalParcialExi,
    };

    novEstVal.push(estadoPar)





  }

  atras() {

    this.toastr.successToastr("Orden transporte", 'Notificación');
    this.route.navigate(['/OrdenTransporte']);
    }
    
    ubicacion() {

      this.toastr.successToastr("Ubicación orden de trabajo: ", 'Notificación');
      this.route.navigate(['/MapsOrden']);
      }
  
      indicadores() {
  
        this.toastr.successToastr("Indicador orden de trabajo: ", 'Notificación');
        this.route.navigate(['/des']);
      }
  
      control() {
  
        this.toastr.successToastr("Control orden de trabajo: ", 'Notificación');
        this.route.navigate(['/rutas']);
      }


}
