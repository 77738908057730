import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule, MatToolbarModule, MatIconModule, MatButtonModule, MatMenuModule, MatListModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { AreaComponent } from './widgets/area/area.component';
import { AreaRutasActivacionesComponent } from './widgets/areaactivaciones/areaactivaciones.component';
import { AreavetasComponent } from './widgets/areaventas/areaventas.component';
import { AreaRutasComponent } from './widgets/arearutas/arearutas.component';
import { AreadosComponent } from './widgets/areados/areados.component';
import { AreaNuevosComponent } from './widgets/areanuevos/areanuevos.component';

import { HighchartsChartModule } from 'highcharts-angular';
import { CardComponent } from './widgets/card/card.component';
import { PieComponent } from './widgets/pie/pie.component';
import { RelojComponent } from './widgets/reloj/reloj.component';
import { RelojEfectividadComponent } from './widgets/relojefectividad/relojefectividad.component';
import { TipoNovedadComponent } from './widgets/tipoNovedades/tiponovedad.component';
import { BarrasIndicadorComponent } from './widgets/barras/barrasIndicador.component';
import { PiedosComponent } from './widgets/piedos/piedos.component';
import { PiecuatroComponent } from './widgets/piecuatro/piecuatro.component';
import { PievalorComponent } from './widgets/pievalor/pievalor.component';
import { PieTipoRuraComponent } from './widgets/pietiporuta/pietiporuta.component';
import { PietresComponent } from './widgets/pietres/pietres.component';
import { PieventaComponent } from './widgets/pieventa/pieventa.component';
import { PieCausalComponent } from './widgets/piecausal/piecausal.component';
import { PieRutaComponent } from './widgets/pieruta/pieruta.component';
import { PieEstadosRutaComponent } from './widgets/pieestadosruta/pieestadosruta.component';
import { PieventaRamoComponent } from './widgets/pieventaramo/pieventaramo.component';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TreeViewModule, TabModule } from '@syncfusion/ej2-angular-navigations';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { AreaventasRamoComponent } from './widgets/areaventasramo/areaventasramo.component';
import { ZingchartAngularModule } from 'zingchart-angular';
import { AreavcumplimientoComponent } from './widgets/areavcumplimiento/areavcumplimiento.component';
import { Areavcumplimientogeneral } from './widgets/areavcumplimientogeneral/areavcumplimientogeneral.component';
import { Areavgeo } from './widgets/areavgeo/areavgeo.component';
import { Areavgeogeneral } from './widgets/areavgeogeneral/areavgeogeneral.component';
import { AreavcumplimientoInfullComponent } from './widgets/areavcumplimientoinfull/areavcumplimientoinfull.component';
import { AreavcumplimientoOntifComponent } from './widgets/areavcumplimientoOftif/areavcumplimientoOftif.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    PievalorComponent,
    AreaComponent,
    AreaventasRamoComponent,
    AreavcumplimientoComponent,
    AreadosComponent,
    AreavcumplimientoInfullComponent,
    CardComponent,
    AreavetasComponent,
    PieComponent,
    PietresComponent,
    Areavgeogeneral,
    PiedosComponent,
    PiecuatroComponent,
    PieventaComponent,
    PieRutaComponent,
    Areavgeo,
    PieTipoRuraComponent,
    PieCausalComponent,
    PieEstadosRutaComponent,
    PieventaRamoComponent,
    AreaRutasComponent,
    AreavcumplimientoOntifComponent,
    Areavcumplimientogeneral,
    AreaNuevosComponent,
    AreaRutasActivacionesComponent,
    RelojComponent,
    RelojEfectividadComponent,
    TipoNovedadComponent,
    BarrasIndicadorComponent
  
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatMenuModule,
    MatListModule,
    RouterModule,
    HighchartsChartModule,
    ZingchartAngularModule,
    ButtonModule,
    ListViewModule,
    DropDownListModule,
    TreeViewModule,
    TabModule,
    RichTextEditorAllModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AreaComponent,
    CardComponent,
    PieComponent,
    PievalorComponent,
    AreaventasRamoComponent,
    AreavcumplimientoComponent,
    AreadosComponent,
    Areavcumplimientogeneral,
    AreaRutasActivacionesComponent,
    AreavcumplimientoOntifComponent,
    PiedosComponent,
    Areavgeo,
    Areavgeogeneral,
    PietresComponent,
    PieventaComponent,
    AreavetasComponent,
    PiecuatroComponent,
    PieCausalComponent,
    PieRutaComponent,
    AreavcumplimientoInfullComponent,
    AreaNuevosComponent,
    PieTipoRuraComponent,
    PieEstadosRutaComponent,
    PieventaRamoComponent,
    AreaRutasComponent,
    RelojComponent,
    RelojEfectividadComponent,
    BarrasIndicadorComponent,
    TipoNovedadComponent,
    ButtonModule,
        TreeViewModule,
        ListViewModule,
        DropDownListModule,
        TabModule,
        RichTextEditorAllModule
  ]
})
export class SharedModule { }
