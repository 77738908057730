import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class AprobacionService {

  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private Aprobacincollection: AngularFirestoreCollection<any>;
  private Aprobacion: Observable<any[]>;

  private MClientecollection: AngularFirestoreCollection<any>;
  private MClienteAprobacion: Observable<any[]>;

  private MContactoscollection: AngularFirestoreCollection<any>;
  private MContactosAprobacion: Observable<any[]>;

  private MRuterollection: AngularFirestoreCollection<any>;
  private MRuteroAprobacion: Observable<any[]>;
  
  private MCCodigollection: AngularFirestoreCollection<any>;
  private MCc: Observable<any[]>;
  
  
  constructor(private readonly db: AngularFirestore, private userd: AuthService) {


  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + ''+ EXCEL_EXTENSION);
  }


  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");


   //*  consultar los clientes del maestro //
  getClienteAprobacion() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.Aprobacincollection = this.db.collection<any>('ClientesOdlAprobacion/');
    this.Aprobacion = this.Aprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.Aprobacion;


  }

  getClienteCodigo() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.MCCodigollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "consecutivos/");
    this.MCc = this.MCCodigollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.MCc;


  }

  
  getClienteAprobacionRfv(rfv:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.Aprobacincollection = this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('rfv', '==', rfv));
    this.Aprobacion = this.Aprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.Aprobacion;


  }

  update(cliente: any) {


    console.log("UPDATE RFV", cliente.rfv);
    console.log("UPDATE ESTADO", cliente.estado);
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    return this.Aprobacincollection.doc(cliente.rfv).update(cliente);
  }

  createCliente(cliente: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.MClientecollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "clientes");
   
    return this.MClientecollection.doc(cliente.codigo).set(cliente);

  }

  createRutero(rutero: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.MRuterollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "ruteros");
   
    return this.MRuterollection.doc( "RT"+rutero.idRuta).set(rutero);

  }
  createContacto(cliente: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.MContactoscollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "contactos");
   
    return this.MContactoscollection.doc(cliente.codigo).set(cliente);

  }

  createCoddio(codigo: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.MCCodigollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "consecutivos/");
    
    return this.MCCodigollection.doc("conclientes").set(codigo);

  }


}


