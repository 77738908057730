import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';

import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ellipse } from 'pdfmake-wrapper';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { IndicadoresService } from 'src/app/shared/indicadores.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DialogNovedades } from './dialogNovedades/dialogNovedades.component';
import { DialogPendientes } from './dialogPendientes/dialogPendientes.component';
import { DialogExitosas } from './dialogExitosas/dialogExitosas.component';
@Component({
  selector: 'app-fragmentIndicadoresVrt',
  templateUrl: './fragmentIndicadoresVrt.component.html',
  styleUrls: ['./fragmentIndicadoresVrt.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoresVrt implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  subscription: Subscription;
  private txtTotalFacRuta: any;
  private txtFecha: any;
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0
  estadoNovedadFallida=0
  txtentregas=0
  private tareasCantidadPlanilla: any;
  private tareasCodigoPlanilla: any;
  private tareasPendientesPlanilla: any; 
  private tareasExitosasPlanilla: any; 
  private tareasParcialesPlanilla: any; 
  private tareasRepisarPlanilla: any; 
  private tareasNovedadPlanilla: any; 
  private tareasFallidaPlanilla: any; 
  private tareasCostoPlanilla: any; 
  private tareasPesoPlanilla: any; 
  private tareasTotalProductoPlanilla: any; 
  private tareasTotalRecaudoPlanilla: any; 
  private tareasTotalFallidaPlanilla: any; 
  private txtPesoKG=0; 
  private txtNovedades=0; 
  private txtPedidos=0; 
  private txtPedidosDev=0; 
  private txtPesoKGDe=0; 
  private txtRutas=0; 
  private txtPesoKGDeGestion=0; 
  private txtPesoKGGestion=0; 

 
  constructor(private indicadoresService: IndicadoresService, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog,public dialogPendientes: MatDialog) {

  }

  displayedColumns: string[] = ['tareasCodigoPlanilla', 'tareasCantidadPlanilla', 'tareasExitosasPlanilla','tareasPendientesPlanilla','tareasNovedadPlanilla'];

  dataSource = new MatTableDataSource();

  ngOnInit(): void {
   this.descargar()
   const currentDate = new Date();
   const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  this.txtFecha=cValue
   localStorage.setItem("pedidoFecha",cValue);
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  clickNovedades():void {

  
  
    }
    
    clickPendientes():void {

      console.log("novedades click");
      this.dialogPendientes.open(DialogPendientes, {
        width: '1600px',
        height:'750px',
        data: {},
        panelClass: 'custom-pendientes',
  
      
      });
    
      }
      clickExitosa():void {

        console.log("novedades click");
        this.dialogagregar.open(DialogNovedades, {
          width: '1700px',
          height:'750px',
          data: {},
          panelClass: 'custom-exitosas',
        
        });
      
        }

  descargar() {
    this.subscription = this.indicadoresService.getIndicadores().subscribe(tareasindicadores => {
   
      console.log("tareasindicadores",tareasindicadores);
      this.txtTotalFacRuta=0
      this.estadoPendiente=0
      this.estadoExitosa=0
      this.estadoParcial=0
      this.estadoRepisar=0
      this.estadoNovedadFallida=0
      this.estadoNovedad=0
      this.txtTotalFacRuta=0
      this.txtentregas=0
      this.txtNovedades=0
      this.txtPedidos=0
      this.txtPedidosDev=0
      this.txtPesoKGDe=0
      this.txtRutas=0



    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let planillas = groupBy(tareasindicadores, 'codigoPlanilla')
    console.log("planillas",planillas);

    
    this.txtPesoKG=0
    this.txtPesoKGGestion=0
    this.txtPesoKGDeGestion=0
      for (let tarea of tareasindicadores) {
        this.txtentregas = this.txtentregas + 1
        this.txtPedidos = this.txtPedidos + 1
        this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
        this.txtPesoKG =  this.txtPesoKG+tarea.PesoNeto 


        if(tarea.estado=="PENDIENTE"){

          this.estadoPendiente=this.estadoPendiente+1
      
         }

         if(tarea.estado=="EXITOSA"){

          this.estadoExitosa=this.estadoExitosa+1
          this.txtPesoKGGestion= this.txtPesoKGGestion+tarea.PesoNeto
      
         }

         if(tarea.estado=="CREACION"){

          this.estadoExitosa=this.estadoExitosa+1
          this.txtPesoKGGestion= this.txtPesoKGGestion+tarea.PesoNeto
      
         }

         if(tarea.estado=="NOVEDAD"){

          this.estadoNovedad=this.estadoNovedad+1
          this.estadoNovedadFallida=this.estadoNovedadFallida+1

          this.txtNovedades=   this.txtNovedades+tarea.novedad_peso
          this.txtPedidosDev=   this.txtPedidosDev+1
          this.txtPesoKGDe=this.txtPesoKGDe+tarea.CostoMerc

          this.txtPesoKGGestion= this.txtPesoKGGestion+tarea.PesoNeto
          this.txtPesoKGDeGestion=this.txtPesoKGDeGestion+tarea.PesoNeto

         }


         if(tarea.estado=="REPISAR"){

          this.estadoNovedad=this.estadoNovedad+1
          this.estadoRepisar=this.estadoRepisar+1
          this.txtNovedades=   this.txtNovedades+tarea.novedad_peso
          this.txtPedidosDev=   this.txtPedidosDev+1
          this.txtPesoKGDe=this.txtPesoKGDe+tarea.CostoMerc

          this.txtPesoKGGestion= this.txtPesoKGGestion+tarea.PesoNeto
          this.txtPesoKGDeGestion=this.txtPesoKGDeGestion+tarea.PesoNeto
         }
         

         if(tarea.estado=="PARCIAL"){

          this.estadoNovedad=this.estadoNovedad+(tarea.porcetaje_novedad/100)
          this.estadoParcial=this.estadoParcial+1
          
          this.txtNovedades=   this.txtNovedades+tarea.novedad_peso
          this.txtPedidosDev=   this.txtPedidosDev+(tarea.porcetaje_novedad/100)
          this.txtPesoKGDe=this.txtPesoKGDe+ parseFloat( tarea.devolucion)

          this.estadoExitosa=this.estadoExitosa+(tarea.porcetaje_entrega/100)  
          
          this.txtPesoKGGestion= this.txtPesoKGGestion+tarea.novedad_peso
          this.txtPesoKGDeGestion=this.txtPesoKGDeGestion+tarea.novedad_peso
         }

      }

      let tareasPanillasUsuarios = []
      for (let item of Object.keys(planillas)) {
        var planillasdia = planillas[item];
        let tareasplanilla = tareasindicadores.filter(tarea => tarea.codigoPlanilla == planillasdia[0].codigoPlanilla)
        this.txtRutas= this.txtRutas+1
        console.log( planillasdia[0].codigoPlanilla,tareasplanilla);
        this.tareasCantidadPlanilla=0
        this.tareasPendientesPlanilla=0
        this.tareasExitosasPlanilla=0
        this.tareasParcialesPlanilla=0
        this.tareasRepisarPlanilla=0
        this.tareasNovedadPlanilla=0
        this.tareasFallidaPlanilla=0
        this.tareasCostoPlanilla=0
        this.tareasPesoPlanilla=0
        this.tareasTotalProductoPlanilla=0
        this.tareasTotalRecaudoPlanilla=0

        for (let tarea of tareasplanilla) {

          this.tareasCantidadPlanilla=  this.tareasCantidadPlanilla+1
          this.tareasCodigoPlanilla=planillasdia[0].codigoPlanilla
          this.tareasCostoPlanilla=this.tareasCostoPlanilla+tarea.CostoMerc
          this.tareasPesoPlanilla=this.tareasPesoPlanilla+tarea.PesoNeto
          if(tarea.estado=="PENDIENTE"){
            this.tareasPendientesPlanilla=this.tareasPendientesPlanilla+1
            this.tareasTotalProductoPlanilla=this.tareasTotalProductoPlanilla+tarea.CostoMerc
           }

           if(tarea.estado=="EXITOSA"){

            this.tareasExitosasPlanilla=this.tareasExitosasPlanilla+1
            this.tareasTotalRecaudoPlanilla=this.tareasTotalRecaudoPlanilla+tarea.CostoMerc
        
           }
  
  
           if(tarea.estado=="NOVEDAD"){
  
            this.tareasNovedadPlanilla=this.tareasNovedadPlanilla+1
            this.tareasFallidaPlanilla=this.tareasFallidaPlanilla+1
            this.tareasTotalProductoPlanilla=this.tareasTotalProductoPlanilla+tarea.CostoMerc

           }
  
  
           if(tarea.estado=="REPISAR"){
  
            this.tareasRepisarPlanilla=this.tareasRepisarPlanilla+1
            this.tareasNovedadPlanilla=this.tareasNovedadPlanilla+1
            this.tareasTotalProductoPlanilla=this.tareasTotalProductoPlanilla+tarea.CostoMerc
           }
  
           if(tarea.estado=="PARCIAL"){
  
            this.tareasNovedadPlanilla=this.tareasNovedadPlanilla+1
            this.tareasParcialesPlanilla=this.tareasParcialesPlanilla+1

            this.tareasTotalProductoPlanilla=this.tareasTotalProductoPlanilla+parseFloat( tarea.devolucion)
            this.tareasTotalRecaudoPlanilla=this.tareasTotalRecaudoPlanilla+parseFloat( tarea.recaudo)
           }


        }
  
        var registroTareasPlanilla = {

          tareasCantidadPlanilla:    this.tareasCantidadPlanilla,
          tareasCodigoPlanilla:    this.tareasCodigoPlanilla,
          tareasPendientesPlanilla:    this.tareasPendientesPlanilla,
          tareasExitosasPlanilla:    this.tareasExitosasPlanilla,
          tareasNovedadPlanilla:    this.tareasNovedadPlanilla,
          tareasFallidaPlanilla:    this.tareasFallidaPlanilla,
          tareasRepisarPlanilla:    this.tareasRepisarPlanilla,
          tareasParcialesPlanilla:    this.tareasParcialesPlanilla,
          tareasCostoPlanilla:    this.tareasCostoPlanilla,
          tareasPesoPlanilla:    this.tareasPesoPlanilla,
          tareasTotalProductoPlanilla:this.tareasTotalProductoPlanilla,
          tareasTotalRecaudoPlanilla:this.tareasTotalRecaudoPlanilla

        };


        tareasPanillasUsuarios.push(registroTareasPlanilla)

        this.dataSource.data = tareasPanillasUsuarios;
        console.log("tareasPanillasUsuarios",tareasPanillasUsuarios);
      }
      
    });
  }




}

