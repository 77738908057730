import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { DialogEditarBanco } from '../../../modules/slcweb/dialogEditarBanco/dialogEditarBanco.component';
import { DialogAgregarBanco } from '../dialogAgregarBanco/dialogAgregarBanco.component';
import { DialogEliminarBanco } from '../dialogEliminarBanco/dialogEliminarBanco.component';
import { Router } from '@angular/router';

export interface DialogData {
  idBanco: string;
  banco: string;
}
@Component({
  selector: 'app-FragmentBancos',
  templateUrl: './FragmentBancos.component.html',
  styleUrls: ['./FragmentBancos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentBancos implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigobanco;
  public codigodescarga;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }
  
  
  idBanco: Number;
  banco: string;
   displayedColumns: string[] = ['idBanco', 'banco','editar','eliminar'];
   dataSource = new MatTableDataSource();

   openDialog(): void {
    
  }
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarBancos()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getConfiguraciones ().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarBancos() {

    this.configuracionesService.getConsecutivoBanco ().subscribe(bancos => {

      for (let banco of bancos) {

           this.codigobanco=banco.banco
           this.codigodescarga=banco.banco
      
      }  

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editarbanco(banco: any) {


  this.dialog.open(DialogEditarBanco, {
      width: '350px',
      data: {idBanco: banco.idBanco, banco: banco.banco, inicial:banco.banco},
    });

   

  }

  nuevoBanco():void {


    this.dialogagregar.open(DialogAgregarBanco, {
        width: '350px',
        data: {idBanco: this.codigodescarga+1,banco:''},
      });
  
     
  
    }

  deletebanco(banco: any) {


    this.dialogEliminar.open(DialogEliminarBanco, {
      width: '350px',
      data: {idBanco: banco.idBanco,banco:banco.banco},
    });

  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaBancos']);
    
  }
  
}
