
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { MapsOrdenService } from 'src/app/shared/mapsorden.service';
import { IndicadoresService } from 'src/app/shared/indicadores.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { OrdersService } from 'src/app/shared/orders.service';
export interface Section {
  usuario: string;
}


@Component({
  selector: 'app-FragmentMpasTransporte',
  templateUrl: './FragmentMpasTransporte.component.html',
  styleUrls: ['./FragmentMpasTransporte.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentMpasTransporte implements OnInit {
 
  idTipo: string;
  tipo: string;
  descripcion: string;
 
  

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   public markers = new Array();
   public ubicaciones = new Array();
   public rutasmapas = new Array();
   public transportes = new Array();
   lat =  parseFloat( localStorage.getItem('latmarketuser'));
   lng = parseFloat( localStorage.getItem('lngmarketuser'));

 
   
    zoom: number = 11;
   bounds: any;
   polygonPts: any[] = [];
   ptsList: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public licencias;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  private anos;
  private dias;
  public usaurio;
  public iconUrlruta = "/assets/trackrojo.svg";
  public iconUrl = "/assets/trackrojo.svg";
  public iconUrlflecha = "/assets/flechaderecha.svg";
  public iconUrltrasfin = "/assets/trackrojo.svg";

  public start_end_mark = [];
  public start_end_mark_fin = [];
  public icon = {
    url: 'http://earth.google.com/images/kml-icons/track-directional/track-4.png',
    scaledSize: {
      width: 30,
      height: 30
    }
}
public iconUrltras = "/assets/cajatrack.svg";
public iconUrltrasInicio = "/assets/banderasalida.png";
  public  latlng = [];
  
  constructor(private mapsOrdenService: MapsOrdenService,private indicadoresService: OrdersService, public toastr: ToastrManager, private route: Router) {}

     displayedColumns: string[] = [ 'hora', 'latitud', 'longitud','direccion_geo'];
     dataSource = new MatTableDataSource();
   
     ngOnInit(): void {
      console.log("lat",this.lat);
      console.log("lng",this.lng);
      this.usaurio =localStorage.getItem('marketuser')
      this.lat =  parseFloat( localStorage.getItem('latmarketuser'));
       this.lng = parseFloat( localStorage.getItem('lngmarketuser'));



       this.descargarubicacionesRuta()
       this.descargarTareas()
   
     }
   
   
     ngAfterViewInit() {
   
       this.dataSource.paginator = this.paginator;
   
     }
   
   
     applyFilter(event: Event) {
       const filterValue = (event.target as HTMLInputElement).value;
       this.dataSource.filter = filterValue.trim().toLowerCase();
     }
   
   

      descargarubicacionesRuta() {
   
   
        const currentDate = new Date();
        const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
        
        this.mapsOrdenService.getMapsOrdenUsuario(localStorage.getItem('marketuser')).subscribe(mapstareas => {
      


         var tareasConLatitud = mapstareas.filter(function(tarea) {
          return tarea.latitud !== "0.0";
      });

         this.ubicaciones = []
         //this.dataSource.data=tareasConLatitud
         console.log("tareasConLatitud ubicaciones control",tareasConLatitud);
         

         var contador = 0
         var loncomparar= 0.0
         var latcomparar= 0.0
         var hora= "08:30:00"

         for (let tarea of tareasConLatitud) {

  
           var distancia = calcularDistancia(latcomparar, loncomparar, parseFloat(tarea.longitud),  parseFloat(tarea.latitud));

           var resultado = calcularDistanciaYVelocidad(latcomparar, loncomparar, hora,  parseFloat(tarea.longitud),  parseFloat(tarea.latitud), tarea.hora);



          if(resultado.distancia < 1000 ){
       
            
            var punto =   [
              parseFloat( tarea.longitud),
              parseFloat( tarea.latitud),
        
            ]
            this.latlng.push(punto)
          }
          contador = contador+1
          parseFloat(tarea.longitud)
          var market = {
            lat:    parseFloat(tarea.longitud),
            lng:   parseFloat(tarea.latitud),
            label: String( tarea.id),
            draggable: false,
            cliente: "tarea.NombredeCliente",
            direccion: contador,
            valor:tarea.id
          }

          
  
          this.lat=tarea.longitud
          this.lng=tarea.latitud
  
          loncomparar = parseFloat(tarea.latitud)
          latcomparar  =  parseFloat(tarea.longitud)

         //this.ubicaciones.push(market)
    
        }


    


      
  
         this.start_end_mark = []
         this.start_end_mark_fin = []
         this.start_end_mark.push(this.latlng[0]);
         this.start_end_mark_fin.push(this.latlng[this.latlng.length - 1]);
   
         console.log("mapstareas rutas this.latlng",this.start_end_mark);


  
      

      
       });
   
     }

     descargarTareas() {
   
   
      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
      console.log("localStorage.getItem('marketuser')",localStorage.getItem('marketuser'));
      this.indicadoresService.getTareasEntrega(localStorage.getItem('marketuser')).subscribe(tareas => {
    
       console.log("tareas ubicaciones",tareas);

  

       this.ubicaciones = []
       var tareasConLatitud = tareas.filter(function(tarea) {
               return tarea.latitud !== "0";
         });
  
       for (let tarea of tareasConLatitud) {
 

        var iconotarea="/assets/exitosaentrega.svg"
        if(tarea.estado=="EXITOSA"){
           iconotarea="/assets/bexitosa.svg"
        }

        if(tarea.estado=="PENDIENTE"){
          iconotarea="/assets/bpendiente.svg"
       }
       if(tarea.estado=="PARCIAL"){
        iconotarea="/assets/bparcial.svg"
     }

     if(tarea.estado=="REPISAR"){
      iconotarea="/assets/brepisar.svg"
   }

   if(tarea.estado=="NOVEDAD"){
    iconotarea="/assets/bnovedad.svg"
     }
         var market = {
           lat:    tarea.lat,
           lng:   tarea.lon,
           draggable: false,
           cliente: tarea.NombredeCliente,
           direccion: tarea.Direccion,
           iconoent:iconotarea
         }
 
         this.lat=tarea.latitud
         this.lng=tarea.longitud

         
   
         this.ubicaciones.push(market)
   

         var market = {
          lat:    tarea.latTarea,
          lng:   tarea.lonTarea,
          draggable: false,
          cliente: tarea.NombredeCliente,
          direccion: tarea.Direccion,
          iconoent:this.iconUrltras
        }

        this.lat=tarea.latitud
        this.lng=tarea.longitud

        
  
        this.ubicaciones.push(market)
         
       }

  
       

     
     });
 
   }
     clickedMarker(market: any) {
       this.zoom=0
       console.log("market",market);
       this.lat=market.lat
       this.lng=market.lon
       this.zoom=18
   
  
   
     }
   
     onZoomChange() {
       this.zoom=16
   }
   
     onMapReady(map) {
       map.setOptions({
           zoomControl: 'true',
           zoomControlOptions: {
               position: google.maps.ControlPosition.RIGHT_CENTER,
               style: google.maps.ZoomControlStyle.DEFAULT
           }
       });

   }
   
   atras() {

    this.route.navigate(['/MapsOrden']);
    }
  
}

function calcularDistancia(lat1, lon1, lat2, lon2) {
  // Radio de la Tierra en metros
  var R = 6371e3; // metros
  var φ1 = lat1 * Math.PI / 180; // φ, latitud en radianes
  var φ2 = lat2 * Math.PI / 180;
  var Δφ = (lat2 - lat1) * Math.PI / 180;
  var Δλ = (lon2 - lon1) * Math.PI / 180;

  // Fórmula de Haversine
  var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distancia en metros
  var distancia = R * c;
  
  return distancia;
}

function horaASegundos(hora) {
  var partes = hora.split(':');
  var horas = parseInt(partes[0], 10);
  var minutos = parseInt(partes[1], 10);
  var segundos = parseInt(partes[2], 10);
  return horas * 3600 + minutos * 60 + segundos;
}

function calcularDistanciaYVelocidad(lat1, lon1, hora1, lat2, lon2, hora2) {
  // Radio de la Tierra en metros
  var R = 6371e3; // metros
  var φ1 = lat1 * Math.PI / 180; // φ, latitud en radianes
  var φ2 = lat2 * Math.PI / 180;
  var Δφ = (lat2 - lat1) * Math.PI / 180;
  var Δλ = (lon2 - lon1) * Math.PI / 180;

  // Fórmula de Haversine para calcular la distancia
  var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distancia en metros
  var distancia = R * c;

  // Convertir la hora en formato HH:mm:ss a segundos
  var tiempo1 = horaASegundos(hora1);
  var tiempo2 = horaASegundos(hora2);

  // Calculo del tiempo transcurrido en segundos
  var tiempoTranscurrido = Math.abs(tiempo2 - tiempo1);

  // Convertir la distancia de metros a kilómetros
  var distanciaEnKm = distancia / 1000;

  // Calcular la velocidad en km/h
  var velocidad = distanciaEnKm / (tiempoTranscurrido / 3600); // convirtiendo tiempo de segundos a horas

  return {
      distancia: distancia,
      velocidad: velocidad
  };
}