
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


export interface DialogDataVh {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  correo: string;
  ncelular: string;
  fdia: string;
  fmes: string;
  fano: string;
  departamento: string;
  ciudad: string;
  barrio: string;
  descripcion: string;
  detalle: string;
  estado: string;
  imgEstado: string;
  dentrega: string;
  dsupervisor: string;
  dgestionvial: string;
  dencuestas: string;
  dgeoreferenciar: string;
  dinformes: string;
}

@Component({
  selector: 'app-DialogEditarTercero',
  templateUrl: './DialogEditarTercero.component.html',
  styleUrls: ['./DialogEditarTercero.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEditarTercero implements OnInit {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  correo: string;
  ncelular: string;
  fdia: string;
  fmes: string;
  fano: string;
  departamento: string;
  ciudad: string;
  barrio: string;
  descripcion: string;
  detalle: string;
  estado: string;
  imgEstado: string;
  dentrega: string;
  dsupervisor: string;
  dgestionvial: string;
  dencuestas: string;
  dgeoreferenciar: string;
  dinformes: string;
  private tipoDocumentos;
  private anos;
  private dias;
  private meses;
  private dep;
  private barrios;
  private ciudades;
  private imgEstadofm;
  private estadofm;
  private s_ano;
  private s_mes;
  private s_dia;
  private s_departamento;
  private s_ciudad;
  private s_barrio;
  private s_tipo;
  private s_entrega;
  private s_supervisor;
  private s_gestionvial;
  private s_encuestas;
  private s_georeferenciar;
  private s_informes;
  private respuestas;
  private estados;
  constructor(public dialogRef: MatDialogRef<DialogEditarTercero>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

    this.descargarTipoDoc()
    this.descargaranos()
    this.descargardias()
    this.descargarmeses()
    this.descargarCiudades()
    this.descargarBarrios()
    this.descargarDepartamentos()
    this.descargarEstados()
    this.descargarRespuestas()

    this.estadofm=this.data.estado
    this.imgEstadofm=this.data.imgEstado
    this.s_departamento=this.data.departamento
    this.s_ciudad=this.data.ciudad
    this.s_barrio=this.data.barrio

    this.s_ano=this.data.fano
    this.s_mes=this.data.fmes
    this.s_dia=this.data.fdia

  
    this.s_tipo=this.data.tipoDoc
    this.s_entrega=this.data.dentrega
    this.s_encuestas=this.data.dencuestas
    this.s_informes=this.data.dinformes
    this.s_georeferenciar=this.data.dgeoreferenciar
    this.s_gestionvial=this.data.dgestionvial
    this.s_supervisor=this.data.dsupervisor
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearTipoDocumento(data: any) {
   


    if(data.nombres && 
      data.apellidos &&  
     this.s_tipo &&
     data.correo &&
     data.identificacion &&
     data.ncelular &&
     this.s_ano &&
     this.s_mes &&
     this.s_dia &&
     this.s_departamento &&
     this.s_ciudad &&
     this.s_barrio &&
     this.s_entrega &&
     this.s_encuestas &&
     this.s_supervisor &&
     this.s_gestionvial &&
     this.s_informes &&
     this.s_georeferenciar &&
     data.descripcion &&
     data.detalle &&
     this.estadofm ) {

      if(this.s_barrio) {

          
    var conop = {
      con: data.id+1

    }

    this.configuracionesService.incrementarTercero(conop)

    var nombres = data.nombres;
    var mayus = nombres.substring(0, 1).toUpperCase();
    var resto = nombres.substring(1, nombres.length).toLowerCase();
    var a_nombres=  mayus.concat(resto.toString());

    
    var apellidos = data.apellidos;
    var mayuslinea = apellidos.substring(0, 1).toUpperCase();
    var restolinea = apellidos.substring(1, apellidos.length).toLowerCase();
    var a_apellidos=  mayuslinea.concat(restolinea.toString());
      
     
    var descripcion = data.descripcion;
    var mayuslinea = descripcion.substring(0, 1).toUpperCase();
    var restolinea = descripcion.substring(1, descripcion.length).toLowerCase();
    var a_descripcion=  mayuslinea.concat(restolinea.toString());
      
    var detalle = data.detalle;
    var mayuslinea = detalle.substring(0, 1).toUpperCase();
    var restolinea = detalle.substring(1, detalle.length).toLowerCase();
    var a_detalle=  mayuslinea.concat(restolinea.toString());
      

    var tipoDoc = {
      id: data.id,
      nombres: a_nombres,
      apellidos: a_apellidos,
      tipoDoc: this.s_tipo,
      correo: data.correo,
      identificacion:data.identificacion,
      ncelular: data.ncelular,
      fechaNacimiento:this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      fdia: this.s_dia,
      fmes: this.s_mes,
      fano: this.s_ano,
      departamento: this.s_departamento,
      ciudad: this.s_ciudad,
      barrio: this.s_barrio,
      descripcion: a_descripcion,
      detalle:a_detalle,
      estado: this.estadofm,
      imgEstado: this.imgEstadofm,
      agencia:localStorage.getItem("agencia"),
      negocio: localStorage.getItem("negocio"),
      entregas: this.s_entrega,
      vial: this.s_gestionvial,
      encuestas: this.s_encuestas,
      geo: this.s_georeferenciar,
      supervisor: this.s_supervisor,
      informes: this.s_informes,
      usuario:  data.identificacion,
      contrasena: data.identificacion,
        }
  
      
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.id,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
          nombres: a_nombres,
          apellidos: a_apellidos,
          tipoDoc: this.s_tipo,
          correo: data.correo,
          identificacion:data.identificacion,
          ncelular: data.ncelular,
          fechaNacimiento:this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
          fdia: this.s_dia,
          fmes: this.s_mes,
          fano: this.s_ano,
          departamento: this.s_departamento,
          ciudad: this.s_ciudad,
          barrio: this.s_barrio,
          descripcion: a_descripcion,
          detalle:a_detalle,
          estado: this.estadofm,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
          entregas: this.s_entrega,
          vial: this.s_gestionvial,
          encuestas: this.s_encuestas,
          geo: this.s_georeferenciar,
          supervisor: this.s_supervisor,
          informes: this.s_informes,
        }
    
        this.configuracionesService.crearAuditriaTercero(auditria)
        this.configuracionesService.crearTerceros(tipoDoc)
        this.toastr.successToastr("Tercero:  creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Tercero Vehículo", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');

    } 



  }

  descargarEstados() {

    this.configuracionesService.getEstado().subscribe(configuraciones => {
  

      this.estados=configuraciones
    
  
    
    });
  }

  descargarTipoDoc() {

    this.configuracionesService.getTiposDocumentos().subscribe(configuraciones => {

      this.tipoDocumentos=configuraciones
  
    
    });
  }


  descargarRespuestas() {

    this.configuracionesService.getRespuestas().subscribe(respuestas => {

      this.respuestas=respuestas
  
    
    });
  }
  descargaranos() {

    this.configuracionesService.getanos().subscribe(configuraciones => {

      this.anos=configuraciones
  
   
    });
  }
 
  descargarDepartamentos() {

    this.configuracionesService.getDepartamentos().subscribe(configuraciones => {

      this.dep=configuraciones
  
    
    });
  }

  descargarCiudades() {

    this.configuracionesService.getCiudades().subscribe(configuraciones => {

      this.ciudades=configuraciones
  
    
    });
  }

  descargarBarrios() {

    this.configuracionesService.getBarrios().subscribe(configuraciones => {

      this.barrios=configuraciones
  
    
    });
  }
  
  descargarmeses() {

    this.configuracionesService.getMes().subscribe(configuraciones => {

      this.meses=configuraciones
  
  
    });
  }

  descargardias() {

    this.configuracionesService.getdia().subscribe(configuraciones => {

      this.dias=configuraciones
  

    });
  }


  sfdia(dato: any) {

    this.s_dia=dato

  }
  sfmes(dato: any) {

    this.s_mes=dato

  }

  sfano(dato: any) {

    this.s_ano=dato

  }

  sdepartamento(dato: any) {

    this.s_departamento=dato

  }

  sciudad(dato: any) {

    this.s_ciudad=dato

  }

  sbarrio(dato: any) {

    this.s_barrio=dato

  }

  stipoDoc(dato: any) {

    this.s_tipo=dato

  }


  sentrega(dato: any) {

    this.s_entrega=dato

  }


  sgestionvial(dato: any) {

    this.s_gestionvial=dato

  }


  sencuestas(dato: any) {

    this.s_encuestas=dato

  }


  sgeoreferenciar(dato: any) {

    this.s_georeferenciar=dato

  }


  ssupervisor(dato: any) {

    this.s_supervisor=dato

  }

  sinformes(dato: any) {

    this.s_informes=dato

  }
  
  sestado(estado: any) {

    this.estadofm=estado

    if(estado=="Activo"){
         this.imgEstadofm="/assets/uactivo.png"
    }

    if(estado=="Bloqueado"){
      this.imgEstadofm="/assets/ubloqueado.png"
    }

    if(estado=="Novedad"){
      this.imgEstadofm="/assets/unovedad.png"
    }

  if(estado=="Inactivo")
      {
    this.imgEstadofm="/assets/uinactivo.png"
   }

  }
}
