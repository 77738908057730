import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
export interface DialogData {
  idTipoDoc: string;
  tipo: string;
  descripcion: string;
  tipoanterior: string;
  descripcionanterior: string;
}

@Component({
  selector: 'app-DialogEditarTipoDoc',
  templateUrl: './DialogEditarTipoDoc.component.html',
  styleUrls: ['./DialogEditarTipoDoc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEditarTipoDoc implements OnInit {

  idTipoDoc: string;
  tipo: string;
  descripcion: string;
  tipoanterior: string;
  descripcionanterior: string;

  constructor(public dialogRef: MatDialogRef<DialogEditarTipoDoc>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearTipoDocumento(data: any) {
   

    console.log('tipodocumento',data);
    if(data.tipo) {

      if(data.descripcion) {

        var tipo = data.tipo.toUpperCase();
        
  
        var descri = data.descripcion;
        var mayusd = descri.substring(0, 1).toUpperCase();
        var restod = descri.substring(1, descri.length).toLowerCase();
        mayusd.concat(restod.toString());

    
        var tipoDoc = {
          idTipoDoc: data.idTipoDoc,
          tipo: tipo,
          descripcion:    mayusd.concat(restod.toString())
    
        }
  
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.idTipoDoc,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Editar',
          tipoanterior:  data.tipoanterior,
          tiponuevo: tipo,
          descractual:mayusd.concat(restod.toString()),
          descranterior:data.descripcionanterior,
        }
    
        this.configuracionesService.crearAuditriaTipoDoc(auditria)
        this.configuracionesService.crearTipoDoc(tipoDoc)
        this.toastr.successToastr("Tipo Documento: "+tipo+ " editado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Ingresar descripción", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Ingresar tipo documento", 'Notificación');

    } 



  }
  
  
}
