import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarTercero } from '../dialogAgregarTercero/dialogAgregarTercero.component';
import { DialogEditarTercero } from '../dialogEditarTercero/dialogEditarTercero.component';
import { DialogAgregarEncuesta } from '../dialogAgregarEncuesta/dialogAgregarEncuesta.component';
import { DialogAgregarEncuestaDetalle } from '../dialogAgregarEncuestaDetalle/dialogAgregarEncuestaDetalle.component';
import { DialogEditarEncuestaDetalle } from '../dialogEditarEncuestaDetalle/dialogEditarEncuestaDetalle.component';
import { DialogEliminarEncuesta } from '../../tipoDoc/dialogEliminarEncuesta/dialogEliminarEncuesta.component';

export interface DialogDataVh {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  correo: string;
  ncelular: string;
  fdia: string;
  fmes: string;
  fano: string;
  departamento: string;
  ciudad: string;
  barrio: string;
  descripcion: string;
  detalle: string;
}
@Component({
  selector: 'app-FragmentTerceroCreacionEncuestas',
  templateUrl: './FragmentTerceroCreacionEncuestas.component.html',
  styleUrls: ['./FragmentTerceroCreacionEncuestas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentTerceroCreacionEncuestas implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  correo: string;
  ncelular: string;
  fdia: string;
  fmes: string;
  fano: string;
  departamento: string;
  ciudad: string;
  barrio: string;
  descripcion: string;
  detalle: string;

   displayedColumns: string[] = ['orden','title','negocio','agencia','tipoOrigen','obligatoria'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getEncuestasCreadas("ENC").subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;
      console.log('encuenstas creadas', configuraciones);
      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getAddConTerceros().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      }  


    });
  }

  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(encunesta: any) {
    console.log('ttipo tercero', encunesta);
    console.log('this.codigo tercero',encunesta.codigo);
    this.dialogagregar.open(DialogEditarEncuestaDetalle, {
      width: '950px',
      data: {agencia: encunesta.agencia,
        id: encunesta.id,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoOrigen: encunesta.tipoOrigen,
        title: encunesta.title,
        idDocument: encunesta.idDocument},
    
    });

  }

  nuevo():void {
    console.log('this.codigo tercero',this.codigo);
    this.dialogagregar.open(DialogAgregarEncuestaDetalle, {
      width: '950px',
      data: {id: this.codigo,tipoOrigen: "ENC"},
    
    });
   
    }

    fecha():void {
      console.log('this.codigo tercero',this.codigo);
      this.dialogagregar.open(DialogAgregarEncuestaDetalle, {
        width: '950px',
        data: {id: this.codigo},
      
      });
     
      }
  
      deleteEncuesta(tipo: any) {
        this.dialogEliminar.open(DialogEliminarEncuesta, {
          width: '350px',
          data: {title: tipo.title,idDocument:tipo.idDocument},
        });
      }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaTercero']);
    
  }
  
}
