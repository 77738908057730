
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { MapsOrdenService } from 'src/app/shared/mapsorden.service';

export interface DialogDataVh {
  nombre:string;
  identificacion:string;
  idTercero:string;
  apellidos:string;
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}

@Component({
  selector: 'app-dialogPendientes',
  templateUrl: './dialogPendientes.component.html',
  styleUrls: ['./dialogPendientes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogPendientes implements OnInit {
  idTipo: string;
  tipo: string;
  descripcion: string;

  lat = 4.570329117;
  lng = -74.0768177;
   zoom: number = 5;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   public markers = new Array();
   public ubicaciones = new Array();
   public rutasmapas = new Array();
   public transportes = new Array();
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public licencias;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  private anos;
  private dias;
  private meses;
  public iconUrlruta = "/assets/camion.png";
  public iconUrl = "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|4286f4";

  constructor(public dialogRef: MatDialogRef<DialogPendientes>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private mapsOrdenService: MapsOrdenService, public toastr: ToastrManager) {}


     displayedColumns: string[] = [ 'hora', 'latitud', 'longitud','direccion_geo'];
     dataSource = new MatTableDataSource();
   
     ngOnInit(): void {
    
       this. descargarubicacionesRuta()
    
     }
   
   
     ngAfterViewInit() {
   
       this.dataSource.paginator = this.paginator;
   
     }
   
   
     applyFilter(event: Event) {
       const filterValue = (event.target as HTMLInputElement).value;
       this.dataSource.filter = filterValue.trim().toLowerCase();
     }
   
   
     descargarTareasMaps() {
     
       localStorage.setItem("rutaindicador", "si");
       
       this.mapsOrdenService.getMapsOrden ().subscribe(mapstareas => {
         console.log("mapstareas",mapstareas);
         
     
         var groupBy = function (xs, key) {
           return xs.reduce(function (rv, x) {
             (rv[x[key]] = rv[x[key]] || []).push(x);
             return rv;
           }, {});
         };
   
         var rutasTareas = groupBy(mapstareas, 'Usuario')
   
         for (let item of Object.keys(rutasTareas)) {
              
           var rutas = rutasTareas[item];
   
   
            var trans = {
             usuario:    rutas[0].Usuario,
           }
            this.transportes.push(trans)
         
         }
   
         this.transportes = Array.from(new Set( this.transportes))
         
        
       
       });
     }

     descargarubicaciones() {


      this.mapsOrdenService.getMapsUbicacionTransporte ().subscribe(mapstareas => {
     
        console.log("mapstareas rutas",mapstareas);
        this.rutasmapas = []
       
        for (let tarea of mapstareas) {
  
          var market = {
            lat:    tarea.longitud,
            lng:   tarea.latitud,
            label: String( tarea.usuario),
            draggable: false,
            cliente: tarea.usuario,
            direccion: tarea.direccion_geo,
            valor:tarea.hora
          }
  
          this.rutasmapas.push(market)
    
        }
        
      
       
  
      
      });
  
    }
   
     transporte(usuario: any) {
   

       this.markers = []
       this.mapsOrdenService.getMapsOrdenUsuario (usuario).subscribe(mapstareas => {
      
       
       
         for (let tarea of mapstareas) {
   
     
           var market = {
             lat:    tarea.lat,
             lng:   tarea.lon,
             label: String( tarea.orden),
             draggable: false,
             cliente: tarea.NombredeCliente,
             direccion: tarea.Direccion,
             valor:tarea.CostoMerc
           }
   
           this.lat=tarea.lat
           this.lng=tarea.lon
   
          this.markers.push(market)
     
         }
         
       

       
       });
   
     }
      descargarubicacionesRuta() {
   
   
       this.mapsOrdenService.getMapsOrdenUsuario ("").subscribe(mapstareas => {
      
         console.log("mapstareas ubicaciones",mapstareas);

         mapstareas.sort((a, b) => {
          return b.id - a.id;
      });



         this.ubicaciones = []
         this.dataSource.data=mapstareas
         var id=0
         for (let tarea of mapstareas) {
   
          id=id+1
           var market = {
             lat:    tarea.longitud,
             lng:   tarea.latitud,
             label: String( id),
             draggable: false,
             cliente: tarea.usuario,
             direccion: tarea.direccion_geo,
             valor:tarea.hora
           }
   
           this.lat=tarea.lat
           this.lng=tarea.lon
   
          this.ubicaciones.push(market)
     
         }
         
         this.descargarubicaciones()

       
       });
   
     }
     clickedMarker(market: any) {
       this.zoom=0
       console.log("market",market);
       this.lat=market.lat
       this.lng=market.lon
       this.zoom=18
   
  
   
     }
   
     onZoomChange() {
       this.zoom=16
   }
   
     onMapReady(map) {
       map.setOptions({
           zoomControl: 'true',
           zoomControlOptions: {
               position: google.maps.ControlPosition.RIGHT_CENTER,
               style: google.maps.ZoomControlStyle.DEFAULT
           }
       });
   }
   
  
}
