import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarMarca } from '../dialogAgregarMarca/dialogAgregarMarca.component';
import { DialogEliminarMarca } from '../dialogEliminarMarca/dialogEliminarMarca.component';
import { DialogEditarMarca } from '../dialogEditarMarca/dialogEditarMarca.component';

export interface DialogData {
  idTipo: string;
  tipo: string;
  descripcion: string;
  tipoanterior: string;
  descripcionanterior: string;
}
@Component({
  selector: 'app-FragmentMarcaVehiculo',
  templateUrl: './FragmentMarcaVehiculo.component.html',
  styleUrls: ['./FragmentMarcaVehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentMarcaVehiculo implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

   idTipoDoc: string;
   tipo: string;
   descripcion: string;
   tipoanterior: string;
   descripcionanterior: string;

   displayedColumns: string[] = ['idTipo', 'tipo','descripcion','editar','eliminar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getTiposMarcas().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getConMarcas().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      }  


    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {

    this.dialog.open(DialogEditarMarca, {
      width: '400px',
      data: {idTipo: tipo.idTipo,
        tipo:tipo.tipo,
        descripcion:tipo.descripcion,
        tipoanterior:tipo.tipo,
        descripcionanterior:tipo.descripcion},
    });
   

  }

  nuevo():void {
    
    this.dialogagregar.open(DialogAgregarMarca, {
      width: '350px',
      data: {idTipo: this.codigo,tipo:'',descripcion:''},
    
    });
  
  
  
    }

  deletebanco(tipo: any) {

    this.dialogEliminar.open(DialogEliminarMarca, {
      width: '350px',
      data: {idTipo: tipo.idTipo,tipo:tipo.tipo,descripcion:tipo.descripcion},
    });
  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaMarca']);
    
  }
  
}
