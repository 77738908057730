import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import * as Mapboxgl from 'mapbox-gl';
import { CurrencyPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { formatDate } from '@angular/common';

export interface Section {
  usuario: string;
  updated: Date;
}

@Component({
  selector: 'app-fragmentGeoTareas',
  templateUrl: './fragmentGeoTareas.component.html',
  styleUrls: ['./fragmentGeoTareas.component.scss']
})

@Pipe({
  name: 'mondeda'
})


export class FragmentGeoTareas implements OnInit {
  mapa: Mapboxgl.Map


  declarations: [
    CurrencyPipe
  ];



  public sumapendientes = 0;
  public sumaexitoso = 0;
  public sumanovedad = 0;
  public sumaparcial = 0;
  public total = 0;
  public estado = 0;
  public longi;
  public datas;
  public clientes;
  public lati;
  public clientesMostrar;
  public pendientes;
  public novedades;
  public exitosas;
  public parcial;
  transportadores: Section[];
  notes: Section[];
  public dateDayrute;

  ngOnInit(): void {

    this.sumapendientes = 0;
    this.sumaexitoso = 0;
    this.sumanovedad = 0;
    this.sumaparcial = 0;
    this.total = 0;

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("pedidoFecha", cValue);

    this.getTransportadores()

    Mapboxgl.accessToken = environment.mapboxkey;
    this.mapa = new Mapboxgl.Map({
      container: 'mapa-mapbox', // container id
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: [-74.0584656, 4.706697], // starting position [lng, lat]
      zoom: 5// starting zoom
    });


    this.orderService.getTareastrasnportador().subscribe(res => {
      if (this.estado == 0) {
      this.sumapendientes = 0
      for (let elemento of res) {
        this.sumapendientes = this.sumapendientes + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'red', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])

          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }


  

        this.pendientes = this.sumapendientes
        this.total = this.sumaexitoso + this.sumanovedad + this.sumaparcial + this.sumapendientes
     
      }
    });


    this.orderService.getTareastrasnportadorENTREGADAS().subscribe(res => {
      this.sumaexitoso = 0
      if (this.estado == 0) {
      for (let elemento of res) {
        this.sumaexitoso = this.sumaexitoso + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'green', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }
     
      this.exitosas = this.sumaexitoso
      this.total = this.sumaexitoso + this.sumanovedad + this.sumaparcial + this.sumapendientes
    }
    });


    this.orderService.getTareastrasnportadorNOVEDADES().subscribe(res => {
      this.sumanovedad = 0
      if (this.estado == 0) {
      for (let elemento of res) {
        this.sumanovedad = this.sumanovedad + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'orange', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }

      this.novedades = this.sumanovedad
      this.total = this.sumaexitoso + this.sumanovedad + this.sumaparcial + this.sumapendientes
    }
    });


    this.orderService.getTareastrasnportadorPARCIAL().subscribe(res => {
      this.sumaparcial = 0
      if (this.estado == 0) {
      for (let elemento of res) {
        this.sumaparcial = this.sumaparcial + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'purple', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }
  
      this.parcial = this.sumaparcial
      this.total = this.sumaexitoso + this.sumanovedad + this.sumaparcial + this.sumapendientes
    }

    });

    this.orderService.getTareastrasnportadorgps().subscribe(res => {
      if (this.estado == 0) {
      for (let elemento of res) {
        var marker = new Mapboxgl.Marker({
          color: 'blue',
          draggable: false

        })

          .setLngLat([elemento.longi, elemento.lati])
          .addTo(this.mapa)
        marker.getElement().addEventListener('click', () => {


          this.mapa.flyTo({
            center: [
              elemento.longi, elemento.lati
            ], zoom: 14,
            essential: true
          });



          console.log('le di click al marcador ', marker);


        });


        console.log("se elmina ", elemento.idEmp);

      }

    }

    });


  }


  constructor(private orderService: OrdersService, private userd: AuthService) {

  }

  getTransportadores() {

    this.orderService.getTransportadores().subscribe(res => {
      this.transportadores = res;
      console.log('vsliar op', res);

    });
  }


  transporte(placa: any) {


    console.log("la placa es  ", placa);

    this.sumapendientes = 0;
    this.sumaexitoso = 0;
    this.sumanovedad = 0;
    this.sumaparcial = 0;
    this.total = 0;

    this.estado = 1;

    var usuario;

    Mapboxgl.accessToken = environment.mapboxkey;
    this.mapa = new Mapboxgl.Map({
      container: 'mapa-mapbox', // container id
      style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
      center: [-74.0584656, 4.706697], // starting position [lng, lat]
      zoom: 5// starting zoom
    });

    this.orderService.getTareasENTREGADAS(placa).subscribe(res => {
      this.sumaexitoso = 0
      console.log("las tareas por entregar ", res);
      for (let elemento of res) {
        this.sumaexitoso = this.sumaexitoso + 1
        var num = elemento.CostoMerc;
        usuario=elemento.Placa
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'green', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }

      this.exitosas = this.sumaexitoso
    });

    this.orderService.getTareastrasnpor(placa).subscribe(res => {
      this.sumapendientes = 0
      for (let elemento of res) {
        this.sumapendientes = this.sumapendientes + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'red', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])

          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }

      this.pendientes = this.sumapendientes
    });

    this.orderService.getTareasNOVEDADES(placa).subscribe(res => {


      for (let elemento of res) {
        this.sumanovedad = 0
        this.sumanovedad = this.sumanovedad + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'orange', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }
      this.novedades = this.sumanovedad

    });

    this.orderService.getTareasPARCIAL(placa).subscribe(res => {
      this.sumaparcial = 0
      for (let elemento of res) {
        this.sumaparcial = this.sumaparcial + 1
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var marker = new Mapboxgl.Marker({ color: 'purple', draggable: false })
          .setPopup(new Mapboxgl.Popup({ offset: 25 })
            .setHTML('<h3>' + elemento.NombredeCliente.toUpperCase() + '</h3><p>'
              + '<h6>Cliente<h6>' + elemento.NombredeCliente + '</p>'
              + '</h3><p>' + '<h6>Envio<h6>' + elemento.Direccion
              + '</p>' + '</p>' + '</h3><p>' + '<h6>Pedido<h6>'
              + twoPlacedFloat + '</p>'))
          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        marker.getElement().addEventListener('click', () => {
          this.mapa.flyTo({ center: [elemento.lon, elemento.lat], zoom: 14, essential: true });

        });

      }
      this.parcial = this.sumaparcial
      this.total = this.sumaexitoso + this.sumanovedad + this.sumaparcial + this.sumapendientes

    });


    this.orderService.getTareasUsuario(placa).subscribe(res => {
   
      for (let elemento of res) {
       
        usuario=elemento.Placa
      }

    
      this.orderService.getTareastrasnportadorgpsplaca(usuario).subscribe(res => {

        for (let elemento of res) {
          var marker = new Mapboxgl.Marker({
            color: 'blue',
            draggable: false
  
          })
  
  
            .setPopup(new Mapboxgl.Popup({ closeOnClick: false })
            .setHTML('<h3>' + placa.toUpperCase() + '</h3><p>' + '<h6>Hora<h6>' + elemento.fecha))
  
            .setLngLat([elemento.longi, elemento.lati])
            .addTo(this.mapa)
          marker.getElement().addEventListener('click', () => {
  
  
            this.mapa.flyTo({
              center: [
                elemento.longi, elemento.lati
              ], zoom: 14,
              essential: true
            });
  
  
  
            console.log('le di click al marcador ', marker);
  
  
          });
  
  
          console.log("se elmina ", elemento.idEmp);
  
        }
  
      });
  
    });

  }


}
