import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { timer, combineLatest } from 'rxjs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ClientesaprobacionService {

  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private clienteAprobacincollection: AngularFirestoreCollection<any>;
  private clienteAprobacion: Observable<any[]>;
  private mesconsulta;
  constructor(private readonly db: AngularFirestore, private userd: AuthService) {


  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + ''+ EXCEL_EXTENSION);
  }


  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");


   //*  consultar los clientes del maestro //
  getClienteAprobacion() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.clienteAprobacincollection = this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('estado', '==', "PENDIENTE"));
    this.clienteAprobacion = this.clienteAprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.clienteAprobacion;


  }

 

  getClienteAprobacionAprobacion() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.clienteAprobacincollection = this.db.collection<any>('clientesActulizacionODL/', ref => ref.where('estado', '==', "APROBADO"));
    this.clienteAprobacion = this.clienteAprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

  
    return this.clienteAprobacion;


  }

  getClienteAprobacionAprobacionmes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.clienteAprobacincollection = this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('estado', '==', "APROBADO").where('fc', '==', "2021/07/29"));
    this.clienteAprobacion = this.clienteAprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

  
    return this.clienteAprobacion;


  }

  getmes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");
    console.log('agenc pruebas', this.agencia);

   return combineLatest(
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/01")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/02")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/03")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/04")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/05")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/06")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/07")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/08")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/09")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/10")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/11")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/12")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/13")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/14")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/15")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/16")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/17")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/18")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/19")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/20")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/21")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/22")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/23")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/24")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/25")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/26")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/27")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/28")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/29")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/30")).valueChanges(),
      this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==',"2021/"+this.mesconsulta+"/31")).valueChanges(), 
    )

  }


  getClienteAprobacionRfv(rfv:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.clienteAprobacincollection = this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('rfv', '==', rfv));
    this.clienteAprobacion = this.clienteAprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.clienteAprobacion;


  }

  getClienteNuevosDia(dia:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.clienteAprobacincollection = this.db.collection<any>('ClientesOdlAprobacion/', ref => ref.where('fc', '==', dia));
    this.clienteAprobacion = this.clienteAprobacincollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.clienteAprobacion;


  }

  createCliente(prefijo: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.clienteAprobacincollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "prefijos");
   
    return this.clienteAprobacincollection.doc().set(prefijo);

  }


}


