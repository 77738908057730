import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class IndicadoresService {

  getClientes(): Observable<any> {
    return this.http.get<any>('https://appggcuba.azure-api.net/api/getClienteGoCuba');
  }
  
  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private IndicadoresCollection: AngularFirestoreCollection<any>;
  private Indicadores: Observable<any[]>;

  private IndicadoresNovedadCollection: AngularFirestoreCollection<any>;
  private IndicadoresNovedad: Observable<any[]>;

  constructor(private http: HttpClient,private readonly db: AngularFirestore, private userd: AuthService) {


  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + ''+ EXCEL_EXTENSION);
  }

  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");

   //*  consultar los clientes del maestro //
   getIndicadores() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.IndicadoresCollection = this.db.collection<any>('tareascargadas/gocuba_cuba/'+this.fecha);
    this.Indicadores = this.IndicadoresCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.Indicadores;
  }

    getIndicadoresNovedad() {
    console.log("tareasindicadores NOVEDAD ENTRA AL METDO");
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.IndicadoresNovedadCollection = this.db.collection<any>('clientGoCuba/maestros/registros');
    this.IndicadoresNovedad = this.IndicadoresNovedadCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.IndicadoresNovedad;
  }

}


