import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import * as Mapboxgl from 'mapbox-gl';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MapsOrdenService } from 'src/app/shared/mapsorden.service';


@Component({
  selector: 'app-fragmentIndicadoresRuta',
  templateUrl: './fragmentIndicadoresRuta.component.html',
  styleUrls: ['./fragmentIndicadoresRuta.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoresRuta implements OnInit {
  mapa: Mapboxgl.Map
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;
  public ubicacionesExitosas = new Array();
  private negocio: any;
  private agencia: any;
  private usuario: any;
  private txtTotalFac: any;
  private txtTotalFacRuta: any;
  private txtPesoKG: any;
  private txtPesoKGDe: any;
  private txtPedidos: any;
  private txtPedidosRuta: any;
  private txtDias: any;
  private txtRutas: any;
  private txtNovedades: any;
  private txtPedidosDev: any;
  private txtPedidosDevZona: any;
  private txtCanGrupo: any;
  private txtDevGrupo: any;
  private txtGrupoPedidos: any;
  private txtGrupoDev: any;
  private txttipoNovedad: any;
  private txtFecha: any;
  private txtRutaInd: any;

  //novedades

  private txtCanCliente: any;
  private txtCanComercial: any;
  private txtCanPlanta: any;
  private txtCanTransporte: any;
  private txtCanTerceros: any;

  private txtCosCliente: any;
  private txtCosComercial: any;
  private txtCosPlanta: any;
  private txtCosTransporte: any;
  private txtCosTerceros: any;

  private totalParcial: any;
  private canParcial: any;

  private totalParcialExi: any;
  private canParcialExi: any;

  private totalExitoso: any;
  private canExitoso: any;

  private totalPendiente: any;
  private canPendiente: any;

  private totalNovedad: any;
  private canNovedad: any;

  private totalGeneral: any;
  private canGeneral: any;

  private datospruebas: any;


  private datosNovCan: any;
  private datosNovValor: any;
  private datosPedidosRutas: any;
  private datosPedidosRutasValor: any;
  private datosEstadosValor: any;
  private datosZonas: any;
  private datosTipoNov: any;
  private datosNov: any;



  private tareasDescargaNovedad: any;
  private tareasDescargaParcial: any;
  private tareasDescargaPendiente: any;
  private tareasDescargaExitosas: any;
  private rutasDescarga: any;
  private usuarioUnicos: any;
  public  latlng = [];
  public start_end_mark = [];
  public start_end_mark_fin = [];
  lat = 4.570329117;
  lng = -74.0768177;
   zoom: number = 5;
  subscriptionruta: Subscription;
  public ubicaciones = new Array();
  myData = [
    { data: [1, 1, 3], name: "first" }
  ];

  private path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }
 
  public iconUrlNovedad = "/assets/bnovedad.svg";
  public iconUrlParcial = "/assets/bparcial.svg";
  public iconUrlDos = "/assets/bexitosa.svg";
  public iconUrlCarro = "/assets/trackrojo.svg";

  public iconUrlruta = "/assets/trackrojo.svg";
  public iconUrl = "/assets/bpendiente.svg";
  public iconUrlflecha = "/assets/flechaderecha.svg";
  public iconUrltrasfin = "/assets/trackrojo.svg";

  public iconUrltras = "/assets/cajatrack.svg";
 public iconUrltrasInicio = "/assets/banderasalida.png";

  constructor(private mapsOrdenService: MapsOrdenService,private route: Router,private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }

  displayedColumns: string[] = ['Zona', 'NombredeCliente', 'agencia'];
  displayedColumnsDev: string[] = ['Zona', 'NombredeCliente', 'agencia', 'Peso neto', 'CostoMerc'];
  displayedColumnsDevZona: string[] = ['idZona', 'Pedidos', 'CostoMerc', 'Dev', 'CanDevoluciones', 'Porcentaje'];
  displayedColumnsNov: string[] = ['detalle', 'Pedidos', 'CostoMerc', 'Porcentaje'];
  displayedColumnsTipo: string[] = ['name', 'Pedidos', 'y'];
  displayedColumnsEstado: string[] = ['name', 'y', 'CostoMerc'];
  displayedColumnsEstadoValor: string[] = ['name', 'y', 'CostoMerc'];
  displayedColumnsRuta: string[] = ['name', 'txtTotalFac', 'txtPedidos', 'CanExi', 'CanParExi', 'CanPen','CanNov'];
  displayedColumnsRutaValor: string[] = ['name', 'txtTotalFac', 'CostoMercExi', 'CostoParMercExi','CostoParMercNov', 'CostoMercPen','CostoMercNov'];

  dataSource = new MatTableDataSource();
  dataSourceDev = new MatTableDataSource();
  dataSourceDevZona = new MatTableDataSource();
  dataSourceNov = new MatTableDataSource();
  dataSourceTipo = new MatTableDataSource();
  dataSourceEstado = new MatTableDataSource();
  dataSourceEstadoValor = new MatTableDataSource();
  dataSourceRuta= new MatTableDataSource();
  dataSourceRutaValor= new MatTableDataSource();


  ngOnInit(): void {

    
    localStorage.setItem("rutaindicador", "si");

    this.descargarrutas();

  if (localStorage.getItem('pedidoFecha').length > 3) {


      

    } else {

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

    }

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      
      this.descargarrutas();

    } else {


      this.subscriptionruta.unsubscribe();


    }



  }

 

  clickedMarker(market: any) {
    
    console.log("market USUARIO",market);
    this.lat=market.lat
    this.lng=market.lon
    this.zoom=18
 

  }



  onMapReady(map) {
    map.setOptions({
        zoomControl: 'true',
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            style: google.maps.ZoomControlStyle.DEFAULT
        }
    });

}

  descargarrutas() {
    var ruta=localStorage.getItem("txtRutaInd") 
    console.log("txtRutaInd", ruta);
    this.subscriptionruta = this.orderService.getTareasdiaRura(ruta).subscribe(taresrutadia => {
      console.log("tareas  antes del filtro", taresrutadia);
  

      this.getTareasRutas(taresrutadia.filter(tarea => tarea.RutaVLI ==ruta));
      this.transporte(ruta,taresrutadia.filter(tarea => tarea.RutaVLI ==ruta))

      this.subscriptionruta.unsubscribe();




    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceDev.paginator = this.paginatordev;
    this.dataSourceDevZona.paginator = this.paginatorzona;
    this.dataSourceNov.paginator = this.paginatornov;
    this.dataSourceTipo.paginator = this.paginatortipo;
    this.dataSourceEstado.paginator = this.paginatorestado;
    this.dataSourceRuta.paginator = this.paginatorruta;
    this.dataSourceRutaValor.paginator = this.paginatorrutavalor;
    this.dataSourceEstadoValor.paginator = this.paginatorestadovalor;
  }

  getTareasRutas(tareas:any) {
    this.txtFecha=localStorage.getItem("ordenTransporteFecha");
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.usuario = localStorage.getItem("correo");
    let tareasNovedadeS =tareas.filter(tarea => tarea.estado == "NOVEDAD")

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutas = groupBy(tareas, 'RutaVLI')
    let zonasODL = groupBy(tareas, 'Zona')
    let novedadODL = groupBy(tareasNovedadeS, 'observaciones')


    this.dataSource.data = tareasNovedadeS;
    this.dataSourceDev.data = tareasNovedadeS;

    this.txtTotalFac = 0;
    this.txtPesoKG = 0;
    this.txtPesoKGDe = 0;
    this.txtPedidos = 0;
    this.txtNovedades = 0;
    this.txtPedidosDev = 0;

    this.totalParcial = 0;
    this.canParcial = 0;
    this.totalParcialExi = 0;
    this.canParcialExi = 0;
    this.totalExitoso = 0;
    this.canExitoso = 0;
    this.totalNovedad = 0;
    this.canNovedad = 0;
    this.totalPendiente = 0;
    this.canPendiente = 0;
    this.totalGeneral = 0;
    this.canGeneral = 0;

    let novEstCan = []
    let novEstVal = []
    for (let tarea of tareas) {


      var zonas = {
        idZona: tarea.Zona,
      };

      this.canGeneral = this.canGeneral + 1

      this.orderService.createZonaAgrupadas(zonas);

      this.txtTotalFac = this.txtTotalFac + tarea.CostoMerc
      this.txtPesoKG = this.txtPesoKG + tarea["Peso neto"]
      this.txtPedidos = this.txtPedidos + 1

      if (tarea.estado == "EXITOSA") {


        this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
        this.canExitoso = this.canExitoso + 1

      }

      if (tarea.estado == "PENDIENTE") {


        this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
        this.canPendiente = this.canPendiente + 1


      }

      if (tarea.estado == "NOVEDAD") {

        this.txtNovedades = this.txtNovedades + tarea.CostoMerc
        this.txtPedidosDev = this.txtPedidosDev + 1
        this.txtPesoKGDe = this.txtPesoKGDe + tarea["Peso neto"]

        this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
        this.canNovedad = this.canNovedad + 1

      }

      if (tarea.estado == "PARCIAL") {

        this.txtNovedades = this.txtNovedades + (tarea.devolucion * 1)

        this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
        this.canParcial = this.canParcial + 1


        this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
        this.canParcialExi = this.canParcialExi + 1


      }

    }

    var estadoPar = {
      name: "Nov Parcial",
      y: this.canParcial,
      CostoMerc: this.totalParcial,
    };

    novEstCan.push(estadoPar)

    var estadoPar = {
      name: "Exi Parcial",
      y: this.canParcialExi,
      CostoMerc: this.totalParcialExi,
    };

    novEstCan.push(estadoPar)


    var estadoPen = {
      name: "Pendiente",
      y: this.canPendiente,
      CostoMerc: this.totalPendiente,
    };

    novEstCan.push(estadoPen)


    var estadoExi = {
      name: "Exitosa",
      y: this.canExitoso,
      CostoMerc: this.totalExitoso,
    };

    novEstCan.push(estadoExi)


    var estadoNov = {
      name: "Novedad",
      y: this.canNovedad,
      CostoMerc: this.totalNovedad,
    };

    novEstCan.push(estadoNov)


    this.datosNovCan = novEstCan
    this.dataSourceEstado.data = this.datosNovCan
    //estados

    var estadoPen = {
      name: "Pendiente",
      y: this.totalPendiente,
      CostoMerc: this.canPendiente,
    };

    novEstVal.push(estadoPen)

    var estadoExi = {
      name: "Exitosa",
      y: this.totalExitoso,
      CostoMerc: this.canExitoso,
    };

    novEstVal.push(estadoExi)

    var estadoNov = {
      name: "Novedad",
      y: this.totalNovedad,
      CostoMerc: this.canNovedad,
    };

    novEstVal.push(estadoNov)

    var estadoPar = {
      name: "Nov Parcial",
      y: this.totalParcial,
      CostoMerc: this.canParcial,
    };

    novEstVal.push(estadoPar)

    var estadoPar = {
      name: "Exi Parcial",
      y: this.totalParcialExi,
      CostoMerc: this.totalParcialExi,
    };

    novEstVal.push(estadoPar)

  

 


    this.datosNovValor = novEstVal;
    this.datosPedidosRutasValor = novEstVal;



    this.getTransportadores(rutas, tareas)
    this.getZonas(zonasODL,tareas);
    this.getNovedades(novedadODL)



  }


  getTransportadores(rutasvli: any, tareasruta:any) {


    this.txtRutas = localStorage.getItem("txtRutaInd") ;
    this.txtDias=1
    let tareasRecaudo = []
    let tareasRecaudoValor = []


    for (let item of Object.keys(rutasvli)) {
      var rutas = rutasvli[item];
   
      let tareas = tareasruta.filter(tarea => tarea.RutaVLI == rutas[0].RutaVLI)


      this.totalParcial = 0;
      this.canParcial = 0;
      this.totalParcialExi = 0;
      this.canParcialExi = 0;
      this.totalExitoso = 0;
      this.canExitoso = 0;
      this.totalNovedad = 0;
      this.canNovedad = 0;
      this.totalPendiente = 0;
      this.canPendiente = 0;


      this.txtTotalFacRuta = 0;
      this.txtPedidosRuta = 0;

      for (let tarea of tareas) {


        this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
        this.txtPedidosRuta = this.txtPedidosRuta + 1

        if (tarea.estado == "EXITOSA") {


          this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
          this.canExitoso = this.canExitoso + 1

        }

        if (tarea.estado == "PENDIENTE") {


          this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
          this.canPendiente = this.canPendiente + 1


        }

        if (tarea.estado == "NOVEDAD") {

          this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
          this.canNovedad = this.canNovedad + 1

        }

        if (tarea.estado == "PARCIAL") {

          this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
          this.canParcial = this.canParcial + 1


          this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
          this.canParcialExi = this.canParcialExi + 1


        }
      }


      var estadoPar = {

        name: rutas[0].RutaVLI,

        CanParExi: this.canParcialExi,
        CostoParMercExi: this.totalParcialExi,

        CanParNov: this.canParcial,
        CostoParMercNov: this.totalParcial,

        CanExi: this.canExitoso,
        CostoMercExi: this.totalExitoso,

        CanPen: this.canPendiente,
        CostoMercPen: this.totalPendiente,

        CanNov: this.canNovedad,
        CostoMercNov: this.totalNovedad,

        y: this.txtPedidosRuta,

        txtTotalFac: this.txtTotalFacRuta,
        txtPedidos: this.txtPedidosRuta,

        txtPedPor: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
        txtNovPor: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
        txtExiPor: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
        txtParPor: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

        txtPedPorM: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
        txtNovPorM: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
        txtExiPorM: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
        txtParPorM: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

      };

      tareasRecaudo.push(estadoPar)

      var estadoPardos = {

        name: rutas[0].RutaVLI,

        CanParExi: this.canParcialExi,
        CostoParMercExi: this.totalParcialExi,

        CanParNov: this.canParcial,
        CostoParMercNov: this.totalParcial,

        CanExi: this.canExitoso,
        CostoMercExi: this.totalExitoso,

        CanPen: this.canPendiente,
        CostoMercPen: this.totalPendiente,

        CanNov: this.canNovedad,
        CostoMercNov: this.totalNovedad,

        y: this.txtTotalFacRuta,

        txtTotalFac: this.txtTotalFacRuta,
        txtPedidos: this.txtPedidosRuta,

        txtPedPor: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
        txtNovPor: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
        txtExiPor: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
        txtParPor: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

        txtPedPorM: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
        txtNovPorM: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
        txtExiPorM: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
        txtParPorM: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

      };

      tareasRecaudoValor.push(estadoPardos)

      this.dataSourceRuta.data = tareasRecaudo;
      this.dataSourceRutaValor.data = tareasRecaudo;
      this.datosPedidosRutas = tareasRecaudo;
      this.datosEstadosValor = tareasRecaudoValor;



    }

  }

  getZonas(zonasODL: any, tareasruta:any) {

    let zonasEnvio = []
    for (let item of Object.keys(zonasODL)) {

      let tareas = tareasruta.filter(tarea => tarea.Zona == item)

      this.txtCanGrupo = 0;
      this.txtDevGrupo = 0;
      this.txtGrupoDev = 0;
      this.txtGrupoPedidos = 0;
      for (let tarea of tareas) {

        this.txtGrupoPedidos = this.txtGrupoPedidos + 1
        this.txtCanGrupo = this.txtCanGrupo + tarea.CostoMerc
        if (tarea.estado == "NOVEDAD") {

          this.txtDevGrupo = this.txtDevGrupo + tarea.CostoMerc
          this.txtGrupoDev = this.txtGrupoDev + 1

        }

        if (tarea.estado == "PARCIAL") {

          this.txtDevGrupo = this.txtDevGrupo + (tarea.devolucion * 1)

        }

      }

      var zonasenvio = {
        idZona: item,
        name: item,
        CostoMerc: this.txtCanGrupo,
        Dev: this.txtDevGrupo,
        y: this.txtDevGrupo,
        Pedidos: this.txtGrupoPedidos,
        CanDevoluciones: this.txtGrupoDev,
        Porcentaje: (this.txtGrupoDev * 100) / this.txtGrupoPedidos,
      };


      zonasEnvio.push(zonasenvio);





    }

    this.dataSourceDevZona.data = zonasEnvio
    this.datosZonas = zonasEnvio;



  }

  getNovedades(novedadODL: any) {


    let novedadesGeneral = []
    let tiposGeneral = []


    this.txtCanCliente = 0
    this.txtCanComercial = 0
    this.txtCanPlanta = 0
    this.txtCanTransporte = 0
    this.txtCanTerceros = 0

    this.txtCosCliente = 0
    this.txtCosComercial = 0
    this.txtCosPlanta = 0
    this.txtCosTransporte = 0
    this.txtCosTerceros = 0

    for (let item of Object.keys(novedadODL)) {

      var tareas = novedadODL[item];

      this.txtCanGrupo = 0;
      this.txtDevGrupo = 0;
      this.txtGrupoDev = 0;
      this.txtGrupoPedidos = 0;
      for (let tarea of tareas) {

        this.txtGrupoPedidos = this.txtGrupoPedidos + 1
        this.txtCanGrupo = this.txtCanGrupo + tarea.CostoMerc
        if (tarea.estado == "NOVEDAD") {

          this.txtDevGrupo = this.txtDevGrupo + tarea.CostoMerc
          this.txtGrupoDev = this.txtGrupoDev + 1

        }

        if (tarea.observaciones == "Cliente no tiene Dinero") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc
        }

        if (tarea.observaciones == "Negocio Cerrado") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Problema Logistico Cliente") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cliente no tenía los Cambios") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Ventana Horaria Cliente") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cliente no Existe") {

          this.txttipoNovedad = "Comercial"
          this.txtCosComercial = this.txtCosComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Pedido Repetido") {

          this.txttipoNovedad = "Comercial"
          this.txtCosComercial = this.txtCosComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Direccion Errada") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Mercancia no solicitada por el cliente") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Diferencia en negociacion") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Producto no roto(Dev en buen estado)") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Baja Rotación") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Fuera de frecuencia") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "sobre stock") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cambios de Factura y/o Periodo Cerrado") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Bonificaciones") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cambios de Factura y/o Periodo Actual") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "PEDIDO PARCIAL") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "No recibe hasta que le envien cambios") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Producto Averiado(Dev mal estado)") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Producto vencido(Dev mal estado)") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Producto mal Presentado") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Producto facturado no despachado") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Sistemas de Informacion") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Calidad") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "No cumple condiciones") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Problema en Distribuciones.") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Problema Logistico Parmalat") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Orden cerrada/vencida") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Falta de mercadeo") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Carga critica insuficiente") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Fechas de vencimiento de los articulos despachados") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Producto facturado no despachado Industrial") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Desastres naturales") {

          this.txttipoNovedad = "Terceros"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Orden publico") {

          this.txttipoNovedad = "Terceros"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Servicio deficiente en la entrega") {

          this.txttipoNovedad = "Transporte"

          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cliente no visitado") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Carro Varado") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Siniestro del transportador") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }


        if (tarea.observaciones == "Horario") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Temperatura") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }


      }

      var zonasenvio = {
        detalle: item,
        name: item,
        CostoMerc: this.txtCanGrupo,
        Dev: this.txtDevGrupo,
        y: this.txtGrupoPedidos,
        Pedidos: this.txtGrupoPedidos,
        CanDevoluciones: this.txtGrupoDev,
        tipo: this.txttipoNovedad,
        Porcentaje: (this.txtGrupoDev * 100) / this.txtGrupoPedidos,
      };

      novedadesGeneral.push(zonasenvio)


    }
    this.datosNov = novedadesGeneral
    this.dataSourceNov.data = novedadesGeneral;
    var tipoenvio = {
      name: "Transporte",
      y: this.txtCosTerceros,
      Pedidos: this.txtCanTerceros,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Cliente",
      y: this.txtCosCliente,
      Pedidos: this.txtCanCliente,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Comercial",
      y: this.txtCosComercial,
      Pedidos: this.txtCanComercial,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Planta Chia",
      y: this.txtCosPlanta,
      Pedidos: this.txtCanPlanta,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Terceros",
      y: this.txtCosTerceros,
      Pedidos: this.txtCanTerceros,
    };
    tiposGeneral.push(tipoenvio)

    this.datosTipoNov = tiposGeneral;
    this.dataSourceTipo.data = tiposGeneral;



    //this.getTipo(novedadesGeneral)

  }

  getTipo(res: any) {

    let tiposGeneral = []





    this.txtCanCliente = 0
    this.txtCanComercial = 0
    this.txtCanPlanta = 0
    this.txtCanTransporte = 0
    this.txtCanTerceros = 0

    this.txtCosCliente = 0
    this.txtCosComercial = 0
    this.txtCosPlanta = 0
    this.txtCosTransporte = 0
    this.txtCosTerceros = 0
    var tipoenvio: any;
    var tipoenvioCo: any;
    var tipoenvioTertipoenvioPla: any;
    var tipoenvioTer: any;
    for (let tiposapp of res) {

      if (tiposapp.tipo == "Transporte") {

        this.txtCanTransporte = this.txtCanTransporte + tiposapp.Pedidos
        this.txtCosTransporte = this.txtCosTransporte + tiposapp.CostoMerc

        tipoenvio = {
          name: tiposapp.tipo,
          y: this.txtCosTransporte,
          Pedidos: this.txtCanTransporte,
        };




      }

      if (tiposapp.tipo == "Cliente") {

        this.txtCanCliente = this.txtCanCliente + tiposapp.Pedidos
        this.txtCosCliente = this.txtCosCliente + tiposapp.CostoMerc
        var tipoenvioCli = {
          name: tiposapp.tipo,
          y: this.txtCosCliente,
          Pedidos: this.txtCanCliente,
        };



      }

      if (tiposapp.tipo == "Comercial") {

        this.txtCanComercial = this.txtCanComercial + tiposapp.Pedidos
        this.txtCosComercial = this.txtCosComercial + tiposapp.CostoMerc

        tipoenvioCo = {
          name: "Comercial",
          y: this.txtCosComercial,
          Pedidos: this.txtCanComercial,
        };



      }

      if (tiposapp.tipo == "Planta Chia") {

        this.txtCanPlanta = this.txtCanPlanta + tiposapp.Pedidos
        this.txtCosPlanta = this.txtCosPlanta + tiposapp.CostoMerc
        tipoenvioTertipoenvioPla = {
          name: tiposapp.tipo,
          y: this.txtCosPlanta,
          Pedidos: this.txtCanPlanta,
        };



      }

      if (tiposapp.tipo == "Terceros") {

        this.txtCanTerceros = this.txtCanTerceros + tiposapp.Pedidos
        this.txtCosTerceros = this.txtCosTerceros + tiposapp.CostoMerc

        tipoenvioTer = {
          name: tiposapp.tipo,
          y: this.txtCosTerceros,
          Pedidos: this.txtCanTerceros,
        };

      }


    }


    // tiposGeneral.push(tipoenvioTer)
    console.log("tipoenvioTer ", tipoenvioTer);
    // tiposGeneral.push(tipoenvioTertipoenvioPla)
    console.log("tipoenvioTertipoenvioPla ", tipoenvioTertipoenvioPla);
    //   tiposGeneral.push(tipoenvioCo)
    console.log("tipoenvioCo ", tipoenvioCo);
    //   tiposGeneral.push(tipoenvioCli)
    console.log("tipoenvioCli ", tipoenvioCli);
    //    tiposGeneral.push(tipoenvio)
    console.log("tipoenvio ", tipoenvio);

    this.datosTipoNov = tiposGeneral;
  }


  

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


    const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
 this.toastr.successToastr("Cambio de fecha "+cValue, 'Notificación');
    localStorage.setItem("pedidoFecha", cValue);
    console.log("fecha q se guardo en el sistema", cValue);

    localStorage.setItem("cargar", 'exitoso');
  
    this.descargarrutas();

  }

  ngOnDestroy() {
    this.subscriptionruta.unsubscribe()

  }


  transporte(placa: any, tareas:any) {



    this.tareasDescargaNovedad  = tareas.filter(tarea => tarea.estado == "NOVEDAD")
    this.tareasDescargaParcial  = tareas.filter(tarea => tarea.estado == "PARCIAL")
    this.tareasDescargaExitosas  = tareas.filter(tarea => tarea.estado == "EXITOSA")
    this.tareasDescargaPendiente  = tareas.filter(tarea => tarea.estado == "PENDIENTE")
    this.usuarioUnicos = [...new Set(tareas.map(tarea => tarea.usuario_app))];
  

    var usuario;

    

    this.ubicacionesExitosas = []

      for (let elemento of  this.tareasDescargaExitosas) {
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var market = {
          lat:    elemento.lat,
          lng:   elemento.lon,
          label: "Entrega",
          draggable: false,
          cliente: elemento.NombredeCliente.toUpperCase(),
          direccion: elemento.Direccion,
          valor:twoPlacedFloat,
          iconUrl:this.iconUrlDos
        }
        
  
      
  
       this.ubicacionesExitosas.push(market)

 
       var market = {
        lat:    elemento.latTarea,
        lng:   elemento.lonTarea,
        label: "Entrega",
        draggable: false,
        cliente: elemento.NombredeCliente.toUpperCase(),
        direccion: elemento.Direccion,
        valor:twoPlacedFloat,
        iconUrl:this.iconUrltras
      }
      

     this.ubicacionesExitosas.push(market)
       

      }


      



      for (let elemento of  this.tareasDescargaPendiente) {
  
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var market = {
          lat:    elemento.lat,
          lng:   elemento.lon,
          label: "Entrega",
          draggable: false,
          cliente: elemento.NombredeCliente.toUpperCase(),
          direccion: elemento.Direccion,
          valor:twoPlacedFloat,
          iconUrl:this.iconUrl
        }
        
  

  
       this.ubicacionesExitosas.push(market)
      
       var market = {
        lat:    elemento.latTarea,
        lng:   elemento.lonTarea,
        label: "Entrega",
        draggable: false,
        cliente: elemento.NombredeCliente.toUpperCase(),
        direccion: elemento.Direccion,
        valor:twoPlacedFloat,
        iconUrl:this.iconUrltras
      }
      

     this.ubicacionesExitosas.push(market)
       

      }



      for (let elemento of  this.tareasDescargaNovedad) {
 
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var market = {
          lat:    elemento.lat,
          lng:   elemento.lon,
          label: "Entrega",
          draggable: false,
          cliente: elemento.NombredeCliente.toUpperCase(),
          direccion: elemento.Direccion,
          valor:twoPlacedFloat,
          iconUrl:this.iconUrlNovedad
        }
        
  
       this.ubicacionesExitosas.push(market)

       var market = {
        lat:    elemento.latTarea,
        lng:   elemento.lonTarea,
        label: "Entrega",
        draggable: false,
        cliente: elemento.NombredeCliente.toUpperCase(),
        direccion: elemento.Direccion,
        valor:twoPlacedFloat,
        iconUrl:this.iconUrltras
      }
      

     this.ubicacionesExitosas.push(market)
       

      }

      for (let elemento of  this.tareasDescargaParcial) {
    
        var num = elemento.CostoMerc;
        var twoPlacedFloat = parseFloat(num).toFixed(2)
        var market = {
          lat:    elemento.lat,
          lng:   elemento.lon,
          label: "Entrega",
          draggable: false,
          cliente: elemento.NombredeCliente.toUpperCase(),
          direccion: elemento.Direccion,
          valor:twoPlacedFloat,
          iconUrl:this.iconUrlParcial
        }
  
       this.ubicacionesExitosas.push(market)

       var market = {
        lat:    elemento.latTarea,
        lng:   elemento.lonTarea,
        label: "Entrega",
        draggable: false,
        cliente: elemento.NombredeCliente.toUpperCase(),
        direccion: elemento.Direccion,
        valor:twoPlacedFloat,
        iconUrl:this.iconUrltras
      }
      

     this.ubicacionesExitosas.push(market)
       

      }

      var carro = ""
      for (let elemento of  this.usuarioUnicos) {
    
        this.orderService.getTareastrasnportadorgpsplaca(elemento).subscribe(res => {
  
          console.log('usuario', JSON.stringify(res));
  
          for (let elemento of  res) {
          
            this.descargarubicacionesRuta(elemento.usuario)
     
            var market = {
              lat:  parseFloat(elemento.longitud),
              lng: parseFloat(elemento.latitud),
              label: "Entrega",
              draggable: false,
              cliente: elemento.usuario,
              direccion: elemento.direccion_geo,
              valor:elemento.hora,
              iconUrl:this.iconUrlCarro
            }
            console.log('market', market);
      
      
           this.ubicacionesExitosas.push(market)
    
          }
        
    
        });
      }

      
    

  }


  descargarubicacionesRuta(carro:String) {
   
   
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    
    console.log('descargarubicacionesRuta carro', carro);

    this.mapsOrdenService.getMapsOrdenUsuario(carro).subscribe(mapstareas => {
  


     var tareasConLatitud = mapstareas.filter(function(tarea) {
      return tarea.latitud !== "0.0";
  });

     this.ubicaciones = []
     //this.dataSource.data=tareasConLatitud
     console.log("tareasConLatitud ubicaciones control",tareasConLatitud);
     

     var contador = 0
     var loncomparar= 0.0
     var latcomparar= 0.0
     var hora= "08:30:00"

     for (let tarea of tareasConLatitud) {


       var distancia = calcularDistancia(latcomparar, loncomparar, parseFloat(tarea.longitud),  parseFloat(tarea.latitud));

       var resultado = calcularDistanciaYVelocidad(latcomparar, loncomparar, hora,  parseFloat(tarea.longitud),  parseFloat(tarea.latitud), tarea.hora);



      if(resultado.distancia < 1000 ){
   
        
        var punto =   [
          parseFloat( tarea.longitud),
          parseFloat( tarea.latitud),
    
        ]
        this.latlng.push(punto)
      }
      contador = contador+1
      parseFloat(tarea.longitud)
      var market = {
        lat:    parseFloat(tarea.longitud),
        lng:   parseFloat(tarea.latitud),
        label: String( tarea.id),
        draggable: false,
        cliente: "tarea.NombredeCliente",
        direccion: contador,
        valor:tarea.id
      }

      

      this.lat=tarea.longitud
      this.lng=tarea.latitud

      loncomparar = parseFloat(tarea.latitud)
      latcomparar  =  parseFloat(tarea.longitud)

     //this.ubicaciones.push(market)

    }





  

     this.start_end_mark = []
     this.start_end_mark_fin = []
     this.start_end_mark.push(this.latlng[0]);
     this.start_end_mark_fin.push(this.latlng[this.latlng.length - 1]);

     console.log("mapstareas rutas this.latlng",this.start_end_mark);



  

  
   });

 }

 async getDynamicIconUrlionn(): Promise<string> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  // Tamaño del ícono
  canvas.width = 10;
  canvas.height = 10;

  // Dibuja el SVG en el canvas
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="30px" height="30px" fill-rule="nonzero"><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(6.4,6.4)"><path d="M2.5,1.5h3v37h-3z" fill="#000000"></path><path d="M5,2v36h-2v-36h2M6,1h-4v38h4v-38z" fill="#000000"></path><g><path d="M29,27.5c-2.839,0 -5.725,-0.764 -8.516,-1.502c-2.782,-0.737 -5.659,-1.498 -8.484,-1.498c-2.418,0 -7.67,1.353 -9.5,1.845v-21.968c1.438,-0.406 6.807,-1.877 9.192,-1.877c2.697,0 5.459,0.712 8.383,1.465c2.929,0.755 5.957,1.535 8.925,1.535c3.075,0 6.95,-1.242 8.5,-1.786v21.937c-1.179,0.425 -5.364,1.849 -8.5,1.849z" fill="#e25805"></path><path d="M11.692,3c2.634,0 5.366,0.704 8.258,1.449c2.959,0.763 6.019,1.551 9.05,1.551c2.769,0 6.152,-0.976 8,-1.587v20.886c-1.471,0.513 -5.181,1.701 -8,1.701c-2.774,0 -5.628,-0.755 -8.388,-1.486c-2.813,-0.744 -5.722,-1.514 -8.612,-1.514c-2.236,0 -6.779,1.114 -9,1.695v-20.939c1.805,-0.502 6.535,-1.756 8.692,-1.756M11.692,2c-2.858,0 -9.692,2 -9.692,2v23c0,0 7.142,-2 10,-2c5.568,0 11.24,3 17,3c3.805,0 9,-2 9,-2v-23c0,0 -5.195,2 -9,2c-5.76,0 -11.74,-3 -17.308,-3z" fill="#000000"></path></g></g></g></svg>`;

  const svgBlob = new Blob([svg], {type: 'image/svg+xml;charset=utf-8'});
  const url = URL.createObjectURL(svgBlob);

  const img = new Image();
  img.src = url;

  return new Promise<string>((resolve) => {
    img.onload = () => {
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Añadir el número en blanco en el centro
      ctx.fillStyle = 'white';
      ctx.font = '12px Arial'; // Ajustar el tamaño de fuente según el ícono
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText("1", canvas.width / 2, canvas.height / 2); // Posición del número

      // Convertir el canvas a imagen base64
      const base64Image = canvas.toDataURL('image/png');
      resolve(base64Image);
    };
  });
}


 getDynamicIconUrlC(): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;
  
  // Tamaño del ícono
  canvas.width = 50;
  canvas.height = 50;
  
  // Dibujar el fondo (por ejemplo, un círculo)
  ctx.fillStyle = '#e25805';  // Color de fondo del ícono
  ctx.beginPath();
  ctx.arc(25, 25, 25, 0, 2 * Math.PI);
  ctx.fill();
  
  // Añadir el número en blanco en el centro
  ctx.fillStyle = 'white';
  ctx.font = '20px Arial';
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText("1", 25, 25); // Posición del número
  
  // Convertir el canvas a imagen base64
  return canvas.toDataURL('image/png');
}

 // En tu componente TypeScript
getDynamicIconUrl(): string {
  // SVG base con el número en el centro
  const svg = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="30px" height="30px" fill-rule="nonzero">
      <g fill="none" stroke="none" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal">
        <g transform="scale(6.4,6.4)">
          <path d="M2.5,1.5h3v37h-3z" fill="#000000"></path>
          <path d="M5,2v36h-2v-36h2M6,1h-4v38h4v-38z" fill="#000000"></path>
          <g>
            <path d="M29,27.5c-2.839,0 -5.725,-0.764 -8.516,-1.502c-2.782,-0.737 -5.659,-1.498 -8.484,-1.498c-2.418,0 -7.67,1.353 -9.5,1.845v-21.968c1.438,-0.406 6.807,-1.877 9.192,-1.877c2.697,0 5.459,0.712 8.383,1.465c2.929,0.755 5.957,1.535 8.925,1.535c3.075,0 6.95,-1.242 8.5,-1.786v21.937c-1.179,0.425 -5.364,1.849 -8.5,1.849z" fill="#e25805"></path>
            <path d="M11.692,3c2.634,0 5.366,0.704 8.258,1.449c2.959,0.763 6.019,1.551 9.05,1.551c2.769,0 6.152,-0.976 8,-1.587v20.886c-1.471,0.513 -5.181,1.701 -8,1.701c-2.774,0 -5.628,-0.755 -8.388,-1.486c-2.813,-0.744 -5.722,-1.514 -8.612,-1.514c-2.236,0 -6.779,1.114 -9,1.695v-20.939c1.805,-0.502 6.535,-1.756 8.692,-1.756M11.692,2c-2.858,0 -9.692,2 -9.692,2v23c0,0 7.142,-2 10,-2c5.568,0 11.24,3 17,3c3.805,0 9,-2 9,-2v-23c0,0 -5.195,2 -9,2c-5.76,0 -11.74,-3 -17.308,-3z" fill="#000000"></path>
          </g>
        </g>
        <!-- Añadir número dinámico -->
        <text x="128" y="128" font-size="96" text-anchor="middle" fill="white">${"1"}</text>
      </g>
    </svg>
  `;

  // Convertir el SVG a base64
  return 'data:image/svg+xml;base64,' + btoa(svg);
}

  

  atras():void {

    this.route.navigate(['/rutas']);
    
  }

}

function calcularDistancia(lat1, lon1, lat2, lon2) {
  // Radio de la Tierra en metros
  var R = 6371e3; // metros
  var φ1 = lat1 * Math.PI / 180; // φ, latitud en radianes
  var φ2 = lat2 * Math.PI / 180;
  var Δφ = (lat2 - lat1) * Math.PI / 180;
  var Δλ = (lon2 - lon1) * Math.PI / 180;

  // Fórmula de Haversine
  var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distancia en metros
  var distancia = R * c;
  
  return distancia;
}

function horaASegundos(hora) {
  var partes = hora.split(':');
  var horas = parseInt(partes[0], 10);
  var minutos = parseInt(partes[1], 10);
  var segundos = parseInt(partes[2], 10);
  return horas * 3600 + minutos * 60 + segundos;
}

function calcularDistanciaYVelocidad(lat1, lon1, hora1, lat2, lon2, hora2) {
  // Radio de la Tierra en metros
  var R = 6371e3; // metros
  var φ1 = lat1 * Math.PI / 180; // φ, latitud en radianes
  var φ2 = lat2 * Math.PI / 180;
  var Δφ = (lat2 - lat1) * Math.PI / 180;
  var Δλ = (lon2 - lon1) * Math.PI / 180;

  // Fórmula de Haversine para calcular la distancia
  var a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Distancia en metros
  var distancia = R * c;

  // Convertir la hora en formato HH:mm:ss a segundos
  var tiempo1 = horaASegundos(hora1);
  var tiempo2 = horaASegundos(hora2);

  // Calculo del tiempo transcurrido en segundos
  var tiempoTranscurrido = Math.abs(tiempo2 - tiempo1);

  // Convertir la distancia de metros a kilómetros
  var distanciaEnKm = distancia / 1000;

  // Calcular la velocidad en km/h
  var velocidad = distanciaEnKm / (tiempoTranscurrido / 3600); // convirtiendo tiempo de segundos a horas

  return {
      distancia: distancia,
      velocidad: velocidad
  };
}