
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DialogAgregarConductor } from '../dialogAgregarConductor/dialogAgregarConductor.component';


export interface DialogDataVh {
  idTerceroConductor: String,
  idConductor: String,
  nombreConductor: String,
  apellidosConductor: String
  
}

@Component({
  selector: 'app-DialogAgregarTerceroLicencia',
  templateUrl: './DialogAgregarTerceroLicencia.component.html',
  styleUrls: ['./DialogAgregarTerceroLicencia.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarTerceroLicencia implements OnInit {
  idTerceroConductor: String;
  idConductor: String;
  nombreConductor: String;
  apellidosConductor: String;
  
  private tipoLicencias;
  private anos;
  private dias;
  private meses;
  private v_tipoLicencia;
  private v_ano;
  private v_mes;
  private v_dia;
  constructor(public dialogRef: MatDialogRef<DialogAgregarTerceroLicencia>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogagregar: MatDialog,public dialogagregarVh: MatDialog) {}


  ngOnInit(): void {

    this.descargarTipoLicencias()
    this.descargaranos()
    this.descargardias()
    this.descargarmeses()


  }

  onNoClick(): void {
    console.log('DialogAgregarPropietario');
    this.dialogRef.close();
    this.dialogagregar.open(DialogAgregarConductor, {
      width: '850px',
      maxHeight:'700px',
      data: {},
    
      });

  }
 
  crearTipoDocumento(data: any) {
   


    if( 
      this.v_tipoLicencia &&
      this.v_ano &&
      this.v_mes &&
      this.v_dia) {

      if(this.v_dia) {



      
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var licencia = {
          idTerceroConductor: data.idTerceroConductor,
          idConductor: data.idConductor,
          nombreConductor: data.nombreConductor,
          apellidosConductor: data.apellidosConductor,
          vdia: this.v_dia ,
          vmes:  this.v_mes,
          vano:  this.v_ano,
          tipoLicencia:this.v_tipoLicencia,
          idTransportador:data.idTransportador
          
        }
    
        this.configuracionesService.createLicenciaTercero(licencia)
       
        this.toastr.successToastr("Tercero:  creado con éxito", 'Notificación');
        this.dialogRef.close();


   

      }else{
        this.toastr.errorToastr("Tercero Vehículo", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');

    } 



  }


  descargarTipoLicencias() {

    this.configuracionesService.getLicencias().subscribe(configuraciones => {

      this.tipoLicencias=configuraciones
  
    
    });
  }



  descargaranos() {

    this.configuracionesService.getanos().subscribe(configuraciones => {

      this.anos=configuraciones
  
   
    });
  }

  
  descargarmeses() {

    this.configuracionesService.getMes().subscribe(configuraciones => {

      this.meses=configuraciones
  
  
    });
  }

  descargardias() {

    this.configuracionesService.getdia().subscribe(configuraciones => {

      this.dias=configuraciones
  

    });
  }



  svdia(dato: any) {

    this.v_dia=dato

  }
  svmes(dato: any) {

    this.v_mes=dato

  }

  svano(dato: any) {

    this.v_ano=dato

  }

  stipoLicencia(licencia: any) {

    this.v_tipoLicencia=licencia

  }
  
}
