import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../../shared/orders.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Options } from "highcharts";

@Component({
  selector: 'app-widget-piedos',
  templateUrl: './piedos.component.html',
  styleUrls: ['./piedos.component.scss']

})
export class PiedosComponent implements OnInit {

  @Input() data: any = [];
  updateFlag = true;

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie'
    },
   title: {
      text: ''
    },
    tooltip: {
      pointFormat: '{point.name}:"<br /> {point.y} </b>'
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.name}</b>:<br />{point.y} <br />({point.percentage:.1f}  %)'
        }
      }
    },
    exporting: {  
      enabled: true
    },
    credits: {
      enabled: false
    },
    series: [{
      name: '',
      type: 'area',
      data: []
    }]
  };
 
  constructor(private orderService: OrdersService,private userd: AuthService) { }

  ngOnInit() {

 
this.getAllestados()
   
  }


  getAllestados() {
    
    HC_exporting(Highcharts);
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')

      );
    }, 300);
    
  
    
  }

  ngOnChanges(change: SimpleChanges) {
    this.chartOptions.series = [{
      name: change.name ? change.name.currentValue : null,
      type: 'pie',
      data: change.data.currentValue,
    }];
    this.updateFlag = true;
  }

}
