import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarVehiculo } from '../dialogAgregarVehiculo/dialogAgregarVehiculo.component';
import { DialogEditarVehiculo } from '../dialogEditarVehiculo/dialogEditarVehiculo.component';
import { DialogAgregarPropietario } from '../dialogAgregarPropietario/dialogAgregarPropietario.component';

export interface DialogDataVh {
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}
@Component({
  selector: 'app-FragmentVehiculo',
  templateUrl: './FragmentVehiculo.component.html',
  styleUrls: ['./FragmentVehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentVehiculo implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  sano: string;
  smes: string;
  sdia: string;
  vdia: string;
  vano: string;
  vmes: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string
  

   displayedColumns: string[] = ['imgEstado','nombrePropietario','nombreTenedor','placa','modelo','marca','aseguradora','configuracion','linea','clase','estado','editar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getRegistroVehiculos().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getvehiculoConNv().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      }  


    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {

    this.dialogagregar.open(DialogEditarVehiculo, {
      width: '850px',
      data: {id: tipo.id,
        placa:tipo.placa,
        modelo:tipo.modelo,
        marca:tipo.marca,
        aseguradora:tipo.aseguradora,
        vensoat:tipo.vensoat,
        ventecno:tipo.ventecno,
        linea:tipo.linea,
        version:tipo.version,
        carga:tipo.carga,
        volumen:tipo.volumen,
        configuracion:tipo.configuracion,
        estado:tipo.estado,
        clase:tipo.clase,
        sano: tipo.sano,
        smes: tipo.smes,
        sdia: tipo.sdia,
        vdia: tipo.vdia,
        vano: tipo.vano,
        vmes: tipo.vmes,
        idTercero: tipo.idTercero,
        idPropietario: tipo.idPropietario,
        nombrePropietario: tipo.nombrePropietario,
        apellidosPropietario: tipo.apellidosPropietario,
        idTerceroTenedor: tipo.idTerceroTenedor,
        idTenedor: tipo.idTenedor,
        nombreTenedor: tipo.nombreTenedor,
        apellidosTenedor: tipo.apellidosTenedor,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio")},
    
    });

  }

  nuevo():void {
 
  
    this.dialogagregar.open(DialogAgregarPropietario, {
      width: '850px',
      maxHeight:'700px',
      data: {id: this.codigo},
    
      });
    }

  deletebanco(tipo: any) {

  
  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaRegistroVehiculo']);
    
  }
  
}
