
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { DialogAgregarArchivoDetalle } from '../dialogAgregarArchivoDetalle/dialogAgregarArchivoDetalle.component';
import { formatDate } from '@angular/common';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { OrdersService } from 'src/app/shared/orders.service';
import { MapsAPILoader } from '@agm/core';

export interface DialogDataAgregar {
  file: any;
  costototal:String;
  
}
@Component({
  selector: 'app-DialogAgregarOrdenTransporteGeo',
  templateUrl: './DialogAgregarOrdenTransporteGeo.component.html',
  styleUrls: ['./DialogAgregarOrdenTransporteGeo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarOrdenTransporteGeo implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginatorDetalle', { static: false }) paginatordetelle: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public detalleCrearOrden: any;
  public pedidosCantidad: any=0;
  public detalleCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public dataarchivo: any;
  public numeroOperacionSiguiente: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  private valorTotalDetalleTemporal: any=0;
  public isaprobar = false;
  public ismenudetalle = false;
  public iscargado = false;
  public iscontenido = true;
  private s_ano;
  private s_mes;
  private s_dia;
  private anos;
  private dias;
  private meses;
  private currentIndex = 0;
  subscription: Subscription;
  subscriptionenvio: Subscription;
  public geos = new Array();
  public resultado = new Array();
  viewportPoints: google.maps.LatLngLiteral[];
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile

  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(private mapsAPILoader: MapsAPILoader,public dialogRef: MatDialogRef<DialogAgregarOrdenTransporteGeo>,private configuracionesService: ConfiguracionesService,private orderServiceTareas: OrdersService,private orderService: TareasTemporalService,     @Inject(MAT_DIALOG_DATA) public data: DialogDataAgregar, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }

   displayedColumns: string[] = ['direccion', 'lat', 'lng'];

    displayedColumnsDetalle: string[] = ['codigoArticulo', 'descripcion', 'ean', 'cantidad', 'valorNetoConIva',
    'numeroPedido'];
  
    dataSource = new MatTableDataSource();

    dataSourceDetalle = new MatTableDataSource();

  ngOnInit(): void {
    var negocio=localStorage.getItem("negocio");
    var agencia=localStorage.getItem("agencia");
    this.negocioAgencia=negocio+"-"+agencia
    this.parseExcel(this.data.file)

    this.descargar()
    this.rutasTemporales=0;
    this.valorTotalDetalleTemporal=0
    this.descargaranos()
    this.descargardias()
    this.descargarmeses()

    this.fechaTareasTemporal = localStorage.getItem("pedidoFecha");

    this.dialog = new Dialog({
      // Enables the header
      header: 'ERROR VALIDAR FORMATO',
      // Enables the close icon button in header
      showCloseIcon: true,
      visible: false,
      // Dialog content

      // The Dialog shows within the target element
      target: document.getElementById("container"),
      // Dialog width
      width: '500px',
      height: '1000px'

    });

    this.dialog.appendTo('#dialog');
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSourceDetalle.paginator=this.paginatordetelle

  }


  getcreate(order: any) {
    this.orderService.createTareaTemporal(order);
  }

  onNoClick(): void {

    this.dialogRef.close();

  }

  onDetelle(): void {
  
   
  
  }
  descargaranos() {

    this.configuracionesService.getanos().subscribe(configuraciones => {

      this.anos=configuraciones
  
   
    });
  }

  descargarmeses() {

let fechaActual = new Date();
let mesesfechas = [];

var numerodia = String(fechaActual.getMonth()+ 1)
  if ( (fechaActual.getMonth()+ 1)<10){
    numerodia = "0" + String(fechaActual.getMonth() + 1)
  }

  var mes = {
    mes:    numerodia,
   
  }

  mesesfechas.push(mes);

  this.meses=mesesfechas

  }

  descargardias() {



    let fechaActual = new Date();
    let dias = [];
    let ultimoDia = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
    
    for (let i = fechaActual.getDate(); i <= ultimoDia.getDate(); i++) {

      var numerodia = String(i)
      if ( i<10){
        numerodia = "0" + String(i)
      }

      var dia = {
        dia:    numerodia,
       
      }
      dias.push(dia);
    }
    this.dias = dias  
  }


  sfdia(dato: any) {

    this.s_dia=dato

  }
  sfmes(dato: any) {

    this.s_mes=dato

  }

  sfano(dato: any) {

    this.s_ano=dato

  }

  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);

        let data = JSON.parse(json_object);

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

              console.log(" carga tarea",elemento);

             
      

            };


            this.valorTotalTareasTemporal = 0;
            this.pesoKG=0;
            this.pedidosCantidad=0;
            var groupBy = function (xs, key) {
              return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };
            var rutasTareas = groupBy(data, 'Ruta')
            var zonasareas = groupBy(data, 'Zona')
            this.rutasTemporales=0;
            this.zonasTemporales=0;

       

            this.tareasCrearOrden=data
            this.dataarchivo=data

            var geo = {
              lat:    0.0,
              lng:   0.0,
              direccion: "",
              postalCode:"",
              urlMaps:""
            }
    
            for (let elemento of data) {
              elemento.lat
              elemento.lng
              elemento.direccion
              elemento.postalCode
              elemento.urlMaps
              this.geos.push(elemento)

            };

        
            this.geocodeNextItem(this.geos)
        
            

          });


        });



     


      }).bind(this), this);
    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };



   geocodeNextItem(dataarchivo:any) {
    console.log('ANTES Todos los elementos se han geocodificado.', dataarchivo);
    if (this.currentIndex >=  dataarchivo.length) {
      console.log('Todos los elementos se han geocodificado.', dataarchivo);
      return;
    }
  
    const item =  dataarchivo[this.currentIndex];
    console.log('item.', item);
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const  wString = item.direccion.replace(/Local/g, "");
      const  wStringdos = wString.replace(/local/g, "");
    geocoder.geocode({ address: wStringdos }, (results, status) => {
      console.log('item.direccion', wStringdos);
      if (status === 'OK') {
        const lat = results[0].geometry.location.lat();
        const lng = results[0].geometry.location.lng();
        
        const location = results[0].geometry.location;
        this.viewportPoints = [
          { lat: results[0].geometry.viewport.getSouthWest().lat(), lng: results[0].geometry.viewport.getSouthWest().lng() },
          { lat: results[0].geometry.viewport.getNorthEast().lat(), lng: results[0].geometry.viewport.getNorthEast().lng() }
        ];
        
        const mapsUrldos = `https://www.google.com/maps/@${location.lat()},${location.lng()},12z?hl=en&bounds=${this.viewportPoints[0].lat},${this.viewportPoints[0].lng}|${this.viewportPoints[1].lat},${this.viewportPoints[1].lng}`;
        item.mapsUrlGeoZona=mapsUrldos
        
        console.log('item.mapsUrlGeoZona', this.viewportPoints);

        const polygonPoints = [
          {lat: 40.712784, lng: -74.005941},
          {lat: 40.720974, lng: -74.010049},
          {lat: 40.721306, lng: -73.998842},
          {lat: 40.712011, lng: -73.996209},
        ];
        const polygonString = polygonPoints.map(point => `${point.lat},${point.lng}`).join('|');
        const mapsUrl = `https://www.google.com/maps/dir/?api=1&polygon=${polygonString}`;
item.mapsUrlGeoZona=mapsUrl       
console.log(`El elemento ${item.direccion} se ha geocodificado correctamente: latitud ${lat}, longitud ${lng}`);
        item.lat = lat;
        item.lng = lng;
        item.urlMaps = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
        this.currentIndex++;
        dataarchivo.lat=lat
        dataarchivo.lng=lng
      
        
    const addressComponents = results[0].address_components;
    const postalCodeComponent = addressComponents.find(component => component.types.includes('postal_code'));
    const postalCode = postalCodeComponent ? postalCodeComponent.short_name : null;
    console.log(`El código postal es ${postalCode}`);
    item.postalCode = postalCode;
    dataarchivo.postalCode=postalCode

 
    let resultText = '';

    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      const addressComponents = result.address_components;
      const formattedAddress = results[i].formatted_address;
      item.formattedAddress=formattedAddress

      item.sur = "NO"


      const regex = /sur/i; 
      if (formattedAddress.match(regex)) {
        item.sur = "RESULTADO"
      }
 

      if (wStringdos.match(regex)  ) {
        item.sur = "INICIAL"
      }

      if (formattedAddress.match(regex) && wStringdos.match(regex) ) {
        item.sur = "AMBAS"
      }

      for (let j = 0; j < addressComponents.length; j++) {
        const addressComponent = addressComponents[j];
        const componentType = addressComponent.types[0];
        const componentValue = addressComponent.long_name;
        item[componentType]=componentValue
     
      }

    }

    item.resultText = resultText;
        setTimeout(() => {
    
          this.geocodeNextItem(dataarchivo)
         this.resultado.push(item)

         this.dataSource.data=this.resultado
         }, 1000);
      } else if (status === 'OVER_QUERY_LIMIT') {
        console.log('Límite de consulta superado. Esperando 5 segundos antes de continuar.');
        setTimeout(() => {
    
          this.geocodeNextItem(dataarchivo)
       
      
         }, 2000); // espera 5 segundos antes de intentar de nuevo
      } else {
        console.error(`El elemento ${item.direccion} no se ha podido geocodificar: ${status}`);
        this.currentIndex++;
        setTimeout(() => {
    
          this.geocodeNextItem(dataarchivo)
          this.resultado.push(item)
      
         }, 1000); // espera 1 segundo antes de hacer la siguiente solicitud
      }
    });
  });
  }

  public onSuccess(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);
  }

  public onSuccessDetalle(args: any): void {
    var files = args.target.files; // FileList object
  
  }

  importFile(e) {
    this.dialog.show();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

 applyFilterDetalle(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDetalle.filter = filterValue.trim().toLowerCase();
  }
  descargar() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription =   this.orderService.getConsecutivo().subscribe(consecutivos => {

      for (let item of consecutivos) {
           
        var numeroSiguiente=1
        numeroSiguiente=numeroSiguiente+item.conoperacion
        this.numeroOperacion="OT"+numeroSiguiente
        this.numeroOperacionSiguiente=numeroSiguiente
      }      
      
     this.subscription.unsubscribe()
  
    });
  }
  
  crearTipoDocumento(data: any) {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
  this.orderServiceTareas.exportAsExcelFile(this.resultado, 'tareas_vrt_');


  }




  
}
