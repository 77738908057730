import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { PedidoService } from '../../shared/pedidos.service';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { ClientesActivacionesService } from '../../shared/clientesActivaciones.service';
import { ClientesaprobacionService } from '../../shared/clientesaprobacion.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-fragmentActivaciones',
  templateUrl: './fragmentActivaciones.component.html',
  styleUrls: ['./fragmentActivaciones.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentActivaciones implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
 
  displayedColumns: string[] = ['ruta', 'y','nuevos','pedidos','novedades','pendientes','c','txPedidosPorMC','x','txEfePenPorMC'];

 
  private rutasDescarga: any;
  private ventasDescarga: any;
  private ClientesNuevos: any;

  subscription: Subscription;
  subscriptionNuevos: Subscription;
  subscriptionVisitas: Subscription;
  subscriptioninforme: Subscription;

  private txtTotalVisitas: any;
  private txtPedidos: any;
  private txtNovedad: any;
  private txtPendiente: any;
  private txtRutas: any;
  private txtVenta: any;
  private txtBase: any;
  private txtNuevos: any;
  private txtClientesAprba:any;
  private txtFecha:any;
  private datosNovCan: any;
  private datosPedidosRutas: any;
  private datosNov: any;
  private datosPend: any;
  private datosCliente: any;

 
  public anos: any[] = [
    { id: "01", Name: 'Enero' },
    { id: "02", Name: 'Febrero' },
    { id: "03", Name: 'Marzo' },
    { id: "04", Name: 'Abril' },
    { id: "05", Name: 'Mayo' },
    { id: "06", Name: 'Junio' },
    { id: "07", Name: 'Julio' },
    { id: "08", Name: 'Agosto' },
    { id: "09", Name: 'Septiembre' },
    { id: "10", Name: 'Octubre' },
    { id: "11", Name: 'Noviembre' },
    { id: "12", Name: 'Deciembre' }
  ];


  dataSourceEstado = new MatTableDataSource();
  
  constructor(private ClientesService: ClientesActivacionesService, public toastr: ToastrManager, public pedidosService: PedidoService,public clientesaprobacionService: ClientesaprobacionService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {

      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });
  }



  ngOnInit(): void {

    if(localStorage.getItem('mesconsulta')=="01" ){

      this.txtFecha= "Enero";

    }

    if(localStorage.getItem('mesconsulta')=="02" ){

      this.txtFecha= "Febrero";

    }

   if(localStorage.getItem('mesconsulta')=="03" ){

      this.txtFecha= "Marzo";

    }

    if(localStorage.getItem('mesconsulta')=="04" ){

      this.txtFecha= "Abril";

    }

    if(localStorage.getItem('mesconsulta')=="05" ){

      this.txtFecha= "Mayo";

    }

    if(localStorage.getItem('mesconsulta')=="06" ){

      this.txtFecha= "Junio";

    }

    if(localStorage.getItem('mesconsulta')=="07" ){

      this.txtFecha= "Julio";

    }

    if(localStorage.getItem('mesconsulta')=="08" ){

      this.txtFecha= "Agosto";

    }

    if(localStorage.getItem('mesconsulta')=="09" ){

      this.txtFecha= "Septiembre";

    }

    if(localStorage.getItem('mesconsulta')=="10" ){

      this.txtFecha= "Octubre";

    }

    if(localStorage.getItem('mesconsulta')=="11" ){

      this.txtFecha= "Noviembre";

    }

    if(localStorage.getItem('mesconsulta')=="12" ){

      this.txtFecha= "Deciembre";

    }
 
    if (localStorage.getItem('rutaindicador') == 'si') {

      localStorage.setItem("rutaindicador", "no");

      
  


    }
 
    if (localStorage.getItem('vistaShow') == 'SHOWN') {

        this.descargar();
      
    

    } else {


      this.subscription.unsubscribe();


    }


  }

  ngAfterViewInit() {
    
    this.dataSourceEstado.paginator = this.paginator;
   
  }

  descargar1a10():void {
    this.subscriptionVisitas = this.pedidosService.getmesDetalle1().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8,a9,a10]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8,a9,a10};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']
      , ...res['a9'], ...res['a10']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"detalle mes 1 a 10")
    

    
    });
  }


  descargar10a20():void {
    this.subscriptionVisitas = this.pedidosService.getmesDetalle2().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8,a9,a10]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8,a9,a10};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']
      , ...res['a9'], ...res['a10']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"detalle mes 10 a 20")
    

    
    });
  }

  descargar21a31():void {
    this.subscriptionVisitas = this.pedidosService.getmesDetalle3().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8,a9,a10,a11]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8,a9,a10,a11};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']
      , ...res['a9'], ...res['a10'], ...res['a11']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"detalle mes 21 a 31")
    

    
    });
  }

  descargarmespedidos():void  {
    this.subscriptionVisitas = this.pedidosService.getmes().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20,a21,a22,a23,a24,a25,a26,a27,a28,a29,a30,a31]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20,a21,a22,a23,a24,a25,a26,a27,a28,a29,a30,a31};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']
      , ...res['a9'], ...res['a10'], ...res['a11'], ...res['a12'], ...res['a13'], ...res['a14'], ...res['a15'], ...res['a16']
      , ...res['a17'], ...res['a18'], ...res['a19'], ...res['a20'], ...res['a21'], ...res['a22'], ...res['a23'], ...res['a24']
      , ...res['a25'], ...res['a26'], ...res['a27'], ...res['a28'], ...res['a29'], ...res['a30'], ...res['a31']];
  
  
     
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"pedidos")

  
    });


  }
  descargar() {
    var numero=0

    numero=parseInt(localStorage.getItem('diaConsulta'))

      this.subscription = this.ClientesService.getClientesActivaciones().subscribe(txtClientesAprba => {
      this.rutasDescarga = this.rutasDescarga = txtClientesAprba;
      this.txtClientesAprba=txtClientesAprba.length
      this.subscription.unsubscribe();



      this.subscriptionVisitas = this.clientesaprobacionService.getClienteAprobacionAprobacion().subscribe(clientes => {
        this.txtNuevos=clientes.length
        this.ClientesNuevos=clientes
        this.getVista(clientes);
        this.subscriptionVisitas.unsubscribe();
        
      
        this.txtVenta= 0
    this.txtBase= 0
    this.txtPedidos=0

    
      });

      
    
    });


  }


  getVista(datosCliente:any){

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(datosCliente, 'idUsr')
    let rutasClientes = []
    this.txtPendiente=0
    for (let item of Object.keys(rutasdia)) {
      var rutasODL = rutasdia[item];
     
  
    
      var ruta = {
        name: rutasODL[0].idUsr,
        y:rutasODL.length,

      };

      rutasClientes.push(ruta)
      //this.ClientesService.createRutasOdl(ruta)
    
    }

  
    this.datosNovCan=rutasClientes


  }



  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
    const fechaNuevos = formatDate(event.value, 'yyyy/MM/dd', 'en-US');
    this.toastr.successToastr("Cambio de fecha "+cValue, 'Notificación');
    localStorage.setItem("pedidoFecha", cValue);
    localStorage.setItem("fechaNuevos", fechaNuevos);
    localStorage.setItem("cargar", 'exitoso');

    var currentDia = event.value.getDay();
    currentDia=currentDia+1
    localStorage.setItem("diaConsulta", currentDia.toString());
  
    this.descargar();

  }

  mes(mes: any) {
 
    localStorage.setItem("rutaindicador", "no");
    localStorage.setItem("mesconsulta", mes);
    console.log('mesconsulta', mes);


  }


}

