import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarArticulo } from '../dialogAgregarArticulo/dialogAgregarArticulo.component';
import { Dialog } from '@syncfusion/ej2-popups';
import * as XLSX from 'xlsx';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
export interface DialogData {
  id: string;
  departamento: string;
}
@Component({
  selector: 'app-FragmentMaestroClientes',
  templateUrl: './FragmentMaestroClientes.component.html',
  styleUrls: ['./FragmentMaestroClientes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentMaestroClientes implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  public dialog: Dialog;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialogagregar: MatDialog) {

  
  }



   displayedColumns: string[] = ['Codcliente', 'Cliente','RazonSocial','Direccion','Barrio','Ciudad','Cedula_Cli','Telefono'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log(json_object)


        let data = JSON.parse(json_object);
        console.log("data", data)


        for (let cliente of data) {

          this.configuracionesService.createMaestroCliente(cliente);


        }

        this.descargar()

        this.uploadObj.clearAll();

      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  public onSuccess(args: any): void {
    var files = args.target.files;
    this.parseExcel(files[0]);

    console.log("archivo", files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }


  descargarConfiguraciones() {

    this.configuracionesService.descargarMaestrosClientes().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getConDepartamento().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      }  

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {

 

  }

  nuevo():void {

   
  
    }

  deletebanco(tipo: any) {

 

  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaDepartamentos']);
    
  }
  
}
