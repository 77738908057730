import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
export interface DialogData {
  id: string;
  barrio: string;
}

@Component({
  selector: 'app-DialogEditarBarrio',
  templateUrl: './DialogEditarBarrio.component.html',
  styleUrls: ['./DialogEditarBarrio.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEditarBarrio implements OnInit {

  id: string;
  barrio: string;

  constructor(public dialogRef: MatDialogRef<DialogEditarBarrio>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearTipoDocumento(data: any) {
   

    if(data.barrio) {

      if(data.barrio) {



    var des = data.barrio;
    var may = des.substring(0, 1).toUpperCase();
    var res = des.substring(1, des.length).toLowerCase();

    var barrio = may.concat(res.toString())
        
  
    
    
        var tipoDoc = {
          id: data.id,
          barrio: barrio,

        }
  
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.id,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Editar',
          barrio:barrio
 
        }
     
        this.configuracionesService.crearAuditriaBarrio(auditria)
        this.configuracionesService.crearBarrio(tipoDoc)
        this.toastr.successToastr("Barrio: "+barrio+ " creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Ingresar Barrio", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Ingresar Ciudad", 'Notificación');

    } 



  }
  
  
}
