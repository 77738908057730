
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { Router } from '@angular/router';
export interface DialogData {
  idBanco: string;
  banco: string;
}

@Component({
  selector: 'app-DialogAprobarPlanilla',
  templateUrl: './DialogAprobarPlanilla.component.html',
  styleUrls: ['./DialogAprobarPlanilla.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAprobarPlanilla implements OnInit {
  idBanco: Number;
  banco: string;
  constructor(public dialogRef: MatDialogRef<DialogAprobarPlanilla>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: TareasTemporalService, public toastr: ToastrManager,private route: Router) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearBanco(data: any) {
     
    var auditria = {
      estado:"En Ruta",
      planilla:data.planilla,
    }
    this.dialogRef.close();
    this.configuracionesService.createupdate(auditria)

    localStorage.setItem("codigoplanilla", data.planilla);
    this.route.navigate(['/Planilla']);

  }
  
}
