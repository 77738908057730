import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class MapsOrdenService {
  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private tareasMpasColletion: AngularFirestoreCollection<any>;
  tareasMaps: Observable<any[]>;
  private tareasMpasRutaColletion: AngularFirestoreCollection<any>;
  tareasMapsRuta: Observable<any[]>;

  private tareasMpasUbicacionColletion: AngularFirestoreCollection<any>;
  tareasUbicacion: Observable<any[]>;
  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");

  constructor(private readonly afs: AngularFirestore, private userd: AuthService) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const cValue= localStorage.getItem("pedidoFecha");
    this.tareasMpasColletion = afs.collection<any>('SLC_Tareas/registros/tareas', ref => ref
    .where('fecha', '==', cValue));
  

  }


  getMapsOrden() {
    this.tareasMaps = this.tareasMpasColletion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareasMaps

  }

  getMapsOrdenUsuario(usaurio:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const cValue= localStorage.getItem("pedidoFecha");
    this.tareasMpasRutaColletion = this.afs.collection<any>('SLC_Recorrido_ruta/'+ this.negocio +"_"+ this.agencia +'/geo', ref => ref
    .where('fecha', '==',cValue).where('usuario', '==',usaurio).orderBy('id'));  
    this.tareasMapsRuta = this.tareasMpasRutaColletion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareasMapsRuta

  }


  getMapsUbicacionTransporte() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.tareasMpasUbicacionColletion = this.afs.collection<any>('SLC_Recorrido/' + this.negocio +"_"+ this.agencia +'/geo', ref => ref
    .where('fecha', '==',localStorage.getItem("ordenTransporteFecha")));
  
    this.tareasUbicacion = this.tareasMpasUbicacionColletion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareasUbicacion

  }
}