
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { PedidoService } from '../../shared/pedidos.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ClientesService } from '../../shared/clientes.service';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-fragmentPedidos',
  templateUrl: './fragmentPedidos.component.html',
  styleUrls: ['./fragmentPedidos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmenPedidos implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['idPedido','cnt','identificacion', 'dir','tel','usr','obs','estado','total'];
  dataSource = new MatTableDataSource();
  

  public negocio: any;
  public agencia: any;
  public usuario: any;

  private subscription: Subscription;
  private subscriptionDetalle: Subscription;
  private subscriptionCliente: Subscription;


  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile
  
  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(private pedidosservice: PedidoService,private clientesService: ClientesService ,public toastr: ToastrManager, private route: Router) {

  localStorage.setItem("vistaShow", 'SHOWN');
  document.addEventListener("visibilitychange", function () {

    if (document.hidden) {

      console.log("Hidden");
      localStorage.setItem("vistaShow", 'Hidden');

    }
    else {

      console.log("SHOWN");
      localStorage.setItem("vistaShow", 'SHOWN');

    }

  });

}
ngAfterViewInit() {

  this.dataSource.paginator = this.paginator;

}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}


ngOnInit(): void {

  const currentDate = new Date();
  const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

  localStorage.setItem("pedidoFecha",cValue);
  
  this.negocio = localStorage.getItem("negocio");
  this.agencia = localStorage.getItem("agencia");
  this.usuario = localStorage.getItem("correo");

  if (localStorage.getItem('rutaindicador') == 'si') {

    localStorage.setItem("rutaindicador", "no");

  }

  if (localStorage.getItem('vistaShow') == 'SHOWN') {

    this.descargar();
    setInterval(() => {

      this.descargar();

    }, 60000);

  } else {


    this.subscription.unsubscribe();


  }

}
descargar() {
  this.subscription = this.pedidosservice.getPedidos().subscribe(clientes => {
    console.log("clientes q trae", clientes);
    this.dataSource.data = clientes;
    this.subscription.unsubscribe();

  });
}


aprobar(order: any) {

  localStorage.setItem("Detalle", order.rfv);
  console.log("seleccion ", order);
  this.toastr.successToastr(order.nmbCmn, 'Notificación');
  this.route.navigate(['/Detalle']);

  }

  export(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscription = this.pedidosservice.getPedidos().subscribe(articulos => {

      this.pedidosservice.exportAsExcelFile(articulos, 'Pedidos');

      this.subscription.unsubscribe();

    });

    this.subscriptionDetalle = this.pedidosservice.getPedidosDetalle().subscribe(articulos => {

      this.pedidosservice.exportAsExcelFile(articulos, 'PedidosDetalle');

      this.subscriptionDetalle.unsubscribe();

    });
    
  }


  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


    const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
    this.toastr.successToastr("Cambio de fecha "+cValue, 'Notificación');
    localStorage.setItem("pedidoFecha", cValue);
    console.log("fecha q se guardo en el sistema", cValue);

    localStorage.setItem("cargar", 'exitoso');
  
    this.descargar();

  }



}
