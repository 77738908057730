import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../../shared/orders.service';
import { AuthService } from '../../../shared/services/auth.service';


@Component({
  selector: 'app-widget-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss']
})
export class AreaComponent implements OnInit {

  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;

  constructor(private orderService: OrdersService,private userd: AuthService) { }

  ngOnInit() {
   
  this.getAllrecorridos()
      
         

  }

  getAllrecorridos() {
    this.orderService.getRecorrido().subscribe(res => {
      this.data= res;
    
      HC_exporting(Highcharts);

      setTimeout(() => {
        window.dispatchEvent(
          new Event('resize')
        );
      }, 500);

    
 
  // Cr
      this.chartOptions = {
        chart: {
          type: 'bar',
         
        },
        title: {
          text: ''
        },
        subtitle: {
          text:  localStorage.getItem("negocio")+ ' '+ localStorage.getItem("agencia"),
        },
        tooltip: {
          split: true,
          valueSuffix: ' COP',
          padding: 2,
          useHTML: true,
           
        },
        credits: {
          enabled: false
        },

        exporting: {
          enabled: true,
        },
        series: res
      };

    });

 
  
    
  }

  
  
}
