import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../shared/services/auth.service';
import { formatDate } from '@angular/common';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import { forkJoin } from 'rxjs';
import { timer, combineLatest } from 'rxjs';
import * as firebase from 'firebase/app';
import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { AnonymousSubject } from 'rxjs/internal/Subject';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  loadedCharacter: {}
  private mesconsulta;

  private ordersCollection: AngularFirestoreCollection<any>;
  orders: Observable<any[]>;

  private orderstareasCollection: AngularFirestoreCollection<any>;
  orderstareas: Observable<any[]>;


  private creacionCollectionmaestro: AngularFirestoreCollection<any>;
  creacionClientemaestro: Observable<any[]>;

  private geoCollectionmaestro: AngularFirestoreCollection<any>;
  geoClientemaestro: Observable<any[]>;


  private informesCollectionmaestro: AngularFirestoreCollection<any>;
  informesClientemaestro: Observable<any[]>;


  private informesCollectionCasa: AngularFirestoreCollection<any>;
  informesClienteCasa: Observable<any[]>;


  private informesCollectionmaestroAsesor: AngularFirestoreCollection<any>;
  informesClientemaestroasesor: Observable<any[]>;

  private articulodetallecolle: AngularFirestoreCollection<any>;
  articulodetalle: Observable<any[]>;

  private tareasfotocoleccion: AngularFirestoreCollection<any>;
  tareafoto: Observable<any[]>;

  private creacionClienteVisitasColl: AngularFirestoreCollection<any>;
  creacionClienteVisistas: Observable<any[]>;

  private detallesEncuestasColl: AngularFirestoreCollection<any>;
  detalleEncuestas: Observable<any[]>;

  private creacionRuteroCollectionmaestro: AngularFirestoreCollection<any>;
  creacionRuteroClientemaestro: Observable<any[]>;

  private creacionContactoCollectionmaestro: AngularFirestoreCollection<any>;
  creacionContactoClientemaestro: Observable<any[]>;



  private clientesAppCollection: AngularFirestoreCollection<any>;
  clientesApp: Observable<any[]>;

  private contactospenditesCollection: AngularFirestoreCollection<any>;
  contactopendientes: Observable<any[]>;

  private asesoresCollection: AngularFirestoreCollection<any>;
  asesores: Observable<any[]>;


  private pikinCollection: AngularFirestoreCollection<any>;
  pikin: Observable<any[]>;

  private pedidoClientesCollection: AngularFirestoreCollection<any>;
  pedidoClientes: Observable<any[]>;


  private pedidoClientesCollectiondetalle: AngularFirestoreCollection<any>;
  pedidoClientesdetalle: Observable<any[]>;

  private crearClientesCollection: AngularFirestoreCollection<any>;
  crearClientes: Observable<any[]>;

  private novedadesClientesCollection: AngularFirestoreCollection<any>;
  pedidoNovededas: Observable<any[]>;

  private pendientesClientesCollection: AngularFirestoreCollection<any>;
  pendientesClientes: Observable<any[]>;

  private zonasCollection: AngularFirestoreCollection<any>;
  zonas: Observable<any[]>;

  private novCollection: AngularFirestoreCollection<any>;
  nov: Observable<any[]>;

  private zonasGruposCollection: AngularFirestoreCollection<any>;
  zonasGrupos: Observable<any[]>;


  private zonasGruposDeCollection: AngularFirestoreCollection<any>;
  zonasGruposDe: Observable<any[]>;

  private articulosCollection: AngularFirestoreCollection<any>;
  articulos: Observable<any[]>;


  private articulosCollectionemec: AngularFirestoreCollection<any>;
  articulosemec: Observable<any[]>;

  private novedadesCollection: AngularFirestoreCollection<any>;
  novedades: Observable<any[]>;

  private unidadCollection: AngularFirestoreCollection<any>;
  unidad: Observable<any[]>;

  private inventariosCollectionemec: AngularFirestoreCollection<any>;
  inventariosemec: Observable<any[]>;

  private inventariosCollection: AngularFirestoreCollection<any>;
  inventarios: Observable<any[]>;


  private ruterosCollection: AngularFirestoreCollection<any>;
  ruteros: Observable<any[]>;

  private listasCollection: AngularFirestoreCollection<any>;
  listas: Observable<any[]>;

  private preciosCollection: AngularFirestoreCollection<any>;
  precios: Observable<any[]>;

  private preciosCollectionemec: AngularFirestoreCollection<any>;
  preciosemec: Observable<any[]>;


  private marcasCollection: AngularFirestoreCollection<any>;
  marcas: Observable<any[]>;

  private categoriasCollection: AngularFirestoreCollection<any>;
  categorias: Observable<any[]>;

  private contactosCollection: AngularFirestoreCollection<any>;
  contactos: Observable<any[]>;

  private lineasCollection: AngularFirestoreCollection<any>;
  lineas: Observable<any[]>;

  private maestrosCollection: AngularFirestoreCollection<any>;
  maestros: Observable<any[]>;


  private ordersCollectionTareasTransportador: AngularFirestoreCollection<any>;
  tareastrasnportador: Observable<any[]>;

  private ordersCollectionnovedades: AngularFirestoreCollection<any>;
  ordersnovedades: Observable<any[]>;


  private ordersCollectionpendientes: AngularFirestoreCollection<any>;
  orderspendientes: Observable<any[]>;


  private ordersCollecadmin: AngularFirestoreCollection<any>;
  ordersadmin: Observable<any[]>;
  ordersadminsuma: Observable<any[]>;

  private recorridoCollection: AngularFirestoreCollection<any>;
  recorido: Observable<any[]>;

  private userwebCollection: AngularFirestoreCollection<any>;
  userweb: Observable<any[]>;


  private avanceCollection: AngularFirestoreCollection<any>;
  avance: Observable<any[]>;

  private estadoCollection: AngularFirestoreCollection<any>;
  estado: Observable<any[]>;

  private cashCollection: AngularFirestoreCollection<any>;
  cash: Observable<any[]>;

  private tableroCollection: AngularFirestoreCollection<any>;
  tablero: Observable<any[]>;


  private rutasCollection: AngularFirestoreCollection<any>;
  rutas: Observable<any[]>;

  private totaloperacion: AngularFirestoreCollection<any>;
  totalope: Observable<any[]>;

  private totalnovedades: AngularFirestoreCollection<any>;
  totalnove: Observable<any[]>;


  private collecinformes: AngularFirestoreCollection<any>;
  informesany: Observable<any[]>;

  private tipoCollection: AngularFirestoreCollection<any>;
  tipo: Observable<any[]>;

  private tipoCrearCollection: AngularFirestoreCollection<any>;
  tipoCrear: Observable<any[]>;


  private tareasEstadoCollection: AngularFirestoreCollection<any>;
  tareasEstado: Observable<any[]>;
  //* Nuevas consultas

  private collecTransportadores: AngularFirestoreCollection<any>;
  transportadoresany: Observable<any[]>;


  private tareasRecaudoCollection: AngularFirestoreCollection<any>;
  tareasRecaudo: Observable<any[]>;

  private ordersCollectionRutas: AngularFirestoreCollection<any>;
  orderrutas: Observable<any[]>;

  constructor(private readonly afs: AngularFirestore, private userd: AuthService,private readonly firebasedb: AngularFirestore,) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha


    this.ordersCollection = afs.collection<any>('tareascargadas/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    

    this.avanceCollection = afs.collection<any>('avance/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.avance = this.avanceCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));
    

    this.recorridoCollection = afs.collection<any>('recorrido/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.recorido = this.recorridoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    
    this.totaloperacion = afs.collection<any>('totaloperacion/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.totalope = this.totaloperacion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    this.estadoCollection = afs.collection<any>('estado/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.estado = this.estadoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    this.cashCollection = afs.collection<any>('cash/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.cash = this.cashCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    this.tableroCollection = afs.collection<any>('tablero/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.tablero = this.tableroCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    

    this.totalnovedades = afs.collection<any>('novedades/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.totalnove = this.totalnovedades.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    
    this.rutasCollection = afs.collection<any>('rutas/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.rutas = this.rutasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  myForm = new FormGroup({
    customerName: new FormControl(''),
    orderNumber: new FormControl(''),
    order: new FormControl(''),
    completed: new FormControl(false)
  });

  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");

  fecha = localStorage.getItem("ordenTransporteFecha");

  public getCat(documentId: string) {

  }

  getinforme1Recorrido() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();



    var data3;


    combineLatest(
      this.afs.collection<any>('SLC_Recorrido_ruta/mrquick_cali/geo/' , ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
   
    ).pipe(
      map(([uno]) => {
        return { uno };
      })
    ).subscribe(res => {
      data3 = [...res["uno"] ];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'recorrido');



    });



    return this.orders;

  }

  getinforme1() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();



    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'INFORME_1_A_8_');



    });



    return this.orders;

  }

  getinforme1abril() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();



    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-01", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-02", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-03", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-04", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-05", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-06", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-07", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-08", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'INFORME_1_A_8_');



    });



    return this.orders;

  }

  getinformeCLIENTES() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    var data3;


    combineLatest(
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-01").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-02").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-03").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-04").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-05").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-06").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-07").valueChanges(),
      this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + "2021-02-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'INFORME_1_A_8_');



    });



    return this.orders;

  }

  getinforme2() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-09", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-10", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-11", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-12", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-13", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-14", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-15", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-16", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']
      ];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'INFORME_9_A_16_');



    });



    return this.orders;

  }



  getinforme2marzo() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-09", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-10", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-11", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-12", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-13", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-14", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-15", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-16", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']
      ];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'INFORME_9_A_16_');



    });



    return this.orders;

  }

  getinforme2abril() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-09", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-10", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-11", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-12", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-13", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-14", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-15", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-16", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']
      ];
      console.log('funciona esto para mejorar', data3);


      this.exportAsExcelFile(data3, 'INFORME_9_A_16_');



    });



    return this.orders;

  }



  getinforme3() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-17", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-18", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-19", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-20", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-21", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-22", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-23", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-24", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'INFORME_17_A_24_');



    });



    return this.orders;

  }


  getinforme3marzo() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-17", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-18", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-19", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-20", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-21", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-22", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-23", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-24", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'INFORME_17_A_24_');



    });



    return this.orders;

  }

  getinforme3abril() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-17", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-18", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-19", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-20", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-21", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-22", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-23", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-24", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'INFORME_17_A_24_');



    });



    return this.orders;

  }


  getinforme4() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(

      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-25", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-26", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-27", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-28", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-29", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-30", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-02-31", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'INFORME_25_A_31__');



    });



    return this.orders;

  }



  getinforme4marzo() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(

      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-25", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-26", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-27", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-28", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-29", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-30", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-03-31", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'INFORME_25_A_31__');



    });



    return this.orders;

  }


  getinforme4abril() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(

      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-25", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-26", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-27", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-28", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-29", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-30", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2021-04-31", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'INFORME_25_A_31__');



    });



    return this.orders;

  }


  getinformegeneralventasmes() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(



      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-16").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-21").valueChanges(),

      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-22").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-23").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-24").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-24").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-25").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-26").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-27").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-28").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-29").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-09-30").valueChanges(),


    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince]) => {
        return {
          uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece,
          catorce, quince
        };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho'], ...res['nueve'], ...res['diez'], , ...res['once'], , ...res['doce'], , ...res['trece']
        , , ...res['catorce'], , ...res['quince']
      ];

      this.exportAsExcelFile(data3, 'VENTAS_GENERAL_DETALLE');



    });



    return this.orders;

  }

  getinformegeneralventas15() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-11").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),



    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince]) => {
        return {
          uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece,
          catorce, quince
        };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho'], ...res['nueve'], ...res['diez'], , ...res['once'], , ...res['doce'], , ...res['trece']
        , , ...res['catorce'], , ...res['quince']
      ];

      this.exportAsExcelFile(data3, 'VENTAS_GENERAL_DETALLE_1A15');



    });



    return this.orders;

  }




  getdetalleNoviembre8() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_OCT_1A8');



    });



    return this.orders;

  }

  getdetalleNoviembre16() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-11").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-12").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_OCT_9A16');



    });



    return this.orders;

  }


  getdetalleNoviembre24() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-21").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-22").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-23").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-24").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_OCT_17A24');



    });



    return this.orders;

  }


  getdetalleNoviembre30() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-25").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-26").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-27").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-28").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-29").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-30").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-31").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-32").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_OCT_25A30');



    });



    return this.orders;

  }


  getpedidoNoviembre8() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_OCT_1A8');



    });



    return this.orders;

  }

  getpedidoNoviembre16() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-11").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-12").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_OCT_9A16');



    });



    return this.orders;

  }


  getpedidoNoviembre24() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-21").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-22").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-23").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-24").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_OCT_17A24');



    });



    return this.orders;

  }

 



  getdetalledic8() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-01").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-02").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-03").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-04").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-05").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-06").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_DIC_1A8');



    });



    return this.orders;

  }

  getdetalledic16() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-10").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-11").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-12").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-15").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-16").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_DIC_9A16');



    });



    return this.orders;

  }


  getdetalledic24() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-21").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-22").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-23").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-24").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_DIC_17A24');



    });



    return this.orders;

  }


  getdetalledic30() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-25").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-26").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-27").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-28").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-29").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-30").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-31").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-12-32").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PED_DIC_25A30');



    });



    return this.orders;

  }






  getpedidoesep8() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-01").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-02").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-03").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-04").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-05").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-06").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_SEP_1A8');



    });



    return this.orders;

  }

  getpedidosep16() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-10").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-11").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-12").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-15").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-16").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_SEP_9A16');



    });



    return this.orders;

  }


  getpedidosep24() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-21").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-22").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-23").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-24").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_SEP_17A24');



    });



    return this.orders;

  }


  getpedidosep30() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-25").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-26").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-27").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-28").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-29").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-30").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-31").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-32").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'PEDENVIO_SEP_25A30');



    });



    return this.orders;

  }





  getclientesep8() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-01").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-02").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-03").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-04").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-05").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-06").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-07").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_SEP_2_1A8');



    });



    return this.orders;

  }

  getclientesep16() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-09").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-10").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-11").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-12").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-13").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-14").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-15").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-16").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_SEP_29A16');



    });



    return this.orders;

  }


  getclientesep24() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-17").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-18").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-19").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-20").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-21").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-22").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-23").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-24").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_SEP_27A24');



    });



    return this.orders;

  }


  getclietesep30() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-25").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-26").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-27").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-28").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-29").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-30").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-31").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-09-32").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_SEP_25A30');



    });



    return this.orders;

  }




  getclienteoct8() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_OCT_1A8');



    });



    return this.orders;

  }

  getclienteoct16() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-11").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-12").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_OCT_9A16');



    });



    return this.orders;

  }


  getclienteoct24() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-17").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-18").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-19").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-20").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-21").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-22").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-23").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-24").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_OCT_17A24');



    });



    return this.orders;

  }


  getclieteoct30() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-25").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-26").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-27").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-28").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-29").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-30").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-31").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-32").valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']
        , ...res['ocho']
      ];

      this.exportAsExcelFile(data3, 'CLIENTES_OCT_25A30');



    });



    return this.orders;

  }








  getdetalleNoviembre30d() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(


      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-21").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-11").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),


    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince]) => {
        return {
          uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece,
          catorce, quince
        };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho'], ...res['nueve'], ...res['diez'], , ...res['once'], , ...res['doce'], , ...res['trece']
        , , ...res['catorce'], , ...res['quince']
      ];

      this.exportAsExcelFile(data3, 'VENTAS_GENERAL_DETALLE_1A15');



    });



    return this.orders;

  }




  getinformegeneracreacion30() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(

      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-11").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),

      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-17").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-18").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-19").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-20").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-21").valueChanges(),

      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-22").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-23").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-24").valueChanges(),

      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-25").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-26").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-27").valueChanges(),

      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-28").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-29").valueChanges(),
      this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "2020-10-30").valueChanges(),




    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, trece, catorce, quince, auno, ados, atres, acuatro,
        acinco, aseis, aciete, aocho, anueve, buno, bdos, btres, bcuatro, bcinco, bseis]) => {
        return {
          uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, trece, catorce, quince, auno, ados, atres, acuatro, acinco
          , aseis, aciete, aocho, anueve, buno, bdos, btres, bcuatro, bcinco, bseis
        };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro']
        , ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho'], ...res['nueve']
        , ...res['diez'], , ...res['once'], , ...res['trece'], , ...res['catorce'], , ...res['quince']
        , , ...res['auno'], , ...res['ados'], , ...res['atres'], , ...res['acuatro'], , ...res['acinco']
        , , ...res['aseis'], , ...res['aciete'], , ...res['anueve'], , ...res['buno'], , ...res['bdos']
        , , ...res['btres'], , ...res['bcuatro'], , ...res['bcinco'], , ...res['bseis']];
      console.log('funciona esto para mejorar', data3);

      this.exportAsExcelFile(data3, 'creacion_clientes');



    });



    return this.orders;

  }


  getinformegeneralventasPEDIDONOVIEMBRE() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(

      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-01").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-02").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-03").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-04").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-05").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-06").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-07").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-08").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-09").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-10").valueChanges(),
      //    this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-11").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-12").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-13").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-14").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-15").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-16").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-10-17").valueChanges(),


      //   this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-16").valueChanges(),
      // this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-17").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-18").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-19").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-20").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-21").valueChanges(),

      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-22").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-23").valueChanges(),
      // this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-24").valueChanges(),
      //   this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-25").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-26").valueChanges(),
      // this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-27").valueChanges(),
      // this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-28").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-29").valueChanges(),
      //   this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-09-30").valueChanges(),



    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']
        , ...res['nueve'], ...res['diez'], , ...res['once'], , ...res['doce'], , ...res['trece']
        , , ...res['catorce'], , ...res['quince']];

      this.exportAsExcelFile(data3, 'VENTAS_1A15_OCTUBRE_HUILA');



    });



    return this.orders;

  }


  getinformegeneralventasPEDIDO() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    console.log('agenc pruebas', this.agencia);
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var data3;


    combineLatest(

      //    this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-01").valueChanges(),
      //   this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-02").valueChanges(),
      //   this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-03").valueChanges(),
      //    this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-04").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-05").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-06").valueChanges(),
      //       this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-07").valueChanges(),
      //    this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-08").valueChanges(),
      //    this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-09").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-10").valueChanges(),
      //   this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-11").valueChanges(),
      //    this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-12").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-13").valueChanges(),
      // this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-14").valueChanges(),
      //  this.afs.collection<any>('pedidoClientesEnvio/'+this.negocio+'_'+this.agencia+'/'+"2020-10-15").valueChanges(),

      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-16").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-17").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-18").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-19").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-20").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-21").valueChanges(),

      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-22").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-23").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-24").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-25").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-26").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-27").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-28").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-29").valueChanges(),
      this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + "2020-09-30").valueChanges(),



    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince]) => {
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho, nueve, diez, once, doce, trece, catorce, quince };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']
        , ...res['nueve'], ...res['diez'], , ...res['once'], , ...res['doce'], , ...res['trece']
        , , ...res['catorce'], , ...res['quince']];

      this.exportAsExcelFile(data3, 'VENTAS_16A30SEPTIEMBRE');



    });



    return this.orders;

  }



  getTareastrasnportador() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'PENDIENTE'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareastrasnpor(placa: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', placa)
    .where('estado', '==', 'PENDIENTE'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareastrasnporZona(zona: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('Zona', '==', zona));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }
  getTareastrasnporZonaRuta(zona: string, ruta:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', ruta)
    .where('Zona', '==', zona));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }


  getTareastrasnporRuta(ruta: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', ruta));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareastrasnporNovedad(novedad: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('observaciones', '==', novedad));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareastrasnporNovedadRuta(novedad: string, ruta:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', ruta)
    .where('observaciones', '==', novedad));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }
  getTareastrasnportadorENTREGADAS() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'EXITOSA'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

   getTareasAny = async () => {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio));
     let orders = await this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return orders;
  }

  getTareasdia() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.ordersCollectionRutas = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + localStorage.getItem("ordenTransporteFecha"), ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio));
    this.orderrutas = this.ordersCollectionRutas.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orderrutas;


  }

  
  getTareasdiaFecha(fecha:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.ordersCollectionRutas = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + fecha, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio));
    this.orderrutas = this.ordersCollectionRutas.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orderrutas;


  }


  getTareasdiaRura(ruta:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha



    return this.firebasedb.collection('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + localStorage.getItem("ordenTransporteFecha"), ref => ref.where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', ruta)).snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));


  }



  getTareasdiaNovedad() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'NOVEDAD'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;

  }

  getTareasdiaNovedadRuta(ruta:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', ruta)
    .where('estado', '==', 'NOVEDAD'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;

  }

  getTareasFoto(ruta:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('NumeroPed', '==', ruta));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;

  }

  getTareasENTREGADAS(placa: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('estado', '==', 'EXITOSA')
    .where('RutaVLI', '==', placa));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareastrasnportadorNOVEDADES() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'NOVEDAD'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareasNOVEDADES(placa: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', placa)
    .where('estado', '==', 'NOVEDAD'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }
  getTareastrasnportadorPARCIAL() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'PARCIAL'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }

  getTareasPARCIAL(placa: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', placa)
    .where('estado', '==', 'PARCIAL'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }
  
  getTareasUsuario(placa: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref
    .where('agencia', '==', this.agencia)
    .where('negocio', '==', this.negocio)
    .where('RutaVLI', '==', placa));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }
  getTareastrasnportadornovedades() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'NOVEDAD'));
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }



  getTareastrasnportadorgps() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.geoCollectionmaestro = this.afs.collection<any>('geopunto/' + this.negocio + '_' + this.agencia + '/' + "seguimiento");
    this.geoClientemaestro = this.geoCollectionmaestro.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.geoClientemaestro;


  }
  getTareastrasnportadorgpsplaca(placa:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.geoCollectionmaestro = this.afs.collection<any>('SLC_Recorrido/' + this.negocio + '_' + this.agencia + '/' + "geo", ref => ref.where('usuario', '==', placa));
    this.geoClientemaestro = this.geoCollectionmaestro.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.geoClientemaestro;


  }



  getRuteroCreacion(id: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.creacionRuteroCollectionmaestro = this.afs.collection<any>('crearRuteroAprobacion/' + this.negocio + '_' + this.agencia + '/' + 'maestros', ref => ref.where('id', '==', id));
    this.creacionRuteroClientemaestro = this.creacionRuteroCollectionmaestro.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.creacionRuteroClientemaestro;


  }


  getContactoCreacion(id: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.creacionRuteroCollectionmaestro = this.afs.collection<any>('crearContactosAprobacion/' + this.negocio + '_' + this.agencia + '/' + 'maestros', ref => ref.where('id_emp', '==', id));
    this.creacionRuteroClientemaestro = this.creacionRuteroCollectionmaestro.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.creacionRuteroClientemaestro;


  }



  getPreciosMec(id: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.preciosCollectionemec = this.afs.collection<any>('preciosemecApp/' + this.negocio + '_' + this.agencia + '/' + 'maestros', ref => ref.where('IdArt', '==', id));
    this.preciosemec = this.preciosCollectionemec.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.preciosemec;


  }

  getPedidosEnviados() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.pedidoClientesCollectiondetalle = this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.pedidoClientesdetalle = this.pedidoClientesCollectiondetalle.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoClientesdetalle;


  }

  getContactosPendientes() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.contactospenditesCollection = this.afs.collection<any>('contactosPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.contactopendientes = this.contactospenditesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.contactopendientes;


  }




  getAsesoresPedidos() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);

    this.asesoresCollection = this.afs.collection<any>('asesoresPedidos/', ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio));
    this.asesores = this.asesoresCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    return this.asesores;

  }



  getPedidosDetallePedidos() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.pedidoClientesCollectiondetalle = this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.pedidoClientesdetalle = this.pedidoClientesCollectiondetalle.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any
        const id = a.payload.doc.id;
        data.idllave = id
        data.rfv = id.substr(1, 60);

        return { ...data };
      })
    ));

    return this.pedidoClientesdetalle;


  }


  getPedidosDetallePedidospikin(idArt: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha
    this.pedidoClientesCollectiondetalle = this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('idArt', '==', idArt));
    this.pedidoClientesdetalle = this.pedidoClientesCollectiondetalle.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any
        const id = a.payload.doc.id;
        data.idllave = id;
        return { ...data };
      })
    ));

    return this.pedidoClientesdetalle;


  }


  getPedidosDetallePedidospikinArticulos(idArt: string) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha
    this.pedidoClientesCollectiondetalle = this.afs.collection<any>('pedidoClientesEnvioDEtalle/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('idArt', '==', idArt));
    this.pedidoClientesdetalle = this.pedidoClientesCollectiondetalle.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any
        const id = a.payload.doc.id;
        data.idllave = id;
        return { ...data };
      })
    ));

    return this.pedidoClientesdetalle;


  }


  getPedidosNovedades() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.novedadesClientesCollection = this.afs.collection<any>('NovedadClienteEnvio/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.pedidoNovededas = this.novedadesClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoNovededas;


  }


  getPedidospikinDia() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    const cValue = this.fecha

    this.pikinCollection = this.afs.collection<any>('pikinapp/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.orderBy('idArt'));
    this.pikin = this.pikinCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pikin;


  }




  getArticusloApp() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    this.articulosCollection = this.afs.collection<any>('articulosApp/' + this.negocio + '_' + this.agencia + '/' + 'maestros');
    this.articulos = this.articulosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.articulos;



  }


  getArticusloAppDetalle(idarticulo: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    console.log("articulo q estoy descargando ", idarticulo);

    this.articulosCollection = this.afs.collection<any>('articulosApp/' + this.negocio + '_' + this.agencia + '/' + 'maestros', ref => ref.where('idArt', '==', idarticulo.idArt));
    this.articulos = this.articulosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.articulos;



  }



  getOrdersnovedades() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.ordersCollectionnovedades = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'NOVEDAD'));
    this.ordersnovedades = this.ordersCollectionnovedades.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.ordersnovedades;



  }

  getNovedades() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const currentDate = new Date();

    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.novedadesClientesCollection = this.afs.collection<any>('NovedadClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.pedidoNovededas = this.novedadesClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoNovededas;



  }




  getRuterosDiaDescarga(dia: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    this.fecha = localStorage.getItem("pedidoFecha");



    this.clientesAppCollection = this.afs.collection<any>('clientesApp/' + this.negocio + '_' + this.agencia + '/' + "maestros", ref => ref.where('dia', '==', dia).where('idempleado', '==', 'RB1'));
    this.clientesApp = this.clientesAppCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    console.log('ESTOS SON LOS CLIENTES DEL RUTERO PV1', this.clientesApp);

    return this.clientesApp;




  }





  getRuterosDiaDescargaasesor(dia: any, asesor: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");



    this.fecha = localStorage.getItem("pedidoFecha");



    this.clientesAppCollection = this.afs.collection<any>('clientesApp/' + this.negocio + '_' + this.agencia + '/' + "maestros", ref => ref.where('dia', '==', dia).where('idempleado', '==', "RB1"));
    this.clientesApp = this.clientesAppCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.clientesApp;


  }




  getOrdersclientes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.ordersCollectionnovedades = this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.ordersnovedades = this.ordersCollectionnovedades.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.ordersnovedades;



  }


  getClientePendientes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha
    this.pendientesClientesCollection = this.afs.collection<any>('pendienteClientes/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.pendientesClientes = this.pendientesClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pendientesClientes;
  }





  getCreacionClientesCrear() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");

    const cValue = this.fecha

    this.crearClientesCollection = this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.crearClientes = this.crearClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        data.fecha = cValue;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.crearClientes;



  }

  getCreacionClientesCrearAsesor(asesor: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.crearClientesCollection = this.afs.collection<any>('clientesPendientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('ususaio', '==', asesor.usuario));
    this.crearClientes = this.crearClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        data.fecha = cValue;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.crearClientes;



  }


  getOrders() {
  this.negocio = localStorage.getItem("negocio");
  this.agencia = localStorage.getItem("agencia");
  
  this.ordersCollection = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + localStorage.getItem("ordenTransporteFecha"));
  this.orders = this.ordersCollection.snapshotChanges().pipe(
    map(actions => actions.map(a => {
      const data = a.payload.doc.data() as any;
      const id = a.payload.doc.id;
      if (data.url_foto_cliente && data.url_foto_cliente.trim() !== '') {
        return {
          ...data,
          url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+data.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+data.NumeroPed+'1.jpg?alt=media',
          url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+data.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+data.NumeroPed+'2.jpg?alt=media'
        };
      } else {
        return { ...data };
      }
    }))
  );

  return this.orders;
}

  getDetalleEncuentas(rfv: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    console.log(" no trae cliente fecha )" + this.fecha);
    this.detallesEncuestasColl = this.afs.collection<any>('SLC_EncuestasDetalle/' + this.negocio + '_' + this.agencia + '/encuentas', ref => ref.where('rfv', '==', rfv));
    this.detalleEncuestas = this.detallesEncuestasColl.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.detalleEncuestas;


  }

  getOrdersFoto(numero: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    console.log(" no trae cliente fecha )" + this.fecha);
    this.tareasfotocoleccion = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('NumeroPed', '==', parseInt(numero)));
    this.tareafoto = this.tareasfotocoleccion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareafoto;


  }

  getTareasEntrega(placa: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");


    console.log(" no trae cliente fecha )" + this.fecha);
    this.tareasfotocoleccion = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + localStorage.getItem("ordenTransporteFecha"), ref => ref.where('usuario_app', '==', placa));
    this.tareafoto = this.tareasfotocoleccion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareafoto;


  }

  getOrdersFotoStrig(numero: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    console.log(" no trae cliente fecha )" + this.fecha);
    this.tareasfotocoleccion = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('NumeroPed', '==', numero));
    this.tareafoto = this.tareasfotocoleccion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareafoto;


  }
  

  getParciales() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha
    this.ordersCollection = this.afs.collection<any>('dbPedidoDetParcial/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }


  getPedidos() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.pedidoClientesCollection = this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.orderBy('id'));
    this.pedidoClientes = this.pedidoClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoClientes;


  }


  getArticulosMec() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.articulosCollectionemec = this.afs.collection<any>('articulosemecApp/' + this.negocio + '_' + this.agencia + '/' + "maestros", ref => ref.orderBy('IdArt'));
    this.articulosemec = this.articulosCollectionemec.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.articulosemec;


  }


  getPedidosUsuario(asesor: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.pedidoClientesCollection = this.afs.collection<any>('pedidoClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('usuario', '==', asesor.usuario));
    this.pedidoClientes = this.pedidoClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoClientes;


  }


  getNovedadesclietnes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.novedadesClientesCollection = this.afs.collection<any>('NovedadClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.pedidoNovededas = this.novedadesClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoNovededas;


  }



  getNovedadesclietnesAsesor(asesor: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const currentDate = new Date();
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.novedadesClientesCollection = this.afs.collection<any>('NovedadClientesEnvio/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('usuario', '==', asesor.usuario));
    this.pedidoNovededas = this.novedadesClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.pedidoNovededas;


  }


  getClientes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.ordersCollection = this.afs.collection<any>('clientes/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.orders = this.ordersCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orders;


  }


  getOrderspedientes() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.ordersCollectionpendientes = this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('estado', '==', 'PENDIENTE'));
    this.orderspendientes = this.ordersCollectionpendientes.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.orderspendientes;

  }

  getOrderspedientesinforme(infomre: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");


    console.log('ingresa pero no se', infomre)
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.ordersCollectionpendientes = this.afs.collection<any>('tareascargadas/' + infomre.negocio + '_' + infomre.agencia + '/' + cValue, ref => ref.where('agencia', '==', infomre.agencia).where('negocio', '==', infomre.negocio).where('estado', '==', 'PENDIENTE').where('Placa', '==', infomre.usuario));
    this.orderspendientes = this.ordersCollectionpendientes.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;


        return { ...data };
      })
    ));



    return this.orderspendientes;

  }

  getOrderadmin() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    let data = null;
    let datainforme = null;
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    this.ordersCollecadmin = this.afs.collection<any>('asesor', ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio));
    this.ordersadmin = this.ordersCollecadmin.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        datainforme = a.payload.doc.data() as any;
        const id = a.payload.doc.id;



        return { ...datainforme };

      })
    ));

    console.log('que voy a correr y por q', datainforme);

    this.ordersadmin.subscribe(res => {

      for (let elemento of res) {

        this.getOrderspedientesinforme(elemento).subscribe(dal => {


          console.log('sus valores', dal.length);
          elemento.porfin = dal.length;
          this.createInforme(elemento);

        });
      }
    });


    return this.ordersadmin;


  }

  getCreacionClientes() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.creacionCollectionmaestro = this.afs.collection<any>('crearClientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "maestros", ref => ref.where('estadoODL', '==', 'PENDIENTE'));
    this.creacionClientemaestro = this.creacionCollectionmaestro.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.creacionClientemaestro;


  }


  getClientesMaestros() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    console.log('agenc pruebas', this.agencia);
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.contactosCollection = this.afs.collection<any>('contactosApp/' + this.negocio + '_' + this.agencia + '/' + "maestros");
    this.contactos = this.contactosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.contactos;


  }


  getOrderadmintotal() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    this.ordersCollecadmin = this.afs.collection<any>('asesor');
    this.ordersadmin = this.ordersCollecadmin.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        data.dia1 = '1'

        return { ...data }

      })
    ));


    return this.ordersadmin;

  }

  getuserweb(documentId: string) {
    this.userwebCollection = this.afs.collection<any>('userWeb', ref => ref.where('correo', '==', documentId));
    this.userweb = this.userwebCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    var data;
    this.userweb.subscribe(res => {
      data = res;

      for (let elemento of data) {

        localStorage.setItem("negocio", elemento.negocio);
        localStorage.setItem("agencia", elemento.agencia);

      }
    });

    return this.userweb

  }


  getAvance() {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.avanceCollection = this.afs.collection<any>('avance/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.avance = this.avanceCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    return this.avance;
  }


  getTipo() {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tipoCollection = this.afs.collection<any>('novIndicador/' + this.negocio + '_' + this.agencia + '/' + cValue, res=>res.orderBy('Dev', "desc"));
    this.tipo = this.tipoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    return this.tipo;
  }


  getTipoRuta(ruta:String) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tipoCollection = this.afs.collection<any>('novIndicadorRuta/' + this.negocio + '_' + this.agencia + '_' + ruta +  '/' + cValue, res=>res.orderBy('Dev', "desc"));
    this.tipo = this.tipoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    return this.tipo;
  }
  updateOrder(order: any) {
    return this.ordersCollection.doc(order.id).update(order);
  }

  updateCrecionCliente(order: any) {
    return this.creacionCollectionmaestro.doc(order.rfv).update(order);
  }


  updainforme(informe: any) {
    return this.ordersCollection.doc(informe.usuario).update(["cantidad", firebase.firestore.FieldValue.increment(1)]);
  }



  deleteclientependiente(id: string) {
    return this.pendientesClientesCollection.doc(id).delete();
  }

  deleteOrder(id: string) {
    return this.ordersCollection.doc(id).delete();
  }

  createOrder(order: any) {
    this.ordersCollection = this.afs.collection<any>('tareascargadas/'+this.negocio+'_'+this.agencia+'/'+order.fechaVL);
    return this.ordersCollection.doc('E' + order.NumeroPed).set(order);
  }

  createmaestro(order: any) {
    return this.clientesAppCollection.doc('C' + order.id_empresa).set(order);
  }


  createarticulos(order: any) {
    return this.articulosCollection.doc('asasd' + order.idArt).set(order);
  }


  createarticulosemec(order: any) {
    return this.articulosCollectionemec.doc('A' + order.IdArt).set(order);
  }

  createarticulospikin(order: any) {
    return this.pikinCollection.doc('p' + order.idArt).set(order);
  }


  createarinventariosemec(order: any) {
    return this.inventariosCollectionemec.doc('I' + order.IdArt).set(order);
  }



  createarinventarios(order: any) {
    return this.inventariosCollection.doc('I' + order.idArt).set(order);
  }



  createaruteros(order: any) {
    return this.ruterosCollection.doc('R' + order.Id).set(order);
  }


  createPrecios(order: any) {
    return this.preciosCollection.doc('P' + order.idArt).set(order);
  }


  createPreciosemec(order: any) {
    return this.preciosCollectionemec.doc('P' + order.IdArt).set(order);
  }


  createLineas(order: any) {
    return this.lineasCollection.doc('L' + order.IdLn).set(order);
  }

  createListas(order: any) {
    return this.listasCollection.doc('LI' + order.idLP).set(order);
  }


  createCategorrias(order: any) {
    return this.categoriasCollection.doc('CA' + order.IdCt).set(order);
  }

  createMarcas(order: any) {
    return this.marcasCollection.doc('MA' + order.idMC).set(order);
  }

  createContactos(order: any) {
    return this.contactosCollection.doc('CO' + order.id_cnto).set(order);
  }


  createunidad(order: any) {
    return this.unidadCollection.doc('U' + order.idUM).set(order);
  }

  createNovedad(order: any) {
    return this.novedadesCollection.doc('N' + order.Idnv).set(order);
  }



  createDetallepedido(order: any) {

    console.log('articulo para modificar que hacer', order);
    return this.pedidoClientesCollectiondetalle.doc(order.idllave).set(order);
  }

  createpedidocargue(order: any) {
    return this.pedidoClientesCollection.doc(order.rfv).set(order);
  }



  createRuta(rutas: any) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    this.rutasCollection = this.afs.collection<any>('rutas/'+this.negocio+'_'+this.agencia+'/'+rutas.fecha);
    this.rutas = this.rutasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.rutasCollection.doc(rutas.ruta).set(rutas);
  }


  createInforme(informe: any) {
    return this.collecinformes.doc(informe.usuario).set(informe);
  }



  createAvance(avance: any) {
    return this.avanceCollection.doc(avance.name).set(avance);
  }


  createEstado(estado: any) {
    return this.estadoCollection.doc(estado.name).set(estado);
  }


  createNovedades(totalnove: any) {
    return this.totalnovedades.doc(totalnove.detalle).set(totalnove);
  }

  createCash(cash: any) {
    return this.cashCollection.doc(cash.name).set(cash);
  }

  createRecorrido(recorido: any) {
    return this.recorridoCollection.doc(recorido.idrecorrido).set(recorido);
  }

  createClientesPendi(recorido: any) {
    return this.pendientesClientesCollection.doc("CC" + recorido.id_empresa).set(recorido);
  }

  createNov(novedad: any) {


    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha;
    this.novCollection =this.afs.collection<any>('novIndicador/'+this.negocio+'_'+this.agencia+'/'+cValue);
    
    return this.novCollection.doc("NOV" + novedad.detalle).set(novedad);

  }

  createNovRuta(novedad: any, ruta:String) {


    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha;
    this.novCollection =this.afs.collection<any>('novIndicadorRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);
    
    return this.novCollection.doc("NOV" + novedad.detalle).set(novedad);

  }

  createTareasEstado(novedad: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha;
    this.tareasEstadoCollection =this.afs.collection<any>('tareasEstado/'+this.negocio+'_'+this.agencia+'/'+cValue);
    
    return this.tareasEstadoCollection.doc("TA" + novedad.name).set(novedad);

  }

  createTareasEstadoRuta(novedad: any, ruta:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha;
    this.tareasEstadoCollection =this.afs.collection<any>('tareasEstadoRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);
    
    return this.tareasEstadoCollection.doc("TA" + novedad.name).set(novedad);

  }


  createTareasEstadoValor(novedad: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha;
    this.tareasEstadoCollection =this.afs.collection<any>('tareasEstadoValor/'+this.negocio+'_'+this.agencia+'/'+cValue);
    
    return this.tareasEstadoCollection.doc("TA" + novedad.name).set(novedad);

  }

  createTareasEstadoValorRuta(novedad: any, ruta:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha;
    this.tareasEstadoCollection =this.afs.collection<any>('tareasEstadoValorRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);
    
    return this.tareasEstadoCollection.doc("TA" + novedad.name).set(novedad);

  }


  getTotalnovedades() {

    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha
    // dejas
    this.totalnovedades = this.afs.collection<any>('novedades/' + this.negocio + '_' + this.agencia + '/' + cValue,ref => ref.where("y", ">", 0).orderBy('y', "desc"));
    this.totalnove = this.totalnovedades.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.totalnove;
  }

  getTareasEstado() {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    // dejas
    this.tareasEstadoCollection = this.afs.collection<any>('tareasEstado/' + this.negocio + '_' + this.agencia + '/' + cValue,ref => ref.where("y", ">", 0).orderBy('y', "desc"));
    this.tareasEstado = this.tareasEstadoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareasEstado;
  }

  getTareasEstadoRuta(ruta:String) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    // dejas
    this.tareasEstadoCollection = this.afs.collection<any>('tareasEstadoRuta/' + this.negocio + '_' + this.agencia + '_' + ruta + '/' + cValue,ref => ref.where("y", ">", 0));
    this.tareasEstado = this.tareasEstadoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareasEstado;
  }



  getTareasEstadoValor() {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    // dejas
    this.tareasEstadoCollection = this.afs.collection<any>('tareasEstadoValor/' + this.negocio + '_' + this.agencia + '/' + cValue,ref => ref.where("y", ">", 0).orderBy('y', "desc"));
    this.tareasEstado = this.tareasEstadoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tareasEstado;
  }

  getRecorrido() {
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.recorridoCollection = this.afs.collection<any>('recorrido/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.recorido = this.recorridoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));
    return this.recorido;

  }

  getZonasGrupos() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.zonasGruposDeCollection = this.afs.collection<any>('zonasGrupos/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.orderBy('Dev',"desc"));
    this.zonasGruposDe = this.zonasGruposDeCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));
    return this.zonasGruposDe;

  }


  getZonasGruposRuta() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.zonasGruposDeCollection = this.afs.collection<any>('zonasGruposRuta/' + this.negocio + '_' + this.agencia + '/' + cValue, ref => ref.orderBy('Dev',"desc"));
    this.zonasGruposDe = this.zonasGruposDeCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));
    return this.zonasGruposDe;

  }


  getTiposNovedades() {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.tipoCrearCollection = this.afs.collection<any>('tipoNovedad/' + this.negocio + '_' + this.agencia + '/' + cValue,res=> res.orderBy('y', "desc"));
    this.tipoCrear = this.tipoCrearCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));
    return this.tipoCrear;

  }

  getTiposNovedadesRuta(ruta:String) {
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha

    this.tipoCrearCollection = this.afs.collection<any>('tipoNovedadRuta/' + this.negocio + '_' + this.agencia + '_' + ruta +'/' + cValue,res=> res.orderBy('y', "desc").where("y",'>',0));
    this.tipoCrear = this.tipoCrearCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));
    return this.tipoCrear;

  }
  getTotaloperacion() {

    // dejar
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.totaloperacion = this.afs.collection<any>('totaloperacion/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.totalope = this.totaloperacion.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));
    return this.totalope;
  }
  getEstado() {

     // dejas
     this.fecha = localStorage.getItem("pedidoFecha");
     const cValue = this.fecha
     this.estadoCollection =this. afs.collection<any>('estado/' + this.negocio + '_' + this.agencia + '/' + cValue);
     this.estado = this.estadoCollection.snapshotChanges().pipe(map(
       actions => actions.map(a => {
         const data = a.payload.doc.data() as any;
         const id = a.payload.doc.id;
         return { ...data };
       })
     ));

    return this.estado;
  }

  getTableros() {
    // dejar
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha
    this.tableroCollection = this.afs.collection<any>('tablero/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.tablero = this.tableroCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));


    return this.tablero;
  }

  getCash() {

       //dejar
       this.fecha = localStorage.getItem("ordenTransporteFecha");
       const cValue = this.fecha
       this.cashCollection = this.afs.collection<any>('cash/' + this.negocio + '_' + this.agencia + '/' + cValue);
       this.cash = this.cashCollection.snapshotChanges().pipe(map(
         actions => actions.map(a => {
           const data = a.payload.doc.data() as any;
           const id = a.payload.doc.id;
           return { ...data };
         })
       ));
    return this.cash;
  }

  getclienteto() {


      // dejar
      this.ruterosCollection = this.afs.collection<any>('ruterosApp/' + this.negocio + '_' + this.agencia + '/' + "maestros");
      this.ruteros = this.ruterosCollection.snapshotChanges().pipe(map(
        actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
  
      ));
    return this.ruteros;
  }

  getinformesapp() {
    // dejar
    const cValue = this.fecha
    this.informesCollectionmaestro = this.afs.collection<any>('indicadoresapp/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.informesClientemaestro = this.informesCollectionmaestro.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    return this.informesClientemaestro;
  }

  getcrearclientesapp() {
    
      // + dejar
      this.creacionCollectionmaestro = this.afs.collection<any>('crearClientesAprobacion/' + this.negocio + '_' + this.agencia + '/' + "maestros");
      this.creacionClientemaestro = this.creacionCollectionmaestro.snapshotChanges().pipe(map(
        actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
  
      ));

  }

  getclientesapp() {

      // dejar

      this.clientesAppCollection = this.afs.collection<any>('clientesApp/' + this.negocio + '_' + this.agencia + '/' + "maestros");
      this.clientesApp = this.clientesAppCollection.snapshotChanges().pipe(map(
        actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
  
      ));

    return this.clientesApp;
  }



  getcontactosapp() {


    this.clientesAppCollection = this.afs.collection<any>('contactosApp/' + this.negocio + '_' + this.agencia + '/' + "maestros");
    this.clientesApp = this.clientesAppCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

    return this.clientesApp;
  }

  getruterosapp() {

      // dejar
      this.ruterosCollection = this.afs.collection<any>('ruterosApp/' + this.negocio + '_' + this.agencia + '/' + "maestros");
      this.ruteros = this.ruterosCollection.snapshotChanges().pipe(map(
        actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
  
      ));

    return this.ruteros;
  }


  getTareasRecaudo() {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudo/' + this.negocio + '_' + this.agencia + '/' + cValue, res=>res.orderBy('txtTotalFac', "desc"));
    this.tareasRecaudo = this.tareasRecaudoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

  return this.tareasRecaudo;
}


getTareasRecaudoRuta(ruta:String) {

  this.fecha = localStorage.getItem("ordenTransporteFecha");
  const cValue = this.fecha
  this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudoRuta/' + this.negocio + '_' + this.agencia + '_' + ruta +  '/' + cValue, res=>res
  .where('name',"==",ruta));
  this.tareasRecaudo = this.tareasRecaudoCollection.snapshotChanges().pipe(map(
    actions => actions.map(a => {
      const data = a.payload.doc.data() as any;
      const id = a.payload.doc.id;
      return { id, ...data };
    })

  ));

return this.tareasRecaudo;
}

getTareasRecaudoValor() {

  this.fecha = localStorage.getItem("ordenTransporteFecha");
  const cValue = this.fecha
  this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudoValor/' + this.negocio + '_' + this.agencia + '/' + cValue, res=>res.orderBy('txtTotalFac', "desc"));
  this.tareasRecaudo = this.tareasRecaudoCollection.snapshotChanges().pipe(map(
    actions => actions.map(a => {
      const data = a.payload.doc.data() as any;
      const id = a.payload.doc.id;
      return { id, ...data };
    })

  ));

return this.tareasRecaudo;
}


  getRuteroclientesapp() {

    return this.creacionRuteroClientemaestro;
  }

  getContactosclientesapp() {

    return this.creacionContactoClientemaestro;
  }




  getinformesappasesor() {

    this.informesClientemaestroasesor = this.informesCollectionmaestroAsesor.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));
    return this.informesClientemaestroasesor;
  }


  

  createCllienteVisitas(recorido: any) {
    return this.pendientesClientesCollection.doc("CC" + recorido.id_empresa).set(recorido);
  }

  createZonaAgrupadas(zona: any) {
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.zonasCollection = this.afs.collection<any>('zonas/'+this.negocio+'_'+this.agencia+'/'+cValue);
     
    return this.zonasCollection.doc("ZN" + zona.idZona).set(zona);

  }

  createZonaAgrupadasRuta(zona: any, ruta:String) {
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.zonasCollection = this.afs.collection<any>('zonasRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);
     
    return this.zonasCollection.doc("ZN" + zona.idZona).set(zona);

  }

  createZonasGrups(zona: any) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.zonasGruposCollection = this.afs.collection<any>('zonasGrupos/'+this.negocio+'_'+this.agencia+'/'+cValue);

    return this.zonasGruposCollection.doc("ZN" + zona.idZona).set(zona);

  }


  createZonasGrupsRuta(zona: any) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.zonasGruposCollection = this.afs.collection<any>('zonasGruposRuta/'+this.negocio+'_'+this.agencia+'/'+cValue);

    return this.zonasGruposCollection.doc("ZN" + zona.idZona).set(zona);

  }


  createTareasRecaudo(recaudo: any) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudo/'+this.negocio+'_'+this.agencia+'/'+cValue);

    return this.tareasRecaudoCollection.doc("TR" + recaudo.name).set(recaudo);

  }

  createTareasRecaudoRuta(recaudo: any,ruta:String) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudoRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);

    return this.tareasRecaudoCollection.doc("TR" + recaudo.name).set(recaudo);

  }

 

    createTareasRecaudoValor(recaudo: any) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudoValor/'+this.negocio+'_'+this.agencia+'/'+cValue);

    return this.tareasRecaudoCollection.doc("TR" + recaudo.name).set(recaudo);

  }

  createTareasRecaudoValorRuta(recaudo: any, ruta:String) {

    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tareasRecaudoCollection = this.afs.collection<any>('tareasRecaudoValorRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);

    return this.tareasRecaudoCollection.doc("TR" + recaudo.name).set(recaudo);

  }



  updateZonaAgrupadas(zona: any) {
   return this.zonasCollection.doc("ZN" + zona.idZona).update(["total", firebase.firestore.FieldValue.increment(zona.total)]);
  
  }

  createinvicadoresapp(indicadores: any) {
    return this.informesCollectionmaestro.doc(indicadores.id).set(indicadores);
  }


  createinvicadoresappAsesor(indicadores: any) {
    return this.informesCollectionmaestroAsesor.doc(indicadores.name).set(indicadores);
  }


  createarticulodetalle(indicadores: any) {
    return this.articulodetallecolle.doc("seleccion").set(indicadores);
  }


  createinvicadoresappCasas(indicadores: any) {
    return this.informesCollectionCasa.doc(indicadores.id).set(indicadores);
  }

  createTipo(tipo: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tipoCrearCollection = this. afs.collection<any>('tipoNovedad/'+this.negocio+'_'+this.agencia+'/'+cValue);
  
    return this.tipoCrearCollection.doc(tipo.name).set(tipo);

  }


  createTipoRuta(tipo: any, ruta:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha
    this.tipoCrearCollection = this. afs.collection<any>('tipoNovedadRuta/'+this.negocio+'_'+this.agencia+'_'+ruta+'/'+cValue);
  
    return this.tipoCrearCollection.doc(tipo.name).set(tipo);

  }


  //* Cambio de iformes

  getZonas() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    const cValue = this.fecha

    this.zonasCollection = this.afs.collection<any>('zonas/' + this.negocio + '_' + this.agencia + '/' + cValue);
    this.zonas = this.zonasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { id, ...data };
      })

    ));

  return this.zonas;
}


  getTransportadores() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha


    this.collecTransportadores = this.afs.collection<any>('rutas/'+this.negocio+'_'+this.agencia+'/'+cValue);
    this.transportadoresany = this.collecTransportadores.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.transportadoresany;

  }

  getTransportadoresRuta(ruta:String) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("ordenTransporteFecha");
    const cValue = this.fecha


    this.collecTransportadores = this.afs.collection<any>('rutas/'+this.negocio+'_'+this.agencia+'/'+cValue , res=> res.where('ruta','==',ruta));
    this.transportadoresany = this.collecTransportadores.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.transportadoresany;

  }

  getreportedos() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");

    console.log('agenc pruebas', this.agencia);

    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-09", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-10", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-11", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-12", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-13", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-14", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-15", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-16", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
      
        uno = uno.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
               url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
               url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
           };
          } else {
            return { ...item };
          }
        });

        dos = dos.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        tres = tres.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        cuatro = cuatro.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        cinco = cinco.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        seis = seis.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        ciete = ciete.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        ocho = ocho.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });
        // Repite lo mismo para cada colección
    
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      this.exportAsExcelFile(data3, 'tareas_' + this.negocio + '_' + this.agencia + "_2024_" + this.mesconsulta + '_9_16');


    });



    return this.orders;

  }

  getreporteuno() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");

    console.log('agenc pruebas', this.agencia);

    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-01", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-02", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-03", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-04", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-05", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-06", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-07", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-08", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
      
        uno = uno.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
                };
              } else {
            return { ...item };
          }
        });

        dos = dos.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        tres = tres.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        cuatro = cuatro.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        cinco = cinco.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        seis = seis.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        ciete = ciete.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        ocho = ocho.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
          } else {
            return { ...item };
          }
        });
        // Repite lo mismo para cada colección
    
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      this.exportAsExcelFile(data3, 'tareas_' + this.negocio + '_' + this.agencia + "_2024_" + this.mesconsulta + '_1_8');


    });



    return this.orders;

  }

  getreporteunomes() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");

    console.log('agenc pruebas', this.agencia);

    return combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-01", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-02", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-03", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-04", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-05", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-06", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-07", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-08", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    )

  
  }


  getreportetres() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");

    console.log('agenc pruebas', this.agencia);

    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-17", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-18", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-19", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-20", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-21", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-22", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-23", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-24", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),

    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
      
        uno = uno.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        dos = dos.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        tres = tres.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        cuatro = cuatro.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        cinco = cinco.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        seis = seis.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        ciete = ciete.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        ocho = ocho.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });
        // Repite lo mismo para cada colección
    
        return { uno, dos, tres, cuatro, cinco, seis, ciete, ocho };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete'], ...res['ocho']];
      this.exportAsExcelFile(data3, 'tareas_' + this.negocio + '_' + this.agencia + "_2024_" + this.mesconsulta + '_17_24');


    });



    return this.orders;

  }

  getreportecuatro() {

    var data3;
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.mesconsulta = localStorage.getItem("mesconsulta");

    console.log('agenc pruebas', this.agencia);

    combineLatest(
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-25", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-26", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-27", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-28", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-29", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-30", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
      this.afs.collection<any>('tareascargadas/' + this.negocio + '_' + this.agencia + '/' + "2024-" + this.mesconsulta + "-31", ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio)).valueChanges(),
  
    ).pipe(
      map(([uno, dos, tres, cuatro, cinco, seis, ciete, ocho]) => {
      
        uno = uno.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        dos = dos.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        tres = tres.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        cuatro = cuatro.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        cinco = cinco.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

        seis = seis.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item,
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
              } else {
            return { ...item };
          }
        });

        ciete = ciete.map(item => {
          if (item.url_foto_cliente && item.url_foto_cliente.trim() !== '') {
            return {...item, 
              url_foto_factura: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'1.jpg?alt=media',
              url_foto_cliente: 'https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F'+item.fechaVL+'%2F'+this.negocio+'%2F'+this.agencia+'%2FE'+item.NumeroPed+'2.jpg?alt=media'
            };
            } else {
            return { ...item };
          }
        });

    
        // Repite lo mismo para cada colección
    
        return { uno, dos, tres, cuatro, cinco, seis, ciete };
      })
    ).subscribe(res => {
      data3 = [...res["uno"], ...res['dos'], ...res['tres'], ...res['cuatro'], ...res['cinco'], ...res['seis'], ...res['ciete']];
      this.exportAsExcelFile(data3, 'tareas_' + this.negocio + '_' + this.agencia + "_2024_" + this.mesconsulta +'_25_31');


    });



    return this.orders;

  }
}