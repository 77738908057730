
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DialogAgregarTercero } from '../dialogAgregarTercero/dialogAgregarTercero.component';
import { Router } from '@angular/router';
import { DialogAgregarEncuesta } from '../dialogAgregarEncuesta/dialogAgregarEncuesta.component';
import { DialogAgregarEncuestaFecha } from '../dialogAgregarEncuestaFecha/dialogAgregarEncuestaFecha.component';
import { DialogAgregarEncuestaSeleccion } from '../dialogAgregarEncuestaSeleccion/dialogAgregarEncuestaSeleccion.component';
import { DialogEditarEncuestaSeleccion } from '../dialogEditarEncuestaSeleccion/dialogEditarEncuestaSeleccion.component';
import { DialogEditarEncuestaFecha } from '../dialogEditarEncuestaFecha/dialogEditarEncuestaFecha.component';
import { DialogEliminarEncuesta } from '../../tipoDoc/dialogEliminarEncuesta/dialogEliminarEncuesta.component';
import { DialogEliminarEncuestaDetalle } from '../../tipoDoc/dialogEliminarEncuestaDetalle/dialogEliminarEncuestaDetalle.component';

export interface DialogDataVh {
  agencia: String,
  id: String,
  negocio: String,
  obligatoria: String,
  orden: String,
  tipoOrigen: String,
  title: String
}

@Component({
  selector: 'app-DialogEditarEncuestaDetalle',
  templateUrl: './DialogEditarEncuestaDetalle.component.html',
  styleUrls: ['./DialogEditarEncuestaDetalle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEditarEncuestaDetalle implements OnInit {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  private dias;
  private s_dia;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  public codigo;
  public tipoOrigen;
  public iscargado = true;
  public ismenu = false;
  ismenudialog=false
  
  constructor(public dialogRef: MatDialogRef<DialogEditarEncuestaDetalle>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogagregar: MatDialog,public dialogEliminar: MatDialog,public dialogagregarVh: MatDialog,private route: Router) {}
     @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
     displayedColumns: string[] = ['obligatoria','etiqueta','tipoDetalle','fuente','orden','tipoOrigen','title'];
     dataSource = new MatTableDataSource();

  ngOnInit(): void {
    this.s_dia= Number(this.data.orden)
    this.descargarCodigoTransportador()
    this.descargardias()
 
  }

  descargardias() {

    this.configuracionesService.getdia().subscribe(configuraciones => {

      this.dias=configuraciones
  

    });
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }

  onFirma(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "8",
      id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }
  onNumero(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "7",
      id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  
  onFecha(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "1",
      id: this.data.id ,
      tipoOrigen: this.data.tipoOrigen
      },
    
    });
    
  
  }

    
  onFoto(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "2",
      id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  onLarga(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "3",
      id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  onCorto(): void {

    this.dialogagregar.open(DialogAgregarEncuestaFecha, {
      width: '950px',
      data: {tipo: "4", id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
    },
    
    });
    
  
  }

  onUnico(): void {

    this.dialogagregar.open(DialogAgregarEncuestaSeleccion, {
      width: '950px',
      data: {tipo: "5",
      id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
     
      },
    
    });
    
  
  }

  onMultiple(): void {

    this.dialogagregar.open(DialogAgregarEncuestaSeleccion, {
      width: '950px',
      data: {tipo: "6",
      id: this.data.id,
      tipoOrigen: this.data.tipoOrigen
      },
    
    });
    
  
  }



  onNuevo(): void {

    this.dialogagregar.open(DialogAgregarEncuesta, {
      width: '950px',
      data: {id: this.data.id,
        tipoOrigen: this.data.tipoOrigen
        
      },
    
    });
    
  
  }

 
  onUnicoEditar(encunesta: any) {

    if(encunesta.metadato == "SU"){
      this.dialogagregar.open(DialogEditarEncuestaSeleccion, {
        width: '950px',
        data: {tipo: "5",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
   }
 


    if(encunesta.metadato == "SM"){
      this.dialogagregar.open(DialogEditarEncuestaSeleccion, {
        width: '950px',
        data: {tipo: "6",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }

 
    if(encunesta.metadato == "IM"){
      this.dialogagregar.open(DialogEditarEncuestaFecha, {
        width: '950px',
        data: {tipo: "2",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }
  
    if(encunesta.metadato == "TC"){
      this.dialogagregar.open(DialogEditarEncuestaFecha, {
        width: '950px',
        data: {tipo: "4",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }

    if(encunesta.metadato == "TL"){
      this.dialogagregar.open(DialogEditarEncuestaFecha, {
        width: '950px',
        data: {tipo: "3",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }

    if(encunesta.metadato == "FE"){
      this.dialogagregar.open(DialogEditarEncuestaFecha, {
        width: '950px',
        data: {tipo: "1",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }

    if(encunesta.metadato == "FM"){
      this.dialogagregar.open(DialogEditarEncuestaFecha, {
        width: '950px',
        data: {tipo: "8",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }


    if(encunesta.metadato == "NU"){
      this.dialogagregar.open(DialogEditarEncuestaFecha, {
        width: '950px',
        data: {tipo: "7",
        agencia: encunesta.agencia,
        etiqueta: encunesta.etiqueta,
        fuente: encunesta.fuente,
        id: encunesta.id,
        metadato: encunesta.metadato,
        negocio: encunesta.negocio,
        obligatoria: encunesta.obligatoria,
        orden: encunesta.orden,
        tipoDetalle: encunesta.tipoDetalle,
        title: encunesta.title,
        tipoOrigen:encunesta.tipoOrigen,
        },
      
      });
    }
  
  }

  onMultipleEditar(encunesta: any) {

 
  
  
  }
  


  onCheckboxChange(element: any) {
    if (element.obligatoria) {
      // Cuando el checkbox esté marcado (obligatoria es true)
      console.log('El campo es obligatorio');
    } else {
      // Cuando el checkbox esté desmarcado (obligatoria es false)
      console.log('El campo no es obligatorio');
    }
  }

  crearDetalleEncunesta(data: any) {
   

    if(data.title) {

        
     var tipoDoc = {
        id:  Number(data.id),
        orden:Number(this.s_dia),
        title:data.title,
        obligatoria: data.obligatoria,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
        tipoOrigen:this.data.tipoOrigen
    
      }

    
      var hoy = new Date();
      var minuto=  hoy.getMinutes() 
      var segundos=hoy.getSeconds()
  
      var minutefinal="00"
      var segundofinal="00"
      if(minuto<10){
  
        minutefinal="0"+minuto
      }else{
        minutefinal=String(minuto)
      }
      if(segundos<10){
  
        segundofinal="0"+segundos
      }else{
        segundofinal=String(segundos)
      }
      var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
      var auditria = {
        id: data.id,
        pregunta: data.pregunta,
        tipo: data.tipo,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
        fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
        movimiento:  'Agregar',
    
     
      }
      console.log(tipoDoc);
     // this.configuracionesService.crearAuditriaTercero(auditria)
      this.configuracionesService.actualizarEncuestaPorIdEncuenta(tipoDoc.id,tipoDoc)
      this.toastr.successToastr("Pregunta:  creado con éxito", 'Notificación');
      this.dialogRef.close();

    }else{
      this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');
    } 

 


}

 
  descargarCodigoTransportador() {

    this.configuracionesService.getConTransporte().subscribe(tipoDocus => {

    
      this.configuracionesService.getDetalleEncuestas(this.data.id, this.data.tipoOrigen).subscribe(configuraciones => {
  
        this.dataSource.data = configuraciones;
  
        setTimeout(() => {
          this.iscargado = false;
          this.ismenu = true;
         }, 200);
  
      
    
      
      });

    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  sfdia(dato: any) {

    this.s_dia=dato

  }

  deleteEncuesta(tipo: any) {
    this.dialogEliminar.open(DialogEliminarEncuestaDetalle, {
      width: '350px',
      data: {title: tipo.title,idDocument:tipo.idDocument},
    });
  }
  
}
