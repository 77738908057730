import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { PedidoService } from '../../shared/pedidos.service';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Section {
  usuario: string;
  updated: Date;
}

@Component({
  selector: 'app-fragmentReportePreventa',
  templateUrl: './fragmentReportePreventa.component.html',
  styleUrls: ['./fragmentReportePreventa.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentReportePreventa implements OnInit {

  public anos: any[] = [
    { id: "01", Name: 'Enero' },
    { id: "02", Name: 'Febrero' },
    { id: "03", Name: 'Marzo' },
    { id: "04", Name: 'Abril' },
    { id: "05", Name: 'Mayo' },
    { id: "06", Name: 'Junio' },
    { id: "07", Name: 'Julio' },
    { id: "08", Name: 'Agosto' },
    { id: "09", Name: 'Septiembre' },
    { id: "10", Name: 'Octubre' },
    { id: "11", Name: 'Noviembre' },
    { id: "12", Name: 'Deciembre' }
  ];

  private subscription: Subscription;
  private ventasDescarga: any;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args: { cancel: boolean; }): void {
    args.cancel = true;
  }

  constructor(private orderService: OrdersService, private userd: AuthService, public pedidosService: PedidoService, public toastr: ToastrManager) {

  }

  ngOnInit(): void {

    localStorage.setItem("mesconsulta", "01");
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("pedidoFecha", cValue);
    if (localStorage.getItem('cargar') == 'pendiente') {

      localStorage.setItem("cargar", 'exitoso');


    }


  }

  mes(mes: any) {

    localStorage.setItem("mesconsulta", mes);
    console.log('mesconsulta', mes);

  }

  exportReporteUno(): void {

    this.subscription = this.pedidosService.getmesNovedad1().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"Visitas mes 1 a 8")
    

    
    });
  }

  exportReporteDos(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscription = this.pedidosService.getmesNovedad2().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"Visitas mes 9 a 16")
    

    
    });

  }


  exportReporteTres(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscription = this.pedidosService.getmesNovedad3().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"Visitas mes 17 a 24")
    

    
    });

  }


  exportReporteCuatro(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscription = this.pedidosService.getmesNovedad4().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']];
  
  
    this.pedidosService.exportAsExcelFile(this.ventasDescarga,"Visitas mes 24 a 31")
    

    
    });

  }


}
