import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
export interface DialogData {
  idTipoDoc: string;
  tipo: string;
  descripcion: string;
}

@Component({
  selector: 'app-DialogEliminarTipoDoc',
  templateUrl: './DialogEliminarTipoDoc.component.html',
  styleUrls: ['./DialogEliminarTipoDoc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEliminarTipoDoc implements OnInit {
  idTipoDoc: string;
  tipo: string;
  descripcion: string;
  constructor(public dialogRef: MatDialogRef<DialogEliminarTipoDoc>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
   
    this.dialogRef.close();
  
  }
 
  deleteTipoDoc(data: any) {
    var hoy = new Date();
    var minuto=  hoy.getMinutes() 
    var segundos=hoy.getSeconds()

    var minutefinal="00"
    var segundofinal="00"
    if(minuto<10){

      minutefinal="0"+minuto
    }else{
      minutefinal=String(minuto)
    }
    if(segundos<10){

      segundofinal="0"+segundos
    }else{
      segundofinal=String(segundos)
    }
    var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
    var auditria = {
      id:data.idTipoDoc,
      usuario:  localStorage.getItem("responsable"),
      hora:  hora,
      fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
      movimiento:  'Eliminar',
      tipoanterior:  data.tipo,
      tiponuevo: data.tipo,
      descractual:data.descripcion,
      descranterior:data.descripcion,
    }

    this.configuracionesService.crearAuditriaTipoDoc(auditria)
    this.configuracionesService.deleteTipoDoc(data)
    this.toastr.successToastr("Tipo documento: "+data.tipo+ " eliminado con éxito", 'Notificación');
    this.dialogRef.close();


  }
  
}
