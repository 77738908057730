import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarTransportadores } from '../dialogAgregarTransportadores/dialogAgregarTransportadores.component';
import { DialogAgregarConductor } from '../dialogAgregarConductor/dialogAgregarConductor.component';

export interface DialogData {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
}
@Component({
  selector: 'app-FragmentTransporte',
  templateUrl: './FragmentTransporte.component.html',
  styleUrls: ['./FragmentTransporte.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentTransporte implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public nombret;
  public apellidost;
  public identificaciont;
  public nombretenedor;
  public apellidostenedor;
  public identificaciontenedor;
  public placat;
  public claset;
  public marca;
  public linea;
  public nombrect;
  public apellidosct;
  public idTransportador;
  public idConductor;
  public transportenumero;
  public configuracion;
  public iscargado = true;
  public ismenu = false;
 
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  public  ispropietario=false
   displayedColumns: string[] = ['idTipoDoc', 'tipo','descripcion','editar','eliminar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
    this.descargarTp()
    this.descargarConductor()

   this.ispropietario=false
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getTiposDocumentos().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getConTransporte().subscribe(tipoDocus => {

      for (let tipo of tipoDocus) {

           this.codigo=tipo.con
           console.log(' this.codigo', this.codigo);
      
      }  

    });
  }

  descargarTp() {
    var idTransportador= localStorage.getItem('idTransportador')
    this.transportenumero=idTransportador
    this.configuracionesService.descargarTp(idTransportador).subscribe(registro => {

      for (let tipo of registro) {
        console.log(' tipo registro', tipo);
          this.nombret=tipo.nombrePropietario
          this.apellidost=tipo.apellidosPropietario
          this.identificaciont=tipo.idPropietario
          this.nombretenedor=tipo.nombreTenedor
          this.apellidostenedor=tipo.apellidosTenedor
          this.identificaciontenedor=tipo.idTenedor
          this.placat=tipo.placa
          this.claset=tipo.clase
          this.marca=tipo.marca
          this.linea=tipo.linea
          this.configuracion=tipo.configuracion
          this.idTransportador=tipo.idTransportador
          this.ispropietario=true
      }  

    });
  }

  descargarConductor() {
    var idTransportador= localStorage.getItem('idTransportador')
    this.configuracionesService.descargarConductor(idTransportador).subscribe(registro => {

      for (let conductor of registro) {
        console.log(' tipo registro', conductor);
          this.nombrect=conductor.nombreConductor
          this.apellidosct=conductor.apellidosConductor
          this.idConductor=conductor.idConductor

          var idTransportador= localStorage.getItem('idTransportador')
          this.transportenumero=idTransportador
          this.configuracionesService.descargarTp(idTransportador).subscribe(registro => {
      
            for (let tipo of registro) {
              console.log(' tipo registro', tipo);
                this.nombret=tipo.nombrePropietario
                this.apellidost=tipo.apellidosPropietario
                this.identificaciont=tipo.idPropietario
                this.nombretenedor=tipo.nombreTenedor
                this.apellidostenedor=tipo.apellidosTenedor
                this.identificaciontenedor=tipo.idTenedor
                this.placat=tipo.placa
                this.claset=tipo.clase
                this.marca=tipo.marca
                this.linea=tipo.linea
                this.configuracion=tipo.configuracion
                this.idTransportador=tipo.idTransportador
                this.ispropietario=true
                tipo.conductorNombre=conductor.nombreConductor
                tipo.apellidosConductor=conductor.apellidosConductor
                tipo.conductorNombre=conductor.nombreConductor
                tipo.idConductor=conductor.idConductor
                tipo.idTerceroConductor=conductor.idTerceroConductor,
                tipo.contrasena=conductor.idConductor
                this.configuracionesService.crearTransporteTemporal(tipo)
            }  
      
           

          });
 
      }  

      
      

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {

  
   

  }

  conductor():void {
    console.log(' idTransportador', this.idTransportador);

    this.dialogagregar.open(DialogAgregarConductor, {
      width: '800px',
      maxHeight:'700px',
      data: {idTransportador: this.idTransportador},
    
    });
  
    }

  deletebanco(tipo: any) {

  }

  
  atras():void {

    this.route.navigate(['/Transportador']);
    
  }
  
}
