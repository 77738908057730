import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';

export interface Section {
  usuario: string;
  updated: Date;
}

@Component({
  selector: 'app-fragmentReporte',
  templateUrl: './fragmentReporte.component.html',
  styleUrls: ['./fragmentReporte.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentReporte implements OnInit {

  public anos: any[] = [
    { id: "01", Name: 'Enero' },
    { id: "02", Name: 'Febrero' },
    { id: "03", Name: 'Marzo' },
    { id: "04", Name: 'Abril' },
    { id: "05", Name: 'Mayo' },
    { id: "06", Name: 'Junio' },
    { id: "07", Name: 'Julio' },
    { id: "08", Name: 'Agosto' },
    { id: "09", Name: 'Septiembre' },
    { id: "10", Name: 'Octubre' },
    { id: "11", Name: 'Noviembre' },
    { id: "12", Name: 'Deciembre' }
  ];

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };
  private subscriptionDetalle: Subscription;
  Remove(args: { cancel: boolean; }): void {
    args.cancel = true;
  }

  constructor(private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager) {

  }

  ngOnInit(): void {

    localStorage.setItem("mesconsulta", "01");
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("pedidoFecha", cValue);
    if (localStorage.getItem('cargar') == 'pendiente') {

      localStorage.setItem("cargar", 'exitoso');

    }


  }

  mes(mes: any) {

    localStorage.setItem("mesconsulta", mes);
    console.log('mesconsulta', mes);

  }

  exportReporteUno(): void {

    
    this.toastr.successToastr('Descarga en progreso foto.', 'Notificación');
    this.subscriptionDetalle = this.orderService.getreporteuno().subscribe(res => {

    });

    this.subscriptionDetalle.unsubscribe()

  }

  exportReporteDos(): void {

    this.toastr.successToastr('Descarga en progreso foto.', 'Notificación');
    this.subscriptionDetalle = this.orderService.getreportedos().subscribe(res => {


    });
    this.subscriptionDetalle.unsubscribe()
    

  }


  exportReporteTres(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscriptionDetalle =  this.orderService.getreportetres().subscribe(res => {


    });
    this.subscriptionDetalle.unsubscribe()
  }


  exportReporteCuatro(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscriptionDetalle =  this.orderService.getreportecuatro().subscribe(res => {


    });
    this.subscriptionDetalle.unsubscribe()
  }


}
