import { Component, OnInit, Input ,SimpleChanges} from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Options } from "highcharts";

@Component({
  selector: 'app-widget-pieruta',
  templateUrl: './pieruta.component.html',
  styleUrls: ['./pieruta.component.scss']

})
export class PieRutaComponent implements OnInit {
  ngOnInit(): void {

  }

  Highcharts: typeof Highcharts = Highcharts;
  @Input() data: Array<number>;
  @Input() name: string;
  updateFlag = false;
  chartOptions: Options = {
    chart: {
      type: "bullet",
      margin: [2, 0, 2, 0],
      width: 200,
      height: 200,
    },
    series: [
      {
        name: '',
        type: 'area',
        data: []
      }
    ] 
  };

  ngOnChanges(change: SimpleChanges) {
      this.chartOptions.series = [{
        name: change.name ? change.name.currentValue : null,
        type: 'area',
        data: change.data.currentValue,
      }];
      this.updateFlag = true;
    }
  }
