import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import * as Mapboxgl from 'mapbox-gl';
import { CurrencyPipe } from '@angular/common';
import { Pipe } from '@angular/core';
import { formatDate } from '@angular/common';

export interface Section {
  usuario: string;
  updated: Date;
}

@Component({
  selector: 'app-fragmentAsesores',
  templateUrl: './fragmentAsesores.component.html',
  styleUrls: ['./fragmentAsesores.component.scss']
})

@Pipe({
  name: 'mondeda'
})


export class FragmentAsesores implements OnInit {
  mapa: Mapboxgl.Map


  declarations: [
    CurrencyPipe
  ];



  public sumapendientes = 0;
  public sumaexitoso = 0;
  public sumanovedad = 0;
  public sumaparcial = 0;
  public total = 0;
  public estado = 0;
  public longi;
  public datas;
  public clientes;
  public lati;
  public clientesMostrar;
  public pendientes;
  public novedades;
  public exitosas;
  public parcial;
  public marker;
  transportadores: Section[];
  notes: Section[];
  public dateDayrute;

  ngOnInit(): void {

    this.sumapendientes = 0;
    this.sumaexitoso = 0;
    this.sumanovedad = 0;
    this.sumaparcial = 0;
    this.total = 0;

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("pedidoFecha", cValue);

    Mapboxgl.accessToken = environment.mapboxkey;
    
    this.mapa = new Mapboxgl.Map({
      container: 'mapa-mapbox', // container id
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-74.1049003, 4.6675419], 
       zoom: 15// starting zoom
    });
  
    this.mapa .on('load', () => {
      this.mapa.addSource('maine', {
        'type': 'geojson',
        'data': {
        'type': 'Feature',
        'geometry': {
        'type': 'Polygon',
        // These coordinates outline Maine.
        'coordinates': [
        [
         [-74.1134041,4.6744501], [-74.1134045,4.6744503], [-74.1134047,4.6744506], [-74.1134051,4.6744512], [-74.1134051,4.6744511], [-74.1134051,4.6744511], [-74.1134051,4.674451], [-74.1134365,4.6745006], [-74.1135361,4.6745992], [-74.1134739,4.6750847], [-74.1133654,4.6751425], [-74.1132385,4.6752356], [-74.1123592,4.6760452], [-74.1113765,4.6773349], [-74.1102159,4.6769594], [-74.1087796,4.6744067], [-74.1078525,4.672732], [-74.1078779,4.672724], [-74.1074932,4.6721114], [-74.1074913,4.6721166], [-74.1074917,4.6721165], [-74.10749,4.6721176], [-74.1073482,4.6718926], [-74.1070802,4.6716294], [-74.1070803,4.6716293], [-74.1070794,4.6716284], [-74.1070793,4.671628], [-74.1061773,4.6706796], [-74.1043175,4.6687918], [-74.1049003,4.6675419], [-74.1067356,4.6659073], [-74.1082321,4.6645809], [-74.109043,4.6637615], [-74.109451,4.6637751], [-74.1112089,4.6662873], [-74.1127683,4.6688508], [-74.1131188,4.6708012], [-74.1131579,4.6733569], [-74.1132454,4.6750743], [-74.1137366,4.6749471], [-74.113725,4.6746738], [-74.1134109,4.6745828], [-74.11344,4.6745199], [-74.1134489,4.6744434], [-74.1133941,4.6744294],
        ]
        ]
        }
        }
        });
          // Add a new layer to visualize the polygon.
          this.mapa.addLayer({
            'id': 'maine',
            'type': 'fill',
            'source': 'maine', // reference the data source
            'layout': {},
            'paint': {
            'fill-color': '#0080ff', // blue color fill
            'fill-opacity': 0.2
            }
            });
            // Add a black outline around the polygon.
            this.mapa.addLayer({
            'id': 'outline',
            'type': 'line',
            'source': 'maine',
            'layout': {},
            'paint': {
            'line-color': '#000',
            'line-width': 3
            }
            });
      });




    setTimeout(() => {
  
  
     this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-67.13734, 45.13745])
      .addTo(this.mapa)
      this. marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              -122.483696, 37.833818
            ], zoom: 14,
            essential: true
          });
    
  
        });
    
    
     }, 7500);


     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-66.96466, 44.8097])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              -122.483482, 37.833174
            ], zoom: 14,
            essential: true
          });
  
    
        });

 
    
     }, 8000);
  

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-68.03252, 44.3252])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              -122.483482, 37.833174
            ], zoom: 14,
            essential: true
          });
  
    
        });

      
    
     }, 8500);
  
     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-69.06, 43.98])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });


    
     }, 9000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.11617, 43.68405])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 9500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.64573, 43.09008])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 10000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.75102, 43.08003])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 10500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.79761, 43.21973])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 11000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.98176, 43.36789])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 11500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.94416, 43.46633])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 12000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-71.08482, 45.30524])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 12500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.66002, 45.46022])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 13000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.66002, 45.46022])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 13500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.66002, 45.46022])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 14000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.30495, 45.91479])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 14500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-70.00014, 46.69317])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 15000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-69.23708, 47.44777])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 15500);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-68.2343, 47.35462])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 16000);

     setTimeout(() => {
    
      this. marker.remove();
      this.marker = new Mapboxgl.Marker({
        color: 'blue',
        draggable: false,
   
      })
      .setLngLat([-67.79035, 47.06624])
      .addTo(this.mapa)
      this.  marker.getElement().addEventListener('click', () => {
    
    
          this.mapa.flyTo({
            center: [
              122.483568, 37.832056
            ], zoom: 14,
            essential: true
          });
  
    
        });

    
    
     }, 16500);

   
  }


  constructor(private orderService: OrdersService, private userd: AuthService) {

  }





}
