import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { MapsOrdenService } from '../../../shared/mapsorden.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogPuntosMpas } from './dialogPuntosMpas/dialogPuntosMpas.component';
import { MapsAPILoader } from '@agm/core';
export interface Section {
  usuario: string;
}
@Component({
  selector: 'app-fragmentMapsOT',
  templateUrl: './fragmentMapsOT.component.html',
  styleUrls: ['./fragmentMapsOT.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class fragmentMapsOT implements OnInit {

  lat = 4.570329117;
  lng = -74.0768177;
   zoom: number = 5;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public markers = new Array();
  public ubicaciones = new Array();
  public transportes = new Array();
  public pedidosCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  public isaprobar = false;
  public iscargado = false;
  public iscontenido = false;
  public iconUrl = "/assets/trackrojo.svg";
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;


  Remove(args): void {
    args.cancel = true;
  }

  constructor(private mapsAPILoader: MapsAPILoader,private mapsOrdenService: MapsOrdenService, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }

  displayedColumns: string[] = ['usuario', 'hora', 'latitud', 'longitud','direccion_geo'];
  dataSource = new MatTableDataSource();

  ngOnInit(): void {
 
    this.descargarubicaciones()

    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      console.log("por aca entro");
      geocoder.geocode({address: 'Calle 18 # 104b 94 BOGOTA '}, (results, status) => {
        if (status === 'OK') {
          console.log("respuesta");
          console.log(results[0].geometry.location);
          console.log("results");
          console.log(results);
          console.log("JSON results");
          console.log( JSON.stringify(results, null, 2));
         
        } else {
          console.error('Geocodificación fallida: ' + status);
        }
      });
    });

  }


  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  descargarTareasMaps() {
  
    localStorage.setItem("rutaindicador", "si");
    
    this.mapsOrdenService.getMapsOrden ().subscribe(mapstareas => {
      console.log("mapstareas",mapstareas);
      
  
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };

      var rutasTareas = groupBy(mapstareas, 'Usuario')

      for (let item of Object.keys(rutasTareas)) {
           
        var rutas = rutasTareas[item];


         var trans = {
          usuario:    rutas[0].Usuario,
        }
         this.transportes.push(trans)
      
      }

      this.transportes = Array.from(new Set( this.transportes))
      
     
    
    });
  }

  transporte(usuario: any) {

    this.iscargado=true
    this.iscontenido=false
    this.markers = []
    this.mapsOrdenService.getMapsOrdenUsuario (usuario).subscribe(mapstareas => {
   
    
    
      for (let tarea of mapstareas) {

  
        var market = {
          lat:    tarea.lat,
          lng:   tarea.lon,
          label: String( tarea.orden),
          draggable: false,
          cliente: tarea.NombredeCliente,
          direccion: tarea.Direccion,
          valor:tarea.CostoMerc
        }

        this.lat=tarea.lat
        this.lng=tarea.lon

       this.markers.push(market)
  
      }
      
    
     
      this.iscargado=false
      this.iscontenido=true
    
    });

  }
   descargarubicaciones() {


    this.mapsOrdenService.getMapsUbicacionTransporte ().subscribe(mapstareas => {
   
      console.log("mapstareas ubicaciones",mapstareas);
      this.ubicaciones = []
      this.dataSource.data=mapstareas
      for (let tarea of mapstareas) {

        var market = {
          lat:    tarea.longitud,
          lng:   tarea.latitud,
          label: String( tarea.usuario),
          draggable: false,
          cliente: tarea.usuario,
          direccion: tarea.direccion_geo,
          valor:tarea.hora
        }

        this.lat=tarea.lat
        this.lng=tarea.lon

       this.ubicaciones.push(market)
  
      }
      
    
     
      this.iscargado=false
      this.iscontenido=true
    
    });

  }
  clickedMarker(market: any) {
    this.zoom=0
    console.log("market USUARIO",market);
    this.lat=market.lat
    this.lng=market.lon
    this.zoom=18
    localStorage.setItem("marketuser", market.cliente);
    localStorage.setItem("latmarketuser", market.lat);
    localStorage.setItem("lngmarketuser", market.lng);
    this.route.navigate(['/FragmentMpasTransporte']);

  }

onZoomChange() {
    this.zoom=16
}

  onMapReady(map) {
    map.setOptions({
        zoomControl: 'true',
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            style: google.maps.ZoomControlStyle.DEFAULT
        }
    });

}


atras() {

  this.toastr.successToastr("Orden transporte", 'Notificación');
  this.route.navigate(['/OrdenTransporte']);
  }

  ubicacion() {

    this.toastr.successToastr("Ubicación orden de trabajo: ", 'Notificación');
    this.route.navigate(['/MapsOrden']);
    }

    indicadores() {

      this.toastr.successToastr("Indicador orden de trabajo: ", 'Notificación');
      this.route.navigate(['/des']);
    }

    control() {

      this.toastr.successToastr("Control orden de trabajo: ", 'Notificación');
      this.route.navigate(['/rutas']);
    }
    
}
