
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';

export interface DialogData {
  codigo: string;
  articulo: string;
  iva: string;
}

@Component({
  selector: 'app-DialogAgregarArticulo',
  templateUrl: './DialogAgregarArticulo.component.html',
  styleUrls: ['./DialogAgregarArticulo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarArticulo implements OnInit {
  id: string;
  departamento: string;
  constructor(public dialogRef: MatDialogRef<DialogAgregarArticulo>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
crearTipoDocumento(data: any) {
if(data.codigo) {
if(data.articulo) {
if(data.iva) {

  var articulo = {
    codigo: data.codigo,
    articulo: data.articulo,
    iva: data.iva,
  }


  this.configuracionesService.crearArticulo(articulo)
  this.toastr.successToastr(articulo.articulo+ " creado con éxito", 'Notificación');
  this.dialogRef.close();

        }else{
          
          this.toastr.errorToastr("Todos los campos son obligatorios", 'Notificación');
    
        } 
      }else{
        
        this.toastr.errorToastr("Todos los campos son obligatorios", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Todos los campos son obligatorios", 'Notificación');

    } 



  }
  
}
