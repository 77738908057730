
import { Injectable } from '@angular/core';
import { UserI } from '../../modules/user.interface'
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public userData: Observable<firebase.User>;
  static afAuth: any;
  constructor(private afAuth: AngularFireAuth) {
    this.userData = afAuth.authState;
  }

  loginByEmail(user: UserI) {

    this.afAuth.auth.signOut();
    const { email, password } = user;
    return this.afAuth.auth.signInWithEmailAndPassword(email, password)

  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.setItem("correo", "pendiente");
    localStorage.setItem("login",  'pendiente');
  }

  getauth(){
    return this.userData;
  }
}
