import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ContactosService } from '../../shared/contactos.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';

@Component({

  selector: 'app-fragmentContactos',
  templateUrl: './fragmentContactos.component.html',
  styleUrls: ['./fragmentContactos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentContactos implements OnInit {

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;

  displayedColumns: string[] = ['codigo','nmbr', 'tel', 'correo','dir'];
  dataSource = new MatTableDataSource();

  public negocio: any;
  public agencia: any;
  public usuario: any;

  private subscription: Subscription;
  private subscriptionDescarga: Subscription;

  Remove(args): void {
    args.cancel = true;
  }

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };


  constructor(private contactosService: ContactosService, private userd: AuthService, public toastr: ToastrManager) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {

      if (document.hidden) {

        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {

        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });

  }
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  ngOnInit(): void {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.usuario = localStorage.getItem("correo");

    this.dialog = new Dialog({
      header: 'ERROR VALIDAR FORMATO',
      showCloseIcon: true,
      visible: false,
      target: document.getElementById("container"),
      width: '500px',
      height: '1000px'

    });


    this.dialog.appendTo('#dialog');

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      this.descargar();
      setInterval(() => {

        this.descargar();

      }, 60000);

    } else {


      this.subscription.unsubscribe();


    }

  }


  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log(json_object)


        let data = JSON.parse(json_object);
        console.log("data", data)


        for (let contacto of data) {

          this.contactosService.createContacto(contacto);


        }

        this.descargar()

        this.uploadObj.clearAll();

      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  public onSuccess(args: any): void {
    var files = args.target.files;
    this.parseExcel(files[0]);

    console.log("archivo", files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  export(): void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    this.subscription = this.contactosService.getContactos().subscribe(articulos => {

      this.contactosService.exportAsExcelFile(articulos, 'ContactosMaestros');

      this.subscription.unsubscribe();

    });
    
  }


  descargar() {
    this.subscription = this.contactosService.getContactos().subscribe(articulos => {

      this.dataSource.data = articulos;
      this.subscription.unsubscribe();

    });
  }

}
