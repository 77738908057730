// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mapboxkey: 'pk.eyJ1Ijoib2RsYXBwIiwiYSI6ImNrYmNkMGljcDAwcTcycXBmd2VxbWk2dXIifQ.Om1clId_-l7lmeacxMeMzw',
  
  configFirebase: {
    apiKey: "AIzaSyBNxsD0g5oKiSmcbogUs3bZPyK9J2ft63Y",
    authDomain: "appvlbogota-43289.firebaseapp.com",
    databaseURL: "https://appvlbogota-43289.firebaseio.com",
    projectId: "appvlbogota-43289",
    storageBucket: "appvlbogota-43289.appspot.com",
    messagingSenderId: "812061236407",
    appId: "1:812061236407:web:1dd0784096037e0c6ac132"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
