import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-widget-areaventasramo',
  templateUrl: './areaventasramo.component.html',
  styleUrls: ['./areaventasramo.component.scss']
})
export class AreaventasRamoComponent implements OnInit {

  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;

  constructor(private orderService: OrdersService,private userd: AuthService) { }

  ngOnInit() {
   

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
 
    localStorage.setItem("pedidoFecha",cValue);



    
         // location.reload();

         this.getAllrecorridos()


      

         

  }

  getAllrecorridos() {


    
    this.orderService.getinformesappasesor().subscribe(res => {
      this.data= res;
    
      HC_exporting(Highcharts);

      setTimeout(() => {
        window.dispatchEvent(
          new Event('resize')
        );
      }, 500);

    
 
  // Cr
      this.chartOptions = {
        chart: {
          type: 'bar',
         
        },
        title: {
          text: 'CUMPLIMIENTO'
        },
        subtitle: {
          text:  localStorage.getItem("negocio")+ ' '+ localStorage.getItem("agencia"),
        },
        tooltip: {
          split: true,
          valueSuffix: ' COP',
          padding: 2,
          useHTML: true,
           
        },
        credits: {
          enabled: false
        },

        exporting: {
          enabled: true,
        },
        series: res
      };

    }); 
  }




}
