
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DialogAgregarVehiculoTrans } from '../dialogAgregarVehiculoTrans/dialogAgregarVehiculoTrans.component';


export interface DialogDataVh {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
}

@Component({
  selector: 'app-DialogAgregarTransportadores',
  templateUrl: './DialogAgregarTransportadores.component.html',
  styleUrls: ['./DialogAgregarTransportadores.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarTransportadores implements OnInit {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  public iscargado = true;
  public ismenu = false;
  ismenudialog=false
  
  constructor(public dialogRef: MatDialogRef<DialogAgregarTransportadores>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogagregar: MatDialog) {}
     @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
     displayedColumns: string[] = ['imgEstado','nombres','apellidos','tipoDoc','identificacion','estado'];
     dataSource = new MatTableDataSource();

  ngOnInit(): void {

 this.descargarMarcas()
 this.descargarConfiguracion()
 this.descargarEstados()
 this.descargarModelos()
 this.descargarClase()
 this.descargarTerceros()

 
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }

  seleccionar(tercero: any):void {


 
  
    }

  crearTipoDocumento(data: any) {
   


    if(data.nombres) {

      if(data.nombres) {

          


    //this.configuracionesService.incrementarvehiculonv(conop)
    console.log('data.idTp',this.data.idTp);
        var tipoDoc = {
          idTp: this.data.idTp,
          nombre:data.nombres,
          identificacion:data.identificacion,
          idTercero:data.id,
          apellidos:data.apellidos
       
        }
  
        console.log('tipodocumento',tipoDoc);
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.id,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
         
        }
    
        //this.configuracionesService.crearAuditriaVehiculo(auditria)
        this.configuracionesService.crearTransporteTemporal(tipoDoc)
        this.toastr.successToastr("Tercero:  creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Tercero Vehículo", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Tercero Vehículo", 'Notificación');

    } 



  }

  descargarEstados() {

    this.configuracionesService.getEstado().subscribe(configuraciones => {
  

      this.estados=configuraciones
    
  
    
    });
  }

  descargarMarcas() {

    this.configuracionesService.getTiposMarcas().subscribe(configuraciones => {
  

      this.marcas=configuraciones
    
  
    
    });
  }


  descargarClase() {

    this.configuracionesService.getClasesVehiculos().subscribe(configuraciones => {
  

      this.clases=configuraciones
    
  
    
    });
  }


  descargarConfiguracion() {

    this.configuracionesService.getconfiguracionesVh().subscribe(configuraciones => {
  

      this.confisvh=configuraciones
    
  
    
    });
  }

  descargarTerceros() {

    this.configuracionesService.getTerceros().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }
  sestado(estado: any) {

    this.estadofm=estado

    if(estado=="Activo"){
         this.imgEstadofm="/assets/activo.png"
    }

    if(estado=="Bloqueado"){
      this.imgEstadofm="/assets/bloqueado.png"
    }

    if(estado=="Novedad"){
      this.imgEstadofm="/assets/novedad.png"
    }

  if(estado=="Inactivo")
      {
    this.imgEstadofm="/assets/inactivo.png"
   }

  }

  

  descargarModelos() {

    this.configuracionesService.getModelos().subscribe(configuraciones => {
  
      this.modelos=configuraciones
      console.log('modelos',configuraciones);
    });
  }

  smodelo(modelo: any) {

    this.modelofm=modelo
   
  }

  smarca(marca: any) {

    this.marcafm=marca

  }

  sconfi(confi: any) {

    this.confifm=confi

  }

  sclase(clase: any) {

    this.clasefm=clase

  }


  addEventVenSoat(type: string, event: MatDatepickerInputEvent<Date>) {


    this.soatvenfm = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
 
  
  }

  addEventVenTecno(type: string, event: MatDatepickerInputEvent<Date>) {


    this.tecnovenfm = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
 
  
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
