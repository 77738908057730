import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { OrdersService } from '../../../shared/orders.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  name;
  departamento;
  perfil;
  constructor(public toastr: ToastrManager,private authSvc: AuthService,private route: Router,private orderService: OrdersService) { }

  public isPrintinginProgress = false;

  public isceo = false;


  ngOnInit(): void  {

    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");
    this.perfil= localStorage.getItem('perfil')

    if(this.perfil=='ceo'){

      this. isceo = true;

    }

    
   }

  toggleSideBar() {
    
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(
        new Event('resize')
      );
    }, 300);

  }

  cerrarsesion():void {

    if(localStorage.getItem('login')=='exitoso'){
  

      if(localStorage.getItem('menu')=='cerrar'){
        
     
        localStorage.setItem("rutaindicador", "si");
     
        this.toggleSideBarForMe.emit()


      }

     
      localStorage.setItem("rutaindicador", "si");
      this.authSvc.logout();
      this.name="";
      localStorage.setItem("login",  'session');
      this.toastr.successToastr('Cerrar sesión.', 'Notificación');
      this.route.navigate(['']);



    }else{

      this.toastr.errorToastr('Para poder ingresar a odl debe iniciar sesión.', '!Oops, algo salió mal!');

      
    }


  }

  ParmalatBogotaSur():void {

    localStorage.setItem("negocio", "autoventa");
    localStorage.setItem("agencia",  "bogota");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Parmalat Bogotá Sur");
    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");

    this.route.navigate(['/des']);



  }

  ParmalatBogotaNorte():void {

    localStorage.setItem("negocio", "preventa");
    localStorage.setItem("agencia",  "bogota");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Parmalat Bogotá Norte");
    this.departamento=localStorage.getItem("departamento");
    this.name=localStorage.getItem("responsable");
    this.route.navigate(['/des']);


  }

  PreventaParmalatBogota():void {

    localStorage.setItem("negocio", "parmalat");
    localStorage.setItem("agencia",  "bogota");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Historial-Preventa Parmalat Bogotá");
    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");
    this.route.navigate(['/des']);

  
  }

  ParmalatMedellin():void {

    localStorage.setItem("negocio", "parmalat");
    localStorage.setItem("agencia",  "medellin");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Parmalat Medellin");
    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");
    this.route.navigate(['/des']);

  
  }

  PreventaParmalatBogotaNorte():void {

    localStorage.setItem("negocio", "parmalat");
    localStorage.setItem("agencia",  "bogota_Norte");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Preventa Parmalat Bogotá Norte");
    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");
    this.route.navigate(['/des']);


  }
  ParmalatBarranquilla():void {

    localStorage.setItem("negocio", "parmalat");
    localStorage.setItem("agencia",  "barranquilla");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Parmalat Barranquilla");
    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");
    this.route.navigate(['/des']);

  }

  FleischmannCali():void {

    localStorage.setItem("negocio", "fleischmann");
    localStorage.setItem("agencia",  "cali");
    localStorage.setItem("login", 'exitoso');
    localStorage.setItem("cargar", 'pendiente');
    localStorage.setItem("rutaindicador", "si");
    localStorage.setItem("departamento", "Fleischmann Cali");
    this.name=localStorage.getItem("responsable");
    this.departamento=localStorage.getItem("departamento");
    this.route.navigate(['/des']);

  }

}
