import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-widget-areaventas',
  templateUrl: './areaventas.component.html',
  styleUrls: ['./areaventas.component.scss']
})
export class AreavetasComponent implements OnInit {

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public txtTotalFac: any;
  public txtTotalFacRuta: any;
  public txtPesoKG: any;
  public txtPesoKGDe: any;
  public txtPedidos: any;
  public txtPedidosRuta: any;
  public txtDias: any;
  public txtRutas: any;
  public txtNovedades: any;
  public txtPedidosDev: any;
  public txtPedidosDevZona: any;
  public txtCanGrupo: any;
  public txtDevGrupo: any;
  public txtGrupoPedidos: any;
  public txtGrupoDev: any;
  public txttipoNovedad: any;
  public txtFecha: any;


  //novedades

  public txtCanCliente: any;
  public txtCanComercial: any;
  public txtCanPlanta: any;
  public txtCanTransporte: any;
  public txtCanTerceros: any;

  public txtCosCliente: any;
  public txtCosComercial: any;
  public txtCosPlanta: any;
  public txtCosTransporte: any;
  public txtCosTerceros: any;

  public totalParcial: any;
  public canParcial: any;

  public totalParcialExi: any;
  public canParcialExi: any;

  public totalExitoso: any;
  public canExitoso: any;

  public totalPendiente: any;
  public canPendiente: any;

  public totalNovedad: any;
  public canNovedad: any;

  public totalGeneral: any;
  public canGeneral: any;

  public datospruebas: any;
  public datatrasnportes: any;
  public dataruta: any;

  public tareasDescarga: any;
  public rutasDescarga: any;


  subscriptionTransporte: Subscription;
  subscriptionTareas: Subscription;

  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;

  constructor(private orderService: OrdersService, private userd: AuthService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
   }

  ngOnInit() {



    if (localStorage.getItem('pedidoFecha').length > 3) {


      

    } else {

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

    }

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      this.descargar();
      setInterval(() => {

        this.descargar();
      
      }, 60000);

    } else {


      this.subscriptionTransporte.unsubscribe();


    }



  }


  descargar() {

    this.subscriptionTareas = this.orderService.getTareasdia().subscribe(products => {
      this.tareasDescarga = this.tareasDescarga = products;


      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  
      this.rutasDescarga = groupBy(this.tareasDescarga, 'RutaVLI')

      this.subscriptionTareas.unsubscribe();
      this.transportes();

    });


  }

  transportes(){

    let tareasRecaudo=[]
    let tareasRecaudoValor=[]

    let zonasEnvio = []
    for (let ruta of Object.keys(this.rutasDescarga)) {


      let tareas = this.tareasDescarga.filter(tarea => tarea.RutaVLI == ruta)

      this.txtRutas = this.txtRutas + 1

   

        this.totalParcial = 0;
        this.canParcial = 0;
        this.totalParcialExi = 0;
        this.canParcialExi = 0;
        this.totalExitoso = 0;
        this.canExitoso = 0;
        this.totalNovedad = 0;
        this.canNovedad = 0;
        this.totalPendiente = 0;
        this.canPendiente = 0;


        this.txtTotalFacRuta = 0;
        this.txtPedidosRuta = 0;

        for (let tarea of tareas) {


          this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
          this.txtPedidosRuta = this.txtPedidosRuta + 1

          if (tarea.estado == "EXITOSA") {


            this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
            this.canExitoso = this.canExitoso + 1

          }

          if (tarea.estado == "PENDIENTE") {


            this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
            this.canPendiente = this.canPendiente + 1


          }

          if (tarea.estado == "NOVEDAD") {

            this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
            this.canNovedad = this.canNovedad + 1

          }

          if (tarea.estado == "PARCIAL") {

            this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
            this.canParcial = this.canParcial + 1


            this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
            this.canParcialExi = this.canParcialExi + 1


          }
        }


        var estadoPar = {

          name: ruta,

          CanParExi: this.canParcialExi,
          CostoParMercExi: this.totalParcialExi,

          CanParNov: this.canParcial,
          CostoParMercNov: this.totalParcial,

          CanExi: this.canExitoso,
          CostoMercExi: this.totalExitoso,

          CanPen: this.canPendiente,
          CostoMercPen: this.totalPendiente,

          CanNov: this.canNovedad,
          CostoMercNov: this.totalNovedad,

          y: this.txtPedidosRuta,

          txtTotalFac: this.txtTotalFacRuta,
          txtPedidos: this.txtPedidosRuta,

          txtPedPor: ((this.totalPendiente * 100) / this.txtTotalFacRuta) + "%",
          txtNovPor: ((this.totalNovedad * 100) / this.txtTotalFacRuta) + "%",
          txtExiPor: ((this.totalExitoso * 100) / this.txtTotalFacRuta) + "%",
          txtParPor: (((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta) + "%",

          txtPedPorM: Math.round(((this.totalPendiente * 100) / this.txtTotalFacRuta)) + "%",
          txtNovPorM: Math.round(((this.totalNovedad * 100) / this.txtTotalFacRuta)) + "%",
          txtExiPorM: Math.round(((this.totalExitoso * 100) / this.txtTotalFacRuta)) + "%",
          txtParPorM: Math.round((((this.totalParcialExi + this.totalParcial) * 100) / this.txtTotalFacRuta)) + "%",

          txtPedPorC: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
          txtNovPorC: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
          txtExiPorC: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
          txtParPorC: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

          txtPedPorMC: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
          txtNovPorMC: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
          txtExiPorMC: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
          txtParPorMC: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

        };

        tareasRecaudo.push(estadoPar)
      
  
        this.dataruta=tareasRecaudo;

        this.getAllrecorridos();

    }



   
  }

  getAllrecorridos() {

      let categarias = []
      let exitosas = []
      let novedades = []
      let pendientes = []
      let parciales = []

      for (let ruta of  this.dataruta) {

        categarias.push(ruta.name)
        exitosas.push(ruta.CanExi)
        novedades.push(ruta.CanNov)
        pendientes.push(ruta.CanPen)
        parciales.push(ruta.CanParExi)

      }


      this.chartOptions = {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Avance'
        },
        xAxis: {
          categories: categarias
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Tareas'
          },
          stackLabels: {
            enabled: true,
            style: {
              fontWeight: 'bold',
              color: ['#c23b22 ','#77dd77','#efa94a'],
            }
          }
        }, legend: {
          align: 'right',
          x: -30,
          verticalAlign: 'top',
          y: 25,
          floating: true,
          backgroundColor:
        ['#c23b22 ','#77dd77','#efa94a'],
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false
        }, tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true
            }
          }
        }, series: [{
          name: 'Pendientes',
          data: pendientes,
          color: "#e23a3a"
        }, {
          name: 'Parciales',
          data: parciales,
          color: "#d05bff"
        }, {
          name: 'Novedad',
          data: novedades,
          color: "#ffb553"
        }, {
          name: 'Exitosas',
          data: exitosas,
          color: "#439e37"
        }]
        
      };
  }


  ngOnDestroy() {
    this.subscriptionTareas.unsubscribe()
 

  }
}