import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import * as XLSX from 'xlsx';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {
  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private configuracionesCollection: AngularFirestoreCollection<any>;
  configuraciones: Observable<any[]>;


  private bancosCollection: AngularFirestoreCollection<any>;
  banco: Observable<any[]>;

  private licenciaTerceroCollection: AngularFirestoreCollection<any>;
  licenciaTercero: Observable<any[]>;

  private departamentosCollection: AngularFirestoreCollection<any>;
  departamentos: Observable<any[]>;

  private ciudadCollection: AngularFirestoreCollection<any>;
  ciudad: Observable<any[]>;

  private licenciasCollection: AngularFirestoreCollection<any>;
  licencias: Observable<any[]>;

  
  private departamentosConCollection: AngularFirestoreCollection<any>;
  departamentosCon: Observable<any[]>;


  private tercerosConCollection: AngularFirestoreCollection<any>;
  tercerosCon: Observable<any[]>;

  private addtercerosConCollection: AngularFirestoreCollection<any>;
  addtercerosCon: Observable<any[]>;

  private transporteConCollection: AngularFirestoreCollection<any>;
  transporte: Observable<any[]>;

  private tercerosCollection: AngularFirestoreCollection<any>;
  terceros: Observable<any[]>;

  private detalleEncunestaCollection: AngularFirestoreCollection<any>;
  detalleEncuesta: Observable<any[]>;

  private encunestaCollection: AngularFirestoreCollection<any>;
  encuesta: Observable<any[]>;

  private tipoDocCollection: AngularFirestoreCollection<any>;
  tipoDoc: Observable<any[]>;

  private estadoCollection: AngularFirestoreCollection<any>;
  estado: Observable<any[]>;

  private modelosCollection: AngularFirestoreCollection<any>;
  modelos: Observable<any[]>;

  private barrioCollection: AngularFirestoreCollection<any>;
  barrio: Observable<any[]>;


  private tipoDocConCollection: AngularFirestoreCollection<any>;
  tipoDocCon: Observable<any[]>;

  private marcaConCollection: AngularFirestoreCollection<any>;
  marcaCon: Observable<any[]>;


  private claseVehiculosConCollection: AngularFirestoreCollection<any>;
  clasesVehiculos: Observable<any[]>;

  private tipoTelConCollection: AngularFirestoreCollection<any>;
  tipoTelCon: Observable<any[]>;


  private tipoVhConCollection: AngularFirestoreCollection<any>;
  tipoConVh: Observable<any[]>;


  private vehiculoConCollection: AngularFirestoreCollection<any>;
  vehiculocon: Observable<any[]>;

  private vehiculoCollection: AngularFirestoreCollection<any>;
  vehiculo: Observable<any[]>;

  private confiVhConCollection: AngularFirestoreCollection<any>;
  confiConVh: Observable<any[]>;

  private tipoOPConCollection: AngularFirestoreCollection<any>;
  tipoOPCon: Observable<any[]>;

  private tipoTelCollection: AngularFirestoreCollection<any>;
  tipoTel: Observable<any[]>;

  private confivhCollection: AngularFirestoreCollection<any>;
  confivh: Observable<any[]>;

  private tipoOPCollection: AngularFirestoreCollection<any>;
  tipoOP: Observable<any[]>;

  private marcaCollection: AngularFirestoreCollection<any>;
  marca: Observable<any[]>;

  private tipoVhCollection: AngularFirestoreCollection<any>;
  tipovh: Observable<any[]>;

  private tpCollection: AngularFirestoreCollection<any>;
  tp: Observable<any[]>;
  
  private auditoriaBancosCollection: AngularFirestoreCollection<any>;
  auditriabanco: Observable<any[]>;

  private auditoriaTipoDocCollection: AngularFirestoreCollection<any>;
  auditriaTipoDoc: Observable<any[]>;


  private auditoriaDepCollection: AngularFirestoreCollection<any>;
  auditriaDep: Observable<any[]>;

  private auditoriaTipoTelCollection: AngularFirestoreCollection<any>;
  auditriaTipoTel: Observable<any[]>;

  private auditoriaTipoVhCollection: AngularFirestoreCollection<any>;
  auditriaTipoVh: Observable<any[]>;

  private anosVhCollection: AngularFirestoreCollection<any>;
  anos: Observable<any[]>;

  private diasCollection: AngularFirestoreCollection<any>;
  dias: Observable<any[]>;

  private mesesCollection: AngularFirestoreCollection<any>;
  meses: Observable<any[]>;

  private auditoriaTipoOpCollection: AngularFirestoreCollection<any>;
  auditriaTipoOp: Observable<any[]>;

  private auditoriaConfiVhCollection: AngularFirestoreCollection<any>;
  auditriaConfiVh: Observable<any[]>;

  private auditoriaVehiculoCollection: AngularFirestoreCollection<any>;
  auditriaVehiculo: Observable<any[]>;

  private auditoriaTerceroCollection: AngularFirestoreCollection<any>;
  auditriaTercero: Observable<any[]>;

  private auditoriaCiudadCollection: AngularFirestoreCollection<any>;
  auditriaCiudad: Observable<any[]>;


  private auditoriaBarrioCollection: AngularFirestoreCollection<any>;
  auditriaBarrio: Observable<any[]>;

  private auditoriaMarcaCollection: AngularFirestoreCollection<any>;
  auditriaMarca: Observable<any[]>;

  private maestrosArticulosCollection: AngularFirestoreCollection<any>;
  maestrosArticulos: Observable<any[]>;

  private maestrosClientesCollection: AngularFirestoreCollection<any>;
  clientes: Observable<any[]>;

  private respuestasCollection: AngularFirestoreCollection<any>;
  respuestas: Observable<any[]>;

  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");

  constructor(private readonly afs: AngularFirestore, private userd: AuthService) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    const cValue= localStorage.getItem("pedidoFecha");
    this.configuracionesCollection = afs.collection<any>('SLC_Maestros/configuraciones/configurar',res=> res.orderBy('idBanco'));
    this.bancosCollection = afs.collection<any>('SCL_Operacion/con/banco/');
    this.tipoDocConCollection = afs.collection<any>('SCL_Operacion/con/tipoDoc/');
    this.tipoTelConCollection = afs.collection<any>('SCL_Operacion/con/tipoTel/');
    this.tipoOPConCollection = afs.collection<any>('SCL_Operacion/con/operacion/');
    this.tipoVhConCollection = afs.collection<any>('SCL_Operacion/con/vehiculo/');
    this.marcaConCollection = afs.collection<any>('SCL_Operacion/con/marca/');
    this.confiVhConCollection = afs.collection<any>('SCL_Operacion/con/confivh/');
    this.vehiculoConCollection = afs.collection<any>('SCL_Operacion/con/vehiculonv/');
    this.departamentosConCollection = afs.collection<any>('SCL_Operacion/con/departamentos/');
    this.tercerosConCollection = afs.collection<any>('SCL_Operacion/con/terceros/');
    this.transporteConCollection = afs.collection<any>('SCL_Operacion/con/transporte/');

    this.addtercerosConCollection = afs.collection<any>('SCL_Operacion/con/addTercero/');  
    this.tipoDocCollection = afs.collection<any>('SLC_Maestros/configuraciones/tipodoc');
    this.respuestasCollection = afs.collection<any>('SLC_Maestros/configuraciones/respuestas');
    this.tipoTelCollection = afs.collection<any>('SLC_Maestros/configuraciones/tipotel');
    this.tipoOPCollection = afs.collection<any>('SLC_Maestros/configuraciones/operacion');
    this.tipoVhCollection = afs.collection<any>('SLC_Maestros/configuraciones/vehiculos');
    this.marcaCollection = afs.collection<any>('SLC_Maestros/configuraciones/marcas');
    this.confivhCollection = afs.collection<any>('SLC_Maestros/configuraciones/confivh');
    this.vehiculoCollection = afs.collection<any>('SLC_Maestros/vehiculos/registro/',ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio));
    this.estadoCollection = afs.collection<any>('SLC_Maestros/configuraciones/estados');
    this.modelosCollection = afs.collection<any>('SLC_Maestros/configuraciones/modelos/',res=> res.orderBy('modelo', "desc"));
    this.claseVehiculosConCollection = afs.collection<any>('SLC_Maestros/configuraciones/clasesvh/');
    this.anosVhCollection = afs.collection<any>('SLC_Maestros/configuraciones/anos/',res=> res.orderBy('fecha'));
    this.diasCollection = afs.collection<any>('SLC_Maestros/configuraciones/dia/',res=> res.orderBy('dia'));
    this.mesesCollection = afs.collection<any>('SLC_Maestros/configuraciones/mes/',res=> res.orderBy('mes'));
    this.departamentosCollection = afs.collection<any>('SLC_Maestros/configuraciones/departamentos/',res=> res.orderBy('id'));
    this.ciudadCollection = afs.collection<any>('SLC_Maestros/configuraciones/ciudad/',res=> res.orderBy('id'));
    this.barrioCollection = afs.collection<any>('SLC_Maestros/configuraciones/barrios/',res=> res.orderBy('id'));
    this.tercerosCollection = afs.collection<any>('SLC_Maestros/configuraciones/terceros/',res=> res.orderBy('id'));
    //this.tercerosCollection = afs.collection<any>('SLC_Maestros/configuraciones/terceros/'+ this.negocio + '_' + this.agencia,res=> res.orderBy('id') );
    this.detalleEncunestaCollection = afs.collection<any>('SLC_Maestros/configuraciones/detallEncunestas/',res=> res.orderBy('id'));
   this.encunestaCollection = afs.collection<any>('SLC_Maestros/configuraciones/encuestas/',res=> res.orderBy('id'));
   
    this.licenciasCollection = afs.collection<any>('SLC_Maestros/configuraciones/licencias/',res=> res.orderBy('id'));
    this.tpCollection = afs.collection<any>('SLC_Maestros/configuraciones/transportetemporal/');
    this.licenciaTerceroCollection = afs.collection<any>('SLC_Maestros/configuraciones/licenciaTercero/');
    this.licenciaTerceroCollection = afs.collection<any>('SLC_Maestros/configuraciones/licenciaTercero/');
    this.maestrosArticulosCollection = afs.collection<any>('SLC_Maestros/articulos/registros/');
    this.maestrosClientesCollection = afs.collection<any>('SLC_Maestros/clientes/registros/');

  }

  createMaestroConfiguracion(configuracion: any) {
    
    return this.configuracionesCollection.doc('BN' + configuracion.idBanco).set(configuracion);
    
  }

  createLicenciaTercero(licencia: any) {
    
    return this.licenciaTerceroCollection.doc('LT' + licencia.idTransportador).set(licencia);
    
  }

  createMaestroCliente(cliente: any) {
    
    return this.maestrosClientesCollection.doc('CL' + cliente.idVrt).set(cliente);
    
  }

  deleteConfiguracion(configuracion: any) {
    
    return this.configuracionesCollection.doc('BN' + configuracion.idBanco).delete();
    
  }


  deleteDocumentByEncuestaId(idencuesta: string) {
  

}
  deleteTipoDoc(tipoDoc: any) {
    
    return this.tipoDocCollection.doc('TP' + tipoDoc.idTipoDoc).delete();
    
  }

  deleteEncuentas(tipoDoc: any) {
    
    return this.encunestaCollection.doc(tipoDoc.idDocument).delete();
    
  }

  deleteEncuentasDetalle(tipoDoc: any) {
    
    return this.detalleEncunestaCollection.doc(tipoDoc.idDocument).delete();
    
  }

  deleteTipoTel(tipoTel: any) {
    
    return this.tipoTelCollection.doc('TT' + tipoTel.idTipoTel).delete();
    
  }

  deleteTipoOp(tiopOP: any) {
    
    return this.tipoOPCollection.doc('OP' + tiopOP.idTipo).delete();
    
  }

  deleteMarca(marca: any) {
    
    return this.marcaCollection.doc('MC' + marca.idTipo).delete();
    
  }

  deleteDepartamento(departamento: any) {
    
    return this.departamentosCollection.doc('DP' + departamento.id).delete();
    
  }

  deleteCiudad(ciudad: any) {
    
    return this.ciudadCollection.doc('CD' + ciudad.id).delete();
    
  }

  deleteBarrio(barrio: any) {
    
    return this.barrioCollection.doc('BO' + barrio.id).delete();
    
  }

  deleteTipoVh(tiopOP: any) {
    
    return this.tipoVhCollection.doc('VH' + tiopOP.idTipo).delete();
    
  }

  deleteTipoConfiVh(confivh: any) {
    
    return this.confivhCollection.doc('CF' + confivh.idTipo).delete();
    
  }
  
  getConfiguraciones() {

    this.configuraciones = this.configuracionesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.configuraciones;

  }

  getConTransporte() {

    this.transporte = this.transporteConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.transporte;

  }

  descargarTp(idTransportador:any) {
    this.tpCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/transportetemporal/', ref => ref.where('idTransportador', '==', parseInt(idTransportador)));
    this.tp = this.tpCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tp;

  }

  descargarArticuloIva(codigo:any) {
    this.maestrosArticulosCollection = this.afs.collection<any>('SLC_Maestros/articulos/registros/', ref => ref.where('codigo', '==', codigo));
    this.maestrosArticulos = this.maestrosArticulosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.maestrosArticulos;

  }

  descargarConductor(idTransportador:any) {
    this.licenciaTerceroCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/licenciaTercero/', ref => ref.where('idTransportador', '==', parseInt(idTransportador)));
    this.licenciaTercero = this.licenciaTerceroCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.licenciaTercero;

  }

  descargarTransportes() {
    this.tpCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/transportetemporal/');
    this.tp = this.tpCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tp;

  }

  descargarMaestrosArticulos() {
    this.maestrosArticulosCollection = this.afs.collection<any>('SLC_Maestros/articulos/registros/');
    this.maestrosArticulos = this.maestrosArticulosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.maestrosArticulos;

  }


  descargarMaestrosClientes() {
    this.maestrosClientesCollection = this.afs.collection<any>('SLC_Maestros/clientes/registros/');
    this.clientes = this.maestrosClientesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.clientes;

  }
  getConTipoDoc() {

    this.tipoDocCon = this.tipoDocConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoDocCon;

  }

  getLicencias() {

    this.licencias = this.licenciasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.licencias;

  }

  getModelos() {

    this.modelos = this.modelosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.modelos;

  }

  getEstado() {

    this.estado = this.estadoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.estado;

  }

  getConVh() {

    this.tipoConVh = this.tipoVhConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoConVh;

  }

  getvehiculoConNv() {

    this.vehiculocon = this.vehiculoConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.vehiculocon;

  }

  getConTerceros() {

    this.tercerosCon = this.tercerosConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tercerosCon;

  }

  getAddConTerceros() {
    this.addtercerosCon = this.addtercerosConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.addtercerosCon;

  }
  getConfiVh() {

    this.confiConVh = this.confiVhConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.confiConVh;

  }

  getConTipoTel() {

    this.tipoTelCon = this.tipoTelConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoTelCon;

  }


  getConTipoOP() {

    this.tipoOPCon = this.tipoOPConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoOPCon;

  }

  getConDepartamento() {

    this.departamentosCon = this.departamentosConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.departamentosCon;

  }

  

  getTiposTel() {

    this.tipoTel = this.tipoTelCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoTel;

  }

  getRespuestas() {

    this.respuestas = this.respuestasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.respuestas;

  }
  
  getTiposDocumentos() {

    this.tipoDoc = this.tipoDocCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoDoc;

  }


  getanos() {

    this.anos = this.anosVhCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.anos;

  }

  getdia() {

    this.dias = this.diasCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.dias;

  }

  getMes() {

    this.meses = this.mesesCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.meses;

  }


  getTiposOp() {

    this.tipoOP = this.tipoOPCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipoOP;

  }

  getTiposVh() {

    this.tipovh = this.tipoVhCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.tipovh;

  }


  getDetalleEncuestas(codigo:any,tipoOrigen:any) {
    this.negocio = localStorage.getItem("negocio");
     this.agencia = localStorage.getItem("agencia");
     
    this.detalleEncunestaCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/detallEncunestas',ref => ref.where('id', '==', codigo)
    .where('tipoOrigen', '==', tipoOrigen)
    .where('negocio', '==', this.negocio)
    .where('agencia', '==', this.agencia)
    .orderBy('orden'))
    this.detalleEncuesta = this.detalleEncunestaCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        data.idDocument = id
        return { ...data };
      })
    ));

    return this.detalleEncuesta;

  }
  getRegistroVehiculos() {

    this.vehiculo = this.vehiculoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.vehiculo;

  }

  getTercerosFiltro() {
    this.negocio = localStorage.getItem("negocio");
     this.agencia = localStorage.getItem("agencia");
     this.fecha = localStorage.getItem("pedidoFecha");
     this.tercerosCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/terceros/',ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio) );
     this.terceros = this.tercerosCollection.snapshotChanges().pipe(map(
       actions => actions.map(a => {
         const data = a.payload.doc.data() as any;
         const id = a.payload.doc.id;
         return { ...data };
       })
     ));
 
     return this.terceros;
 
 
   }
 
  getTerceros() {
  
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.tercerosCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/terceros/',ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio) );
    this.terceros = this.tercerosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.terceros;

  }

  getEncuestasCreadas(tipo:any) {
  
    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.encunestaCollection = this.afs.collection<any>('SLC_Maestros/configuraciones/encuestas/',ref => ref.where('agencia', '==', this.agencia).where('negocio', '==', this.negocio).where('tipoOrigen', '==', tipo).orderBy("orden")  );
    this.encuesta = this.encunestaCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        data.idDocument = id
        return { ...data };
      })
    ));

    return this.encuesta;

  }

  getDepartamentos() {

    this.departamentos = this.departamentosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.departamentos;

  }

  getCiudades() {

    this.ciudad = this.ciudadCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.ciudad;

  }

  getBarrios() {

    this.barrio = this.barrioCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.barrio;

  }

  getconfiguracionesVh() {

    this.confivh = this.confivhCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.confivh;

  }


  getTiposMarcas() {

    this.marca = this.marcaCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.marca;

  }

  getClasesVehiculos() {

    this.clasesVehiculos = this.claseVehiculosConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.clasesVehiculos;

  }

  getConMarcas() {

    this.marcaCon = this.marcaConCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.marcaCon;

  }
  getAuditoriaBancos() {
    this.auditoriaBancosCollection = this.afs.collection<any>('SCL_Auditoria/bancos/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriabanco = this.auditoriaBancosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriabanco;
  }

  getAuditoriaTerceros() {
    this.auditoriaTerceroCollection = this.afs.collection<any>('SCL_Auditoria/tercero/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaTercero = this.auditoriaTerceroCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaTercero;
  }

  getAuditoriaTipoDoc() { 
    this.auditoriaTipoDocCollection = this.afs.collection<any>('SCL_Auditoria/tipodoc/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaTipoDoc = this.auditoriaTipoDocCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaTipoDoc;

  }


  getAuditoriaTipoTel() { 
    this.auditoriaTipoTelCollection = this.afs.collection<any>('SCL_Auditoria/tipotel/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaTipoTel = this.auditoriaTipoTelCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaTipoTel;

  }

  getAuditoriaTipoOp() { 
    this.auditoriaTipoOpCollection = this.afs.collection<any>('SCL_Auditoria/tipooperacion/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaTipoOp = this.auditoriaTipoOpCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaTipoOp;

  }

  getAuditoriaVehiculos() { 
    this.auditoriaVehiculoCollection = this.afs.collection<any>('SCL_Auditoria/vehiculonv/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaVehiculo = this.auditoriaVehiculoCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaVehiculo;

  }



  getAuditoriaTipoVh() { 
    this.auditoriaTipoVhCollection = this.afs.collection<any>('SCL_Auditoria/tipovh/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaTipoVh = this.auditoriaTipoVhCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaTipoVh;

  }

  getAuditoriaConfiVh() { 
    this.auditoriaConfiVhCollection = this.afs.collection<any>('SCL_Auditoria/confivh/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaConfiVh = this.auditoriaConfiVhCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaConfiVh;

  }

  getAuditoriaMarca() { 
    this.auditoriaMarcaCollection = this.afs.collection<any>('SCL_Auditoria/marca/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaMarca = this.auditoriaMarcaCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaMarca;

  }

  getAuditoriaDepartamentos() { 
    this.auditoriaDepCollection = this.afs.collection<any>('SCL_Auditoria/departamentos/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaDep = this.auditoriaDepCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaDep;

  }


  getAuditoriaCiudades() { 
    this.auditoriaCiudadCollection = this.afs.collection<any>('SCL_Auditoria/ciudad/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaCiudad = this.auditoriaCiudadCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaCiudad;

  }

  getAuditoriaBarrios() { 
    this.auditoriaBarrioCollection = this.afs.collection<any>('SCL_Auditoria/barrio/seguimiento/',res=> res.orderBy('fechahora', "desc"));
    this.auditriaBarrio = this.auditoriaBarrioCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.auditriaBarrio;

  }
  //Bancos

  getConsecutivoBanco() {

    this.banco = this.bancosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.banco;

  }

  crearBanco(banco: any) {
    return this.bancosCollection.doc("conbanco").set(banco);
   
   }

   incrementarTipoDoc(contipodoc: any) {
    return this.tipoDocConCollection.doc("contipodoc").set(contipodoc);
   
   }

   incrementarTipoOP(conop: any) {
    return this.tipoOPConCollection.doc("conop").set(conop);
   
   }

   incrementarDepartamento(conop: any) {
    return this.departamentosConCollection.doc("con").set(conop);
   
   }

   incrementarMarca(con: any) {
    return this.marcaConCollection.doc("con").set(con);
   
   }

   incrementarVh(conop: any) {
    return this.tipoVhConCollection.doc("convh").set(conop);
   
   }

   incrementarvehiculonv(conop: any) {
    return this.vehiculoConCollection.doc("con").set(conop);
   
   }

   incrementarcontransportador(conop: any) {
    return this.transporteConCollection.doc("con").set(conop);
   
   }


   incrementarTercero(conop: any) {
    return this.tercerosConCollection.doc("con").set(conop);
   
   }

   addincrementarTercero(conop: any) {
    return this.addtercerosConCollection.doc("con").set(conop);
   
   }

   incrementarConfiVh(conop: any) {
    return this.confiVhConCollection.doc("con").set(conop);
   
   }


   incrementarTipoTel(contel: any) {
    return this.tipoTelConCollection.doc("contel").set(contel);
   
   }

   crearTipoDoc(tipoDoc: any) {
    return this.tipoDocCollection.doc('TP' + tipoDoc.idTipoDoc).set(tipoDoc);
    
   
   }

   crearTipoOP(tipoOP: any) {
   
    return this.tipoOPCollection.doc('OP' + tipoOP.idTipo).set(tipoOP);
    
   
   }

   crearCiudad(ciudad: any) {
   
    return this.ciudadCollection.doc('CD' + ciudad.id).set(ciudad);
    
   
   }

   crearBarrio(barrio: any) {
   
    return this.barrioCollection.doc('BO' + barrio.id).set(barrio);
    
   
   }

   crearDepartamento(departamento: any) {
   
    return this.departamentosCollection.doc('DP' + departamento.id).set(departamento);
    
   
   }


   crearArticulo(articulo: any) {
   
    return this.maestrosArticulosCollection.doc('AT' + articulo.codigo).set(articulo);
    
   }

   crearMarca(marca: any) {

    return this.marcaCollection.doc('MC' + marca.idTipo).set(marca);
    
   
   }

   crearTipoVh(tipovh: any) {

    return this.tipoVhCollection.doc('VH' + tipovh.idTipo).set(tipovh);
    
   
   }

   crearVehiculonv(vehiculo: any) {

    return this.vehiculoCollection.doc('VH' + vehiculo.id).set(vehiculo);
    
   
   }

   crearTransporteTemporal(transporte: any) {

    return this.tpCollection.doc('TP' + transporte.idTransportador).set(transporte);
    
   
   }

   crearTerceros(tercero: any) {

    return this.tercerosCollection.doc('TC' + tercero.id).set(tercero);
    
   
   }

   crearDetalleEncuenstas(detalleEncunesta: any) {

    return this.detalleEncunestaCollection.add(detalleEncunesta)
   }

   actualizarDetalleEncuestaPorIdEncuenta(idEncuenta: Number,etiqueta: String, datosActualizados: any) {
    return this.detalleEncunestaCollection.ref.where('id', '==', idEncuenta).where('etiqueta', '==', etiqueta).get().then(querySnapshot => {
      if (querySnapshot.empty) {
        throw new Error('No se encontró ningún documento con el idEncuenta especificado.');
      }

      const batch = this.afs.firestore.batch();
      querySnapshot.forEach(doc => {
        batch.update(doc.ref, datosActualizados);
      });

      return batch.commit();
    });
  }

   crearEncuenta(encuesta: any) {

    return this.encunestaCollection.add(encuesta)
   }


    actualizarEncuestaPorIdEncuenta(idEncuenta: Number, datosActualizados: any) {
    return this.encunestaCollection.ref.where('id', '==', idEncuenta).get().then(querySnapshot => {
      if (querySnapshot.empty) {
        throw new Error('No se encontró ningún documento con el idEncuenta especificado.');
      }

      const batch = this.afs.firestore.batch();
      querySnapshot.forEach(doc => {
        batch.update(doc.ref, datosActualizados);
      });

      return batch.commit();
    });
  }

  crearConfiVh(confi: any) {

   return this.confivhCollection.doc('CF' + confi.idTipo).set(confi);
   
  
  }

   crearTipoTel(tipoTel: any) {
    return this.tipoTelCollection.doc('TT' + tipoTel.idTipoTel).set(tipoTel);
    
   
   }

  crearAuditriaBancos(auditoria: any) {
    this.auditoriaBancosCollection = this.afs.collection<any>('SCL_Auditoria/bancos/seguimiento/');
 
    return this.auditoriaBancosCollection.add(auditoria)
   
   }

   crearAuditriaTipoDoc(auditoria: any) {
    this.auditoriaTipoDocCollection = this.afs.collection<any>('SCL_Auditoria/tipodoc/seguimiento/');
 
    return this.auditoriaTipoDocCollection.add(auditoria)
   
   }

   crearAuditriaTipoOp(auditoria: any) {
    this.auditoriaTipoOpCollection = this.afs.collection<any>('SCL_Auditoria/tipooperacion/seguimiento/');
 
    return this.auditoriaTipoOpCollection.add(auditoria)
   
   }

   crearAuditriaTipoTel(auditoria: any) {
    this.auditoriaTipoTelCollection = this.afs.collection<any>('SCL_Auditoria/tipotel/seguimiento/');
 
    return this.auditoriaTipoTelCollection.add(auditoria)
   
   }

   crearAuditriaTipoVh(auditoria: any) {
    this.auditoriaTipoVhCollection = this.afs.collection<any>('SCL_Auditoria/tipovh/seguimiento/');
 
    return this.auditoriaTipoVhCollection.add(auditoria)
   
   }

   crearAuditriaVehiculo(auditoria: any) {
    this.auditoriaVehiculoCollection = this.afs.collection<any>('SCL_Auditoria/vehiculonv/seguimiento/');
 
    return this.auditoriaVehiculoCollection.add(auditoria)
   
   }

   crearAuditriaTercero(auditoria: any) {
    this.auditoriaTerceroCollection = this.afs.collection<any>('SCL_Auditoria/tercero/seguimiento/');
 
    return this.auditoriaTerceroCollection.add(auditoria)
   
   }


   crearAuditriaConfiVh(auditoria: any) {
    this.auditoriaConfiVhCollection = this.afs.collection<any>('SCL_Auditoria/confivh/seguimiento/');
 
    return this.auditoriaConfiVhCollection.add(auditoria)
   
   }

   crearAuditriaMarca(auditoria: any) {
    this.auditoriaMarcaCollection = this.afs.collection<any>('SCL_Auditoria/marca/seguimiento/');
 
    return this.auditoriaMarcaCollection.add(auditoria)
   
   }

   crearAuditriaDepartamento(auditoria: any) {
    this.auditoriaDepCollection = this.afs.collection<any>('SCL_Auditoria/departamentos/seguimiento/');
 
    return this.auditoriaDepCollection.add(auditoria)
   
   }

   crearAuditriaCiudad(auditoria: any) {
    this.auditoriaCiudadCollection = this.afs.collection<any>('SCL_Auditoria/ciudad/seguimiento/');
 
    return this.auditoriaCiudadCollection.add(auditoria)
   
   }

   crearAuditriaBarrio(auditoria: any) {
    this.auditoriaBarrioCollection = this.afs.collection<any>('SCL_Auditoria/barrio/seguimiento/');
 
    return this.auditoriaBarrioCollection.add(auditoria)
   
   }
}