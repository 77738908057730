
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


export interface DialogDataVh {
  nombre:string;
  identificacion:string;
  idTercero:string;
  apellidos:string;
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}

@Component({
  selector: 'app-DialogAgregarVehiculoTrans',
  templateUrl: './DialogAgregarVehiculoTrans.component.html',
  styleUrls: ['./DialogAgregarVehiculoTrans.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarVehiculoTrans implements OnInit {
  idTipo: string;
  tipo: string;
  descripcion: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public licencias;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  private anos;
  private dias;
  private meses;
  
  constructor(public dialogRef: MatDialogRef<DialogAgregarVehiculoTrans>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 this.descargarMarcas()
 this.descargarConfiguracion()
 this.descargarEstados()
 this.descargarModelos()
 this.descargarClase()
 this.descargarLicencias()
 this.descargardias()
 this.descargaranos()
 this.descargarmeses()

 this.marcafm=this.data.marca
 this.confifm=this.data.configuracion
 this.estadofm="Activo"
 this.modelofm=this.data.modelo
 this.imgEstadofm="/assets/activo.png"
 
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }

  descargaranos() {

    this.configuracionesService.getanos().subscribe(configuraciones => {

      this.anos=configuraciones
  
   
    });
  }
 

  descargarmeses() {

    this.configuracionesService.getMes().subscribe(configuraciones => {

      this.meses=configuraciones
  
  
    });
  }

  descargardias() {

    this.configuracionesService.getdia().subscribe(configuraciones => {

      this.dias=configuraciones
  

    });
  }
 
  crearTipoDocumento(data: any) {
   


    if(data.placa) {

      if(this.modelofm) {

          
    var conop = {
      con: data.id+1

    }

    this.configuracionesService.incrementarvehiculonv(conop)


    var aseguradora = data.aseguradora;
    var mayus = aseguradora.substring(0, 1).toUpperCase();
    var resto = aseguradora.substring(1, aseguradora.length).toLowerCase();
    mayus.concat(resto.toString());

    
    var linea = data.linea;
    var mayuslinea = linea.substring(0, 1).toUpperCase();
    var restolinea = linea.substring(1, linea.length).toLowerCase();
    mayuslinea.concat(restolinea.toString());
        var tipoDoc = {
          id: data.id,
          placa: data.placa,
          modelo:   this.modelofm,
          marca: this.marcafm,
          aseguradora:  mayus.concat(resto.toString()),
          vensoat: this.soatvenfm ,
          ventecno:  this.tecnovenfm,
          linea:  mayuslinea.concat(restolinea.toString()),
          version: data.version,
          carga: data.carga,
          volumen: data.volumen,
          configuracion: this.confifm,
          estado: this.estadofm,
          imgEstado: this.imgEstadofm,
          clase:  this.clasefm
        }
  
        console.log('tipodocumento',tipoDoc);
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.id,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
          placa: data.placa,
          modelo:   this.modelofm,
          marca: this.marcafm,
          aseguradora:  mayus.concat(resto.toString()),
          vensoat: this.soatvenfm ,
          ventecno:  this.tecnovenfm,
          linea:  mayuslinea.concat(restolinea.toString()),
          version: data.version,
          carga: data.carga,
          volumen: data.volumen,
          configuracion: this.confifm,
          estado: this.estadofm,
          imgEstado: this.imgEstadofm,
          clase:this.clasefm
        }
    
        this.configuracionesService.crearAuditriaVehiculo(auditria)
        this.configuracionesService.crearVehiculonv(tipoDoc)
        this.toastr.successToastr("Vehículo:  creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Ingresar Vehículo", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Ingresar Vehículo", 'Notificación');

    } 



  }

  descargarEstados() {

    this.configuracionesService.getEstado().subscribe(configuraciones => {
  

      this.estados=configuraciones
    
  
    
    });
  }

  descargarMarcas() {

    this.configuracionesService.getTiposMarcas().subscribe(configuraciones => {
  

      this.marcas=configuraciones
    
  
    
    });
  }


  descargarClase() {

    this.configuracionesService.getClasesVehiculos().subscribe(configuraciones => {
  

      this.clases=configuraciones
    
  
    
    });
  }


  descargarConfiguracion() {

    this.configuracionesService.getconfiguracionesVh().subscribe(configuraciones => {
  

      this.confisvh=configuraciones
    
  
    
    });
  }

  sestado(estado: any) {

    this.estadofm=estado

    if(estado=="Activo"){
         this.imgEstadofm="/assets/activo.png"
    }

    if(estado=="Bloqueado"){
      this.imgEstadofm="/assets/bloqueado.png"
    }

    if(estado=="Novedad"){
      this.imgEstadofm="/assets/novedad.png"
    }

  if(estado=="Inactivo")
      {
    this.imgEstadofm="/assets/inactivo.png"
   }

  }

  descargarModelos() {

    this.configuracionesService.getModelos().subscribe(configuraciones => {
  
      this.modelos=configuraciones
      console.log('modelos',configuraciones);
    });
  }

  descargarLicencias() {

    this.configuracionesService.getLicencias().subscribe(configuraciones => {
  
      this.licencias=configuraciones

    });
  }

  smodelo(modelo: any) {

    this.modelofm=modelo
   
  }

  smarca(marca: any) {

    this.marcafm=marca

  }

  sconfi(confi: any) {

    this.confifm=confi

  }

  sclase(clase: any) {

    this.clasefm=clase

  }


  
}
