import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../shared/services/auth.service';
import { OrdersService } from '../../../shared/orders.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public iscomercial = false;
  public isadmin = false;


  public ismaestros = false;
  public ispreventa = false;
  public isentregas = false;
  public isadministracion = false;
   perfil;
  ismenuentrega=false;
  isindicadores=false;
  isruta=false;
  istarea=false;
  isgeo=false;
  isconsolidado=false;
  ismenuordenabrir=true;
  ismenuordencerrar=false;

  ismenuterceroabrir=true;
  ismenutercerocerrar=false;

  ismenudepabrir=true;
  ismenudepcerrar=false;
  ismenuMaestrosabrir=true;
  ismenuMaestroscerrar=false;

  ismenuvhabrir=true;
  ismenuvhcerrar=false;
  constructor(private userd: AuthService,private orderService: OrdersService ) { }

  ngOnInit() {

    this.ismenuordenabrir=true;
    this.perfil= localStorage.getItem('perfil')

    if(this.perfil=='ceo'){

      this. ismaestros = true;
      this. ispreventa = true;
      this. isentregas = true;

    }

    if(this.perfil=='ceopreventa'){
      this. ispreventa = true;

    }
    if(this.perfil=='ceoentregas'){
      this. isentregas = true;

    }

    if(this.perfil=='administracion'){
      this. ismaestros = false;
      this. ispreventa = false;
      this. isentregas = false;
      this.isadministracion=true;

    }

  }

  aprobar(){

   
    localStorage.setItem("rutaindicador", "si");

  }  

  menuordencerrar():void{

    this.ismenuordenabrir=true;
    this.ismenuordencerrar=false;
  }  

  menuordenabrir():void{


    this.ismenuordenabrir=false;
    this.ismenuordencerrar=true;


  } 


  menuvhabrir():void{


    this.ismenuvhabrir=false;
    this.ismenuvhcerrar=true;


  } 

  menutercerocerrar():void{

    this.ismenuterceroabrir=true;
    this.ismenutercerocerrar=false;
  }  

  menuvhcerrar():void{

    this.ismenuvhabrir=true;
    this.ismenuvhcerrar=false;
  }

  menuterceroabrir():void{
    this.ismenuterceroabrir=false;
    this.ismenutercerocerrar=true;
  } 

  menuMaestroscerrar():void{

    this.ismenuMaestrosabrir=true;
    this.ismenuMaestroscerrar=false;
  }  

  menudepcerrar():void{

    this.ismenudepabrir=true;
    this.ismenudepcerrar=false;
  }  

  menudepabrir():void{

this.ismenudepabrir=false;
this.ismenudepcerrar=true;

  } 


  menuMaestrosabrir():void{

    this.ismenuMaestrosabrir=false;
    this.ismenuMaestroscerrar=true;

  }

}
