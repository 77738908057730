import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { DialogAprobarPlanilla } from '../dialogAprobarPlanilla/dialogAprobarPlanilla.component';
export interface DialogDataVh {
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}
@Component({
  selector: 'app-FragmentPlanillaDetalle',
  templateUrl: './FragmentPlanillaDetalle.component.html',
  styleUrls: ['./FragmentPlanillaDetalle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentPlanillaDetalle implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: TareasTemporalService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  sano: string;
  smes: string;
  sdia: string;
  vdia: string;
  vano: string;
  vmes: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string
  estadoplanilla: any

   displayedColumns: string[] = ['imgEstado','codigoArticulo','descripcion','estado','facturados','recibido','sobrantes','faltantes','averias','productos'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {
   var ordenTransporte=localStorage.getItem("codigoplanilla");
    this.descargarConfiguraciones(ordenTransporte)

  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones(ordentransporte: string) {

    this.configuracionesService.getProductosPlanilla(ordentransporte).subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;
      this.estadoplanilla=configuraciones;
      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {



  }

  aprobarPlanilla():void {
 
    let tareasNovedadeS = this.estadoplanilla.filter(tarea => tarea.estado == "Pendiente")
    console.log("this.estadoplanilla.",this.estadoplanilla);
    if(tareasNovedadeS.length==0){

      this.dialogagregar.open(DialogAprobarPlanilla, {
        width: '400px',
        data: {planilla:localStorage.getItem("codigoplanilla")},
      
      });

 

    }else{

      this.toastr.errorToastr("Estado: Cargue incompleto.", 'Notificación');
       
     }
 
    }

  deletebanco(tipo: any) {

  
  }

  
  atras():void {

    this.route.navigate(['/Planilla']);
    
  }
  
}
