import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { OrdenTransporteService } from '../../../../shared/ordentransporte.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogAgregarArchivo } from '../dialogAgregarArchivo/dialogAgregarArchivo.component';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { TareasTemporalService } from 'src/app/shared/tareastemporal.service';
import { DialogAgregarArchivoGeo } from '../dialogAgregarArchivoGeo/dialogAgregarArchivoGeo.component';
import { PedidoService } from 'src/app/shared/pedidos.service';

@Component({
  selector: 'app-FragmentEncuestas',
  templateUrl: './FragmentEncuestas.component.html',
  styleUrls: ['./FragmentEncuestas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentEncuestas implements OnInit {

  lat = 51.678418;
  lng = 7.809007;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public pedidosCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  public isaprobar = false;
  public isnuevo = true;
  subscription: Subscription;
  public numeroOperacionSiguiente: any=0;
  public path: Object = {

    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;


  Remove(args): void {
    args.cancel = true;
  }

  constructor(private pedidoService: PedidoService,private orderService: TareasTemporalService,private ordenTransporteService: OrdenTransporteService, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }

  displayedColumns: string[] = [ 'fecha', 'negocio', 'tarea', 'titulo','tipo','usuario_app','agencia'];
  dataSource = new MatTableDataSource();

  ngOnInit(): void {
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    localStorage.setItem("pedidoFecha", cValue);
    this.getEncuestas()
  }


  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  nuevo():void {

    this.dialogagregar.open(DialogAgregarArchivo, {
      width: '700px',
      height:'210px',
      data: {},
    
      });

  }

  geo():void {

    this.dialogagregar.open(DialogAgregarArchivoGeo, {
      width: '700px',
      height:'210px',
      data: {},
    
      });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

 


  getEncuestas() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription=    this.ordenTransporteService.getEncuentas ().subscribe(ordenesTransporte => {
      this.subscription.unsubscribe()
      
      console.log("ordenesTransporte",ordenesTransporte)
    
      this.dataSource.data = ordenesTransporte;
    
    });
  }


  
    indicadores(order: any) {
      localStorage.setItem("rfvDetalleEncuesta", order.rfv);
      this.toastr.successToastr("Detalle Encuesta", 'Notificación');
      this.route.navigate(['/FragmentDetalleEncuesta']);
    }

    

    
  
}
