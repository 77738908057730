import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-widget-piecausal',
  templateUrl: './piecausal.component.html',
  styleUrls: ['./piecausal.component.scss']

})
export class PieCausalComponent implements OnInit {

 
  chartOptions = {};

  @Input() data: any = [];
  Highcharts = Highcharts;
 
  constructor(private orderService: OrdersService,private userd: AuthService) { }

  ngOnInit() {

 
          this.getAllestados()

   
  }


  getAllestados() {
    

    var tipo=localStorage.getItem("txtRutaInd")
    this.orderService.getTipoRuta(tipo ).subscribe(res => {
  
      this.data= res;
  
      HC_exporting(Highcharts);
      setTimeout(() => {
        window.dispatchEvent(
          new Event('resize')

        );
      }, 300);
      
    
      this.chartOptions = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        colors: ['#c23b22 ','#77dd77','#efa94a'],
       title: {
          text: ''
        },
        tooltip: {
          pointFormat: '{point.detalle}:"<br /> {point.y} </b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '{point.detalle}</b>:<br />{point.y} <br />({point.percentage:.1f}  %)'
            }
          }
        },
        exporting: {  
          enabled: true
        },
        credits: {
          enabled: false
        },
        series: [{
          name: '',
          colorByPoint: true,
          data: this.data
        }]
      };
     

    });
  

    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: ''
      },
      tooltip: {
        pointFormat: '{point.detalle}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.detalle}</b>: {point.percentage:.1f} %'
          }
        }
      },
      colors: ['#2f7ed8'],
      exporting: {
        enabled: true
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Brands',
        colorByPoint: true,
        data: this.data
      }]
    };


    
  }

}
