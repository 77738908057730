import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { OrdersService } from '../../shared/orders.service';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  sideBarOpen = false;
  constructor(public toastr: ToastrManager) { }

  ngOnInit() {
    
      this.sideBarOpen = false;
  
      if(localStorage.getItem('login')=='session') {

      localStorage.setItem("login",  'cerrar');
 

    }
    
  }


  sideBarToggler() {

    if(localStorage.getItem('login')=='exitoso'){

      if(localStorage.getItem('menu')=='cerrar'){

        localStorage.setItem("menu",  'abrir');

      }else{
        localStorage.setItem("menu",  'cerrar');

      }
      this.sideBarOpen = !this.sideBarOpen;
      
    }else{

      this.toastr.errorToastr('Para poder ingresar a odl debe iniciar sesión.', '!Oops, algo salió mal!');

    }

  }

  sideBarTogglercoser() {

    this.sideBarOpen = false;
    
  }

}
