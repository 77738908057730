import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { OrdenTransporteService } from '../../../../shared/ordentransporte.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { DialogAgregarOrdenTransporte } from '../dialogAgregarOrdenTransporte/dialogAgregarOrdenTransporte.component';
import { DialogAgregarArchivo } from '../dialogAgregarArchivo/dialogAgregarArchivo.component';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { TareasTemporalService } from 'src/app/shared/tareastemporal.service';
import { OrdersService } from 'src/app/shared/orders.service';
import { DialogAgregarArchivoGeo } from '../dialogAgregarArchivoGeo/dialogAgregarArchivoGeo.component';

@Component({
  selector: 'app-FragmentOrdenTransportes',
  templateUrl: './FragmentOrdenTransportes.component.html',
  styleUrls: ['./FragmentOrdenTransportes.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentOrdenTransportes implements OnInit {

  lat = 51.678418;
  lng = 7.809007;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public pedidosCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  public isaprobar = false;
  public isnuevo = true;
  subscription: Subscription;
  public numeroOperacionSiguiente: any=0;
  public path: Object = {

    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;


  Remove(args): void {
    args.cancel = true;
  }

  constructor(private orderServiceTareas: OrdersService,private orderService: TareasTemporalService,private ordenTransporteService: OrdenTransporteService, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }

  displayedColumns: string[] = ['codigodeorden', 'fechacreacion', 'negocio', 'agencia', 'peso','tareas','estado','horacreacion','valorconiva','rutas','fechaoperacion','lat',
  'lon','zonas'];
  dataSource = new MatTableDataSource();

  ngOnInit(): void {
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
    localStorage.setItem("pedidoFecha", cValue);
    this.descargarOrdenesTransporte()
  }


  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  nuevo():void {

    this.dialogagregar.open(DialogAgregarArchivo, {
      width: '700px',
      height:'210px',
      data: {},
    
      });

  }

  geo():void {

    this.dialogagregar.open(DialogAgregarArchivoGeo, {
      width: '700px',
      height:'210px',
      data: {},
    
      });

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  descargarConsicutivo() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription =   this.orderService.getConsecutivo().subscribe(consecutivos => {

      for (let item of consecutivos) {
           
        var numeroSiguiente=1
        numeroSiguiente=numeroSiguiente+item.conoperacion
        this.numeroOperacion="OT"+numeroSiguiente
        this.numeroOperacionSiguiente=numeroSiguiente
      }      
      
     this.subscription.unsubscribe()
     this.crearOperacion()
    });
  }

  crearOperacion() {

    this.isaprobar=false
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');


    var operacion = {
      conoperacion:    this.numeroOperacionSiguiente,
      contarea:   this.numeroOperacionSiguiente,

    }

    this.orderService.incrementarCodigo(operacion);
    var hoy = new Date();
    var minuto=  hoy.getMinutes() 
    var segundos=hoy.getSeconds()

    var minutefinal="00"
    var segundofinal="00"
    if(minuto<10){

      minutefinal="0"+minuto
    }else{
      minutefinal=String(minuto)
    }
    if(segundos<10){

      segundofinal="0"+segundos
    }else{
      segundofinal=String(segundos)
    }

    this.pedidosCantidad=0;
    this.valorTotalTareasTemporal = 0;

    this.pesoKG=0
    for (let tarea of this.tareasCrearOrden) {
     
      this.pedidosCantidad = this.pedidosCantidad+1 
      this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
      this.pesoKG = this.pesoKG + tarea.PesoNeto
        

    }
    var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
    var ordendetranspote = {
      codigodeorden:    this.numeroOperacion,
      tareas:    this.pedidosCantidad,
      valorconiva:   this.valorTotalTareasTemporal,
      rutas:   this.rutasTemporales,
      zonas:   this.zonasTemporales,
      peso:   this.pesoKG,
      fechacreacion:   cValue,
      fechaoperacion:   cValue,
      horacreacion:hora,
      negocio:localStorage.getItem("negocio"),
      agencia:localStorage.getItem("agencia"),
      estado:'Creada'
    }

    console.log("orden",ordendetranspote)

    this.orderService.crearOrdenTransporte(ordendetranspote);

   
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };


    let planillas = groupBy(this.tareasCrearOrden, 'RutaVLI')


      for (let item of Object.keys(planillas)) {

      let tareasplanillas = this.tareasCrearOrden.filter(tarea => tarea.RutaVLI == item)

      var totalCosto=0
      var pesoKG=0
      var tareas=0
      var planilla=""
      var usuario=""
      
      for (let tareaplanilla of tareasplanillas) {
   
        totalCosto = totalCosto+tareaplanilla.CostoMerc
        pesoKG = pesoKG+tareaplanilla.PesoNeto
        planilla=tareaplanilla.RutaVLI
        usuario=tareaplanilla.RutaVLI
        tareas=tareas+1
  
      }

    
    var planillacrear = {
      planilla: planilla+this.numeroOperacionSiguiente,
      agencia:localStorage.getItem("agencia"),
      negocio:localStorage.getItem("negocio"),
      CostoMerc: totalCosto,
      fecha: cValue,
      fechaoperacion:   cValue,
      pesoKG:pesoKG,
      usuario:usuario,
      ordentransporte:this.numeroOperacion,
      estado:"En Tramite",
      tareas:tareas
     

    };

 
    this.orderService.createPlanillas(planillacrear);
    }

    this.descargarOrdenesTransporteactulizar()


}

  descargarOrdenesTransporte() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription=    this.ordenTransporteService.getOrdenTransporte ().subscribe(ordenesTransporte => {
      this.subscription.unsubscribe()
      this.dataSource.data = ordenesTransporte;
      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      let via = ordenesTransporte.filter(tarea => tarea.fechacreacion == cValue)
      
      console.log("via",via)
      if(via.length>0){
        this.isnuevo=false
      }else{
        this.subscription = this.orderServiceTareas.getTareasdiaFecha(cValue).subscribe(tarescrear => {
          this.subscription.unsubscribe()
          this.tareasCrearOrden = tarescrear;
          
          let tareasdia =  this.tareasCrearOrden.filter(tarea => tarea.fechaVL == cValue)
          if(tareasdia.length>0){
            this.descargarConsicutivo()
          }

        });
      
      }
    
    
    });
  }

  descargarOrdenesTransporteactulizar() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription=    this.ordenTransporteService.getOrdenTransporte ().subscribe(ordenesTransporte => {
      this.dataSource.data = ordenesTransporte;
      this.subscription.unsubscribe()
      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      let via = ordenesTransporte.filter(tarea => tarea.fechacreacion == cValue)
      
      if(via.length>0){
        this.isnuevo=false
      }
    
    
    });
  }

  ubicacion(order: any) {
    localStorage.setItem("ordenTransporteFecha", order.fechaoperacion);
    this.toastr.successToastr("Ubicación orden de trabajo: "+order.codigodeorden, 'Notificación');
    this.route.navigate(['/MapsOrden']);
    }
  
    indicadores(order: any) {
      localStorage.setItem("ordenTransporteFecha", order.fechaoperacion);
      this.toastr.successToastr("Indicador orden de trabajo: "+order.codigodeorden, 'Notificación');
      this.route.navigate(['/des']);
    }

    planillas(order: any) {
      localStorage.setItem("ordenTransporteFecha", order.fechaoperacion);
      this.toastr.successToastr("Indicador orden de trabajo: "+order.codigodeorden, 'Notificación');
      this.route.navigate(['/Planilla']);
    }

    control(order: any) {
      localStorage.setItem("ordenTransporteFecha", order.fechaoperacion);
      localStorage.setItem("ordenTransporte", order.codigodeorden);
      this.toastr.successToastr("Control orden de trabajo: "+order.codigodeorden, 'Notificación');
      this.route.navigate(['/rutas']);
    }

    
  
}
