
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { MapsOrdenService } from 'src/app/shared/mapsorden.service';
import { IndicadoresService } from 'src/app/shared/indicadores.service';
import { Subscription } from 'rxjs';

export interface DialogDataVh {
  nombre:string;
  identificacion:string;
  idTercero:string;
  apellidos:string;
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}

@Component({
  selector: 'app-dialogNovedades',
  templateUrl: './dialogNovedades.component.html',
  styleUrls: ['./dialogNovedades.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogNovedades implements OnInit {
  idTipo: string;
  tipo: string;
  descripcion: string;

  lat = 4.570329117;
  lng = -74.0768177;
   zoom: number = 5;
   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   subscription: Subscription;
   private txtTotalFacRuta: any;
   private txtFecha: any;
   estadoPendiente=0
   estadoExitosa=0
   estadoNovedad=0
   estadoParcial=0
   estadoRepisar=0
   estadoNovedadFallida=0
   txtentregas=0
   public listItems: any[]
   public tareasPanillasUsuarios= []
   public tareasPanillasdescarga= []
   private tareasCantidadPlanilla: any;
   private tareasCodigoPlanilla: any;
   private tareasPendientesPlanilla: any; 
   private tareasExitosasPlanilla: any; 
   private tareasParcialesPlanilla: any; 
   private tareasRepisarPlanilla: any; 
   private tareasNovedadPlanilla: any; 
   private tareasFallidaPlanilla: any; 
   private tareasCostoPlanilla: any; 
   private tareasPesoPlanilla: any; 
   private tareasTotalProductoPlanilla: any; 
   private tareasTotalRecaudoPlanilla: any; 
   private tareasTotalFallidaPlanilla: any; 
   private txtPesoKG=0; 
   private txtNovedades=0; 
   private txtPedidos=0; 
   private txtPedidosDev=0; 
   private txtPesoKGDe=0; 
   private txtRutas=0; 
   private txtPesoKGDeGestion=0; 
   private txtPesoKGGestion=0; 
 
  public iconUrlruta = "/assets/camion.png";
  public iconUrl = "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|4286f4";

  constructor(public dialogRef: MatDialogRef<DialogNovedades>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private indicadoresService: IndicadoresService, public toastr: ToastrManager) {}


     displayedColumns: string[] = ['tareasCodigoPlanilla', 'tareasCostoPlanilla','tareasTotalProductoPlanilla','tareasTotalRecaudoPlanilla', 'tareasCantidadPlanilla', 'tareasExitosasPlanilla','tareasPendientesPlanilla','tareasNovedadPlanilla','tareasFallidaPlanilla','tareasParcialesPlanilla','tareasRepisarPlanilla'];

     dataSource = new MatTableDataSource();

  
     ngOnInit(): void {
     
      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
     this.txtFecha=cValue
      localStorage.setItem("pedidoFecha",cValue);
      this.listItems = [];
      console.log("ACA NOVEDAD");

    
      this.subscription = this.indicadoresService.getIndicadoresNovedad().subscribe(tareasindicadores => {
      
        console.log("tareasindicadores NOVEDAD",tareasindicadores);
        this.tareasPanillasdescarga= []

        this.txtTotalFacRuta=0
        this.estadoPendiente=0
        this.estadoExitosa=0
        this.estadoParcial=0
        this.estadoRepisar=0
        this.estadoNovedadFallida=0
        this.estadoNovedad=0
        this.txtTotalFacRuta=0
        this.txtentregas=0
        this.txtNovedades=0
        this.txtPedidos=0
        this.txtPedidosDev=0
        this.txtPesoKGDe=0
        this.txtRutas=0
  
  
  
      var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
  
      let planillas = groupBy(tareasindicadores, 'codigoPlanilla')
      console.log("planillas",planillas);
  
      
      this.txtPesoKG=0
      this.txtPesoKGGestion=0
      this.txtPesoKGDeGestion=0
        for (let tarea of tareasindicadores) {
     
          this.tareasPanillasdescarga.push(tarea)
  
        }
  
  
        
      });

     }
   
   
     ngAfterViewInit() {
       this.dataSource.paginator = this.paginator;
     }
   
   
     exportAsXLSXgeneral():void {

      this.toastr.successToastr('Descarga en progreso.', 'Notificación');
    
      this.subscription = this.indicadoresService.getIndicadoresNovedad().subscribe(tareasindicadores => {
      
        this.indicadoresService.exportAsExcelFile(tareasindicadores, 'ClientesCreados');
        this.subscription.unsubscribe();

      }); 
    
    }
}
