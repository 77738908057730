
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MatDialog } from '@angular/material';
import { MapsOrdenService } from 'src/app/shared/mapsorden.service';
import { IndicadoresService } from 'src/app/shared/indicadores.service';
import { formatDate } from '@angular/common';

export interface DialogDataVh {
  nombre:string;
  identificacion:string;
  idTercero:string;
  apellidos:string;
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}

@Component({
  selector: 'app-dialogPuntosMpas',
  templateUrl: './dialogPuntosMpas.component.html',
  styleUrls: ['./dialogPuntosMpas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogPuntosMpas implements OnInit {
  idTipo: string;
  tipo: string;
  descripcion: string;
 
  

   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
   public markers = new Array();
   public ubicaciones = new Array();
   public rutasmapas = new Array();
   public transportes = new Array();
   lat = 4.570329117;
   lng = -74.0768177;
    zoom: number = 5;
   bounds: any;
   polygonPts: any[] = [];
   ptsList: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public licencias;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  private anos;
  private dias;
  private meses;
  public iconUrlruta = "/assets/puntocontrol.svg";
  public iconUrl = "/assets/controlrojo.svg";
  public iconUrlflecha = "/assets/flechaderecha.svg";
  public start_end_mark = [];
  public icon = {
    url: 'http://earth.google.com/images/kml-icons/track-directional/track-4.png',
    scaledSize: {
      width: 30,
      height: 30
    }
}

  public  latlng = [];
  
  constructor(public dialogRef: MatDialogRef<DialogPuntosMpas>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private mapsOrdenService: MapsOrdenService,private indicadoresService: IndicadoresService, public toastr: ToastrManager) {}


     displayedColumns: string[] = [ 'hora', 'latitud', 'longitud','direccion_geo'];
     dataSource = new MatTableDataSource();
   
     ngOnInit(): void {
    
       this. descargarubicacionesRuta()
       
   
     }
   
   
     ngAfterViewInit() {
   
       this.dataSource.paginator = this.paginator;
   
     }
   
   
     applyFilter(event: Event) {
       const filterValue = (event.target as HTMLInputElement).value;
       this.dataSource.filter = filterValue.trim().toLowerCase();
     }
   
   

      descargarubicacionesRuta() {
   
   
        const currentDate = new Date();
        const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
        
        this.mapsOrdenService.getMapsOrdenUsuario(localStorage.getItem('marketuser')).subscribe(mapstareas => {
      
         console.log("mapstareas ubicaciones",mapstareas);

    

         this.ubicaciones = []
         this.dataSource.data=mapstareas
    
         for (let tarea of mapstareas) {
   
 
           var market = {
             lat:    tarea.longitud,
             lng:   tarea.latitud,
             label: String( tarea.id),
             draggable: false,
             cliente: tarea.usuario,
             direccion: tarea.direccion_geo,
             valor:tarea.hora
           }
   
           this.lat=tarea.latitud
           this.lng=tarea.longitud

           var punto =   [
            parseFloat( tarea.longitud),
            parseFloat( tarea.latitud),
      
          ]
          this.latlng.push(punto)
     
           this.ubicaciones.push(market)
     
           
         }

         console.log("market",market);
         this.start_end_mark = []
         this.start_end_mark.push(this.latlng[0]);
         //this.start_end_mark.push(this.latlng[this.latlng.length - 1]);
        
         console.log("mapstareas rutas this.latlng",this.start_end_mark);
         

       
       });
   
     }
     clickedMarker(market: any) {
       this.zoom=0
       console.log("market",market);
       this.lat=market.lat
       this.lng=market.lon
       this.zoom=18
   
  
   
     }
   
     onZoomChange() {
       this.zoom=16
   }
   
     onMapReady(map) {
       map.setOptions({
           zoomControl: 'true',
           zoomControlOptions: {
               position: google.maps.ControlPosition.RIGHT_CENTER,
               style: google.maps.ZoomControlStyle.DEFAULT
           }
       });

   }
   

   getIconUrl(index: number) {
    const angle = this.getAngle(index);
    return `https://via.placeholder.com/20/000000/FFFFFF/?text=${angle}`;
  }

  getAngle(index: number) {
    const current = this.ubicaciones[index];
    const next = this.ubicaciones[index + 1];
    if (!next) {
      return 0;
    }
    return (Math.atan2(next.lng - current.lng, next.lat - current.lat) * 180) / Math.PI;
  }
  
}
