
import { MatTableDataSource, MatPaginator, MatFormFieldModule, MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { AuthService } from '../../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { DialogAgregarArchivoDetalle } from '../dialogAgregarArchivoDetalle/dialogAgregarArchivoDetalle.component';
import { formatDate } from '@angular/common';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { OrdersService } from 'src/app/shared/orders.service';
export interface DialogDataAgregar {
  file: any;
  costototal:String;
  
}
@Component({
  selector: 'app-DialogAgregarOrdenTransporte',
  templateUrl: './DialogAgregarOrdenTransporte.component.html',
  styleUrls: ['./DialogAgregarOrdenTransporte.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarOrdenTransporte implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginatorDetalle', { static: false }) paginatordetelle: MatPaginator;
  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public detalleCrearOrden: any;
  public pedidosCantidad: any=0;
  public detalleCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public numeroOperacionSiguiente: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  private valorTotalDetalleTemporal: any=0;
  public isaprobar = false;
  public ismenudetalle = false;
  public iscargado = false;
  public iscontenido = true;
  private s_ano;
  private s_mes;
  private s_dia;
  private anos;
  private dias;
  private meses;
  subscription: Subscription;
  subscriptionenvio: Subscription;
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile

  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(public dialogRef: MatDialogRef<DialogAgregarOrdenTransporte>,private configuracionesService: ConfiguracionesService,private orderServiceTareas: OrdersService,private orderService: TareasTemporalService,     @Inject(MAT_DIALOG_DATA) public data: DialogDataAgregar, private userd: AuthService, public toastr: ToastrManager, private route: Router,public dialogagregar: MatDialog) {

  }

   displayedColumns: string[] = ['NumeroPed', 'RutaVLI', 'Placa', 'NombredeCliente', 'Direccion',
    'CostoMerc'];

    displayedColumnsDetalle: string[] = ['codigoArticulo', 'descripcion', 'ean', 'cantidad', 'valorNetoConIva',
    'numeroPedido'];
  
    dataSource = new MatTableDataSource();

    dataSourceDetalle = new MatTableDataSource();

  ngOnInit(): void {
    var negocio=localStorage.getItem("negocio");
    var agencia=localStorage.getItem("agencia");
    this.negocioAgencia=negocio+"-"+agencia
   this.parseExcel(this.data.file)
    this.descargar()
    this.rutasTemporales=0;
    this.valorTotalDetalleTemporal=0
    this.descargaranos()
    this.descargardias()
    this.descargarmeses()

    this.fechaTareasTemporal = localStorage.getItem("pedidoFecha");

    this.dialog = new Dialog({
      // Enables the header
      header: 'ERROR VALIDAR FORMATO',
      // Enables the close icon button in header
      showCloseIcon: true,
      visible: false,
      // Dialog content

      // The Dialog shows within the target element
      target: document.getElementById("container"),
      // Dialog width
      width: '500px',
      height: '1000px'

    });

    this.dialog.appendTo('#dialog');
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSourceDetalle.paginator=this.paginatordetelle

  }


  getcreate(order: any) {
    this.orderService.createTareaTemporal(order);
  }

  onNoClick(): void {

    this.dialogRef.close();

  }

  onDetelle(): void {
  
   
  
  }
  descargaranos() {


    let fechaActual = new Date();
let anofechas = [];


  var ano = {
    fecha:    "2024",
   
  }

  anofechas.push(ano);

  this.anos = anofechas
  }

  descargarmeses() {

let fechaActual = new Date();
let mesesfechas = [];

var numerodia = String(fechaActual.getMonth()+ 1)
  if ( (fechaActual.getMonth()+ 1)<10){
    numerodia = "0" + String(fechaActual.getMonth() + 1)
  }

  var mes = {
    mes:    numerodia,
   
  }

  mesesfechas.push(mes);

  this.meses=mesesfechas

  }

  descargardias() {



    let fechaActual = new Date();
    let dias = [];
    let ultimoDia = new Date(fechaActual.getFullYear(), fechaActual.getMonth() + 1, 0);
    
    for (let i = fechaActual.getDate(); i <= ultimoDia.getDate(); i++) {

      var numerodia = String(i)
      if ( i<10){
        numerodia = "0" + String(i)
      }

      var dia = {
        dia:    numerodia,
       
      }
      dias.push(dia);
    }
    this.dias = dias  
  }


  sfdia(dato: any) {

    this.s_dia=dato

  }
  sfmes(dato: any) {

    this.s_mes=dato

  }

  sfano(dato: any) {

    this.s_ano=dato

  }

  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);

        let data = JSON.parse(json_object);

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

      

            };


            this.valorTotalTareasTemporal = 0;
            this.pesoKG=0;
            this.pedidosCantidad=0;
            var groupBy = function (xs, key) {
              return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };
            var rutasTareas = groupBy(data, 'Ruta')
            var zonasareas = groupBy(data, 'Zona')
            this.rutasTemporales=0;
            this.zonasTemporales=0;

            for (let item of Object.keys(rutasTareas)) {
           
              this.rutasTemporales = this.rutasTemporales + 1
            }

            for (let item of Object.keys(zonasareas)) {
           
              this.zonasTemporales = this.zonasTemporales + 1
            }

            this.dataSource.data = data;

            this.tareasCrearOrden=data

            for (let tarea of data) {

              this.pedidosCantidad = this.pedidosCantidad+1 
              this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
              this.pesoKG = this.pesoKG + tarea.PesoNeto
          
          
            }

            this.valorTotalTareasTemporal= Math.trunc(this.valorTotalTareasTemporal)
            this.toastr.successToastr('Por favor cargar el archivo de detalles.', 'Notificación');
            this.ismenudetalle=true


          });


        });



        this.uploadObj.clearAll();



      }).bind(this), this);
    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  parseExcelDetalle(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });

      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log("detalle carga json_object",json_object);
        let data = JSON.parse(json_object);

        console.log("detalle carga",data);

        this.detalleCrearOrden=data;

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

              this.negocioAgencia=elemento.negocio

            };


            this.dataSourceDetalle.data = data;

            this.valorTotalDetalleTemporal = 0;
            this.detalleCantidad=0;
      

            for (let tarea of data) {

              this.detalleCantidad = this.detalleCantidad +1 
              this.valorTotalDetalleTemporal =  this.valorTotalDetalleTemporal + tarea.valorNetoConIva;

        
            }

            this.isaprobar=true


          });


        });


        this.uploadObj.clearAll();



      }).bind(this), this);

    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };
  public onSuccess(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);
  }

  public onSuccessDetalle(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcelDetalle(files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

 applyFilterDetalle(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceDetalle.filter = filterValue.trim().toLowerCase();
  }
  descargar() {
    localStorage.setItem("rutaindicador", "si");
    this.subscription =   this.orderService.getConsecutivo().subscribe(consecutivos => {

      for (let item of consecutivos) {
           
        var numeroSiguiente=1
        numeroSiguiente=numeroSiguiente+item.conoperacion
        this.numeroOperacion="OT"+numeroSiguiente
        this.numeroOperacionSiguiente=numeroSiguiente
      }      
      
     this.subscription.unsubscribe()
  
    });
  }
  
  crearTipoDocumento(data: any) {

    if(this.valorTotalTareasTemporal==data.costototal){
      if(this.s_ano &&this.s_mes &&this.s_dia ){
        this.crearOperacion()  
        this.toastr.successToastr('Costo correcto.', 'Notificación');
          
      }else{
       this.toastr.errorToastr('Todos los campos son obligatorios.', 'Notificación');
          
     }
      /*
      this.dialogagregar.open(DialogAgregarArchivoDetalle, {
        width: '700px',
        height:'210px',
        data: {file:this.data.file},
      
        });

         this.dialogRef.close();
         this.toastr.successToastr('Costo correcto.', 'Notificación');
               */

       }else{
        this.toastr.errorToastr('El total del costo es diferente.', 'Notificación');
           
      }
 

  }


  crearOperacion():void {
    console.log("crearOperacion si llega ", operacion);
    setTimeout(() => {
      this.dialogRef.close();
      localStorage.setItem("ordenTransporte",this.numeroOperacion);
      this.route.navigate(['/Planilla']);
   
    
     }, 1000);

    this.iscontenido=false
    this.iscargado=true
    this.isaprobar=false
    this.toastr.successToastr('Carga en progreso.', 'Notificación');
    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("ordenTransporteFecha", this.s_ano+"-"+this.s_mes+"-"+this.s_dia);
    var operacion = {
      conoperacion:    this.numeroOperacionSiguiente,
      contarea:   this.numeroOperacionSiguiente,

    }

    console.log("operacion si llega ", operacion);
    this.orderService.incrementarCodigo(operacion);
    var hoy = new Date();
    var minuto=  hoy.getMinutes() 
    var segundos=hoy.getSeconds()

    var minutefinal="00"
    var segundofinal="00"
    if(minuto<10){

      minutefinal="0"+minuto
    }else{
      minutefinal=String(minuto)
    }
    if(segundos<10){

      segundofinal="0"+segundos
    }else{
      segundofinal=String(segundos)
    }

    this.pedidosCantidad=0;
    this.valorTotalTareasTemporal = 0;

    this.pesoKG=0
    for (let tarea of this.tareasCrearOrden) {
      var ruta = {
        ruta: tarea.RutaVLI,
        usuario: String(tarea.usuario),
        id:1,
        estado:'disponible',
        placa: 'pendiente',
        fecha: this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
        agencia:localStorage.getItem("agencia"),
        negocio: localStorage.getItem("negocio"),
      }
   
      console.log("negocio si llega ", localStorage.getItem("negocio"));
      console.log("agencia si llega ", localStorage.getItem("agencia"));
      console.log("tareasCrearOrden si llega ", this.tareasCrearOrden);
      this.orderServiceTareas.createRuta(ruta);
      this.pedidosCantidad = this.pedidosCantidad+1 
      this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
  
              this.pesoKG = this.pesoKG + tarea.PesoNeto
              tarea.idpedido = 'PENDIENTE';
              tarea.estado = 'PENDIENTE';
              tarea.observaciones = '';
              tarea.codigoOperacion=  " "
              tarea.codigoPlanilla=  ""
              tarea.codigoRegistro= " "
              tarea.fechaVL =  this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
              tarea.fechaoperacion=cValue,

              tarea.negocio = localStorage.getItem("negocio");
              tarea.agencia = localStorage.getItem("agencia");  
              tarea.lonTarea=0.0;
              tarea.latTarea=0.0;
              tarea.lonEnvio=0.0;
              tarea.latEnvio=0.0;
              tarea.devolucion=0.0;
              tarea.metros_reporte=0.0;
              tarea.metros_envio=0.0;
              tarea.sitio_reporte='';
              tarea.sitio_envio='';
              tarea.direccion_geo='';
              tarea.hora_envio='';
              tarea.hora_reporte='';
              tarea.usuario_app='';
              tarea.reporte='';
              tarea.foto_cliente='';
              tarea.foto_factura='';
              tarea.url_foto_cliente='';
              tarea.url_foto_factura='';
              tarea.estado_foto_cliente='';
              tarea.estado_foto_factura='';
              tarea.id_foto_cliente='';
              tarea.id_foto_factura='';
              tarea.geo_zona='';
              tarea.causal_estado='';
              tarea.estadoEnvio='';
              tarea.estadoEnvio='';
              tarea.recaudo=0.0;
              tarea.porcetaje_entrega=0.0;
              tarea.porcetaje_entrega_peso=0.0;
              tarea.porcetaje_novedad_peso=0.0;
              tarea.porcetaje_novedad=0.0;
              tarea.entrega_peso=0.0;
              tarea.novedad_peso=0.0;
              tarea.longitud_transporte=0.0;
              tarea.latitud_transporte=0.0;
              tarea.direccion_transporte='';
              tarea.hora_transporte='';
              tarea.metros_transporte='';
              tarea.estadoEnvio='PENDIENTE';
              console.log(tarea);
              
      //this.orderService.numeroRegistro(tarea);
      this.orderServiceTareas.createOrder(tarea);

    }
    var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
    var ordendetranspote = {
      codigodeorden:    this.numeroOperacion,
      tareas:    this.pedidosCantidad,
      valorconiva:   this.valorTotalTareasTemporal,
      rutas:   this.rutasTemporales,
      zonas:   this.zonasTemporales,
      peso:   this.pesoKG,
      fechacreacion:   this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      fechaoperacion:   this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      horacreacion:hora,
      negocio:localStorage.getItem("negocio"),
      agencia:localStorage.getItem("agencia"),
      estado:'Creada'
    }

    this.orderService.crearOrdenTransporte(ordendetranspote);

   
    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };


    let planillas = groupBy(this.tareasCrearOrden, 'RutaVLI')
    console.log('planillas',planillas);

      for (let item of Object.keys(planillas)) {

      let tareasplanillas = this.tareasCrearOrden.filter(tarea => tarea.RutaVLI == item)

      var totalCosto=0
      var pesoKG=0
      var tareas=0
      var planilla=""
      var usuario=""
      
      for (let tareaplanilla of tareasplanillas) {
   
        totalCosto = totalCosto+tareaplanilla.CostoMerc
        pesoKG = pesoKG+tareaplanilla.PesoNeto
        planilla=tareaplanilla.RutaVLI
        usuario=tareaplanilla.RutaVLI
        tareas=tareas+1
  
      }

    
    var planillacrear = {
      planilla: planilla+this.numeroOperacionSiguiente,
      agencia:localStorage.getItem("agencia"),
      negocio:localStorage.getItem("negocio"),
      CostoMerc: totalCosto,
      fecha: this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      fechaoperacion:   this.s_ano+"-"+this.s_mes+"-"+this.s_dia,
      pesoKG:pesoKG,
      usuario:usuario,
      ordentransporte:this.numeroOperacion,
      estado:"En Tramite",
      tareas:tareas
     

    };

 
    this.orderService.createPlanillas(planillacrear);

    }
  

}

  
}
