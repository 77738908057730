import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ClientesService } from '../../clientes.service';
import { PedidoService } from '../../pedidos.service';
import { ClientesaprobacionService } from '../../clientesaprobacion.service';

@Component({
  selector: 'app-widget-arearutas',
  templateUrl: './arearutas.component.html',
  styleUrls: ['./arearutas.component.scss']
})
export class AreaRutasComponent implements OnInit {

  
 
  private rutasDescarga: any;
  private ClientesNuevos: any;

  subscription: Subscription;
  subscriptionContactos: Subscription;
  subscriptionVisitas: Subscription;

  private txtTotalVisitas: any;
  private txtPedidos: any;
  private txtNovedad: any;
  private txtPendiente: any;
  private txtRutas: any;
  private txtVenta: any;
  private txtClientes:any;
  private txtFecha:any;
  private datosNovCan: any;
  private datosPedidosRutas: any;
  private datosNov: any;
  private datosPend: any;

  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;
  
  constructor(private ClientesService: ClientesService, public pedidosService: PedidoService, public clientesaprobacionService: ClientesaprobacionService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });
  }

  ngOnInit(): void {

    if (localStorage.getItem('rutaindicador') == 'si') {

        localStorage.setItem("rutaindicador", "no");
    
    }

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      this.descargar();
      setInterval(() => {

        this.descargar();
      
      }, 60000);

    } else {


      this.subscription.unsubscribe();


    }


  }

  descargar() {
    var numero=0

    numero=parseInt(localStorage.getItem('diaConsulta'))
      this.subscription = this.ClientesService.getClientesDia(numero).subscribe(rutas => {
      this.rutasDescarga = this.rutasDescarga = rutas;
      this.txtClientes=rutas.length

      this.subscriptionVisitas = this.clientesaprobacionService.getClienteNuevosDia(localStorage.getItem('fechaNuevos') ).subscribe(clientes => {
        this.ClientesNuevos=clientes
        this.subscriptionVisitas.unsubscribe();
        this.getTareas();

      
      });
  
    
    });


  }


  getVista(datosCliente:any){

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(datosCliente, 'idempleado')

    let rutasClientes = []
    for (let item of Object.keys(rutasdia)) {
      var rutasODL = rutasdia[item];

  
      let tareasNovedadeS = rutasODL.filter(tarea => tarea.name == "Novedad")
      let tareasPedidos = rutasODL.filter(tarea => tarea.name == "Pedido")
      let Clientes = this.rutasDescarga.filter(cli => cli.idempleado == rutasODL[0].idempleado)
      let nuevos = this.ClientesNuevos.filter(cli => cli.idUsr == rutasODL[0].idempleado)
     
      var ruta = {
        ruta: rutasODL[0].idempleado,
        name: rutasODL[0].idempleado,
        y:Clientes.length,
        x:Clientes.length,
        nuevos:nuevos.length,
        novedades:tareasNovedadeS.length,
        pedidos:tareasPedidos.length,
        pendientes:Clientes.length-tareasNovedadeS.length-tareasPedidos.length,
        txPedidosPorMC: Math.round(((tareasPedidos.length * 100) / Clientes.length)) + "%",
        txtPendientesPorMC: Math.round((((Clientes.length-tareasPedidos.length)  * 100) / Clientes.length)) + "%",
        txEfePorMC: Math.round((((tareasPedidos.length+tareasNovedadeS.length) * 100) / Clientes.length)) + "%",
        txEfePenPorMC: Math.round((((Clientes.length-(tareasPedidos.length+tareasNovedadeS.length))  * 100) / Clientes.length)) + "%",
   

      };

      rutasClientes.push(ruta)
      //this.ClientesService.createRutasOdl(ruta)
    
    }

    let rutasclientesP = groupBy(this.rutasDescarga, 'idempleado')

    for (let item of Object.keys(rutasclientesP)) {
      var rutasODL = rutasclientesP[item];
      let Clientes =rutasClientes.filter(cli => cli.name == rutasODL[0].idempleado)

      let nuevos = this.ClientesNuevos.filter(cli => cli.idUsr == rutasODL[0].idempleado)
     
      let ClientesDescarga = this.rutasDescarga.filter(cli => cli.idempleado == rutasODL[0].idempleado)

      if(Clientes.length>0){


      }else{

        var rutape = {
          ruta: rutasODL[0].idempleado,
          name: rutasODL[0].idempleado,
          y:ClientesDescarga.length,
          x:ClientesDescarga.length,
          nuevos:nuevos.length,
          novedades:0,
          pedidos:0,
          pendientes:ClientesDescarga.length,
          txPedidosPorMC: Math.round(((0 * 100) / ClientesDescarga.length)) + "%",
          txtPendientesPorMC: Math.round((((ClientesDescarga.length-0)  * 100) / ClientesDescarga.length)) + "%",
          txEfePorMC: Math.round((((0) * 100) / ClientesDescarga.length)) + "%",
          txEfePenPorMC: Math.round((((ClientesDescarga.length-(0))  * 100) / ClientesDescarga.length)) + "%",
     
  
        };
  
        rutasClientes.push(rutape)

      }

   
    }

    this.getAllrecorridos(rutasClientes)


  }

  getTareas() {

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(this.rutasDescarga, 'idempleado')

    let vistasClientes = []
    this.txtPedidos=0;
    this.txtNovedad=0;
    this.txtPendiente=0;
    this.txtRutas=0
    this.txtVenta=0
    this.txtTotalVisitas=0

  
    let rutasClientes = []
    for (let item of Object.keys(rutasdia)) {
      this.txtRutas=this.txtRutas+1
   
    }

    this.subscriptionVisitas = this.pedidosService.getPedidos().subscribe(visitas => {
   
        this.txtTotalVisitas=  this.txtTotalVisitas+1
      for (let visita of visitas){

        if(visita.fnt==20){

          this.txtNovedad=  this.txtNovedad+1

          var vistacliente = {
  
            idEmpresa: visita.idEmp,
            cliente: visita.razon,
            total: visita.total,
            tipo: visita.fnt,
            obs: visita.obs,
            idempleado:visita.usr,
            name:"Novedad"
          };

          vistasClientes.push(vistacliente)
    
         // this.ClientesService.createVistasOdl(vistacliente)
   
     
        }

        if(visita.fnt==10){
          
          this.txtPedidos=  this.txtPedidos+1
          this.txtVenta=  this.txtVenta+ visita.total

          var vistacliente = {
  
            idEmpresa: visita.idEmp,
            cliente: visita.razon,
            total: visita.total,
            tipo: visita.fnt,
            obs: visita.obs,
            idempleado:visita.usr,
            name:"Pedido",
  
  
          };
    
          vistasClientes.push(vistacliente)
         // this.ClientesService.createVistasOdl(vistacliente)
         
        }


       }

       this.subscriptionVisitas.unsubscribe();
      this.getVista(vistasClientes)
  

    });


  }



  getAllrecorridos(vistas: any) {

    let categarias = []
    let exitosas = []
    let novedades = []
    let pendientes = []


    for (let ruta of  vistas) {

      categarias.push(ruta.name)
      exitosas.push(ruta.pedidos)
      novedades.push(ruta.novedades)
      pendientes.push(ruta.pendientes)


    }

    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Rutas'
      },
      xAxis: {
        categories: categarias
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Rutas'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ['#c23b22 ','#77dd77','#efa94a'],
          }
        }
      }, legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
      ['#c23b22 ','#77dd77','#efa94a'],
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      }, tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      }, series: [{
        name: 'Pendientes',
        data: pendientes,
        color: "#e23a3a"
      }, {
        name: 'Novedad',
        data: novedades,
        color: "#ffb553"
      }, {
        name: 'Exitosas',
        data: exitosas,
        color: "#439e37"
      }]
      
    };
}

}