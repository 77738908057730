
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { ClientesActulizacionService } from '../../shared/clientesActulizacion.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-fragmenClientesActulizacion',
  templateUrl: './fragmenClientesActulizacion.component.html',
  styleUrls: ['./fragmenClientesActulizacion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmenClientesActulizacion implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = ['nmbCmn','idnt', 'tel','mail','idUsr'];
  dataSource = new MatTableDataSource();
  

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public tareas: any;
  private datosNov: any;
  private subscription: Subscription;


  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile
  
  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(private clienteApro: ClientesActulizacionService,private userd: AuthService ,public toastr: ToastrManager, private route: Router) {

  localStorage.setItem("vistaShow", 'SHOWN');
  document.addEventListener("visibilitychange", function () {

    if (document.hidden) {

      console.log("Hidden");
      localStorage.setItem("vistaShow", 'Hidden');

    }
    else {

      console.log("SHOWN");
      localStorage.setItem("vistaShow", 'SHOWN');

    }

  });

}
ngAfterViewInit() {

  this.dataSource.paginator = this.paginator;

}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.dataSource.filter = filterValue.trim().toLowerCase();
}


ngOnInit(): void {

  this.negocio = localStorage.getItem("negocio");
  this.agencia = localStorage.getItem("agencia");
  this.usuario = localStorage.getItem("correo");


  if (localStorage.getItem('vistaShow') == 'SHOWN') {

    this.descargar();
    setInterval(() => {

      this.descargar();

    }, 60000);

  } else {


    this.subscription.unsubscribe();


  }

}
descargar() {

  this.subscription = this.clienteApro.getClienteAprobacion().subscribe(clientes => {

    this.dataSource.data = clientes;
    this.subscription.unsubscribe();

  });


}


aprobar(order: any) {

  localStorage.setItem("clienteActulizar", order.rfv);

  this.toastr.successToastr(order.nmbCmn, 'Notificación');
  this.route.navigate(['/actualizacion']);

  }

}
