import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-widget-pieventaramo',
  templateUrl: './pieventaramo.component.html',
  styleUrls: ['./pieventaramo.component.scss']

})
export class PieventaRamoComponent implements OnInit {

 
  chartOptionsdos = {};

  @Input() data: any = [];
  Highcharts = Highcharts;
 
  constructor(private orderService: OrdersService,private userd: AuthService) { }

  ngOnInit() {
 
    this.getAllrecorridos()



}


getAllrecorridos() {


this.orderService.getZonasGrupos().subscribe(res => {

this.data= res;

HC_exporting(Highcharts);
setTimeout(() => {
  window.dispatchEvent(
    new Event('resize')

  );
}, 300);


this.chartOptionsdos = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: ''
  },
  tooltip: {
    pointFormat: '{point.idZona}: {point.Dev} <br />({point.percentage:.1f})  %'
  },

  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        format: '<b>{point.idZona}</b>:<br />{point.Dev}<br />({point.percentage:.1f}  %)'
      }
    }
  },
  exporting: {  
    enabled: true
  },
  credits: {
    enabled: false
  },
  series: [{
    name: '',
    colorByPoint: true,
    data: this.data
  }]
};


});


this.chartOptionsdos = {
chart: {
  plotBackgroundColor: null,
  plotBorderWidth: null,
  plotShadow: false,
  type: 'pie'
},
title: {
  text: ''
},
tooltip: {
  pointFormat: '{series.idZona}: <b>{point.percentage:.1f}%</b>'
},
plotOptions: {
  pie: {
    allowPointSelect: true,
    cursor: 'pointer',
    dataLabels: {
      enabled: true,
      format: '<b>{point.idZona}</b>: {point.percentage:.1f} %'
    }
  }
},
exporting: {
  enabled: true
},
credits: {
  enabled: false
},
series: [{
  name: 'Brands',
  colorByPoint: true,
  data: this.data
}]
};



}
  

}
