
import { DashboardService } from '../dashboard.service';
import { MatTableDataSource, MatPaginator,MatFormFieldModule } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { element } from 'protractor';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-fragmentTareas',
  templateUrl: './fragmentTareas.component.html',
  styleUrls: ['./fragmentTareas.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentTareas implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('defaultupload', {read: UploaderComponent, static: false})
  public uploadObj: UploaderComponent;

  @ViewChild('grid', {read: GridComponent, static: false})
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public suma;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public fecha;
  public iscomercial = false;
  private subscription: Subscription;
  private subscriptionDetalle: Subscription;

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile
  
  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(private orderService: OrdersService,private userd: AuthService ,public toastr: ToastrManager, private route: Router) {

  }

 
  displayedColumns: string[] = ['NumeroPed','RutaVLI', 'Placa','NombredeCliente','Direccion',
  'CostoMerc','estado','observaciones','negocio','orden'];
  dataSource = new MatTableDataSource();

  displayedColumnstotal: string[] = ['Costo'];
 dataSourcetotal = new MatTableDataSource();

  ngOnInit(): void {

    this.fecha=localStorage.getItem("pedidoFecha");
      this.getAllOrders();
      this.getAllcosto();


    this.getAllcosto();
   


    this.dialog = new Dialog({
      // Enables the header
      header: 'ERROR VALIDAR FORMATO',
      // Enables the close icon button in header
      showCloseIcon: true,
      visible: false,
      // Dialog content
     
      // The Dialog shows within the target element
      target: document.getElementById("container"),
      // Dialog width
      width: '500px',
      height: '1000px'
      
    });

    this.dialog.appendTo('#dialog');
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    
  }


  getAllOrders() {

    this.orderService.getOrders().subscribe(res => {
      this.dataSource.data = res;
      this.datas=res;

      console.log('vsliar op',res);
    });
  }

  getAllcosto() {
    this.orderService.getTotaloperacion().subscribe(res => {
      this.dataSourcetotal.data = res;
    });
  }
 

  getcreate(order: any) {
    this.orderService.createOrder(order);
  }

  onDelete(id: string) {
    this.orderService.deleteOrder(id);
  }
  onChangeStatus(order: any) {
    order.completed = true;
    this.orderService.updateOrder(order);
  }


 
  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });

     
      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        console.log(json_object)

       
        let data=JSON.parse(json_object);
        console.log("data", data)
    
    
        const currentDate = new Date();
        const cValue =localStorage.getItem("pedidoFecha");

        this.fecha=localStorage.getItem("pedidoFecha");
        var i=0;

          this.userd.getauth().subscribe(res => {
          res;
          console.log('varlidar res', res )
          var dataemail ;
          this.orderService.getuserweb(res.email).subscribe(res => {
             dataemail = res;
    
             for (let elemento of dataemail) {
              localStorage.setItem("negocio",  elemento.negocio);
              localStorage.setItem("agencia",  elemento.agencia);
              console.log( 'raro no se que hacer', elemento);
                
            };
   
      
            var suma;   
            for(let x in data){
              suma =suma+ data[x].CostoMerc;//Ahora que es un objeto javascript, tiene propiedades
              let list: Array<number> = [0];
    
              
              var recorridoExitosos = {
              name: data[x].Placa,
              data:list,
              idrecorrido: data[x].Placa,
        
            };
        
              console.log('placa',name);
        
              i=i+1;
              //this.orderService.createAvance(recorridoExitosos);
              //this.orderService.createRecorrido(recorridoExitosos);
    
              var ruta = {
                ruta: data[x].RutaVLI,
                id:1,
                estado:'disponible',
                placa: 'pendiente',
                agencia:localStorage.getItem("agencia"),
                negocio: localStorage.getItem("negocio"),
           
           
              }
           
           
              this.orderService.createRuta(ruta);
    
              console.log('rutas creadas',ruta);
          
    
    
            }
        

            var i=0;
            suma=0;
        

            for (let elemento of data) {
              suma =suma+elemento.CostoMerc;
              console.log('porq no da', suma);
              elemento.idpedido='PENDIENTE';
              elemento.estado='PENDIENTE';
              elemento.observaciones='';
              elemento.fechaVL=cValue;
              elemento.negocio=localStorage.getItem("negocio");
              elemento.agencia=localStorage.getItem("agencia");
              console.log(elemento);
              this.orderService.createOrder(elemento);
                i=i+1;
             }
   
            
      
           
            var pendiente = {
             name: 'Pend',
             y: i,
             valor:   suma
           };
   
           var novedad = {
             name: 'Novedad',
             y: 0,
             valor:0
           };
   
   
           var Exitosas = {
             name: 'Exitosas',
             y: 0,
             valor:0
           };
   
           var pendientecash = {
             name: 'Pend',
             y:  suma,
            
           };
   
           var novedadcash = {
             name: 'Novedad',
             y: 0,
           
           };
   
   
           var Exitosascash = {
             name: 'Exitosas',
             y: 0,
        
           };
  
          // this.orderService.createEstado(pendiente);
          // this.orderService.createEstado(novedad);
          // this.orderService.createEstado(Exitosas);
  
         // this.orderService.createCash(pendientecash);
        //  this.orderService.createCash(novedadcash);
        //  this.orderService.createCash(Exitosascash);

            //desde aca

           var novedadcliente = {
            detalle: 'Cliente no tiene Dinero',
            y: 0,
            id:1
       
          };
          this.orderService.createNovedades(novedadcliente);
          var novedadcliente = {
            detalle: 'Cliente no Existe',
            y: 0,
            id:2
       
          };
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Pedido Repetido',
            y: 0,
            id:3
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
          var novedadcliente = {
            detalle: 'Direccion Errada',
            y: 0,
            id:4
       
          };
          this.orderService.createNovedades(novedadcliente);
  
          var novedadcliente = {
            detalle: 'Mercancia no solicitada por el cliente',
            y: 0,
            id:5
       
          };
          this.orderService.createNovedades(novedadcliente);
  
          var novedadcliente = {
            detalle: 'Diferencia en negociacion',
            y: 0,
            id:6
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
      
          var novedadcliente = {
            detalle: 'No recibe hasta que le envien cambios',
            y: 0,
            id:7
       
          };
          this.orderService.createNovedades(novedadcliente);
  
          var novedadcliente = {
            detalle: 'Negocio Cerrado',
            y: 0,
            id:8
       
          };
  
  
          this.orderService.createNovedades(novedadcliente);
  
          
          var novedadcliente = {
            detalle: 'Producto no roto(Dev en buen estado)',
            y: 0,
            id:9
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Producto vencido Dev mal estado',
            y: 0,
            id:10
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Producto mal Presentado',
            y: 0,
            id:11
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Servicio deficiente en la entrega',
            y: 0,
            id:12
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Cliente no visitado',
            y: 0,
            id:13
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Carro Varado',
            y: 0,
            id:14
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Siniestro del transportador',
            y: 0,
            id:15
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
              var novedadcliente = {
            detalle: 'Desastres naturales',
            y: 0,
            id:16
       
          };
  
          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
        detalle: 'Orden publico',
        y: 0,
        id:17
   
      };




          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
        detalle: 'Producto facturado no despachado',
        y: 0,
        id:18
   
      };

          this.orderService.createNovedades(novedadcliente);
  
  
          var novedadcliente = {
            detalle: 'Sistemas de Informacion',
            y: 0,
            id:19
       
          };
    
              this.orderService.createNovedades(novedadcliente);



          var novedadcliente = {
            detalle: 'Calidad',
            y: 0,
            id:20
       
          };
    
              this.orderService.createNovedades(novedadcliente);




          var novedadcliente = {
            detalle: 'No cumple condiciones',
            y: 0,
            id:21
       
          };
    
              this.orderService.createNovedades(novedadcliente);


              var novedadcliente = {
                detalle: 'Baja Rotación',
                y: 0,
                id:22
           
              };
        
                  this.orderService.createNovedades(novedadcliente);
    

                  
                  var novedadcliente = {
                    detalle: 'Problema Logistico Cliente',
                    y: 0,
                    id:23
               
                  };
            
                      this.orderService.createNovedades(novedadcliente);
        
        
            

                  
                  var novedadcliente = {
                    detalle: 'Problema en Distribuciones.',
                    y: 0,
                    id:24
               
                  };
            
                      this.orderService.createNovedades(novedadcliente);


                  
                  var novedadcliente = {
                    detalle: 'Problema Logistico Parmalat',
                    y: 0,
                    id:25
               
                  };

            
                      this.orderService.createNovedades(novedadcliente);

                      var novedadcliente = {
                        detalle: 'Horario',
                        y: 0,
                        id:26
                   
                      };
                      
                
                          this.orderService.createNovedades(novedadcliente);


                          var novedadcliente = {
                            detalle: 'Temperatura',
                            y: 0,
                            id:27
                       
                          };
                          
                    
                              this.orderService.createNovedades(novedadcliente);


                              var novedadcliente = {
                                detalle: 'Cambios',
                                y: 0,
                                id:28
                           
                              };
                              
                        
                                  this.orderService.createNovedades(novedadcliente);


                                  var novedadcliente = {
                                    detalle: 'Orden cerrada/vencida',
                                    y: 0,
                                    id:29
                               
                                  };
                                  
                        

                                      this.orderService.createNovedades(novedadcliente);


                                      var novedadcliente = {
                                        detalle: 'Falta de mercadeo',
                                        y: 0,
                                        id:30
                                   
                                      };
                                      
                                
                                          this.orderService.createNovedades(novedadcliente);

                                          
                                      var novedadcliente = {
                                        detalle: 'Fuera de frecuencia',
                                        y: 0,
                                        id:31
                                   
                                      };
                                      
                                
                                          this.orderService.createNovedades(novedadcliente);


                                          var novedadcliente = {
                                            detalle: 'Carga critica insuficiente',
                                            y: 0,
                                            id:32
                                       
                                          };
                                          
                                    
                                              this.orderService.createNovedades(novedadcliente);


                                              var novedadcliente = {
                                                detalle: 'Fechas de vencimiento de los articilos despachados',
                                                y: 0,
                                                id:33
                                           
                                              };
                                              
                                        
                                                  this.orderService.createNovedades(novedadcliente);

                                                  var novedadcliente = {
                                                    detalle: 'sobre stock',
                                                    y: 0,
                                                    id:34
                                               
                                                  };
                                                  
                                            
                                                      this.orderService.createNovedades(novedadcliente);

                                                      var novedadcliente = {
                                                        detalle: 'Cambios de Factura y/o Periodo Cerrado',
                                                        y: 0,
                                                        id:35
                                                   
                                                      };
                                                      
                                                
                                                          this.orderService.createNovedades(novedadcliente);


                                                      var novedadcliente = {
                                                        detalle: 'Bonificaciones',
                                                        y: 0,
                                                        id:36
                                                  
                                                      };
                                                      
                                                
                                                          this.orderService.createNovedades(novedadcliente);



                                                      var novedadcliente = {
                                                        detalle: 'Cambios de Factura y/o Periodo Actual',
                                                        y: 0,
                                                        id:37
                                                   
                                                      };
                                                      
                                                
                                                          this.orderService.createNovedades(novedadcliente);

                                                          var novedadcliente = {
                                                            detalle: 'Producto facturado no despachado Industrial',
                                                            y: 0,
                                                            id:38
                                                       
                                                          };
                                                          
                                                    
                                                              this.orderService.createNovedades(novedadcliente);

                                                              var novedadcliente = {
                                                                detalle: 'Cliente no tenía los Cambios',
                                                                y: 0,
                                                                id:39
                                                           
                                                              };
                                                              
                                                        
                                                                  this.orderService.createNovedades(novedadcliente);


                                          


    
          });
    
    
          
        });

    
      
  
      
        this.uploadObj.clearAll();
     
    

      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  public onSuccess(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);

    console.log("archivo",files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  exportAsXLSX():void {

    this.orderService.getOrdersnovedades().subscribe(res => {
      this.datas=res;
      this.orderService.exportAsExcelFile(this.datas, 'novedades');
    }); 
 }

 exportAsXLSXpendientes():void {

  this.orderService.getOrderspedientes().subscribe(res => {
    this.datas=res;
    this.orderService.exportAsExcelFile(this.datas, 'pendientes');
  }); 
}


exportAsXLSXprimero():void {

  this.orderService.getinforme1().subscribe(res => {

  }); 
}

exportAsXLSXsegundo():void {

  this.orderService.getinforme2().subscribe(res => {
   

  }); 
}


exportAsXLSXtres():void {

  this.orderService.getinforme3().subscribe(res => {
   

  }); 
}




exportAsXLSXcuatro():void {

  this.orderService.getinforme4().subscribe(res => {
   

  }); 
}



exportAsXLSXgeneral():void {

  this.toastr.successToastr('Descarga en progreso.', 'Notificación');

  this.subscription=  this.orderService.getOrders().subscribe(res => {
    this.datas=res;
    this.orderService.exportAsExcelFile(this.datas, 'general');
    this.subscription.unsubscribe();


  
  }); 

  
 this.subscriptionDetalle= this.orderService.getParciales().subscribe(res => {
    console.log('detalle parcial',res)
    this.orderService.exportAsExcelFile(res, 'detalle parcial');
    

    this.subscriptionDetalle.unsubscribe();
  
  }); 
}

addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


  const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
this.toastr.successToastr("Cambio de fecha "+cValue, 'Notificación');
  localStorage.setItem("pedidoFecha", cValue);
  console.log("fecha q se guardo en el sistema", cValue);

  localStorage.setItem("cargar", 'exitoso');



}



deleteTicket(order: any){



  localStorage.setItem("tareafotoid", order.NumeroPed);

  this.toastr.successToastr('Tarea con fotos.', 'Notificación');
  this.route.navigate(['/fototarea']);
 

}

deleteTicket2(order: any){


  var  id=order.NumeroPed;

  const currentDate = new Date();
  const cValue  = localStorage.getItem("pedidoFecha");
 
  console.log('numero a buscar',id)




  if(order.estado=="NOVEDAD"){

    this.toastr.successToastr('Tarea con fotos.', 'Notificación');

  window.open("https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F"+cValue+"%2FE"+id +"2?alt=media", "_blank");
    

  }else{

    if(order.estado=="PARCIAL"){

      this.toastr.successToastr('Tarea con fotos.', 'Notificación');
  
    window.open("https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F"+cValue+"%2FE"+id +"2?alt=media", "_blank");
      
  
    }else{
  
      this.toastr.errorToastr('Tarea sin fotos.', '!Oops, algo salió mal!');
  
    }

  }


 

}


}
