import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { OrdersService } from 'src/app/shared/orders.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MapsAPILoader } from '@agm/core';
export interface DialogDataVh {
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}
@Component({
  selector: 'app-FragmentDetalleVehiculo',
  templateUrl: './FragmentDetalleVehiculo.component.html',
  styleUrls: ['./FragmentDetalleVehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentDetalleVehiculo implements OnInit {
  mapStyles: any[] = [
    {
      featureType: 'all',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#ffffff' }]
    },
    {
      featureType: 'all',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#000000' }, { lightness: 13 }]
    },
    // otros estilos personalizados
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('MatPaginatorDetalle', { static: false }) paginatordetelle: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private userd: AuthService,private orderService: OrdersService,private route: Router,private configuracionesService: TareasTemporalService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }


  zoom: number = 16;
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  sano: string;
  smes: string;
  sdia: string;
  vdia: string;
  vano: string;
  vmes: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string
  

  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public detalleCrearOrden: any;
  public pedidosCantidad: any=0;
  public detalleCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public numeroOperacionSiguiente: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  private valorTotalDetalleTemporal: any=0;
  public isaprobar = false;
  public ismenudetalle = false;
  public iscontenido = true;
  public ordenTransporte = "";
  private s_ano;
  private s_mes;
  private s_dia;
  private anos;
  private dias;
  private meses;
  subscription: Subscription;
  subscriptionenvio: Subscription;

   displayedColumns: string[] = ['pregunta','fecha','hora','respuesta','tarea','tipo','agencia','negocio'];
   dataSource = new MatTableDataSource();


   displayedColumnstareas: string[] = ['pregunta','tarea','fecha','hora','respuesta','tipo','agencia','negocio'];

    dataSourcetareas = new MatTableDataSource();
 
  ngOnInit(): void {
    this.ordenTransporte=localStorage.getItem("ordenTransporte");
 
   this.getAllOrders()

   this.dataSource = new MatTableDataSource();


  
   this.dataSourcetareas = new MatTableDataSource();
  }

  isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }
  
  isValidCoordinates(lat: string, lng: string): boolean {
    const latNum = parseFloat(lat);
    const lngNum = parseFloat(lng);
    return !isNaN(latNum) && isFinite(latNum) && !isNaN(lngNum) && isFinite(lngNum);
  }
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSourcetareas.paginator = this.paginatordetelle;
  }
  onZoomChange() {
    this.zoom=16
}

  onMapReady(map) {
    map.setOptions({
        zoomControl: 'true',
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            style: google.maps.ZoomControlStyle.DEFAULT
        }
    });
  }
  getAllOrders() {

    setTimeout(() => {
      this.iscargado = false;
      this.ismenu = true;
     }, 200);

     console.log("local encuenta",localStorage.getItem("rfvDetalleEncuesta"))
    this.subscription= this.orderService.getDetalleEncuentas(localStorage.getItem("rfvDetalleEncuesta")).subscribe(data => {
      this.dataSourcetareas.data = data;
      this.subscription.unsubscribe();
     
      console.log("rfvDetalleEncuesta",data)

    });
  }

  getAllOrdersCambio() {

    this.subscription= this.orderService.getOrders().subscribe(data => {
      
      this.dataSourcetareas = new MatTableDataSource();
      this.dataSourcetareas.data = data;
     
    this.toastr.successToastr('Tarea con fotos.', 'Notificación');
    this.subscription.unsubscribe();
     this.route.navigate(['/fototarea']);

    });
  }
  
 
  


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  applyFilterdetalle(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log("filterValue",filterValue)
    this.dataSourcetareas.filter = filterValue.trim().toLowerCase();
  }

  applyFilterdetallecambio() {
    const filterValue = "";
    console.log("filterValue",filterValue)
    this.dataSourcetareas.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {
    localStorage.setItem("codigoplanilla", tipo.planilla);
    this.route.navigate(['/PlanillaDetalle']);

  }

  recuado(tipo: any) {
    localStorage.setItem("codigoplanilla", tipo.planilla);
    this.route.navigate(['/RecaudoPlanilla']);

  }


  nuevo():void {
 
  
 
    }

  deletebanco(tipo: any) {

  
  }

  
  atras():void {

    this.route.navigate(['/Parqueadero']);
    
  }

  exportAsXLSXgeneral():void {

    this.toastr.successToastr('Descarga en progreso.', 'Notificación');
  


    this.subscription= this.orderService.getDetalleEncuentas(localStorage.getItem("rfvDetalleEncuesta")).subscribe(data => {
      this.datas=data;
      this.orderService.exportAsExcelFile(this.datas, 'encuentas_vrt_');
      this.subscription.unsubscribe();

    });
    
  

  }
  
  fotos(order: any){
    console.log("fotos",order)
    window.open(order.respuesta, '_blank');
   
  
  }
}
