import { MatTableDataSource, MatPaginator } from '@angular/material';
import { PedidoService } from '../../shared/pedidos.service';
import { AprobacionService } from '../../shared/aprobacion.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject, ElementRef } from '@angular/core';
import * as Mapboxgl from 'mapbox-gl';
import { ClientesService } from '../../shared/clientes.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-fragmentDetalle',
  templateUrl: './fragmentDetalle.component.html',
  styleUrls: ['./fragmentDetalle.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentDetalle implements OnInit {
  mapa: Mapboxgl.Map
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns: string[] = ['Cod','Art','IVA', 'UM','Cant','total_before','total'];
  dataSource = new MatTableDataSource();

  private txtCliente: any;
  private txtNit: any;
  private txtCorreo: any;
  private txtTel: any;
  private txtDireccion: any;

  private txtDescuento=0;
  private txtBaseantes=0;
  private txtIva=0;
  private txtTotal=0;
  private txtBase=0;

  private cliente: any;
  private clienteAp: any;

  subscriptionruta: Subscription;
  subscriptioncodigo: Subscription;
  subscriptiondetalle: Subscription;
  myData = [
    { data: [1, 1, 3], name: "first" }
  ];

  private path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }

  constructor(private pedidosService: PedidoService, private clientesService: ClientesService, public toastr: ToastrManager, private route: Router) {

    this.cliente = localStorage.getItem('Detalle')

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }



  ngOnInit(): void {

    localStorage.setItem("rutaindicador", "si");
    if (localStorage.getItem('vistaShow') == 'SHOWN') {
  
      this.descargar();
      setInterval(() => {
  
        this.descargar();
  
      }, 60000);
  
    } else {
  
  
      this.subscriptiondetalle.unsubscribe();
  
  
    }
    this.cliente = localStorage.getItem('Detalle')

    this.pedidosService.getPedidosRfv(this.cliente).subscribe(clientesaprobar => {

      console.log(" no trae cliente)",clientesaprobar);

      Mapboxgl.accessToken = environment.mapboxkey;
      this.mapa = new Mapboxgl.Map({
        container: 'mapa-mapbox', // container id
        style: 'mapbox://styles/mapbox/streets-v11'
      });

      for (let elemento of clientesaprobar) {

        console.log("elemento mapas " + elemento);
        console.log("elemento lon " + elemento.lonEnvio)
        console.log("elemento lat " + elemento.latEnvio)

        this.clienteAp = elemento

        this.txtCliente = elemento.razon
        this.txtCorreo = elemento.correo
        this.txtDireccion = elemento.dir
        this.txtNit = elemento.identificacion
        this.txtTel = elemento.tel

        this.txtBaseantes=elemento.total_before
        this.txtTotal=elemento.total
        this.txtIva=elemento.total-elemento.total_before

        var marker = new Mapboxgl.Marker({
          color: 'blue',
          draggable: false

        })



          .setPopup(new Mapboxgl.Popup({ closeOnClick: false })
            .setHTML('<h3><h6>Envio<h6>' + elemento.razon.toUpperCase() + '</h3><p>'))

          .setLngLat([elemento.lonEnvio, elemento.latEnvio])
          .addTo(this.mapa)

        this.mapa.flyTo({
          center: [
            elemento.lonEnvio, elemento.latEnvio
          ], zoom: 14,
          essential: true
        });



        marker.getElement().addEventListener('click', () => {


          this.mapa.flyTo({
            center: [
              elemento.lonEnvio, elemento.latEnvio
            ], zoom: 20,
            essential: true
          });



        });


        var marker = new Mapboxgl.Marker({
          color: 'red',
          draggable: false

        })



          .setPopup(new Mapboxgl.Popup({ closeOnClick: false })
            .setHTML('<h3><h6>Cliente<h6>' + elemento.razon.toUpperCase() + '</h3><p>'))

          .setLngLat([elemento.lonCliente, elemento.latCliente])
          .addTo(this.mapa)

        this.mapa.flyTo({
          center: [
            elemento.lonCliente, elemento.latCliente
          ], zoom: 14,
          essential: true
        });


        marker.getElement().addEventListener('click', () => {


          this.mapa.flyTo({
            center: [
              elemento.lonCliente, elemento.latCliente
            ], zoom: 20,
            essential: true
          });



        });


        var marker = new Mapboxgl.Marker({
          color: 'orange',
          draggable: false

        })



          .setPopup(new Mapboxgl.Popup({ closeOnClick: false })
            .setHTML('<h3><h6>Pedido<h6>' + elemento.razon.toUpperCase() + '</h3><p>'))

          .setLngLat([elemento.lonPedido, elemento.latPedido])
          .addTo(this.mapa)

        this.mapa.flyTo({
          center: [
            elemento.lonPedido, elemento.latPedido
          ], zoom: 14,
          essential: true
        });


        marker.getElement().addEventListener('click', () => {


          this.mapa.flyTo({
            center: [
              elemento.lonPedido, elemento.latPedido
            ], zoom: 20,
            essential: true
          });



        });

      }

      
    });

  

  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  descargar() {
    this.subscriptiondetalle = this.pedidosService.getPedidosDetalleRfv(this.cliente).subscribe(detalle => {
      console.log("pedido q tieen los detalles q detalle", detalle);
      this.dataSource.data = detalle;
      this.subscriptiondetalle.unsubscribe();
  
    });
  }




}
