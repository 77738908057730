import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { PedidoService } from '../../shared/pedidos.service';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Subscription } from 'rxjs';
import { ClientesService } from '../../shared/clientes.service';
import { ClientesaprobacionService } from '../../shared/clientesaprobacion.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';


@Component({
  selector: 'app-FragmentIndicadoresRuteros',
  templateUrl: './FragmentIndicadoresRuteros.component.html',
  styleUrls: ['./FragmentIndicadoresRuteros.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoresRuteros implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
 
  displayedColumns: string[] = ['ruta', 'y','nuevos','pedidos','novedades','pendientes','c','txPedidosPorMC','x','txEfePenPorMC'];

 
  private rutasDescarga: any;
  private ClientesNuevos: any;

  subscription: Subscription;
  subscriptionNuevos: Subscription;
  subscriptionVisitas: Subscription;
  subscriptioninforme: Subscription;

  private txtTotalVisitas: any;
  private txtPedidos: any;
  private txtNovedad: any;
  private txtPendiente: any;
  private txtRutas: any;
  private txtVenta: any;
  private txtNuevos: any;
  private txtClientes:any;
  private txtFecha:any;
  private datosNovCan: any;
  private datosPedidosRutas: any;
  private datosNov: any;
  private datosPend: any;
  private datosCliente: any;

 

  dataSourceEstado = new MatTableDataSource();
  
  constructor(private ClientesService: ClientesService, public toastr: ToastrManager, public pedidosService: PedidoService,public clientesaprobacionService: ClientesaprobacionService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });
  }



  ngOnInit(): void {

    this.txtFecha= localStorage.getItem("pedidoFecha");
 
    if (localStorage.getItem('rutaindicador') == 'si') {

      localStorage.setItem("rutaindicador", "no");

      
   


    }


    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      this.descargar();
      setInterval(() => {

        this.descargar();
      
      }, 60000);

    } else {


      this.subscription.unsubscribe();


    }


  }

  ngAfterViewInit() {
    
    this.dataSourceEstado.paginator = this.paginator;
   
  }

  descargar() {
    var numero=0

    numero=parseInt(localStorage.getItem('diaConsulta'))

      this.subscription = this.ClientesService.getClientesDia(numero).subscribe(rutas => {
      this.rutasDescarga = this.rutasDescarga = rutas;
      this.txtClientes=rutas.length
      this.subscription.unsubscribe();


      this.subscriptionVisitas = this.clientesaprobacionService.getClienteNuevosDia(localStorage.getItem('fechaNuevos') ).subscribe(clientes => {
        this.txtNuevos=clientes.length
        this.ClientesNuevos=clientes
        this.subscriptionVisitas.unsubscribe();
        this.getTareas();

      
      });
    
    });


  }


  getVista(datosCliente:any){

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(datosCliente, 'idempleado')

    let rutasClientes = []
    this.txtPendiente=0
    for (let item of Object.keys(rutasdia)) {
      var rutasODL = rutasdia[item];

  
      let tareasNovedadeS = rutasODL.filter(tarea => tarea.name == "Novedad")
      let tareasPedidos = rutasODL.filter(tarea => tarea.name == "Pedido")
      let Clientes = this.rutasDescarga.filter(cli => cli.idempleado == rutasODL[0].idempleado)
      let nuevos = this.ClientesNuevos.filter(cli => cli.idUsr == rutasODL[0].idempleado)
      this.txtPendiente=this.txtPendiente+Clientes.length-tareasNovedadeS.length-tareasPedidos.length

      var ruta = {
        ruta: rutasODL[0].idempleado,
        name: rutasODL[0].idempleado,
        y:Clientes.length,
        x:Clientes.length,
        c:Clientes.length,
        nuevos:nuevos.length,
        novedades:tareasNovedadeS.length,
        pedidos:tareasPedidos.length,
        pendientes:Clientes.length-tareasNovedadeS.length-tareasPedidos.length,
        txPedidosPorMC: Math.round(((tareasPedidos.length * 100) / Clientes.length)) + "%",
        txtPendientesPorMC: Math.round((((Clientes.length-tareasPedidos.length)  * 100) / Clientes.length)) + "%",
        txEfePorMC: Math.round((((tareasPedidos.length+tareasNovedadeS.length) * 100) / Clientes.length)) + "%",
        txEfePenPorMC: Math.round((((Clientes.length-(tareasPedidos.length+tareasNovedadeS.length))  * 100) / Clientes.length)) + "%",
   

      };

      rutasClientes.push(ruta)
      //this.ClientesService.createRutasOdl(ruta)
    
    }

    let rutasclientesP = groupBy(this.rutasDescarga, 'idempleado')
  
    for (let item of Object.keys(rutasclientesP)) {
      var rutasODL = rutasclientesP[item];
      let Clientes =rutasClientes.filter(cli => cli.name == rutasODL[0].idempleado)
      let nuevos = this.ClientesNuevos.filter(cli => cli.idUsr == rutasODL[0].idempleado)
     
      let ClientesDescarga = this.rutasDescarga.filter(cli => cli.idempleado == rutasODL[0].idempleado)
      if(Clientes.length>0){


      }else{

        this.txtPendiente=this.txtPendiente+ClientesDescarga.length

        var rutape = {
          ruta: rutasODL[0].idempleado,
          name: rutasODL[0].idempleado,
          y:ClientesDescarga.length,
          x:ClientesDescarga.length,
          c:ClientesDescarga.length,
          nuevos:nuevos.length,
          novedades:0,
          pedidos:0,
          pendientes:ClientesDescarga.length,
          txPedidosPorMC: Math.round(((0 * 100) / ClientesDescarga.length)) + "%",
          txtPendientesPorMC: Math.round((((ClientesDescarga.length-0)  * 100) / ClientesDescarga.length)) + "%",
          txEfePorMC: Math.round((((0) * 100) / ClientesDescarga.length)) + "%",
          txEfePenPorMC: Math.round((((ClientesDescarga.length-(0))  * 100) / ClientesDescarga.length)) + "%",
     
  
        };
  
        rutasClientes.push(rutape)

      }
   
    }

    this.dataSourceEstado.data=rutasClientes


  }

  getTareas() {

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(this.rutasDescarga, 'idempleado')

    let vistasClientes = []
    this.txtPedidos=0;
    this.txtNovedad=0;
    this.txtPendiente=0;
    this.txtRutas=0
    this.txtVenta=0
    this.txtTotalVisitas=0

  
    let rutasClientes = []
    for (let item of Object.keys(rutasdia)) {
      this.txtRutas=this.txtRutas+1
   
    }

    this.subscriptionVisitas = this.pedidosService.getPedidos().subscribe(visitas => {
   
        this.txtTotalVisitas= visitas.length
      for (let visita of visitas){

        if(visita.fnt==20){

          this.txtNovedad=  this.txtNovedad+1

          var vistacliente = {
  
            idEmpresa: visita.idEmp,
            cliente: visita.razon,
            total: visita.total,
            tipo: visita.fnt,
            obs: visita.obs,
            idempleado:visita.usr,
            name:"Novedad"
          };

          vistasClientes.push(vistacliente)
    
         // this.ClientesService.createVistasOdl(vistacliente)
   
     
        }

        if(visita.fnt==10){
          
          this.txtPedidos=  this.txtPedidos+1
          this.txtVenta=  this.txtVenta+ visita.total

          var vistacliente = {
  
            idEmpresa: visita.idEmp,
            cliente: visita.razon,
            total: visita.total,
            tipo: visita.fnt,
            obs: visita.obs,
            idempleado:visita.usr,
            name:"Pedido",
  
  
          };
    
          vistasClientes.push(vistacliente)
         // this.ClientesService.createVistasOdl(vistacliente)
         
        }


       }

       this.subscriptionVisitas.unsubscribe();
      this.getVista(vistasClientes)
  

    });


  }


  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {

    const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
    const fechaNuevos = formatDate(event.value, 'yyyy/MM/dd', 'en-US');
    this.toastr.successToastr("Cambio de fecha "+cValue, 'Notificación');
    localStorage.setItem("pedidoFecha", cValue);
    localStorage.setItem("fechaNuevos", fechaNuevos);
    localStorage.setItem("cargar", 'exitoso');

    var currentDia = event.value.getDay();
    currentDia=currentDia+1
    localStorage.setItem("diaConsulta", currentDia.toString());

    this.descargar();

  }


}

