import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { IndicadoresService } from '../../indicadores.service';
@Component({
  selector: 'app-widget-tiponovedad',
  templateUrl: './tiponovedad.component.html',
  styleUrls: ['./tiponovedad.component.scss']

})
export class TipoNovedadComponent implements OnInit {
  subscription: Subscription;
  @Input() data: any = [];
  updateFlag = false;
  myConfiges: any
  enviados=0
  operacion=0
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0

  constructor(private indicadoresService: IndicadoresService) { }


  ngOnInit(): void {
    this.descargarIndicadores()
  }

  descargarIndicadores() {
    this.subscription = this.indicadoresService.getIndicadores().subscribe(tareasindicadores => {
   
      console.log("tareasindicadores",tareasindicadores);
      
      for (let tarea of tareasindicadores) {

        if(tarea.estado=="PENDIENTE"){

          this.estadoPendiente=this.estadoPendiente+1
      
         }

         if(tarea.estado=="EXITOSA"){

          this.estadoExitosa=this.estadoExitosa+1
      
         }


         if(tarea.estado=="NOVEDAD"){

          this.estadoNovedad=this.estadoNovedad+1
      
         }


         if(tarea.estado=="REPISAR"){

          this.estadoRepisar=this.estadoRepisar+1
      
         }

         if(tarea.estado=="PARCIAL"){

          this.estadoParcial=this.estadoParcial+1
      
         }

      }

      this.myConfiges = {
        type: "ring",
        
        plot: {
          borderColor: "#2B313B",
          borderWidth: 0.8,
           slice: "50%",
       
          tooltip: {
            fontSize: '18',
            fontFamily: "Open Sans",
            padding: "5 10",
            text: "%npv%"
          },
          animation: {
            effect: 2,
            method: 5,
            speed: 900,
            sequence: 1,
            delay: 3000
          }
        },
        source: {
          text: '',
          fontColor: "#8e99a9",
          fontFamily: "Open Sans"
        },
    
        plotarea: {
          margin: "0 0 0 0"
        },
        series: [{
            values: [this.estadoNovedad],
            text: "",
            backgroundColor: '#fbca4f',
          },
          {
            values: [this.estadoParcial],
            text: '',
            backgroundColor: '#9dbebb',
            detached: true
          },
          {
            text: '',
            values: [this.estadoRepisar],
            backgroundColor: '#77aca2'
          }
        ]
      };

    });
  }

}
