
import { ConfiguracionesService } from '../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';

export interface DialogData {
  idBanco: string;
  banco: string;
}

@Component({
  selector: 'app-DialogAgregarBanco',
  templateUrl: './DialogAgregarBanco.component.html',
  styleUrls: ['./DialogAgregarBanco.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarBanco implements OnInit {
  idBanco: Number;
  banco: string;
  constructor(public dialogRef: MatDialogRef<DialogAgregarBanco>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearBanco(data: any) {
     
    var operacion = {
      banco:   data.idBanco,

    }

    if(data.banco) {

        this.configuracionesService.crearBanco(operacion)

        var texto = data.banco;
        var mayus = texto.substring(0, 1).toUpperCase();
        var resto = texto.substring(1, texto.length).toLowerCase();
        mayus.concat(resto.toString());
  
        var banco = {
          banco:mayus.concat(resto.toString()),
          idBanco:data.idBanco
    
        }
  
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          idBanco:data.idBanco,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
          actual:  mayus.concat(resto.toString()),
          cambio: mayus.concat(resto.toString()),
        }
    
        this.configuracionesService.crearAuditriaBancos(auditria)
        this.configuracionesService.createMaestroConfiguracion(banco)
        this.toastr.successToastr("Banco: "+mayus.concat(resto.toString())+ " creado con éxito", 'Notificación');
        this.dialogRef.close();

    }else{
      this.toastr.errorToastr("Ingresar banco", 'Notificación');

    } 



  }
  
}
