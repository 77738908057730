import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { ActulizacionService } from '../../shared/actulizacion.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import * as Mapboxgl from 'mapbox-gl';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-fragmentActulizacion',
  templateUrl: './fragmentActulizacion.component.html',
  styleUrls: ['./fragmentActulizacion.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentActulizacion implements OnInit {
  mapa: Mapboxgl.Map
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;

  private txtCliente: any;
  private txtNit: any;
  private txtCorreo: any;
  private txtTel: any;
  private txtDireccion: any;

  private cliente: any;
  private clienteAp: any;
  private img: any;


  subscriptionruta: Subscription;
  subscriptioncodigo: Subscription;
  myData = [
    { data: [1, 1, 3], name: "first" }
  ];

  private path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }

  constructor(private orderService: OrdersService, private clienteserveAprobar: ActulizacionService, private userd: AuthService, public toastr: ToastrManager, private route: Router) {

    this.cliente = localStorage.getItem('clienteActulizar')

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }



  ngOnInit(): void {

    this.cliente = localStorage.getItem('clienteActulizar')

    console.log("clienteActulizar",    this.cliente);

    this.clienteserveAprobar.getClienteAprobacionRfv(this.cliente).subscribe(clientesaprobar => {

      console.log(" no trae cliente)" + this.cliente);

      Mapboxgl.accessToken = environment.mapboxkey;
      this.mapa = new Mapboxgl.Map({
        container: 'mapa-mapbox', // container id
        style: 'mapbox://styles/mapbox/streets-v11'
      });

      for (let elemento of clientesaprobar) {
        const currentDate = new Date();
        const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
        console.log("elemento mapas " + elemento);
        console.log("elemento lon " + elemento.lon)
        console.log("elemento lat " + elemento.lat)

        this.clienteAp = elemento

        this.txtCliente = elemento.nmbCmn
        this.txtCorreo = elemento.mail
        this.txtDireccion = elemento.direccion
        this.txtNit = elemento.idnt
        this.txtTel = elemento.tel
        this.img="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/FotosLocal%2F"+cValue+"%2FE"+this.cliente +"?alt=media"

        var marker = new Mapboxgl.Marker({
          color: 'blue',
          draggable: false

        })



          .setPopup(new Mapboxgl.Popup({ closeOnClick: false })
            .setHTML('<h3>' + elemento.nmbCmn.toUpperCase() + '</h3><p>' + '<h6>Hora<h6>' + elemento.fc))

          .setLngLat([elemento.lon, elemento.lat])
          .addTo(this.mapa)

        this.mapa.flyTo({
          center: [
            elemento.lon, elemento.lat
          ], zoom: 18,
          essential: true
        });


        marker.getElement().addEventListener('click', () => {


          this.mapa.flyTo({
            center: [
              elemento.lon, elemento.lat
            ], zoom: 20,
            essential: true
          });



        });



      }

    });

  }


  ngAfterViewInit() {

  }

  aprobar() {


    this.subscriptioncodigo =   this.clienteserveAprobar.getClienteCodigo().subscribe(codigos => {

      this.subscriptioncodigo.unsubscribe();
      console.log(" no trae cliente)" + this.cliente);

      var dateDay = new Date().getDay();

      dateDay=dateDay+1
    for (let elemento of codigos) {
    
        var clienteAprobar = {
          codigo: "CL"+this.cliente,
          id_empresa: this.cliente,
          dia: dateDay,
          id_lista_precio: 20,
          idempleado: this.clienteAp.idUsr,
          identificacion: this.clienteAp.idnt,
          latitud: this.clienteAp.lat,
          longitud: this.clienteAp.lon,
          nombre_comun: this.clienteAp.nmbCmn,
          razon_social: this.clienteAp.nmbCmn,
          tipoId: "CC",
        };

        var contactoAprobar = {
          IdDir: this.cliente,
          codigo: "CT"+this.cliente,
          correo: this.clienteAp.mail,
          dir: this.clienteAp.direccion,
          id_cnto: this.cliente,
          id_empresa: this.cliente,
          idempleado:this.clienteAp.idUsr,
          nmbr: this.clienteAp.nmbCmn,
          tel: this.clienteAp.tel
        };

        var ruteroAprobar = {
          dia: dateDay,
          idRuta: this.cliente,
          id_empresa: this.cliente,
          idempleado: this.clienteAp.idUsr,
          orden: 1
        };
    
        console.log("clienta aprobar", clienteAprobar);
    
        this.clienteserveAprobar.createCliente(clienteAprobar)

        this.clienteserveAprobar.createContacto(contactoAprobar)

       // this.clienteserveAprobar.createRutero(ruteroAprobar)

        this.clienteAp.estado="APROBADO"
        this.clienteserveAprobar.update(this.clienteAp)

    
        this.toastr.successToastr("APROBADO "+this.clienteAp.nmbCmn, 'Notificación');
        this.route.navigate(['/clientesActulizacion']);

      }


    });



  }


  rechazar() {

    this.clienteAp.estado="RECHAZADO"
    this.clienteserveAprobar.update(this.clienteAp)

    this.toastr.successToastr("RECHAZADO "+this.clienteAp.nmbCmn, 'Notificación');
    this.route.navigate(['/clientesActulizacion']);



  }
}
