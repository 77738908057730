
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';

export interface DialogData {
  idTipo: string;
  tipo: string;
  descripcion: string;
}

@Component({
  selector: 'app-DialogAgregarTipoVehiculo',
  templateUrl: './DialogAgregarTipoVehiculo.component.html',
  styleUrls: ['./DialogAgregarTipoVehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAgregarTipoVehiculo implements OnInit {
  idTipo: string;
  tipo: string;
  descripcion: string;
  public marcas = new Array();
  constructor(public dialogRef: MatDialogRef<DialogAgregarTipoVehiculo>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 
  
  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearTipoDocumento(data: any) {
   

    console.log('tipodocumento',data);
    if(data.tipo) {

      if(data.descripcion) {

          
    var conop = {
      convh: data.idTipo+1

    }

    this.configuracionesService.incrementarVh(conop)

    var des = data.tipo;
    var may = des.substring(0, 1).toUpperCase();
    var res = des.substring(1, des.length).toLowerCase();

    var tipo = may.concat(res.toString())
        
  
        var descri = data.descripcion;
        var mayusd = descri.substring(0, 1).toUpperCase();
        var restod = descri.substring(1, descri.length).toLowerCase();
        mayusd.concat(restod.toString());

    
        var tipoDoc = {
          idTipo: data.idTipo,
          tipo: tipo,
          descripcion:    mayusd.concat(restod.toString())
    
        }
  
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.idTipo,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
          tipoanterior: tipo,
          tiponuevo: tipo,
          descractual:mayusd.concat(restod.toString()),
          descranterior:mayusd.concat(restod.toString()),
        }
    
        this.configuracionesService.crearAuditriaTipoVh(auditria)
        this.configuracionesService.crearTipoVh(tipoDoc)
        this.toastr.successToastr("Tipo Vehículos: "+tipo+ " creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Ingresar Vehículos", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Ingresar tipo Vehículos", 'Notificación');

    } 



  }
  
}
