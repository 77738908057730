
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';


export interface DialogDataVh {
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
  sano: string;
  smes: string;
  sdia: string;
  vdia: string;
  vano: string;
  vmes: string;
}

@Component({
  selector: 'app-DialogEditarVehiculo',
  templateUrl: './DialogEditarVehiculo.component.html',
  styleUrls: ['./DialogEditarVehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogEditarVehiculo implements OnInit {
  idTipo: string;
  tipo: string;
  descripcion: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public soatvenfm;
  public tecnovenfm;
  public estados;
  public estadofm;
  public imgEstadofm;
  public estadodata;
  public modelos;
  public modelofm;
  public clases;
  public clasefm;
  private s_ano;
  private s_mes;
  private s_dia;
  private v_ano;
  private v_mes;
  private v_dia;
  private anos;
  private dias;
  private meses;
  dateFormControl = new FormControl(new Date())
  constructor(public dialogRef: MatDialogRef<DialogEditarVehiculo>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager) {}


  ngOnInit(): void {

 this.descargarMarcas()
 this.descargarConfiguracion()
 this.descargarEstados()
 this.descargarModelos()
 this.descargarClase()
 this.descargaranos()
 this.descargardias()
 this.descargarmeses()

 this.marcafm=this.data.marca
 this.confifm=this.data.configuracion
 this.estadofm=this.data.estado
 this.modelofm=this.data.modelo
 this.clasefm=this.data.clase

 this.s_dia=this.data.sdia
 this.s_mes=this.data.smes
 this.s_ano=this.data.sano
 
 this.v_ano=this.data.vano
 this.v_dia=this.data.vdia
 this.v_mes=this.data.vmes
 

 if(this.data.estado=="Activo"){
  this.imgEstadofm="/assets/activo.png"
}

if(this.data.estado=="Bloqueado"){
    this.imgEstadofm="/assets/bloqueado.png"
   }

   if(this.data.estado=="Novedad"){
    this.imgEstadofm="/assets/novedad.png"
   }

   if(this.data.estado=="Inactivo"){
    this.imgEstadofm="/assets/inactivo.png"
   }

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }
 
  crearTipoDocumento(data: any) {
   


    if(data.placa && 
      this.modelofm &&  
      this.marcafm &&
      data.aseguradora &&
      data.linea &&
     this.s_ano &&
     this.s_mes &&
     this.s_dia &&
     this.v_ano &&
     this.v_mes &&
     this.v_dia &&
     data.version &&
     data.carga &&
     data.volumen &&
     this.confifm &&
     this.estadofm &&
     this.clasefm) {

      if(data.placa) {

  

    var aseguradora = data.aseguradora;
    var mayus = aseguradora.substring(0, 1).toUpperCase();
    var resto = aseguradora.substring(1, aseguradora.length).toLowerCase();
    mayus.concat(resto.toString());

    
    var linea = data.linea;
    var mayuslinea = linea.substring(0, 1).toUpperCase();
    var restolinea = linea.substring(1, linea.length).toLowerCase();
    mayuslinea.concat(restolinea.toString());
        var tipoDoc = {
          id: data.id,
          placa: data.placa,
          modelo:   this.modelofm,
          marca: this.marcafm,
          aseguradora:  mayus.concat(resto.toString()),
          sdia: this.s_dia ,
          smes:  this.s_mes,
          sano:  this.s_ano,
          vdia: this.v_dia ,
          vmes:  this.v_mes,
          vano:  this.v_ano,
          linea:  mayuslinea.concat(restolinea.toString()),
          version: data.version,
          carga: data.carga,
          volumen: data.volumen,
          configuracion: this.confifm,
          estado: this.estadofm,
          imgEstado: this.imgEstadofm,
          clase:this.clasefm,
          idTercero: data.idTercero,
          idPropietario: data.idPropietario,
          nombrePropietario: data.nombrePropietario,
          apellidosPropietario: data.apellidosPropietario,
          idTerceroTenedor: data.idTerceroTenedor,
          idTenedor: data.idTenedor,
          nombreTenedor: data.nombreTenedor,
          apellidosTenedor: data.apellidosTenedor,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
        }
  
        console.log('tipodocumento',tipoDoc);
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.id,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Editar',
          placa: data.placa,
          modelo:   this.modelofm,
          marca: this.marcafm,
          aseguradora:  mayus.concat(resto.toString()),
          sdia: this.s_dia ,
          smes:  this.s_mes,
          sano:  this.s_ano,
          vdia: this.v_dia ,
          vmes:  this.v_mes,
          vano:  this.v_ano,
          linea:  mayuslinea.concat(restolinea.toString()),
          version: data.version,
          carga: data.carga,
          volumen: data.volumen,
          configuracion: this.confifm,
          estado: this.estadofm,
          imgEstado: this.imgEstadofm,
          clase:this.clasefm,
          idTercero: data.idTercero,
          idPropietario: data.idPropietario,
          nombrePropietario: data.nombrePropietario,
          apellidosPropietario: data.apellidosPropietario,
          idTerceroTenedor: data.idTerceroTenedor,
          idTenedor: data.idTenedor,
          nombreTenedor: data.nombreTenedor,
          apellidosTenedor: data.apellidosTenedor,
          agencia:localStorage.getItem("agencia"),
          negocio: localStorage.getItem("negocio"),
        }
    
       this.configuracionesService.crearAuditriaVehiculo(auditria)
        this.configuracionesService.crearVehiculonv(tipoDoc)
        this.toastr.successToastr("Vehículo:  creado con éxito", 'Notificación');
        this.dialogRef.close();

      }else{
        this.toastr.errorToastr("Ingresar Vehículo", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Todos los campos de un formulario son obligatorios", 'Notificación');

    } 



  }

  descargarMarcas() {

    this.configuracionesService.getTiposMarcas().subscribe(configuraciones => {
  

      this.marcas=configuraciones
    
  
    
    });
  }


  descargarClase() {

    this.configuracionesService.getClasesVehiculos().subscribe(configuraciones => {
  

      this.clases=configuraciones
    
  
    
    });
  }

  descargarModelos() {

    this.configuracionesService.getModelos().subscribe(configuraciones => {
  
      this.modelos=configuraciones
      console.log('modelos',configuraciones);
    });
  }

  descargarConfiguracion() {

    this.configuracionesService.getconfiguracionesVh().subscribe(configuraciones => {
  

      this.confisvh=configuraciones
    
  
    
    });
  }

  descargarEstados() {

    this.configuracionesService.getEstado().subscribe(configuraciones => {
  

      this.estados=configuraciones
    
  
    
    });
  }

  smarca(marca: any) {

    this.marcafm=marca
   console.log('marcafm',    this.marcafm);
  }


  smodelo(modelo: any) {

    this.modelofm=modelo
   
  }
  sconfi(confi: any) {

    this.confifm=confi
  
  }


  sestado(estado: any) {

    this.estadofm=estado

    if(estado=="Activo"){
         this.imgEstadofm="/assets/activo.png"
    }

    if(estado=="Bloqueado"){
      this.imgEstadofm="/assets/bloqueado.png"
    }

    if(estado=="Novedad"){
      this.imgEstadofm="/assets/novedad.png"
    }

  if(estado=="Inactivo")
      {
    this.imgEstadofm="/assets/inactivo.png"
   }

  }

  sclase(clase: any) {

    this.clasefm=clase

  }



  
  sfdia(dato: any) {

    this.s_dia=dato

  }
  sfmes(dato: any) {

    this.s_mes=dato

  }

  sfano(dato: any) {

    this.s_ano=dato

  }


  svdia(dato: any) {

    this.v_dia=dato

  }
  svmes(dato: any) {

    this.v_mes=dato

  }

  svano(dato: any) {

    this.v_ano=dato

  }

  descargarmeses() {

    this.configuracionesService.getMes().subscribe(configuraciones => {

      this.meses=configuraciones
  
  
    });
  }

  descargardias() {

    this.configuracionesService.getdia().subscribe(configuraciones => {

      this.dias=configuraciones
  

    });
  }

  descargaranos() {

    this.configuracionesService.getanos().subscribe(configuraciones => {

      this.anos=configuraciones
  
   
    });
  }
}
