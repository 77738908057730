import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { DialogAprobarPlanilla } from '../dialogAprobarPlanilla/dialogAprobarPlanilla.component';
import { DialogAprobarConsignacion } from '../dialogAprobarConsignacion/dialogAprobarConsignacion.component';
export interface DialogDataVh {
  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  vensoat: string;
  ventecno: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
}
@Component({
  selector: 'app-FragmentPlanillaRecaudo',
  templateUrl: './FragmentPlanillaRecaudo.component.html',
  styleUrls: ['./FragmentPlanillaRecaudo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentPlanillaRecaudo implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: TareasTemporalService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }

  id: string;
  placa: string;
  modelo: string;
  marca: string;
  aseguradora: string;
  sano: string;
  smes: string;
  sdia: string;
  vdia: string;
  vano: string;
  vmes: string;
  linea: string;
  version: string;
  carga: string;
  volumen: string;
  configuracion: string;
  estado: string;
  clase: string;
  idTercero: string;
  idPropietario: string;
  nombrePropietario: string;
  apellidosPropietario: string
  

   displayedColumns: string[] = ['banco','estado','fecha','nombre','recibo','referenciaBanco','cuenta','tipo','valor','editar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {
   var ordenTransporte=localStorage.getItem("codigoplanilla");
   console.log("codigoplanilla",ordenTransporte);
   this.descargarConfiguraciones(ordenTransporte)

  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones(ordentransporte: string) {

    this.configuracionesService.getRecaudoPlanilla(ordentransporte).subscribe(configuraciones => {
      console.log("configuraciones",configuraciones);
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }



  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(consignacion: any) {

    this.dialogagregar.open(DialogAprobarConsignacion, {
      width: '580px',
      maxHeight:'750px',
      data: {banco:consignacion.banco,
        convenio:consignacion.convenio,
        cuenta:consignacion.cuenta,
        fecha:consignacion.fecha,
        nombre:consignacion.nombre,
        recibo:consignacion.recibo,
        referenciaBanco:consignacion.referenciaBanco,
        tipo:consignacion.tipo,
        valor:consignacion.valor
      },
    
    });

  }

  aprobarPlanilla(tipo):void {
 
  
 
    }

  deletebanco(tipo: any) {

  
  }

  
  atras():void {

    this.route.navigate(['/Planilla']);
    
  }
  
}
