import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-fragmentIndicadores',
  templateUrl: './fragmentIndicadores.component.html',
  styleUrls: ['./fragmentIndicadores.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadores implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;

  public negocio: any;
  public agencia: any;
  public usuario: any;
  public txtTotalFac: any;
  public txtTotalFacRuta: any;
  public txtPesoKG: any;
  public txtPesoKGDe: any;
  public txtPedidos: any;
  public txtPedidosRuta: any;
  public txtDias: any;
  public txtRutas: any;
  public txtNovedades: any;
  public txtPedidosDev: any;
  public txtPedidosDevZona: any;
  public txtCanGrupo: any;
  public txtDevGrupo: any;
  public txtGrupoPedidos: any;
  public txtGrupoDev: any;
  public txttipoNovedad: any;
  public txtFecha: any;


  //novedades

  public txtCanCliente: any;
  public txtCanComercial: any;
  public txtCanPlanta: any;
  public txtCanTransporte: any;
  public txtCanTerceros: any;

  public txtCosCliente: any;
  public txtCosComercial: any;
  public txtCosPlanta: any;
  public txtCosTransporte: any;
  public txtCosTerceros: any;

  public totalParcial: any;
  public canParcial: any;

  public totalParcialExi: any;
  public canParcialExi: any;

  public totalExitoso: any;
  public canExitoso: any;

  public totalPendiente: any;
  public canPendiente: any;

  public totalNovedad: any;
  public canNovedad: any;

  public totalGeneral: any;
  public canGeneral: any;

  public datospruebas: any;

  myData = [
    { data: [1, 1, 3], name: "first" }
  ];

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }

  constructor(private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager) {

  }

  displayedColumns: string[] = ['Zona', 'NombredeCliente', 'agencia'];
  displayedColumnsDev: string[] = ['Zona', 'NombredeCliente', 'agencia', 'Peso neto', 'CostoMerc'];
  displayedColumnsDevZona: string[] = ['idZona', 'Pedidos', 'CostoMerc', 'Dev', 'CanDevoluciones', 'Porcentaje'];
  displayedColumnsNov: string[] = ['detalle', 'Pedidos', 'CostoMerc', 'Porcentaje'];
  displayedColumnsTipo: string[] = ['name', 'Pedidos', 'y'];
  displayedColumnsEstado: string[] = ['name', 'y', 'CostoMerc'];
  displayedColumnsEstadoValor: string[] = ['name', 'y', 'CostoMerc'];
  displayedColumnsRuta: string[] = ['name', 'txtTotalFac', 'txtPedidos', 'CanExi', 'CanParExi', 'CanPen','CanNov'];
  displayedColumnsRutaValor: string[] = ['name', 'txtTotalFac', 'CostoMercExi', 'CostoParMercExi','CostoParMercNov', 'CostoMercPen','CostoMercNov'];

  dataSource = new MatTableDataSource();
  dataSourceDev = new MatTableDataSource();
  dataSourceDevZona = new MatTableDataSource();
  dataSourceNov = new MatTableDataSource();
  dataSourceTipo = new MatTableDataSource();
  dataSourceEstado = new MatTableDataSource();
  dataSourceEstadoValor = new MatTableDataSource();
  dataSourceRuta= new MatTableDataSource();
  dataSourceRutaValor= new MatTableDataSource();


  ngOnInit(): void {


    this.txtDias = 1

    if (localStorage.getItem('cargar') == 'pendiente') {

      localStorage.setItem("cargar", 'exitoso');

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

  

    }

    this.txtFecha=localStorage.getItem("pedidoFecha");
    this.getTareas();
    this.getNovedades();
    this.getTipo();
    this.getTipoNovedad();
    this.getEstadosTareas();
    this.getTareasRecaudo();

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceDev.paginator = this.paginatordev;
    this.dataSourceDevZona.paginator = this.paginatorzona;
    this.dataSourceNov.paginator = this.paginatornov;
    this.dataSourceTipo.paginator = this.paginatortipo;
    this.dataSourceEstado.paginator = this.paginatorestado;
    this.dataSourceRuta.paginator=this.paginatorruta;
    this.dataSourceRutaValor.paginator=this.paginatorrutavalor;
    this.dataSourceEstadoValor.paginator=this.paginatorestadovalor;
  }

  getTareas() {


    this.orderService.getTareasdia().subscribe(res => {

      this.txtTotalFac = 0;
      this.txtPesoKG = 0;
      this.txtPesoKGDe = 0;
      this.txtPedidos = 0;
      this.txtNovedades = 0;
      this.txtPedidosDev = 0;

      this.totalParcial = 0;
      this.canParcial = 0;
      this.totalParcialExi = 0;
      this.canParcialExi = 0;
      this.totalExitoso = 0;
      this.canExitoso = 0;
      this.totalNovedad = 0;
      this.canNovedad = 0;
      this.totalPendiente = 0;
      this.canPendiente = 0;
      this.totalGeneral = 0;
      this.canGeneral = 0;

      for (let tarea of res) {


        var zonas = {
          idZona: tarea.Zona,
        };

        this.canGeneral = this.canGeneral + 1

        this.orderService.createZonaAgrupadas(zonas);

        this.txtTotalFac = this.txtTotalFac + tarea.CostoMerc
        this.txtPesoKG = this.txtPesoKG + tarea["Peso neto"]
        this.txtPedidos = this.txtPedidos + 1

        if (tarea.estado == "EXITOSA") {


          this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
          this.canExitoso = this.canExitoso + 1

        }

        if (tarea.estado == "PENDIENTE") {


          this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
          this.canPendiente = this.canPendiente + 1


        }

        if (tarea.estado == "NOVEDAD") {

          this.txtNovedades = this.txtNovedades + tarea.CostoMerc
          this.txtPedidosDev = this.txtPedidosDev + 1
          this.txtPesoKGDe = this.txtPesoKGDe + tarea["Peso neto"]

          this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
          this.canNovedad = this.canNovedad + 1

        }

        if (tarea.estado == "PARCIAL") {

          this.txtNovedades = this.txtNovedades + (tarea.devolucion * 1)

          this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
          this.canParcial = this.canParcial + 1


          this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
          this.canParcialExi = this.canParcialExi + 1


        }

      }

      var estadoPar = {
        name: "Nov Parcial",
        y: this.canParcial,
        CostoMerc: this.totalParcial,
      };

      this.orderService.createTareasEstado(estadoPar);


      var estadoPar = {
        name: "Exi Parcial",
        y: this.canParcialExi,
        CostoMerc: this.totalParcialExi,
      };

      this.orderService.createTareasEstado(estadoPar);


      var estadoPen = {
        name: "Pendiente",
        y: this.canPendiente,
        CostoMerc: this.totalPendiente,
      };

      this.orderService.createTareasEstado(estadoPen);

      var estadoExi = {
        name: "Exitosa",
        y: this.canExitoso,
        CostoMerc: this.totalExitoso,
      };

      this.orderService.createTareasEstado(estadoExi);

      var estadoNov = {
        name: "Novedad",
        y: this.canNovedad,
        CostoMerc: this.totalNovedad,
      };

      this.orderService.createTareasEstado(estadoNov);


      //estados

      var estadoPar = {
        name: "Nov Parcial",
        y: this.totalParcial,
        CostoMerc: this.canParcial,
      };

      this.orderService.createTareasEstadoValor(estadoPar);


      var estadoPar = {
        name: "Exi Parcial",
        y: this.totalParcialExi,
        CostoMerc: this.totalParcialExi,
      };

      this.orderService.createTareasEstadoValor(estadoPar);

      var estadoPen = {
        name: "Pendiente",
        y: this.totalPendiente,
        CostoMerc: this.canPendiente,
      };

      this.orderService.createTareasEstadoValor(estadoPen);


      var estadoExi = {
        name: "Exitosa",
        y: this.totalExitoso,
        CostoMerc: this.canExitoso,
      };

      this.orderService.createTareasEstadoValor(estadoExi);

      var estadoNov = {
        name: "Novedad",
        y: this.totalNovedad,
        CostoMerc: this.canNovedad,
      };

      this.orderService.createTareasEstadoValor(estadoNov);


      this.getTransportadores()

    });

    this.orderService.getTareasdiaNovedad().subscribe(res => {
      this.dataSource.data = res;
      this.dataSourceDev.data = res;
    });
  }

  getTransportadores() {

    this.orderService.getTransportadores().subscribe(res => {

      this.txtRutas = 0;
      for (let rutas of res) {

        this.txtRutas = this.txtRutas + 1

        this.orderService.getTareastrasnporRuta(rutas.ruta).subscribe(tareas => {


          this.totalParcial = 0;
          this.canParcial = 0;
          this.totalParcialExi = 0;
          this.canParcialExi = 0;
          this.totalExitoso = 0;
          this.canExitoso = 0;
          this.totalNovedad = 0;
          this.canNovedad = 0;
          this.totalPendiente = 0;
          this.canPendiente = 0;


          this.txtTotalFacRuta = 0;
          this.txtPedidosRuta = 0;

          for (let tarea of tareas) {


            this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
            this.txtPedidosRuta = this.txtPedidosRuta + 1

            if (tarea.estado == "EXITOSA") {


              this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
              this.canExitoso = this.canExitoso + 1

            }

            if (tarea.estado == "PENDIENTE") {


              this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
              this.canPendiente = this.canPendiente + 1


            }

            if (tarea.estado == "NOVEDAD") {

              this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
              this.canNovedad = this.canNovedad + 1

            }

            if (tarea.estado == "PARCIAL") {

              this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
              this.canParcial = this.canParcial + 1


              this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
              this.canParcialExi = this.canParcialExi + 1


            }
          }


          var estadoPar = {

            name: rutas.ruta,

            CanParExi: this.canParcialExi,
            CostoParMercExi: this.totalParcialExi,

            CanParNov: this.canParcial,
            CostoParMercNov: this.totalParcial,

            CanExi: this.canExitoso,
            CostoMercExi: this.totalExitoso,

            CanPen: this.canPendiente,
            CostoMercPen: this.totalPendiente,

            CanNov: this.canNovedad,
            CostoMercNov: this.totalNovedad,

            y: this.txtPedidosRuta,

            txtTotalFac:this.txtTotalFacRuta,
            txtPedidos:this.txtPedidosRuta,
            
            txtPedPor:((this.canPendiente*100)/this.txtPedidosRuta)+"%",
            txtNovPor:((this.canNovedad*100)/this.txtPedidosRuta)+"%",
            txtExiPor:((this.canExitoso*100)/this.txtPedidosRuta)+"%",
            txtParPor:((this.canParcial*100)/this.txtPedidosRuta)+"%",
            
            txtPedPorM:Math.round(((this.canPendiente*100)/this.txtPedidosRuta))+"%",
            txtNovPorM:Math.round(((this.canNovedad*100)/this.txtPedidosRuta))+"%",
            txtExiPorM:Math.round(((this.canExitoso*100)/this.txtPedidosRuta))+"%",
            txtParPorM:Math.round(((this.canParcial*100)/this.txtPedidosRuta))+"%"

          };

          this.orderService.createTareasRecaudo(estadoPar);

          var estadoPardos = {

            name: rutas.ruta,

            CanParExi: this.canParcialExi,
            CostoParMercExi: this.totalParcialExi,

            CanParNov: this.canParcial,
            CostoParMercNov: this.totalParcial,

            CanExi: this.canExitoso,
            CostoMercExi: this.totalExitoso,

            CanPen: this.canPendiente,
            CostoMercPen: this.totalPendiente,

            CanNov: this.canNovedad,
            CostoMercNov: this.totalNovedad,

            y: this.txtTotalFacRuta,

            txtTotalFac:this.txtTotalFacRuta,
            txtPedidos:this.txtPedidosRuta,

            txtPedPor:((this.canPendiente*100)/this.txtPedidosRuta)+"%",
            txtNovPor:((this.canNovedad*100)/this.txtPedidosRuta)+"%",
            txtExiPor:((this.canExitoso*100)/this.txtPedidosRuta)+"%",
            txtParPor:((this.canParcial*100)/this.txtPedidosRuta)+"%",

            txtPedPorM:Math.round(((this.canPendiente*100)/this.txtPedidosRuta))+"%",
            txtNovPorM:Math.round(((this.canNovedad*100)/this.txtPedidosRuta))+"%",
            txtExiPorM:Math.round(((this.canExitoso*100)/this.txtPedidosRuta))+"%",
            txtParPorM:Math.round(((this.canParcial*100)/this.txtPedidosRuta))+"%"

          };

          this.orderService.createTareasRecaudoValor(estadoPardos);



        });

      }
    });
  }


  getZonasGrupos() {

    this.orderService.getZonasGrupos().subscribe(res => {

      this.dataSourceDevZona.data = res;

    });

  }

  getTareasRecaudo() {

    this.orderService.getTareasRecaudo().subscribe(res => {

      this.dataSourceRuta.data = res;
      this.dataSourceRutaValor.data=res;


    });

  }

  getEstadosTareas() {

    this.orderService.getTareasEstado().subscribe(res => {

      this.dataSourceEstado.data = res;
      this.dataSourceEstadoValor.data=res;
      this.datospruebas=res;
      console.log("esto es lo q recorre", res);

    });

  }

  getTipoNovedad() {

    this.orderService.getTiposNovedades().subscribe(res => {

      this.dataSourceTipo.data = res;

    });

  }

  getNovedades() {

    this.orderService.getTotalnovedades().subscribe(res => {



      for (let novedad of res) {

        this.orderService.getTareastrasnporNovedad(novedad.detalle).subscribe(tareas => {

          this.txtCanGrupo = 0;
          this.txtDevGrupo = 0;
          this.txtGrupoDev = 0;
          this.txtGrupoPedidos = 0;
          
          for (let tarea of tareas) {

            this.txtGrupoPedidos = this.txtGrupoPedidos + 1
            this.txtCanGrupo = this.txtCanGrupo + tarea.CostoMerc
            if (tarea.estado == "NOVEDAD") {

              this.txtDevGrupo = this.txtDevGrupo + tarea.CostoMerc
              this.txtGrupoDev = this.txtGrupoDev + 1

            }

            if (tarea.observaciones == "Cliente no tiene Dinero") {

              this.txttipoNovedad = "Cliente"

            }

            if (tarea.observaciones == "Negocio Cerrado") {

              this.txttipoNovedad = "Cliente"

            }

            if (tarea.observaciones == "Problema Logistico Cliente") {

              this.txttipoNovedad = "Cliente"

            }

            if (tarea.observaciones == "Cliente no tenía los Cambios") {

              this.txttipoNovedad = "Cliente"

            }

            if (tarea.observaciones == "Ventana Horaria Cliente") {

              this.txttipoNovedad = "Cliente"

            }

            if (tarea.observaciones == "Cliente no Existe") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "Pedido Repetido") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "Direccion Errada") {

              this.txttipoNovedad = "Comercial"

            }
            if (tarea.observaciones == "Mercancia no solicitada por el cliente") {

              this.txttipoNovedad = "Comercial"

            }
            if (tarea.observaciones == "Diferencia en negociacion") {

              this.txttipoNovedad = "Comercial"

            }
            if (tarea.observaciones == "Producto no roto(Dev en buen estado)") {

              this.txttipoNovedad = "Comercial"

            }
            if (tarea.observaciones == "Baja Rotación") {

              this.txttipoNovedad = "Comercial"

            }
            if (tarea.observaciones == "Fuera de frecuencia") {

              this.txttipoNovedad = "Comercial"

            }
            if (tarea.observaciones == "sobre stock") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "Cambios de Factura y/o Periodo Cerrado") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "Bonificaciones") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "Cambios de Factura y/o Periodo Actual") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "PEDIDO PARCIAL") {

              this.txttipoNovedad = "Comercial"

            }

            if (tarea.observaciones == "No recibe hasta que le envien cambios") {

              this.txttipoNovedad = "Planta Chia"

            }

            if (tarea.observaciones == "Producto Averiado(Dev mal estado)") {

              this.txttipoNovedad = "Planta Chia"

            }


            if (tarea.observaciones == "Producto vencido(Dev mal estado)") {

              this.txttipoNovedad = "Planta Chia"

            }


            if (tarea.observaciones == "Producto mal Presentado") {

              this.txttipoNovedad = "Planta Chia"

            }


            if (tarea.observaciones == "Producto facturado no despachado") {

              this.txttipoNovedad = "Planta Chia"

            }


            if (tarea.observaciones == "Sistemas de Informacion") {

              this.txttipoNovedad = "Planta Chia"

            }


            if (tarea.observaciones == "Calidad") {

              this.txttipoNovedad = "Planta Chia"

            }


            if (tarea.observaciones == "No cumple condiciones") {

              this.txttipoNovedad = "Planta Chia"

            }

            if (tarea.observaciones == "Problema en Distribuciones.") {

              this.txttipoNovedad = "Planta Chia"

            }

            if (tarea.observaciones == "Problema Logistico Parmalat") {

              this.txttipoNovedad = "Planta Chia"

            }

            if (tarea.observaciones == "Orden cerrada/vencida") {

              this.txttipoNovedad = "Planta Chia"

            }
            if (tarea.observaciones == "Falta de mercadeo") {

              this.txttipoNovedad = "Planta Chia"

            }
            if (tarea.observaciones == "Carga critica insuficiente") {

              this.txttipoNovedad = "Planta Chia"

            }
            if (tarea.observaciones == "Fechas de vencimiento de los articulos despachados") {

              this.txttipoNovedad = "Planta Chia"

            }
            if (tarea.observaciones == "Producto facturado no despachado Industrial") {

              this.txttipoNovedad = "Planta Chia"

            }

            if (tarea.observaciones == "Desastres naturales") {

              this.txttipoNovedad = "Terceros"

            }

            if (tarea.observaciones == "Orden publico") {

              this.txttipoNovedad = "Terceros"

            }


            if (tarea.observaciones == "Servicio deficiente en la entrega") {

              this.txttipoNovedad = "Transporte"

            }

            if (tarea.observaciones == "Cliente no visitado") {

              this.txttipoNovedad = "Transporte"

            }

            if (tarea.observaciones == "Carro Varado") {

              this.txttipoNovedad = "Transporte"

            }

            if (tarea.observaciones == "Siniestro del transportador") {

              this.txttipoNovedad = "Transporte"

            }


            if (tarea.observaciones == "Horario") {

              this.txttipoNovedad = "Transporte"

            }

            if (tarea.observaciones == "Temperatura") {

              this.txttipoNovedad = "Transporte"

            }


          }

          var zonasenvio = {
            detalle: novedad.detalle,
            CostoMerc: this.txtCanGrupo,
            Dev: this.txtDevGrupo,
            Pedidos: this.txtGrupoPedidos,
            CanDevoluciones: this.txtGrupoDev,
            tipo: this.txttipoNovedad,
            Porcentaje: (this.txtGrupoDev * 100) / this.txtGrupoPedidos,
          };

          this.orderService.createNov(zonasenvio);

        });

      }


    });

  }

  getTipo() {

    this.orderService.getTipo().subscribe(res => {

      this.dataSourceNov.data = res;

      this.txtCanCliente = 0
      this.txtCanComercial = 0
      this.txtCanPlanta = 0
      this.txtCanTransporte = 0
      this.txtCanTerceros = 0

      this.txtCosCliente = 0
      this.txtCosComercial = 0
      this.txtCosPlanta = 0
      this.txtCosTransporte = 0
      this.txtCosTerceros = 0

      for (let tiposapp of res) {

        if (tiposapp.tipo == "Transporte") {

          this.txtCanTransporte = this.txtCanTransporte + tiposapp.Pedidos
          this.txtCosTransporte = this.txtCosTransporte + tiposapp.CostoMerc

          var tipoenvio = {
            name: tiposapp.tipo,
            y: this.txtCosTransporte,
            Pedidos: this.txtCanTransporte,
          };

          this.orderService.createTipo(tipoenvio);

        }

        if (tiposapp.tipo == "Cliente") {

          this.txtCanCliente = this.txtCanCliente + tiposapp.Pedidos
          this.txtCosCliente = this.txtCosCliente + tiposapp.CostoMerc
          var tipoenvioCli = {
            name: tiposapp.tipo,
            y: this.txtCosCliente,
            Pedidos: this.txtCanCliente,
          };

          this.orderService.createTipo(tipoenvioCli);
        }

        if (tiposapp.tipo == "Comercial") {

          this.txtCanComercial = this.txtCanComercial + tiposapp.Pedidos
          this.txtCosComercial = this.txtCosComercial + tiposapp.CostoMerc

          var tipoenvioCo = {
            name: "Comercial",
            y: this.txtCosComercial,
            Pedidos: this.txtCanComercial,
          };

          this.orderService.createTipo(tipoenvioCo);
        }

        if (tiposapp.tipo == "Planta Chia") {

          this.txtCanPlanta = this.txtCanPlanta + tiposapp.Pedidos
          this.txtCosPlanta = this.txtCosPlanta + tiposapp.CostoMerc
          var tipoenvioPla = {
            name: tiposapp.tipo,
            y: this.txtCosPlanta,
            Pedidos: this.txtCanPlanta,
          };

          this.orderService.createTipo(tipoenvioPla);
        }

        if (tiposapp.tipo == "Terceros") {

          this.txtCanTerceros = this.txtCanTerceros + tiposapp.Pedidos
          this.txtCosTerceros = this.txtCosTerceros + tiposapp.CostoMerc

          var tipoenvioTer = {
            name: tiposapp.tipo,
            y: this.txtCosTerceros,
            Pedidos: this.txtCanTerceros,
          };

          this.orderService.createTipo(tipoenvioTer);

        }


      }


      this.getZonas();


    });
  }

  getZonas() {

    this.orderService.getZonas().subscribe(res => {


      for (let zonas of res) {

        this.orderService.getTareastrasnporZona(zonas.idZona).subscribe(tareas => {

          this.txtCanGrupo = 0;
          this.txtDevGrupo = 0;
          this.txtGrupoDev = 0;
          this.txtGrupoPedidos = 0;
          for (let tarea of tareas) {

            this.txtGrupoPedidos = this.txtGrupoPedidos + 1
            this.txtCanGrupo = this.txtCanGrupo + tarea.CostoMerc
            if (tarea.estado == "NOVEDAD") {

              this.txtDevGrupo = this.txtDevGrupo + tarea.CostoMerc
              this.txtGrupoDev = this.txtGrupoDev + 1

            }

            if (tarea.estado == "PARCIAL") {

              this.txtDevGrupo = this.txtDevGrupo + (tarea.devolucion * 1)

            }

          }

          var zonasenvio = {
            idZona: zonas.idZona,
            CostoMerc: this.txtCanGrupo,
            Dev: this.txtDevGrupo,
            y: this.txtDevGrupo,
            Pedidos: this.txtGrupoPedidos,
            CanDevoluciones: this.txtGrupoDev,
            Porcentaje: (this.txtGrupoDev * 100) / this.txtGrupoPedidos,
          };

          this.orderService.createZonasGrups(zonasenvio);
          this.getZonasGrupos()
        });

      }


    });
  }


  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


    const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');

    localStorage.setItem("pedidoFecha", cValue);
    console.log("fecha q se guardo en el sistema", cValue);

    localStorage.setItem("cargar", 'exitoso');


  }



}

