import { formatDate } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { ClientesService } from '../../clientes.service';
import { PedidoService } from '../../pedidos.service';
import { ClientesaprobacionService } from '../../clientesaprobacion.service';
import { ClientesActivacionesService } from '../../clientesActivaciones.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-widget-areanuevos',
  templateUrl: './areanuevos.component.html',
  styleUrls: ['./areanuevos.component.scss']
})
export class AreaNuevosComponent implements OnInit {

  
 
  private rutasDescarga: any;
  private ClientesNuevos: any;
  private diaCliente: any;
  private ventasDescarga: any;
  subscription: Subscription;
  subscriptionContactos: Subscription;
  subscriptionVisitas: Subscription;

  private txtTotalVisitas: any;
  private txtPedidos: any;
  private txtNovedad: any;
  private txtPendiente: any;
  private txtRutas: any;
  private txtVenta: any;
  private txtClientes:any;
  private txtFecha:any;
  private datosNovCan: any;
  private datosPedidosRutas: any;
  private datosNov: any;
  private datosPend: any;

  chartOptions: {};
  @Input() data: any = [];

  Highcharts = Highcharts;
  
  constructor(private clientesActivacionesService: ClientesActivacionesService, private ClientesService: ClientesService, public pedidosService: PedidoService, public clientesaprobacionService: ClientesaprobacionService) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });
  }

  ngOnInit(): void {

    if (localStorage.getItem('rutaindicador') == 'si') {

        localStorage.setItem("rutaindicador", "no");

    }

    if (localStorage.getItem('vistaShow') == 'SHOWN') {


  

    } else {


      this.subscription.unsubscribe();


    }


  }

  descargar() {
    this.subscriptionVisitas = this.clientesaprobacionService.getmes().pipe(
      map(([a1, a2, a3, a4, a5, a6, a7, a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20,a21,a22,a23,a24,a25,a26,a27,a28,a29,a30,a31]) => {
        return { a1, a2, a3, a4, a5, a6, a7, a8,a9,a10,a11,a12,a13,a14,a15,a16,a17,a18,a19,a20,a21,a22,a23,a24,a25,a26,a27,a28,a29,a30,a31};
      })
    ).subscribe(res => {
      this.ventasDescarga = [...res["a1"], ...res['a2'], ...res['a3'], ...res['a4'], ...res['a5'], ...res['a6'], ...res['a7'], ...res['a8']
      , ...res['a9'], ...res['a10'], ...res['a11'], ...res['a12'], ...res['a13'], ...res['a14'], ...res['a15'], ...res['a16']
      , ...res['a17'], ...res['a18'], ...res['a19'], ...res['a20'], ...res['a21'], ...res['a22'], ...res['a23'], ...res['a24']
      , ...res['a25'], ...res['a26'], ...res['a27'], ...res['a28'], ...res['a29'], ...res['a30'], ...res['a31']];
  

      this.subscriptionVisitas.unsubscribe();
      this.getVista(this.ventasDescarga)

    });
    
    

  }
 


  getVista(datosCliente:any){

    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutasdia = groupBy(datosCliente, 'fc')



    let rutasClientes = []
    for (let item of Object.keys(rutasdia)) {
      var rutasODL = rutasdia[item];

      let pedidosenvio = rutasODL.filter(tarea => tarea.fc ==rutasODL[0].fc)

      var ruta = {
        x: pedidosenvio.length,
        name: rutasODL[0].fc,
       
      };

      rutasClientes.push(ruta)

    }

    this.getAllrecorridos(rutasClientes)

  }

  getAllrecorridos(vistas: any) {

    let categarias = []
    let exitosas = []

 
    for (let ruta of  vistas) {

      categarias.push(ruta.name)
      exitosas.push(ruta.x)
    
    
    }

    this.chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: 'Rutas'
      },
      xAxis: {
        categories: categarias
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Rutas'
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: 'bold',
            color: ['#c23b22 ','#77dd77','#efa94a'],
          }
        }
      }, legend: {
        align: 'right',
        x: -30,
        verticalAlign: 'top',
        y: 25,
        floating: true,
        backgroundColor:
      ['#c23b22 ','#77dd77','#efa94a'],
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      }, tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: true
          }
        }
      }, series: [ {
        name: 'Clientes',
        data: exitosas,
        color: "#8f0088"
      }]
      
    };
}

}