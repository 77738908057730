import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ellipse } from 'pdfmake-wrapper';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fragmentIndicadoresGeo',
  templateUrl: './fragmentIndicadoresGeo.component.html',
  styleUrls: ['./fragmentIndicadoresGeo.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoresGeo implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;

  private negocio: any;
  private agencia: any;
  private usuario: any;
  private txtTotalFac: any;
  private txtTotalFacRuta: any;
  private txtPesoKG: any;
  private txtPesoKGDe: any;
  private txtPedidos: any;
  private txtPedidosRuta: any;
  private txtDias: any;
  private txtRutas: any;
  private txtNovedades: any;
  private txtPedidosDev: any;
  private txtPedidosDevZona: any;
  private txtCanGrupo: any;
  private txtDevGrupo: any;
  private txtGrupoPedidos: any;
  private txtGrupoDev: any;
  private txttipoNovedad: any;
  private txtFecha: any;


  //novedades

  private txtCanCliente: any;
  private txtCanComercial: any;
  private txtCanPlanta: any;
  private txtCanTransporte: any;
  private txtCanTerceros: any;

  private txtCosCliente: any;
  private txtCosComercial: any;
  private txtCosPlanta: any;
  private txtCosTransporte: any;
  private txtCosTerceros: any;

  private totalParcial: any;
  private canParcial: any;

  private totalParcialExi: any;
  private canParcialExi: any;

  private totalExitoso: any;
  private canExitoso: any;

  private totalPendiente: any;
  private canPendiente: any;

  private totalNovedad: any;
  private canNovedad: any;

  private totalGeneral: any;
  private canGeneral: any;

  private datosNovCan: any;
  private datosNovValor: any;
  private datosPedidosRutas: any;
  private datosPedidosRutasValor: any;
  private datosEstadosValor: any;
  private datosZonas: any;
  private datosTipoNov: any;
  private datosNov: any;


  private tareasDescarga: any;
  private rutasDescarga: any;
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0

  estadoPendientec=0
  estadoExitosac=0
  estadoNovedadc=0
  estadoParcialc=0
  estadoRepisarc=0

  subscription: Subscription;

  myData = [
    { data: [1, 1, 3], name: "first" }
  ];

  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }

  constructor(private route: Router,private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager) {

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
       
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
 
        localStorage.setItem("vistaShow", 'SHOWN');

      }

    });
  }

  displayedColumns: string[] = ['Zona', 'NombredeCliente', 'agencia'];
  displayedColumnsDev: string[] = ['Zona', 'NombredeCliente', 'agencia', 'Peso neto', 'CostoMerc'];
  displayedColumnsDevZona: string[] = ['idZona', 'Pedidos', 'CostoMerc', 'Dev', 'CanDevoluciones', 'Porcentaje'];
  displayedColumnsNov: string[] = ['detalle', 'Pedidos', 'CostoMerc', 'Porcentaje'];
  displayedColumnsTipo: string[] = ['name', 'Pedidos', 'y'];
  displayedColumnsEstado: string[] = ['name', 'y', 'CostoMerc'];
  displayedColumnsEstadoValor: string[] = ['name', 'y', 'CostoMerc'];
  displayedColumnsRuta: string[] = ['name', 'txtTotalFac', 'txtPedidos', 'CanExi', 'CanParExi', 'CanPen', 'CanNov'];
  displayedColumnsRutaValor: string[] = ['name', 'txtTotalFac', 'CostoMercExi', 'CostoParMercExi', 'CostoParMercNov', 'CostoMercPen', 'CostoMercNov'];

  dataSource = new MatTableDataSource();
  dataSourceDev = new MatTableDataSource();
  dataSourceDevZona = new MatTableDataSource();
  dataSourceNov = new MatTableDataSource();
  dataSourceTipo = new MatTableDataSource();
  dataSourceEstado = new MatTableDataSource();
  dataSourceEstadoValor = new MatTableDataSource();
  dataSourceRuta = new MatTableDataSource();
  dataSourceRutaValor = new MatTableDataSource();


  ngOnInit(): void {

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
 
    localStorage.setItem("pedidoFecha",cValue);

  
    this.negocio = localStorage.getItem("departamento");
    this.agencia = localStorage.getItem("usuario");
    
    if (localStorage.getItem('rutaindicador') == 'si') {

      localStorage.setItem("rutaindicador", "no");

      



    }

    if (localStorage.getItem('pedidoFecha').length > 3) {


      

    } else {

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

    }

    if (localStorage.getItem('vistaShow') == 'SHOWN') {

      this.descargar();
      

    } else {


    


    }



  }

  descargar() {
     this.orderService.getTareasdia().subscribe(tareasindicadores => {
      this.tareasDescarga = this.tareasDescarga = tareasindicadores;
      this.estadoPendiente=0
      this.estadoExitosa=0
      this.estadoNovedad=0
      this.estadoRepisar=0
      this.estadoParcial=0

      this.estadoPendientec=0
      this.estadoExitosac=0
      this.estadoNovedadc=0
      this.estadoRepisarc=0
      this.estadoParcialc=0

      for (let tarea of tareasindicadores) {

        if(tarea.estado=="PENDIENTE"){

          this.estadoPendiente=this.estadoPendiente+1
          this.estadoPendientec=this.estadoPendientec+(tarea.CostoMerc * 1)
      
         }

         if(tarea.estado=="EXITOSA"){

          this.estadoExitosa=this.estadoExitosa+1
          this.estadoExitosac=this.estadoExitosac+(tarea.CostoMerc * 1)
         }


         if(tarea.estado=="NOVEDAD"){

          this.estadoNovedad=this.estadoNovedad+1
          this.estadoNovedadc=this.estadoNovedadc+(tarea.CostoMerc * 1)
         }


         if(tarea.estado=="REPISAR"){

 
          this.estadoRepisar=this.estadoRepisar+1
          this.estadoRepisarc=this.estadoRepisarc+(tarea.CostoMerc * 1)
         }

         if(tarea.estado=="PARCIAL"){

        
          this.estadoParcial=this.estadoParcial+1
          this.estadoParcialc=this.estadoParcialc+(tarea.CostoMerc * 1)
         }

      }
   

      this.getTareas();
   

    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSourceDev.paginator = this.paginatordev;
    this.dataSourceDevZona.paginator = this.paginatorzona;
    this.dataSourceNov.paginator = this.paginatornov;
    this.dataSourceTipo.paginator = this.paginatortipo;
    this.dataSourceEstado.paginator = this.paginatorestado;
    this.dataSourceRuta.paginator = this.paginatorruta;
    this.dataSourceRutaValor.paginator = this.paginatorrutavalor;
    this.dataSourceEstadoValor.paginator = this.paginatorestadovalor;
  }

  getTareas() {
    this.txtFecha=localStorage.getItem("ordenTransporteFecha");
    this.negocio = localStorage.getItem("departamento");
    this.usuario = localStorage.getItem("correo");
    this.tareasDescarga = this.tareasDescarga;
    let tareasNovedadeS = this.tareasDescarga.filter(tarea => tarea.estado == "NOVEDAD")


    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let rutas = groupBy(this.tareasDescarga, 'RutaVLI')
    let zonasODL = groupBy(this.tareasDescarga, 'Zona')
    let novedadODL = groupBy(tareasNovedadeS, 'observaciones')


    this.dataSource.data = tareasNovedadeS;
    this.dataSourceDev.data = tareasNovedadeS;

    this.txtTotalFac = 0;
    this.txtPesoKG = 0;
    this.txtPesoKGDe = 0;
    this.txtPedidos = 0;
    this.txtNovedades = 0;
    this.txtPedidosDev = 0;

    this.totalParcial = 0;
    this.canParcial = 0;
    this.totalParcialExi = 0;
    this.canParcialExi = 0;
    this.totalExitoso = 0;
    this.canExitoso = 0;
    this.totalNovedad = 0;
    this.canNovedad = 0;
    this.totalPendiente = 0;
    this.canPendiente = 0;
    this.totalGeneral = 0;
    this.canGeneral = 0;

    let novEstCan = []
    let novEstVal = []
    for (let tarea of this.tareasDescarga) {


      var zonas = {
        idZona: tarea.Zona,
      };

      this.canGeneral = this.canGeneral + 1

      this.orderService.createZonaAgrupadas(zonas);

      this.txtTotalFac = this.txtTotalFac + tarea.CostoMerc
      this.txtPesoKG = this.txtPesoKG + tarea["Peso neto"]
      this.txtPedidos = this.txtPedidos + 1

      if (tarea.estado == "EXITOSA") {


        this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
        this.canExitoso = this.canExitoso + 1

      }

      if (tarea.estado == "PENDIENTE") {


        this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
        this.canPendiente = this.canPendiente + 1


      }

      if (tarea.estado == "NOVEDAD") {

        this.txtNovedades = this.txtNovedades + tarea.CostoMerc
        this.txtPedidosDev = this.txtPedidosDev + 1
        this.txtPesoKGDe = this.txtPesoKGDe + tarea["Peso neto"]

        this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
        this.canNovedad = this.canNovedad + 1

      }

      if (tarea.estado == "PARCIAL") {

        this.txtNovedades = this.txtNovedades + (tarea.devolucion * 1)

        this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
        this.canParcial = this.canParcial + 1


        this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
        this.canParcialExi = this.canParcialExi + 1


      }

    }

    var estadoPar = {
      name: "Nov Parcial",
      y: this.canParcial,
      CostoMerc: this.totalParcial,
    };

    novEstCan.push(estadoPar)

    var estadoPar = {
      name: "Exi Parcial",
      y: this.canParcialExi,
      CostoMerc: this.totalParcialExi,
    };

    novEstCan.push(estadoPar)


    var estadoPen = {
      name: "Pendiente",
      y: this.canPendiente,
      CostoMerc: this.totalPendiente,
    };

    novEstCan.push(estadoPen)


    var estadoExi = {
      name: "Exitosa",
      y: this.canExitoso,
      CostoMerc: this.totalExitoso,
    };

    novEstCan.push(estadoExi)


    var estadoNov = {
      name: "Novedad",
      y: this.canNovedad,
      CostoMerc: this.totalNovedad,
    };

    novEstCan.push(estadoNov)


    this.datosNovCan = novEstCan
    this.dataSourceEstado.data = this.datosNovCan
    //estados

    var estadoPen = {
      name: "Pendiente",
      y: this.totalPendiente,
      CostoMerc: this.canPendiente,
    };

    novEstVal.push(estadoPen)

    var estadoExi = {
      name: "Exitosa",
      y: this.totalExitoso,
      CostoMerc: this.canExitoso,
    };

    novEstVal.push(estadoExi)

    var estadoNov = {
      name: "Novedad",
      y: this.totalNovedad,
      CostoMerc: this.canNovedad,
    };

    novEstVal.push(estadoNov)

    var estadoPar = {
      name: "Nov Parcial",
      y: this.totalParcial,
      CostoMerc: this.canParcial,
    };

    novEstVal.push(estadoPar)

    var estadoPar = {
      name: "Exi Parcial",
      y: this.totalParcialExi,
      CostoMerc: this.totalParcialExi,
    };

    novEstVal.push(estadoPar)

  

 


    this.datosNovValor = novEstVal;
    this.datosPedidosRutasValor = novEstVal;



    this.getTransportadores(rutas)
    this.getZonas(zonasODL);
    this.getNovedades(novedadODL)



  }


  getTransportadores(rutasvli: any) {


    this.txtRutas = 0;
    this.txtDias=1
    let tareasRecaudo = []
    let tareasRecaudoValor = []
    for (let item of Object.keys(rutasvli)) {
      var rutas = rutasvli[item];
      this.txtRutas = this.txtRutas + 1
      let tareas = this.tareasDescarga.filter(tarea => tarea.RutaVLI == rutas[0].RutaVLI)


      this.totalParcial = 0;
      this.canParcial = 0;
      this.totalParcialExi = 0;
      this.canParcialExi = 0;
      this.totalExitoso = 0;
      this.canExitoso = 0;
      this.totalNovedad = 0;
      this.canNovedad = 0;
      this.totalPendiente = 0;
      this.canPendiente = 0;


      this.txtTotalFacRuta = 0;
      this.txtPedidosRuta = 0;

      for (let tarea of tareas) {


        this.txtTotalFacRuta = this.txtTotalFacRuta + tarea.CostoMerc
        this.txtPedidosRuta = this.txtPedidosRuta + 1

        if (tarea.estado == "EXITOSA") {


          this.totalExitoso = this.totalExitoso + (tarea.CostoMerc * 1)
          this.canExitoso = this.canExitoso + 1

        }

        if (tarea.estado == "PENDIENTE") {


          this.totalPendiente = this.totalPendiente + (tarea.CostoMerc * 1)
          this.canPendiente = this.canPendiente + 1


        }

        if (tarea.estado == "NOVEDAD") {

          this.totalNovedad = this.totalNovedad + (tarea.CostoMerc * 1)
          this.canNovedad = this.canNovedad + 1

        }

        if (tarea.estado == "PARCIAL") {

          this.totalParcial = this.totalParcial + (tarea.devolucion * 1)
          this.canParcial = this.canParcial + 1


          this.totalParcialExi = this.totalParcialExi + ((tarea.CostoMerc * 1) - (tarea.devolucion * 1))
          this.canParcialExi = this.canParcialExi + 1


        }
      }


      var estadoPar = {

        name: rutas[0].RutaVLI,

        CanParExi: this.canParcialExi,
        CostoParMercExi: this.totalParcialExi,

        CanParNov: this.canParcial,
        CostoParMercNov: this.totalParcial,

        CanExi: this.canExitoso,
        CostoMercExi: this.totalExitoso,

        CanPen: this.canPendiente,
        CostoMercPen: this.totalPendiente,

        CanNov: this.canNovedad,
        CostoMercNov: this.totalNovedad,

        y: this.txtPedidosRuta,

        txtTotalFac: this.txtTotalFacRuta,
        txtPedidos: this.txtPedidosRuta,

        txtPedPor: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
        txtNovPor: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
        txtExiPor: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
        txtParPor: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

        txtPedPorM: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
        txtNovPorM: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
        txtExiPorM: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
        txtParPorM: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

      };

      tareasRecaudo.push(estadoPar)

      var estadoPardos = {

        name: rutas[0].RutaVLI,

        CanParExi: this.canParcialExi,
        CostoParMercExi: this.totalParcialExi,

        CanParNov: this.canParcial,
        CostoParMercNov: this.totalParcial,

        CanExi: this.canExitoso,
        CostoMercExi: this.totalExitoso,

        CanPen: this.canPendiente,
        CostoMercPen: this.totalPendiente,

        CanNov: this.canNovedad,
        CostoMercNov: this.totalNovedad,

        y: this.txtTotalFacRuta,

        txtTotalFac: this.txtTotalFacRuta,
        txtPedidos: this.txtPedidosRuta,

        txtPedPor: ((this.canPendiente * 100) / this.txtPedidosRuta) + "%",
        txtNovPor: ((this.canNovedad * 100) / this.txtPedidosRuta) + "%",
        txtExiPor: ((this.canExitoso * 100) / this.txtPedidosRuta) + "%",
        txtParPor: ((this.canParcial * 100) / this.txtPedidosRuta) + "%",

        txtPedPorM: Math.round(((this.canPendiente * 100) / this.txtPedidosRuta)) + "%",
        txtNovPorM: Math.round(((this.canNovedad * 100) / this.txtPedidosRuta)) + "%",
        txtExiPorM: Math.round(((this.canExitoso * 100) / this.txtPedidosRuta)) + "%",
        txtParPorM: Math.round(((this.canParcial * 100) / this.txtPedidosRuta)) + "%"

      };

      tareasRecaudoValor.push(estadoPardos)

      this.dataSourceRuta.data = tareasRecaudo;
      this.dataSourceRutaValor.data = tareasRecaudo;
      this.datosPedidosRutas = tareasRecaudo;
      this.datosEstadosValor = tareasRecaudoValor;



    }

  }

  getZonas(zonasODL: any) {

    let zonasEnvio = []
    for (let item of Object.keys(zonasODL)) {

      let tareas = this.tareasDescarga.filter(tarea => tarea.Zona == item)

      this.txtCanGrupo = 0;
      this.txtDevGrupo = 0;
      this.txtGrupoDev = 0;
      this.txtGrupoPedidos = 0;
      for (let tarea of tareas) {

        this.txtGrupoPedidos = this.txtGrupoPedidos + 1
        this.txtCanGrupo = this.txtCanGrupo + tarea.CostoMerc
        if (tarea.estado == "NOVEDAD") {

          this.txtDevGrupo = this.txtDevGrupo + tarea.CostoMerc
          this.txtGrupoDev = this.txtGrupoDev + 1

        }

        if (tarea.estado == "PARCIAL") {

          this.txtDevGrupo = this.txtDevGrupo + (tarea.devolucion * 1)

        }

      }

      var zonasenvio = {
        idZona: item,
        name: item,
        CostoMerc: this.txtCanGrupo,
        Dev: this.txtDevGrupo,
        y: this.txtDevGrupo,
        Pedidos: this.txtGrupoPedidos,
        CanDevoluciones: this.txtGrupoDev,
        Porcentaje: (this.txtGrupoDev * 100) / this.txtGrupoPedidos,
      };


      zonasEnvio.push(zonasenvio);





    }

    this.dataSourceDevZona.data = zonasEnvio
    this.datosZonas = zonasEnvio;



  }

  getNovedades(novedadODL: any) {


    let novedadesGeneral = []
    let tiposGeneral = []


    this.txtCanCliente = 0
    this.txtCanComercial = 0
    this.txtCanPlanta = 0
    this.txtCanTransporte = 0
    this.txtCanTerceros = 0

    this.txtCosCliente = 0
    this.txtCosComercial = 0
    this.txtCosPlanta = 0
    this.txtCosTransporte = 0
    this.txtCosTerceros = 0

    for (let item of Object.keys(novedadODL)) {

      var tareas = novedadODL[item];

      this.txtCanGrupo = 0;
      this.txtDevGrupo = 0;
      this.txtGrupoDev = 0;
      this.txtGrupoPedidos = 0;
      for (let tarea of tareas) {

        this.txtGrupoPedidos = this.txtGrupoPedidos + 1
        this.txtCanGrupo = this.txtCanGrupo + tarea.CostoMerc
        if (tarea.estado == "NOVEDAD") {

          this.txtDevGrupo = this.txtDevGrupo + tarea.CostoMerc
          this.txtGrupoDev = this.txtGrupoDev + 1

        }

        if (tarea.observaciones == "Cliente no tiene Dinero") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc
        }

        if (tarea.observaciones == "Negocio Cerrado") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Problema Logistico Cliente") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cliente no tenía los Cambios") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Ventana Horaria Cliente") {

          this.txttipoNovedad = "Cliente"
          this.txtCanCliente = this.txtCanCliente + 1
          this.txtCosCliente = this.txtCosCliente + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cliente no Existe") {

          this.txttipoNovedad = "Comercial"
          this.txtCosComercial = this.txtCosComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Pedido Repetido") {

          this.txttipoNovedad = "Comercial"
          this.txtCosComercial = this.txtCosComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Direccion Errada") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Mercancia no solicitada por el cliente") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Diferencia en negociacion") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Producto no roto(Dev en buen estado)") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Baja Rotación") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "Fuera de frecuencia") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }
        if (tarea.observaciones == "sobre stock") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cambios de Factura y/o Periodo Cerrado") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Bonificaciones") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cambios de Factura y/o Periodo Actual") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "PEDIDO PARCIAL") {

          this.txttipoNovedad = "Comercial"
          this.txtCanComercial = this.txtCanComercial + 1
          this.txtCosComercial = this.txtCosComercial + tarea.CostoMerc

        }

        if (tarea.observaciones == "No recibe hasta que le envien cambios") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Producto Averiado(Dev mal estado)") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Producto vencido(Dev mal estado)") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Producto mal Presentado") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Producto facturado no despachado") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Sistemas de Informacion") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Calidad") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "No cumple condiciones") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Problema en Distribuciones.") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Problema Logistico Parmalat") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Orden cerrada/vencida") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Falta de mercadeo") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Carga critica insuficiente") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Fechas de vencimiento de los articulos despachados") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }
        if (tarea.observaciones == "Producto facturado no despachado Industrial") {

          this.txttipoNovedad = "Planta Chia"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Desastres naturales") {

          this.txttipoNovedad = "Terceros"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }

        if (tarea.observaciones == "Orden publico") {

          this.txttipoNovedad = "Terceros"
          this.txtCanPlanta = this.txtCanPlanta + 1
          this.txtCosPlanta = this.txtCosPlanta + tarea.CostoMerc

        }


        if (tarea.observaciones == "Servicio deficiente en la entrega") {

          this.txttipoNovedad = "Transporte"

          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Cliente no visitado") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Carro Varado") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Siniestro del transportador") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }


        if (tarea.observaciones == "Horario") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }

        if (tarea.observaciones == "Temperatura") {

          this.txttipoNovedad = "Transporte"
          this.txtCanTerceros = this.txtCanTerceros + 1
          this.txtCosTerceros = this.txtCosTerceros + tarea.CostoMerc

        }


      }

      var zonasenvio = {
        detalle: item,
        name: item,
        CostoMerc: this.txtCanGrupo,
        Dev: this.txtDevGrupo,
        y: this.txtGrupoPedidos,
        Pedidos: this.txtGrupoPedidos,
        CanDevoluciones: this.txtGrupoDev,
        tipo: this.txttipoNovedad,
        Porcentaje: (this.txtGrupoDev * 100) / this.txtGrupoPedidos,
      };

      novedadesGeneral.push(zonasenvio)


    }
    this.datosNov = novedadesGeneral
    this.dataSourceNov.data = novedadesGeneral;
    var tipoenvio = {
      name: "Transporte",
      y: this.txtCosTerceros,
      Pedidos: this.txtCanTerceros,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Cliente",
      y: this.txtCosCliente,
      Pedidos: this.txtCanCliente,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Comercial",
      y: this.txtCosComercial,
      Pedidos: this.txtCanComercial,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Planta Chia",
      y: this.txtCosPlanta,
      Pedidos: this.txtCanPlanta,
    };
    tiposGeneral.push(tipoenvio)
    var tipoenvio = {
      name: "Terceros",
      y: this.txtCosTerceros,
      Pedidos: this.txtCanTerceros,
    };
    tiposGeneral.push(tipoenvio)

    this.datosTipoNov = tiposGeneral;
    this.dataSourceTipo.data = tiposGeneral;



    //this.getTipo(novedadesGeneral)

  }

  getTipo(res: any) {

    let tiposGeneral = []





    this.txtCanCliente = 0
    this.txtCanComercial = 0
    this.txtCanPlanta = 0
    this.txtCanTransporte = 0
    this.txtCanTerceros = 0

    this.txtCosCliente = 0
    this.txtCosComercial = 0
    this.txtCosPlanta = 0
    this.txtCosTransporte = 0
    this.txtCosTerceros = 0
    var tipoenvio: any;
    var tipoenvioCo: any;
    var tipoenvioTertipoenvioPla: any;
    var tipoenvioTer: any;
    for (let tiposapp of res) {

      if (tiposapp.tipo == "Transporte") {

        this.txtCanTransporte = this.txtCanTransporte + tiposapp.Pedidos
        this.txtCosTransporte = this.txtCosTransporte + tiposapp.CostoMerc

        tipoenvio = {
          name: tiposapp.tipo,
          y: this.txtCosTransporte,
          Pedidos: this.txtCanTransporte,
        };




      }

      if (tiposapp.tipo == "Cliente") {

        this.txtCanCliente = this.txtCanCliente + tiposapp.Pedidos
        this.txtCosCliente = this.txtCosCliente + tiposapp.CostoMerc
        var tipoenvioCli = {
          name: tiposapp.tipo,
          y: this.txtCosCliente,
          Pedidos: this.txtCanCliente,
        };



      }

      if (tiposapp.tipo == "Comercial") {

        this.txtCanComercial = this.txtCanComercial + tiposapp.Pedidos
        this.txtCosComercial = this.txtCosComercial + tiposapp.CostoMerc

        tipoenvioCo = {
          name: "Comercial",
          y: this.txtCosComercial,
          Pedidos: this.txtCanComercial,
        };



      }

      if (tiposapp.tipo == "Planta Chia") {

        this.txtCanPlanta = this.txtCanPlanta + tiposapp.Pedidos
        this.txtCosPlanta = this.txtCosPlanta + tiposapp.CostoMerc
        tipoenvioTertipoenvioPla = {
          name: tiposapp.tipo,
          y: this.txtCosPlanta,
          Pedidos: this.txtCanPlanta,
        };



      }

      if (tiposapp.tipo == "Terceros") {

        this.txtCanTerceros = this.txtCanTerceros + tiposapp.Pedidos
        this.txtCosTerceros = this.txtCosTerceros + tiposapp.CostoMerc

        tipoenvioTer = {
          name: tiposapp.tipo,
          y: this.txtCosTerceros,
          Pedidos: this.txtCanTerceros,
        };

      }


    }


  

    this.datosTipoNov = tiposGeneral;
  }


  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {


    const cValue = formatDate(event.value, 'yyyy-MM-dd', 'en-US');
   this.toastr.successToastr("Cambio de fecha "+cValue, 'Notificación');
    localStorage.setItem("pedidoFecha", cValue);

    localStorage.setItem("cargar", 'exitoso');
  
    this.descargar();

  }

  ngOnDestroy() {


  }

  atras():void {

    this.route.navigate(['/OrdenTransporte']);
    
  }

  clickExitosa():void {

   // this.route.navigate(['/TareasExitosas']);
    
  }

}

