import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-FragmentAuditoriaCiudad',
  templateUrl: './FragmentAuditoriaCiudad.component.html',
  styleUrls: ['./FragmentAuditoriaCiudad.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentAuditoriaCiudad implements OnInit {

  public iscargado = true;
  public ismenu = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
 
  constructor(private route: Router,private configuracionesService: ConfiguracionesService) {}

   displayedColumns: string[] = ['id','ciudad','fechahora','movimiento','usuario'];
   dataSource = new MatTableDataSource();


  ngOnInit(): void {

    this.descargarConfiguraciones()
 
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getAuditoriaCiudades().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;
     setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200); 
    
    });
  }


  atras():void {
    
    this.route.navigate(['/Ciudad']);

  }
 

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
 
  
}
