import { MatTableDataSource, MatPaginator } from '@angular/material';
import { OrdersService } from '../../shared/orders.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent, RemovingEventArgs } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { Dialog } from '@syncfusion/ej2-popups';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Ellipse } from 'pdfmake-wrapper';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fragmentIndicadoContent',
  templateUrl: './fragmentIndicadoContent.component.html',
  styleUrls: ['./fragmentIndicadoContent.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentIndicadoContent implements OnInit {

  gestión
  tabs = [
    { label: 'Gestión' },
    { label: 'Costo' },
    { label: 'Costo Devolución' },
    { label: 'Cumplimiento' },
    { label: 'Ubicación' }
  ];
  
  selectedTabIndex = 0;

  selectTab(index: number): void {
    this.selectedTabIndex = index;
  }


  Remove(args): void {
    args.cancel = true;
  }

  constructor(private route: Router,private orderService: OrdersService, private userd: AuthService, public toastr: ToastrManager) {

  }

  


  ngOnInit(): void {

    const currentDate = new Date();
    const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
 
    localStorage.setItem("pedidoFecha",cValue);

  
    if (localStorage.getItem('rutaindicador') == 'si') {

      localStorage.setItem("rutaindicador", "no");

      



    }

    if (localStorage.getItem('pedidoFecha').length > 3) {


      

    } else {

      const currentDate = new Date();
      const cValue = formatDate(currentDate, 'yyyy-MM-dd', 'en-US');
  
      localStorage.setItem("pedidoFecha", cValue);

    }

  


  }




}

