import { MatTableDataSource, MatPaginator } from '@angular/material';
import { FotosService } from '../../shared/fotos.service';
import { ActulizacionService } from '../../shared/actulizacion.service';
import { AuthService } from '../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import * as Mapboxgl from 'mapbox-gl';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { OrdersService } from 'src/app/shared/orders.service';
import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-fragmentFotoTarea',
  templateUrl: './fragmentFotoTarea.component.html',
  styleUrls: ['./fragmentFotoTarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})

@Injectable()
export class FragmentFotoTarea implements OnInit {
  mapa: Mapboxgl.Map
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('MatPaginator2', { static: false }) paginatordev: MatPaginator;
  @ViewChild('MatPaginator3', { static: false }) paginatorzona: MatPaginator;
  @ViewChild('MatPaginator4', { static: false }) paginatornov: MatPaginator;
  @ViewChild('MatPaginator5', { static: false }) paginatortipo: MatPaginator;
  @ViewChild('MatPaginator6', { static: false }) paginatorestado: MatPaginator;
  @ViewChild('MatPaginator7', { static: false }) paginatorruta: MatPaginator;
  @ViewChild('MatPaginator8', { static: false }) paginatorrutavalor: MatPaginator;
  @ViewChild('MatPaginator9', { static: false }) paginatorestadovalor: MatPaginator;
  public ubicaciones = new Array();
  private txtmestros: any;
  private txtlat: any;
  private txtlon: any;
  private txthora: any;
  private txttipo: any;
  private txtsitio: any;
  private cliente: any;
  private txtcli: any;
  private txtdircli: any;
  private txtdirrep: any;
  private txtfecha: any;
  private txtRuta: any;
  private txtEstado: any;
  private txtCausal: any;
  private clienteAp: any;
  private img: any;
  private imgfac: any;
  lat = 4.570329117;
  lng = -74.0768177;
   zoom: number = 5;
  subscriptionruta: Subscription;
  subscriptioncodigo: Subscription;
  myData = [
    { data: [1, 1, 3], name: "first" }
  ];
  public iconUrl = "/assets/trackrojo.svg";
  public iconUrlDos = "/assets/bexitosa.svg";
  private path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  Remove(args): void {
    args.cancel = true;
  }

  constructor(private mapsAPILoader: MapsAPILoader,private fotosservice: OrdersService, private clienteserveAprobar: ActulizacionService, private userd: AuthService, public toastr: ToastrManager, private route: Router) {

    this.cliente = localStorage.getItem('tareafotoid')

    localStorage.setItem("vistaShow", 'SHOWN');
    document.addEventListener("visibilitychange", function () {
      if (document.hidden) {
        //do whatever you want
        console.log("Hidden");
        localStorage.setItem("vistaShow", 'Hidden');

      }
      else {
        //do whatever you want
        console.log("SHOWN");
        localStorage.setItem("vistaShow", 'SHOWN');



      }

    });
  }

  onZoomChange() {
    this.zoom=16
}

  onMapReady(map) {
    map.setOptions({
        zoomControl: 'true',
        zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_CENTER,
            style: google.maps.ZoomControlStyle.DEFAULT
        }
    });

}

  ngOnInit(): void {

    this.cliente = localStorage.getItem('tareafotoid')

    console.log("tareafotoid",    this.cliente);

    this.fotosservice.getOrdersFoto(this.cliente).subscribe(clientesaprobar => {

      for (let elemento of clientesaprobar) {

        console.log(" no trae cliente)" + clientesaprobar);


        /*


        Mapboxgl.accessToken = environment.mapboxkey;
        this.mapa = new Mapboxgl.Map({
          container: 'mapa-mapbox', // container id
          style: 'mapbox://styles/mapbox/streets-v11'
        });

        */

   
      this.ubicaciones = []
      var market = {
        lat:    elemento.lat,
        lng:   elemento.lon,
        label: "Entrega",
        draggable: false,
        cliente: "Cliente",
        direccion: elemento.Direccion,
        valor:elemento.hora_reporte,
        iconUrl:this.iconUrlDos
      }
      

      this.lat=elemento.lat
      this.lng=elemento.lon

     this.ubicaciones.push(market)

      
     var market = {
      lat:    elemento.latTarea,
      lng:   elemento.lonTarea,
      label: "Entrega",
      draggable: false,
      cliente: "Reporte",
      direccion: elemento.direccion_geo,
      valor:elemento.hora_reporte,
      iconUrl:this.iconUrl
    }
    

    this.lat=elemento.lat
    this.lng=elemento.lon

    this.zoom=20



   this.ubicaciones.push(market)

        
        const currentDate = new Date();
   
        const cValue  = localStorage.getItem("ordenTransporteFecha");
 
        console.log("elemento fotos " + elemento);
        console.log("elemento lon " + elemento.lon)
        console.log("elemento lat " + elemento.lat)
  
        this.clienteAp = elemento

        this.txthora = elemento.hora_reporte
        this.txtlat = elemento.lat
        this.txtlon = elemento.lon
        this.txtsitio = elemento.sitio_reporte
        this.txttipo = "Foto"
        this.txtmestros = elemento.metros_reporte
        this.txtcli = elemento.NombredeCliente
        this.txtdircli = elemento.Direccion
        this.txtdirrep = elemento.direccion_geo
        this.txtfecha = elemento.fechaVL
        this.txtRuta= elemento.RutaVLI
        this.txtEstado= elemento.estado
        this.txtCausal= elemento.causal_estado

        
        this.img="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F"+cValue+"%2F"+localStorage.getItem("negocio")+"%2F"+localStorage.getItem("agencia")+"%2FE"+this.cliente +"1.jpg?alt=media"
        this.imgfac="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F"+cValue+"%2F"+localStorage.getItem("negocio")+"%2F"+localStorage.getItem("agencia")+"%2FE"+this.cliente +"2.jpg?alt=media"

      



      }

    });

    this.fotosservice.getOrdersFotoStrig(this.cliente).subscribe(clientesaprobar => {
      
      for (let elemento of clientesaprobar) {
        
    
  
        const currentDate = new Date();
   
        const cValue  = localStorage.getItem("pedidoFecha");
 
        console.log("elemento fotos " + elemento);
        console.log("elemento lon " + elemento.lon)
        console.log("elemento lat " + elemento.lat)

        this.clienteAp = elemento

        this.txthora = elemento.hora_envio
        this.txtlat = elemento.lat
        this.txtlon = elemento.lon
        this.txtsitio = elemento.sitio_reporte
        this.txttipo = "Foto"
        this.txtmestros = elemento.metros_reporte
        this.txtcli = elemento.NombredeCliente
        this.txtdircli = elemento.Direccion
        this.txtdirrep = elemento.direccion_geo
        this.txtfecha = elemento.fechaVL
        this.txtRuta= elemento.RutaVLI
        this.txtEstado= elemento.estado
        this.txtCausal= elemento.causal_estado
        console.log("elemento.negocio " + elemento.negocio)
        this.img="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F"+cValue+"%2F"+localStorage.getItem("negocio")+"%2F"+localStorage.getItem("agencia")+"%2FE"+this.cliente +"1.jpg?alt=media"
        this.imgfac="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/Fotos%2F"+cValue+"%2F"+localStorage.getItem("negocio")+"%2F"+localStorage.getItem("agencia")+"%2FE"+this.cliente +"2.jpg?alt=media"

       



        



      }

    });

  }

  clickedMarker(market: any) {
    
    console.log("market USUARIO",market);
    this.lat=market.lat
    this.lng=market.lon
    this.zoom=18
 

  }
  ngAfterViewInit() {

  }

  atras():void {

    this.route.navigate(['/Planilla']);
    
  }


}
