import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { ConfiguracionesService } from '../../../../../shared/configuraciones.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, ElementRef, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { Router } from '@angular/router';
import { DialogAgregarDepartamentos } from '../dialogAgregarDepartamentos/dialogAgregarDepartamentos.component';
import { DialogEliminarDepartamento } from '../dialogEliminarDepartamento/dialogEliminarDepartamento.component';
import { DialogEditarDepartamento } from '../dialogEditarDepartamento/dialogEditarDepartamento.component';

export interface DialogData {
  id: string;
  departamento: string;
}
@Component({
  selector: 'app-FragmentDepartamentos',
  templateUrl: './FragmentDepartamentos.component.html',
  styleUrls: ['./FragmentDepartamentos.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentDepartamentos implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  bancovalue: string;
  constructor(private route: Router,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogEliminar: MatDialog,public dialog: MatDialog,public dialogagregar: MatDialog) {

  
  }



   displayedColumns: string[] = ['id', 'departamento','editar','eliminar'];
   dataSource = new MatTableDataSource();

 
  ngOnInit(): void {

    this.descargarConfiguraciones()
    this.descargarConTipoDoc()  
  
  }

  
  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  descargarConfiguraciones() {

    this.configuracionesService.getDepartamentos().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

  descargarConTipoDoc() {

    this.configuracionesService.getConDepartamento().subscribe(tipos => {

      for (let tipo of tipos) {

           this.codigo=tipo.con
      
      
      }  

    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(tipo: any) {

    this.dialog.open(DialogEditarDepartamento, {
      width: '400px',
      data: {id: tipo.id,
        departamento:tipo.departamento},
    });

  }

  nuevo():void {
 
    this.dialogagregar.open(DialogAgregarDepartamentos, {
      width: '400px',
      data: {id: this.codigo},
    
    });
   
  
    }

  deletebanco(tipo: any) {

    this.dialogEliminar.open(DialogEliminarDepartamento, {
      width: '350px',
      data: {id: tipo.id,departamento:tipo.departamento},
    });

  }

  
  auditoria():void {

    this.route.navigate(['/AuditoriaDepartamentos']);
    
  }
  
}
