import { MatTableDataSource, MatPaginator, MatFormFieldModule } from '@angular/material';
import { TareasTemporalService } from '../../../shared/tareastemporal.service';
import { AuthService } from '../../../shared/services/auth.service';
import { Component, ViewChild, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { UploaderComponent } from '@syncfusion/ej2-angular-inputs';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import * as XLSX from 'xlsx';
import { Dialog } from '@syncfusion/ej2-popups';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';

@Component({
  selector: 'app-FragmentDetalleOperacion',
  templateUrl: './FragmentDetalleOperacion.component.html',
  styleUrls: ['./FragmentDetalleOperacion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class FragmentDetalleOperacion implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('defaultupload', { read: UploaderComponent, static: false })
  public uploadObj: UploaderComponent;

  @ViewChild('grid', { read: GridComponent, static: false })
  public gridObj: GridComponent;
  public dialog: Dialog;
  public dialogcarga: Dialog;
  public datas;
  public negocio: any;
  public agencia: any;
  public usuario: any;
  public pesoKG: any=0;
  public tareasCrearOrden: any;
  public pedidosCantidad: any=0;
  public rutasTemporales: any=0;
  public zonasTemporales: any=0;
  public numeroOperacion: any=0;
  public negocioAgencia: any="";
  public fechaTareasTemporal: any= localStorage.getItem("pedidoFecha");
  public iscomercial = false;
  private valorTotalTareasTemporal: any=0;
  public isaprobar = false;
  
  public path: Object = {
    saveUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Save',
    removeUrl: 'https://aspnetmvc.syncfusion.com/services/api/uploadbox/Remove'
  };

  public dropElement: HTMLElement = document.getElementsByClassName('control-fluid')[0] as HTMLElement;

  public onFile

  Remove(args): void {
    args.cancel = true;
  }

  public cats = [];

  cards = [71, 78, 39, 66];
  myDate = new Date();

  constructor(private orderService: TareasTemporalService, private userd: AuthService, public toastr: ToastrManager, private route: Router) {

  }

  displayedColumns: string[] = ['NumeroPed', 'Ruta', 'Usuario', 'NombredeCliente', 'Direccion',
    'CostoMerc'];
  dataSource = new MatTableDataSource();

  displayedColumnstotal: string[] = ['Costo'];
  dataSourcetotal = new MatTableDataSource();

  ngOnInit(): void {

    this.descargar()
    this.rutasTemporales=0;
  

    this.fechaTareasTemporal = localStorage.getItem("pedidoFecha");

    this.dialog = new Dialog({
      // Enables the header
      header: 'ERROR VALIDAR FORMATO',
      // Enables the close icon button in header
      showCloseIcon: true,
      visible: false,
      // Dialog content

      // The Dialog shows within the target element
      target: document.getElementById("container"),
      // Dialog width
      width: '500px',
      height: '1000px'

    });

    this.dialog.appendTo('#dialog');
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }


  getcreate(order: any) {
    this.orderService.createTareaTemporal(order);
  }


  parseExcel(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });


      workbook.SheetNames.forEach((function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);

        let data = JSON.parse(json_object);

        const cValue = localStorage.getItem("pedidoFecha");

        this.fecha = localStorage.getItem("pedidoFecha");
        var i = 0;

        this.userd.getauth().subscribe(res => {
          res;
        
          var dataemail;
          this.orderService.getuserweb(res.email).subscribe(res => {
            dataemail = res;

            for (let elemento of dataemail) {
              localStorage.setItem("negocio", elemento.negocio);
              localStorage.setItem("agencia", elemento.agencia);
        

              this.negocioAgencia=elemento.negocio

            };


            this.valorTotalTareasTemporal = 0;
            this.pesoKG=0;
            this.pedidosCantidad=0;
            var groupBy = function (xs, key) {
              return xs.reduce(function (rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
              }, {});
            };
            var rutasTareas = groupBy(data, 'Ruta')
            var zonasareas = groupBy(data, 'Zona')
            this.rutasTemporales=0;
            this.zonasTemporales=0;

            for (let item of Object.keys(rutasTareas)) {
           
              this.rutasTemporales = this.rutasTemporales + 1
            }

            for (let item of Object.keys(zonasareas)) {
           
              this.zonasTemporales = this.zonasTemporales + 1
            }

            this.dataSource.data = data;

            this.tareasCrearOrden=data

            for (let tarea of data) {

              this.pedidosCantidad = this.pedidosCantidad+1 
              this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
              this.pesoKG = this.pesoKG + tarea["Peso neto"]
          
          
            }

            this.isaprobar=true


          });


        });



        this.uploadObj.clearAll();



      }).bind(this), this);
    };


    
    reader.onerror = function (ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  public onSuccess(args: any): void {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);
  }

  importFile(e) {
    this.dialog.show();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  descargar() {
    this.orderService.getConsecutivo().subscribe(consecutivos => {

      for (let item of consecutivos) {
           
        var numeroSiguiente=1
        numeroSiguiente=numeroSiguiente+item.conoperacion
        this.numeroOperacion="OP"+numeroSiguiente

      }      
      

    });
  }


  crearOperacion():void {

    this.toastr.successToastr('Carga en progreso.', 'Notificación');
    const cValue = localStorage.getItem("pedidoFecha");

    this.pedidosCantidad=0;


    for (let tarea of this.tareasCrearOrden) {

      this.pedidosCantidad = this.pedidosCantidad+1 
      this.valorTotalTareasTemporal =  this.valorTotalTareasTemporal + tarea.CostoMerc;
      this.pesoKG = this.pesoKG + tarea["Peso neto"]
      tarea.idpedido = 'Pendiente';
      tarea.estado = 'Pendiente';
      tarea.observaciones = '';
      tarea.codigoOperacion=  this.numeroOperacion
      tarea.codigoPlanilla=  this.numeroOperacion+"RT"+tarea.Ruta
      tarea.codigoRegistro= this.numeroOperacion+"TA"+tarea.NumeroTarea
      tarea.fechaVL = cValue;
      tarea.negocio = localStorage.getItem("negocio");
      tarea.agencia = localStorage.getItem("agencia");  
     
      this.orderService.numeroRegistro(tarea);


    }

    this.orderService.incrementarCodigo(this.numeroOperacion+1);
  }
  
}
