
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { TareasTemporalService } from '../../../../shared/tareastemporal.service';
import { Router } from '@angular/router';
export interface DialogData {
  banco: string;
  convenio: string;
  cuenta: string;
  fecha: string;
  nombre: string;
  recibo: string;
  referenciaBanco: string;
  tipo: string;
  valor: string;
}



@Component({
  selector: 'app-DialogAprobarConsignacion',
  templateUrl: './DialogAprobarConsignacion.component.html',
  styleUrls: ['./DialogAprobarConsignacion.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class DialogAprobarConsignacion implements OnInit {

  private imgfac: any;
  private banco: any;
  private convenio: any;
  private cuenta: any;
  private fecha: any;
  private nombre: any;
  private recibo: any;
  private referenciaBanco: any;
  private tipo: any;
  private valor: any;
 
  constructor(public dialogRef: MatDialogRef<DialogAprobarConsignacion>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogData,private configuracionesService: TareasTemporalService, public toastr: ToastrManager,private route: Router) {}


  ngOnInit(): void {

    this.imgfac="https://firebasestorage.googleapis.com/v0/b/appvlbogota-43289.appspot.com/o/FotosCosiganciones%2F"+this.data.fecha+"%2FR"+this.data.recibo +"?alt=media"

    this.banco=this.data.banco
    this.convenio=this.data.convenio
    this.cuenta=this.data.cuenta
    this.fecha=this.data.fecha
    this.nombre=this.data.nombre
    this.recibo=this.data.recibo
    this.referenciaBanco=this.data.referenciaBanco
    this.tipo=this.data.tipo
    this.valor=this.data.valor
    
  }

  onNoClick(): void {
    console.log('data',this.data);
    this.dialogRef.close();
   
  
  }
 
  crearBanco(data: any) {
     
    var auditria = {
      estado:"En Ruta",
      planilla:data.planilla,
    }
    this.dialogRef.close();
    this.configuracionesService.createupdate(auditria)

    localStorage.setItem("codigoplanilla", data.planilla);
    this.route.navigate(['/Planilla']);

  }
  
}
