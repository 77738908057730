
import { ConfiguracionesService } from '../../../../shared/configuraciones.service';
import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';
import { MatDialog, MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog'
import { formatDate } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { DialogAgregarTercero } from '../../tercero/dialogAgregarTercero/dialogAgregarTercero.component';
import { Router } from '@angular/router';
import { DialogAgregarConductor } from '../dialogAgregarConductor/dialogAgregarConductor.component';

export interface DialogDataVh {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
}

@Component({
  selector: 'app-dialogAgregarPropietarioVehiculo',
  templateUrl: './dialogAgregarPropietarioVehiculo.component.html',
  styleUrls: ['./dialogAgregarPropietarioVehiculo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
@Injectable()
export class dialogAgregarPropietarioVehiculo implements OnInit {
  id: string;
  nombres: string;
  apellidos: string;
  tipoDoc: string;
  identificacion: string;
  estado: string;
  idTp: string;
  public marcas;
  public marcafm;
  public confifm;
  public confisvh;
  public estados;
  public soatvenfm;
  public tecnovenfm;
  public modelos;
  public clases;
  public modelofm;
  public imgEstadofm;
  public estadofm;
  public clasefm;
  public codigo;
  public iscargado = true;
  public ismenu = false;
  ismenudialog=false
  
  constructor(public dialogRef: MatDialogRef<dialogAgregarPropietarioVehiculo>, 
     @Inject(MAT_DIALOG_DATA) public data: DialogDataVh,private configuracionesService: ConfiguracionesService, public toastr: ToastrManager,public dialogagregar: MatDialog,public dialogagregarVh: MatDialog,private route: Router) {}
     @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
     displayedColumns: string[] = ['imgEstado','nombrePropietario','placa','linea','clase','estado'];
     dataSource = new MatTableDataSource();

  ngOnInit(): void {

    this.descargarCodigoTransportador()
    this.descargarVehiculos()
 
  }

  ngAfterViewInit() {

    this.dataSource.paginator = this.paginator;

  }

  onNoClick(): void {
    console.log('cerrado');
    this.dialogRef.close();
  
  }



  onNuevo(): void {
    this.dialogRef.close();
    this.route.navigate(['/Vehiculos']);
  
  }

 

  vehiculo(data: any) {
   
    this.dialogRef.close();

    var conop = {
      con: this.codigo+1

    }

    if(data.id) {

      if(data.id) {

        localStorage.setItem("idTransportador", this.codigo );

        var tipoDoc = {
          idVehiculo: data.id,
          idTercero:data.idTercero,
          idPropietario:data.idPropietario,
          nombrePropietario:data.nombrePropietario,
          apellidosPropietario:data.apellidosPropietario,
          placa:data.placa,
          idTransportador:this.codigo,
          clase:data.clase,
          linea:data.linea,
          configuracion:data.configuracion,
          marca:data.marca,
          idTerceroTenedor: data.idTerceroTenedor,
          idTenedor: data.idTenedor,
          nombreTenedor: data.nombreTenedor,
          apellidosTenedor: data.apellidosTenedor,
          agencia:localStorage.getItem("agencia"),
          negocio:localStorage.getItem("negocio"),
          usuario:"TPE"+this.codigo,
       
        }
  
        this.configuracionesService.incrementarcontransportador(conop)
      
        console.log('tipodocumento',tipoDoc);
        var hoy = new Date();
        var minuto=  hoy.getMinutes() 
        var segundos=hoy.getSeconds()
    
        var minutefinal="00"
        var segundofinal="00"
        if(minuto<10){
    
          minutefinal="0"+minuto
        }else{
          minutefinal=String(minuto)
        }
        if(segundos<10){
    
          segundofinal="0"+segundos
        }else{
          segundofinal=String(segundos)
        }
        var hora = hoy.getHours() + ':' + minutefinal + ':' + segundofinal;
        var auditria = {
          id:data.id,
          usuario:  localStorage.getItem("responsable"),
          hora:  hora,
          fechahora: formatDate(hoy, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          fecha: formatDate(hoy, 'yyyy-MM-dd', 'en-US'),
          movimiento:  'Agregar',
         
        }
    
        //this.configuracionesService.crearAuditriaVehiculo(auditria)
        this.configuracionesService.crearTransporteTemporal(tipoDoc)
        this.toastr.successToastr("Tercero:  creado con éxito", 'Notificación');
        this.dialogRef.close();

        this.route.navigate(['/Transporte']);
       

      }else{
        this.toastr.errorToastr("Tercero Vehículo", 'Notificación');
  
      } 

    }else{
      this.toastr.errorToastr("Tercero Vehículo", 'Notificación');

    } 

   
  }

  descargarVehiculos() {

    this.configuracionesService.getRegistroVehiculos().subscribe(configuraciones => {
  
      this.dataSource.data = configuraciones;

      setTimeout(() => {
        this.iscargado = false;
        this.ismenu = true;
       }, 200);

    
  
    
    });
  }

 
  descargarCodigoTransportador() {

    this.configuracionesService.getConTransporte().subscribe(tipoDocus => {

      for (let tipo of tipoDocus) {

           this.codigo=tipo.con
           console.log(' this.codigo', this.codigo);
      
      }  

    });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
}
