import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../../shared/services/auth.service';
import { UserI } from '../../../modules/user.interface'
import { OrdersService } from '../../../shared/orders.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authSvc: AuthService, private route: Router,private orderService: OrdersService) {}

  
  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });


  ngOnInit() {

    this.authSvc
   // this.authSvc.logout();

   if(localStorage.getItem('correo')=='pendiente'){


   }else{

    this.route.navigate(['/Maestros']);



   }


    
  }

  logout() {
    this.authSvc.logout();
   
}

  onLogin(form: UserI) {

    localStorage.clear();
    this.authSvc.logout();


    
    this.authSvc
      .loginByEmail(form)
      .then(res =>{

        localStorage.setItem("correo",  res.user.email);
        localStorage.setItem("login",  'pendiente');
        var data ;


        this.orderService.getuserweb(res.user.email).subscribe(res => {
           data = res;

           for (let elemento of data) {
            localStorage.setItem("negocio",  elemento.negocio);
            localStorage.setItem("agencia",  elemento.agencia);
          
            console.log(elemento);
            
           }



           if(localStorage.getItem('correo')=='autoventagloria@vli.com.co'){


            this.route.navigate(['/Maestros']);


         
            this.route.navigate(['/Maestros']);



           }else if(localStorage.getItem('correo')=='autoventa@vli.com.co'){

           
            this.route.navigate(['/Maestros']);


          
            this.route.navigate(['/Maestros']);



           }else{

    
           if(localStorage.getItem('correo')=='ju.herrera@gloria.com.co'){

       
            this.route.navigate(['/Gloria']);




          
            this.route.navigate(['/Gloria']);



           }else{



           if(localStorage.getItem('correo')=='distritolima@vli.com'){

           
            this.route.navigate(['/Clientes']);



           }else{




           if(localStorage.getItem('correo')=='gloriabogota@vli.com.co'){

   
            this.route.navigate(['/desabogota']);



           }else{
          

           if(localStorage.getItem('negocio')=='VLI')
           {
            
            this.route.navigate(['/desadmin']);
           }
    
           if(localStorage.getItem('negocio')!='VLI')
           {
          
            this.route.navigate(['/OrdenTransporte']);
           }

          }
        }

    

      }
      }
          

        });
        
      
       
      } )
      .catch(res =>(console.log('Error',res)))
      
  }


}
