import { Component, OnInit, Input,SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
import { OrdersService } from '../../orders.service';
import { AuthService } from '../../services/auth.service';
import { Options } from "highcharts";
import { Subscription } from 'rxjs';
import { formatDate } from '@angular/common';
import { ClientesService } from '../../clientes.service';
import { PedidoService } from '../../pedidos.service';
import { IndicadoresService } from '../../indicadores.service';
@Component({
  selector: 'app-widget-barrasIndicador',
  templateUrl: './barrasIndicador.component.html',
  styleUrls: ['./barrasIndicador.component.scss']

})
export class BarrasIndicadorComponent implements OnInit {
  subscription: Subscription;
  @Input() data: any = [];
  updateFlag = false;

  myConfiges: any
  estadoPendiente=0
  estadoExitosa=0
  estadoNovedad=0
  estadoParcial=0
  estadoRepisar=0
  estadoNovedadFallida=0
  txtentregas=0
  private tareasCantidadPlanilla: any;
  private tareasCodigoPlanilla: any;
  private tareasPendientesPlanilla: any; 
  private tareasExitosasPlanilla: any; 
  private tareasParcialesPlanilla: any; 
  private tareasRepisarPlanilla: any; 
  private tareasNovedadPlanilla: any; 
  private tareasFallidaPlanilla: any; 
  private tareasCostoPlanilla: any; 
  private tareasPesoPlanilla: any; 
  constructor(private ClientesService: ClientesService,private pedidoService: PedidoService,private indicadoresService: IndicadoresService) { }


  ngOnInit(): void {

   this.descargar()

  }



  descargar() {
    this.subscription = this.indicadoresService.getIndicadores().subscribe(tareasindicadores => {
   
      console.log("tareasindicadores",tareasindicadores);
      this.estadoPendiente=0
      this.estadoExitosa=0
      this.estadoParcial=0
      this.estadoRepisar=0
      this.estadoNovedadFallida=0
    
      this.txtentregas=0



    var groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    let planillas = groupBy(tareasindicadores, 'codigoPlanilla')
    console.log("planillas",planillas);

      for (let tarea of tareasindicadores) {
        this.txtentregas = this.txtentregas + 1
  

        if(tarea.estado=="PENDIENTE"){

          this.estadoPendiente=this.estadoPendiente+1
      
         }

         if(tarea.estado=="EXITOSA"){

          this.estadoExitosa=this.estadoExitosa+1
      
         }


         if(tarea.estado=="NOVEDAD"){

          this.estadoNovedad=this.estadoNovedad+1
          this.estadoNovedadFallida=this.estadoNovedadFallida+1

         }


         if(tarea.estado=="REPISAR"){

          this.estadoNovedad=this.estadoNovedad+1
          this.estadoRepisar=this.estadoRepisar+1
         }

         if(tarea.estado=="PARCIAL"){

          this.estadoNovedad=this.estadoNovedad+1
          this.estadoParcial=this.estadoParcial+1
         }

      }

      let tareasPanillasUsuarios = []
      let PanillasUsuarios = []
      let avancePanillasUsuarios = []
      for (let item of Object.keys(planillas)) {
        var planillasdia = planillas[item];
        let tareasplanilla = tareasindicadores.filter(tarea => tarea.codigoPlanilla == planillasdia[0].codigoPlanilla)
   
        console.log( planillasdia[0].codigoPlanilla,tareasplanilla);
        PanillasUsuarios.push(planillasdia[0].codigoPlanilla)
        this.tareasCantidadPlanilla=0
        this.tareasPendientesPlanilla=0
        this.tareasExitosasPlanilla=0
        this.tareasParcialesPlanilla=0
        this.tareasRepisarPlanilla=0
        this.tareasNovedadPlanilla=0
        this.tareasFallidaPlanilla=0
        this.tareasCostoPlanilla=0
        this.tareasPesoPlanilla=0
        for (let tarea of tareasplanilla) {

          this.tareasCantidadPlanilla=  this.tareasCantidadPlanilla+1
          this.tareasCodigoPlanilla=planillasdia[0].codigoPlanilla
          this.tareasCostoPlanilla=this.tareasCostoPlanilla+tarea.CostoMerc
          this.tareasPesoPlanilla=this.tareasPesoPlanilla+tarea.PesoNeto
          if(tarea.estado=="PENDIENTE"){
            this.tareasPendientesPlanilla=this.tareasPendientesPlanilla+1
           }

           if(tarea.estado=="EXITOSA"){

            this.tareasExitosasPlanilla=this.tareasExitosasPlanilla+1
        
           }
  
  
           if(tarea.estado=="NOVEDAD"){
  
            this.tareasNovedadPlanilla=this.tareasNovedadPlanilla+1
            this.tareasFallidaPlanilla=this.tareasFallidaPlanilla+1
  
           }
  
  
           if(tarea.estado=="REPISAR"){
  
            this.tareasRepisarPlanilla=this.tareasRepisarPlanilla+1
            this.tareasNovedadPlanilla=this.tareasNovedadPlanilla+1
           }
  
           if(tarea.estado=="PARCIAL"){
  
            this.tareasNovedadPlanilla=this.tareasNovedadPlanilla+1
            this.tareasParcialesPlanilla=this.tareasParcialesPlanilla+1
           }


        }
  
    
        var total=this.tareasCantidadPlanilla-this.tareasPendientesPlanilla

        avancePanillasUsuarios.push(total)
        tareasPanillasUsuarios.push(this.tareasCantidadPlanilla)
       
        console.log("tareasPanillasUsuarios",tareasPanillasUsuarios);

        this.myConfiges =  { // chart configuration
          type: 'vbullet',
          scaleX: {
            labels: PanillasUsuarios
          },
          tooltip: { // tooltip changes based on value
            fontSize: 12,
            borderRadius: 1,
            borderWidth: 0,
            shadow: true
          },
          plot: {
            valueBox: [{
              type: 'all',
              color: '#000',
              placement: 'goal',
              text: '[%node-value / %node-goal-value]'
            }]
          },
          series: [{
            dataDragging: true, // need this to enable drag
            values: avancePanillasUsuarios,
            goals: tareasPanillasUsuarios,
            goal: {
              backgroundColor: '#64b5f6',
              borderWidth: 0,
            },
            rules: [ // rules for color
              {
                rule: '%v >= %g', // if greater than goal
                backgroundColor: '#468189'
              },
              {
                rule: '%v < %g/2', // if less than half goal
                backgroundColor: '#ee630d'
              },
              {
                rule: '%v >= %g/2 && %v < %g', // if in between
                backgroundColor: '#fbca4f'
              }
            ]
          }]
        };
      }
      
    });
  }
}
