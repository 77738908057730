import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})

export class ContactosService {

  collection(arg0: string) {
    throw new Error("Method not implemented.");
  }

  private contactosCollection: AngularFirestoreCollection<any>;
  private contactos: Observable<any[]>;

  constructor(private readonly db: AngularFirestore, private userd: AuthService) {


  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + ''+ EXCEL_EXTENSION);
  }


  negocio = localStorage.getItem("negocio");
  agencia = localStorage.getItem("agencia");
  fecha = localStorage.getItem("pedidoFecha");


   //*  consultar los clientes del maestro //
  getContactos() {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.contactosCollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "contactos", ref => ref.orderBy('id_cnto'));
    this.contactos = this.contactosCollection.snapshotChanges().pipe(map(
      actions => actions.map(a => {
        const data = a.payload.doc.data() as any;
        const id = a.payload.doc.id;
        return { ...data };
      })
    ));

    return this.contactos;


  }

     //*  consultar los clientes del maestro //
     getContactosIemp(idcliente) {

      this.negocio = localStorage.getItem("negocio");
      this.agencia = localStorage.getItem("agencia");
      this.fecha = localStorage.getItem("pedidoFecha");
      this.contactosCollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "contactos", ref => ref.where('id_empresa','==',idcliente));
      this.contactos = this.contactosCollection.snapshotChanges().pipe(map(
        actions => actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { ...data };
        })
      ));
  
      return this.contactos;
  
  
    }

  createContacto(cliente: any) {

    this.negocio = localStorage.getItem("negocio");
    this.agencia = localStorage.getItem("agencia");
    this.fecha = localStorage.getItem("pedidoFecha");
    this.contactosCollection = this.db.collection<any>('maestrosODL/' + this.negocio + '_' + this.agencia + '/' + "contactos");
   
    return this.contactosCollection.doc( cliente.codigo).set(cliente);

  }


}


